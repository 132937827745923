import React, { useState, useEffect, useReducer } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  FormGroup,
  InputGroup,
  Alert,
} from "react-bootstrap";
import Select from "react-select";
import config from "@Config/app.config";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Data_Parner } from "./data";
//import img from "../../assets/img/bank3";
import { ExtralargeModal } from "./addparner";
import Apisetting from "../../api/Apisetting";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import SettingAPI from "../api/SettingAPI";

const Setting_partner = () => {
  const [basic, setbasic] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const [smShowt, setSmShowt] = useState("");
  const [lgShow, setLgShow] = useState(false);
  const [gridshow, setGridshow] = useState(false);
  const [gridshow1, setGridshow1] = useState(false);

  const viewDemoClose = () => {
    setbasic(!basic);
  };

  const viewDemoClose2 = () => {
    return 1;
  };

  useEffect(() => {
    getapisetting();
    get_datapraner();
  }, []);

  const getapisetting = () => {
    SettingAPI.getdatasetting_fontend()
      .then((res) => {
        console.log(res.data);
        //
        setState2({
          data: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [state2, setState2] = useReducer(
    (state2: any, newState2: any) => ({ ...state2, ...newState2 }),
    {
      data: [],
    }
  );
  // Postcreate_partner

  const get_datapraner = () => {
    SettingAPI.get_datapraners()
      .then((res) => {
        console.log(res.data);
        //
        setState21({
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [state21, setState21] = useReducer(
    (state21: any, newState21: any) => ({ ...state21, ...newState21 }),
    {
      data: [],
    }
  );

  const [valuedd, setvaluedd] = React.useState("");

  const handleOnchange = (data: any) => {
    setvaluedd(data.id);
    // console.log(data)
    // setvalue(value);
  };
  const validationContactForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      password: "",
      password2: "",
      phone: "",
      partner_ref: "",
      partner_x: "",
      name: "",
      accnum: "",
      bank_id: "",
      linkpartner: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("กรุณาตั้งชื่อโปรโมชั่น"),
      phone: Yup.string().required("กรุณา ระบุ คำอธิบายของโปรโมชั่น"),
      partner_ref: Yup.string().required("กรุณา ระบุ จำนวนเทริน"),
      partner_x: Yup.string().required("กรุณา ระบุ จำนวนเปอร์เซนที่ได้รับ"),
      // bank_id: Yup.string().required("กรุณา ระบุ จำนวน เครดิต รับได้ไม่เกิน"),
      name: Yup.string().required("กรุณา ระบุ จำนวน ถอนได้สูงสุด"),
      accnum: Yup.string().required("กรุณา ระบุ จำนวน ถอนได้สูงสุด"),
      // password: Yup.string()
      //   .required("กรุณากรอกรหัสผ่าน")
      //   .min(8, "รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร"), // เพิ่มการตรวจสอบความยาว
    }),

    onSubmit: (values) => {
    
      let datapost = {
        username: values.username,
        password: values.password,
        //  password2: values,
        phone: values.phone,
        partner_ref: values.partner_ref,
        partner_x: values.partner_x,
        name: values.name,
        accnum: values.accnum,
        bank_id: valuedd,
        linkpartner: values.linkpartner,
      };
     
       SettingAPI.Postcreate_partner(datapost)
         .then((res) => {

          //console.log(res.data)
          if (res.data.success) {
            toast.success(
              <p className="text-white tx-16 mb-0">
                {`เพิ่มโปรโมชั่น  Successfully`}
              </p>,
              {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
                autoClose: 5000,
                theme: "colored",
              }
            );
            setTimeout(function () {
              window.location.reload();
            }, 2500);
          } else {
            //   setSuccessMessage("");
          }
         })
         .catch(function (error) {
          console.log("error ", error);
        });
    },
  });

  const coppy = () => {
    setGridshow(true);
    setTimeout(function () {
      setGridshow(false);
    }, 1500);
  };

  const options = [
    {
      id: 1,
      bank_id: "bbl",
      bankNameEn: "BANGKOK BANK",
      bankNameTh: "ธนาคารกรุงเทพ",
      bank_name: "กรุงเทพ",
      accountLength: 10,
      kbank_code: "003",
      scb_code: "002",
      url_pic: `${config.apiURL}/storage/bank3/bbl.png`,
      deleted_at: null,
      created_at: "2023-05-11T08:28:34.000Z",
      updated_at: null,
    },
    {
      id: 2,
      bank_id: "scb",
      bankNameEn: "Siam Commercial Bank",
      bankNameTh: "ธนาคารไทยพาณิชย์",
      bank_name: `https://shadow-fiends.com/public/bank3/bank2/scb.png`,
      accountLength: 10,
      kbank_code: "010",
      scb_code: "014",
      // url_pic: `${config.apiURL}/storage/bank3/scb.png`,
      url_pic: `${config.apiURL}/storage/bank3/scb.png`,
      deleted_at: null,
      created_at: "2023-05-11T08:32:23.000Z",
      updated_at: null,
    },
    {
      id: 3,
      bank_id: "kbank",
      bankNameEn: "KASIKORNBANK",
      bankNameTh: "ธนาคารกสิกรไทย",
      bank_name: "ธนาคารกสิกรไทย",
      accountLength: 10,
      kbank_code: "001",
      scb_code: "004",
      url_pic: `${config.apiURL}/storage/bank3/kbank.png`,
      deleted_at: null,
      created_at: "2023-05-11T08:33:50.000Z",
      updated_at: null,
    },
    {
      id: 4,
      bank_id: "bay",
      bankNameEn: "BANK OF AYUDHYA",
      bankNameTh: "ธนาคารกรุงศรีอยุธยา",
      bank_name: "ธนาคารกรุงศรีอยุธยา",
      accountLength: 10,
      kbank_code: "017",
      scb_code: "025",
      url_pic: `${config.apiURL}/storage/bank3/bay.png `,
      deleted_at: null,
      created_at: "2023-05-11T08:35:20.000Z",
      updated_at: null,
    },
    {
      id: 5,
      bank_id: "ktb",
      bankNameEn: "KRUNG THAI BANK",
      bankNameTh: "ธนาคารกรุงไทย",
      bank_name: "กรุงไทย",
      accountLength: 10,
      kbank_code: "004",
      scb_code: "006",
      url_pic: `${config.apiURL}/storage/bank3/ktb.png`,
      deleted_at: null,
      created_at: "2023-05-11T08:36:38.000Z",
      updated_at: null,
    },
    {
      id: 6,
      bank_id: "tmb",
      bankNameEn: "TMBThanachart Bank PCL",
      bankNameTh: "ธนาคารทหารไทยธนชาต จำกัด (มหาชน",
      bank_name: "ทหารไทย",
      accountLength: 10,
      kbank_code: "007",
      scb_code: "011",
      url_pic: `${config.apiURL}/storage/bank3/ttb.png`,
      deleted_at: null,
      created_at: "2023-05-11T08:37:07.000Z",
      updated_at: null,
    },
    {
      id: 8,
      bank_id: "gsb",
      bankNameEn: "Government Savings Bank",
      bankNameTh: "ธนาคารออมสิน",
      bank_name: "ออมสิน",
      accountLength: 15,
      kbank_code: "022",
      scb_code: "030",
      url_pic: `${config.apiURL}/storage/bank3/gsb.png`,
      deleted_at: null,
      created_at: "2023-05-11T08:40:57.000Z",
      updated_at: null,
    },
    {
      id: 9,
      bank_id: "baac",
      bankNameEn: "Bank for Agriculture and Agricultural Cooperatives",
      bankNameTh: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
      bank_name: "เพื่อการเกษตรและสหกรณ์การ",
      accountLength: 12,
      kbank_code: "026",
      scb_code: "034",
      // url_pic: "https://rtimage.kasikornbank.com/LogoBank/BAAC.png",
      url_pic: `${config.apiURL}/storage/bank3/baac.png`,
      deleted_at: null,
      created_at: "2023-05-11T08:41:37.000Z",
      updated_at: null,
    },
    {
      id: 10,
      bank_id: "uob",
      bankNameEn: "United Overseas Bank (Thai)",
      bankNameTh: "ธนาคารยูโอบี",
      bank_name: "ยูโอบี",
      accountLength: 10,
      kbank_code: "016",
      scb_code: "024",
      //url_pic: "https://rtimage.kasikornbank.com/LogoBank/UOB.png",
      url_pic: `${config.apiURL}/storage/bank3/uob.png`,

      deleted_at: null,
      created_at: "2023-05-11T08:42:58.000Z",
      updated_at: null,
    },
    {
      id: 11,
      bank_id: "tisco",
      bankNameEn: "Tisco Bank Public Company Limited",
      bankNameTh: "ธนาคารทิสโก้",
      bank_name: "ทิสโก้",
      accountLength: 14,
      kbank_code: "029",
      scb_code: "067",
      // url_pic: "https://rtimage.kasikornbank.com/LogoBank/TSCO.png",
      url_pic: `${config.apiURL}/storage/bank3/tsco.png`,

      deleted_at: null,
      created_at: "2023-05-11T08:43:34.000Z",
      updated_at: null,
    },
    // {
    //   id: 12,
    //   bank_id: "cimb",
    //   bankNameEn: "CIMB Bank",
    //   bankNameTh: "ธนาคารซีไอเอ็มบี",
    //   bank_name: "ซีไอเอ็มบีไทย",
    //   accountLength: 10,
    //   kbank_code: "018",
    //   scb_code: "022",
    // //  url_pic: "https://rtimage.kasikornbank.com/LogoBank/CIMB.png",
    // url_pic: `${config.apiURL}/storage/bank3/cimb.jpg`,
    //   deleted_at: null,
    //   created_at: "2023-05-11T08:44:37.000Z",
    //   updated_at: null,
    // },
    // {
    //   id: 13,
    //   bank_id: "lhb",
    //   bankNameEn: "Land and Houses Bank",
    //   bankNameTh: "ธนาคารแลนด์ แอนด์ เฮ้าส์",
    //   bank_name: "แลนด์แอนด์เฮ้าส์",
    //   accountLength: 10,
    //   kbank_code: "020",
    //   scb_code: "073",
    //   url_pic: "https://rtimage.kasikornbank.com/LogoBank/LHBANK.png",
    //   deleted_at: null,
    //   created_at: "2023-05-11T08:45:27.000Z",
    //   updated_at: null,
    // },
    // {
    //   id: 14,
    //   bank_id: "TRUE",
    //   bankNameEn: "truewallet",
    //   bankNameTh: "ทรูวอเล็ต",
    //   bank_name: "https://img.fenix-888.com/bank3/truewallet.png",
    //   accountLength: 10,
    //   kbank_code: "TRUE",
    //   scb_code: "TRUE",
    //   url_pic: "../../",
    //   deleted_at: null,
    //   created_at: "2023-05-11T08:46:24.000Z",
    //   updated_at: null,
    // },
    // {
    //   id: 15,
    //   bank_id: "Bonus",
    //   bankNameEn: "Bonus",
    //   bankNameTh: "โบนัส",
    //   bank_name: "https://img.fenix-888.com/bank3/gift.png",
    //   accountLength: 10,
    //   kbank_code: "000",
    //   scb_code: "000",
    //   url_pic: "https://img.fenix-888.com/bank3/gift.png",
    //   deleted_at: null,
    //   created_at: "2024-01-22T19:33:38.000Z",
    //   updated_at: null,
    // },
    // {
    //   id: 16,
    //   bank_id: "truewallet",
    //   bankNameEn: "truewallet",
    //   bankNameTh: "ทรูวอเล็ต",
    //   bank_name: "https://img.fenix-888.com/bank3/truewallet.png",
    //   accountLength: 10,
    //   kbank_code: "TRUE",
    //   scb_code: "TRUE",
    //   url_pic: "https://img.fenix-888.com/bank3/truewallet.png",
    //   deleted_at: null,
    //   created_at: "2023-05-11T08:46:24.000Z",
    //   updated_at: null,
    // },
  ];
  return (
    <>
      <div>
        {/* <!-- breadcrumb --> */}
        <div className="breadcrumb-header justify-content-between">
          <div className="left-content">
            <span className="main-content-title mg-b-0 mg-b-lg-1">
              พันธมิตร
            </span>
          </div>
          <div className="justify-content-center mt-2">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item className="breadcrumb-item tx-15" href="#">
                พันธมิตร
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="breadcrumb-item "
                active
                aria-current="page"
              >
                พันธมิตร
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        {/* <Col lg={4} md={6} sm={4}>
          <Card className="custom-card">
            <Card.Body>
              <div>
                <h6 className="card-title">Extra-large</h6>
              </div>
              <ExtralargeModal setdata={viewDemoClose2} />
            </Card.Body>
          </Card>
        </Col> */}
        {/* <!-- /breadcrumb -->

					<!-- row --> */}
        <Row>
          <Col md={12} xl={12} xs={12} sm={12}>
            <Card>
              <Card.Body>
                <div>
                  <button
                    className="btn  btn-primary  float-end"
                    onClick={viewDemoClose}
                  >
                    เพิ่ม พันธมิตร
                  </button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className=" row-sm">
          <Col lg={12}>
            <Card className="custom-card overflow-hidden">
              <Card.Body>
                <div>
                  <h6 className="main-content-label mb-1">รายชื่อ พันธมิตร</h6>
                  <p className="text-muted card-sub-title"></p>
                </div>
                <div className="table-responsive stickyheader export-table">
                  {state21?.data?.length != 0 && (
                    <>
                      <Data_Parner
                        data={state21?.data}
                        setdata={() => get_datapraner()}
                      />
                    </>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal
          show={basic}
          size="lg"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <h4 className="tx-danger">เพิ่มรายชื่อ พันธมิตร</h4>{" "}
            <Button
              variant=""
              className="btn btn-close"
              onClick={() => {
                viewDemoClose();
                getapisetting();
                get_datapraner();
              }}
            >
              x
            </Button>
          </Modal.Header>
          <Modal.Body>
            <form
              className=""
              onSubmit={(e) => {
                e.preventDefault();
                validationContactForm.handleSubmit();
                return false;
              }}
            >
              <div className="">
                {" "}
                <div className="row">
                  <div className="col-10 mx-auto">
                    <label className="form-label float-start">
                      <span>*</span> Username
                    </label>
                    <InputGroup className="input-group mb-3">
                      <InputGroup.Text
                        className="input-group-text"
                        id="basic-addon1"
                      >
                        <i className="fa fa-address-book"></i>
                      </InputGroup.Text>
                      <Form.Control
                        aria-describedby="basic-addon1"
                        onChange={validationContactForm.handleChange}
                        onBlur={validationContactForm.handleChange}
                        value={validationContactForm.values.username}
                        name="username"
                        className="form-control"
                        placeholder="Username"
                        type="text"
                      />
                    </InputGroup>
                    {validationContactForm.errors.username ? (
                      <>
                        <div className="text-danger">
                          {validationContactForm.errors.username}
                        </div>
                      </>
                    ) : null}
                    <label className="form-label float-start">
                      <span>*</span> Password
                    </label>
                    <InputGroup className="input-group mb-3">
                      <InputGroup.Text
                        className="input-group-text"
                        id="basic-addon1"
                      >
                        <i className="fa fa-address-book"></i>
                      </InputGroup.Text>
                      <Form.Control
                        aria-describedby="basic-addon1"
                        aria-label="Password"
                        onChange={validationContactForm.handleChange}
                        onBlur={validationContactForm.handleChange}
                        value={validationContactForm.values.password}
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        type="password"
                      />
                    </InputGroup>
                    {validationContactForm.errors.password ? (
                      <>
                        <div className="text-danger">
                          {validationContactForm.errors.password}
                        </div>
                      </>
                    ) : null}
                    <label className="form-label float-start">
                      <span>*</span> เบอร์โทร
                    </label>
                    <InputGroup className="input-group mb-3">
                      <InputGroup.Text
                        className="input-group-text"
                        id="basic-addon1"
                      >
                        <i className="fa fa-address-book"></i>
                      </InputGroup.Text>
                      <Form.Control
                        aria-describedby="basic-addon1"
                        aria-label="เบอร์โทร"
                        onChange={validationContactForm.handleChange}
                        onBlur={validationContactForm.handleChange}
                        value={validationContactForm.values.phone}
                        name="phone"
                        className="form-control"
                        placeholder="เบอร์โทร"
                        type="text"
                      />
                    </InputGroup>
                    {validationContactForm.errors.phone ? (
                      <>
                        <div className="text-danger">
                          {validationContactForm.errors.phone}
                        </div>
                      </>
                    ) : null}
                    <label className="form-label float-start">
                      <span>*</span> ชื่อ
                    </label>
                    <InputGroup className="input-group mb-3">
                      <InputGroup.Text
                        className="input-group-text"
                        id="basic-addon1"
                      >
                        <i className="fa fa-address-book"></i>
                      </InputGroup.Text>
                      <Form.Control
                        aria-describedby="basic-addon1"
                        aria-label="ชื่อ"
                        onChange={validationContactForm.handleChange}
                        onBlur={validationContactForm.handleChange}
                        value={validationContactForm.values.name}
                        name="name"
                        className="form-control"
                        placeholder="ชื่อ"
                        type="text"
                      />
                    </InputGroup>
                    {validationContactForm.errors.name ? (
                      <>
                        <div className="text-danger">
                          {validationContactForm.errors.name}
                        </div>
                      </>
                    ) : null}
                    <label className="form-label float-start">
                      <span>*</span> เลขบัญชี
                    </label>
                    <InputGroup className="input-group mb-3">
                      <InputGroup.Text
                        className="input-group-text"
                        id="basic-addon1"
                      >
                        <i className="fa fa-address-book"></i>
                      </InputGroup.Text>
                      <Form.Control
                        aria-describedby="basic-addon1"
                        aria-label="เลขบัญชี"
                        onChange={validationContactForm.handleChange}
                        onBlur={validationContactForm.handleChange}
                        value={validationContactForm.values.accnum}
                        name="accnum"
                        className="form-control"
                        placeholder="เลขบัญชี"
                        type="text"
                      />
                    </InputGroup>
                    {validationContactForm.errors.accnum ? (
                      <>
                        <div className="text-danger">
                          {validationContactForm.errors.accnum}
                        </div>
                      </>
                    ) : null}

                    <FormGroup className="form-group">
                      <Row className="row-sm">
                        <Col sm={12}>
                          <Row className="row-sm">
                            <Col sm={12} className="mg-t-10 mg-sm-t-0">
                              <Select
                                classNamePrefix="selectproduct text-denger"
                                options={options}
                                onChange={(e) => handleOnchange(e)}
                                // onBlur={validationContactForm.handleChange}

                                placeholder="--เลือกธนาคาร--"
                                name="bank_id"
                                formatOptionLabel={(option) => (
                                  <div
                                  //className="Select2__input-container css-19bb58m"
                                  // style={{ display: "flex", alignItems: "center" }}
                                  >
                                    <img
                                      src={option.url_pic}
                                      alt={option.bankNameTh}
                                      style={{
                                        width: 20,
                                        height: 20,
                                        marginRight: 5,
                                      }}
                                    />
                                    <span>{option.bankNameTh}</span>
                                  </div>
                                )}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </FormGroup>
                    <div className="alert alert-secondary">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Partner_ref</label>{" "}
                            <div className="input-group">
                              <span className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fa fa-user" />
                                </span>
                              </span>{" "}
                              <input
                                type="text"
                                name="partner_ref"
                                onChange={validationContactForm.handleChange}
                                onBlur={validationContactForm.handleChange}
                                value={validationContactForm.values.partner_ref}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>{" "}
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>ส่วนแบ่งที่ได้รับ %</label>{" "}
                            <div className="input-group">
                              <span className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fa fa-percent" />
                                </span>
                              </span>{" "}
                              <input
                                type="number"
                                step="0.1"
                                onChange={validationContactForm.handleChange}
                                onBlur={validationContactForm.handleChange}
                                value={validationContactForm.values.partner_x}
                                name="partner_x"
                                // name="partner_x"
                                className="form-control"
                              />
                            </div>
                          </div>{" "}
                          <small className="text-white-50">
                            พันธมิตรสามารถ login เพื่อดูสถิติของตนเองได้
                          </small>
                        </div>{" "}
                        {/**/}
                      </div>
                    </div>

                    {validationContactForm.values.linkpartner != "" && (
                      <>
                        <div className="col-12">
                          <div className="input-group">
                            <input
                              type="url"
                              value={`${state2.data.bg_treem}/signuppage?partner=${validationContactForm.values.linkpartner}`}
                              id="customer-url"
                              className="form-control"
                            />{" "}
                            <span className="input-group-append">
                              <CopyToClipboard
                                onCopy={coppy}
                                text={`${state2.data.bg_treem}/signuppage?partner=${validationContactForm.values.linkpartner}`}
                              >
                                <button
                                  type="button"
                                  data-clipboard-target="#customer-url"
                                  className="btn btn-secondary customer-copy"
                                >
                                  <i className="fa fa-copy" />
                                </button>
                              </CopyToClipboard>
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    {gridshow && (
                      <>
                        <Alert className="fade alert fade show alert-dismissible alert-success alert show mt-3">
                          คัดลอกลิงค์ สำเร็จ
                        </Alert>
                      </>
                    )}
                    {lgShow && (
                      <>
                        <Alert className="fade alert fade show alert-dismissible alert-danger alert show mt-3">
                          <h2 className="text-center">เกิดข้อผิดพลาด</h2>
                          <h4>{smShowt}</h4>
                        </Alert>
                      </>
                    )}
                    {gridshow1 && (
                      <>
                        <Alert className="fade alert fade show alert-dismissible alert-success alert show mt-3">
                          <h2 className="text-center">ทำรายการสำเร็จ</h2>
                          <h4>{smShowt}</h4>
                        </Alert>
                      </>
                    )}
                  </div>
                </div>
                <hr />
                <Button
                  variant=""
                 // aria-label="Close"
                  className="btn ripple btn-danger pd-x-25 my-2"
                  type="submit"
                >
                  บันทึก เพิ่มรายชื่อ พันธมิตร
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

Setting_partner.propTypes = {};

Setting_partner.defaultProps = {};

export default Setting_partner;
