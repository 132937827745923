import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Alert,
  Row,
} from "react-bootstrap";
import Apisetting from "../../../api/Apisetting";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Agent = () => {
  const [showconnect, setShowconnect] = React.useState(false);

  useEffect(() => {
    getapisetting();
  }, []);

  const getapisetting = () => {
    Apisetting.data_settingagent()
      .then((res) => {
        if (res.data.success == true) {
          setShowconnect(true);
          //  console.log(res.data)
          setState({
            data: res.data.data,
          });
        }
        // console.log(res.data)
        //
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
    },
  );

  const [show, setShow] = React.useState(false);
  const [showloader2, setShowloader2] = React.useState(false);
  let [msg, setMsg] = React.useState("");

  const [statepost, setStatepost] = useState({
    agent: "",
    member: "",
    key: "",
    prefix: "",
    Web: "",
    domain: "",
  });

  //console.log(statepost);

  const { agent, key, member, prefix, Web, domain } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  const post_connet = (e: any) => {
    e.preventDefault();
    setShowloader2(true);
    let datapost = {
      agent: agent,
      member: member,
      key: key,
      prefix: prefix,
      Web: Web,
      domain: domain,
    };

    if (datapost.agent == "") {
      setShowloader2(false);
      setShow(true);
      setMsg("กรุณาระบุ agent ");
      setTimeout(function () {
        setShow(false);
      }, 5500);
    } else if (datapost.member == "") {
    } else if (datapost.key == "") {
    } else if (datapost.prefix == "") {
    } else if (datapost.Web == "") {
    } else if (datapost.domain == "") {
    } else {
      Apisetting.Postupdate_settings(datapost)
        .then((res) => {
          toast.success("แก้ไข เกี่ยวกับเว็บ สำเร็จ ... ", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          // setTimeout(function () {

          // }, 5500);

          //
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // console.log(state.data.lblsCredit)

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Agent</span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb>
            <Breadcrumb.Item className="tx-15" href="#">
              Agent
            </Breadcrumb.Item>
            <Breadcrumb.Item active aria-current="page">
              Agent
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}

      <Card className="mg-b-20">
        <Card.Body>
          <Col lg={12}>
            <Card className="custom-card">
              <Card.Body>
                <div>
                  <h6 className="main-content-label mb-1">
                    {" "}
                    {showconnect ? (
                      <>
                        <span className="text-success">
                          เชื่อมต่อระบบ Agent สำเร็จ
                        </span>{" "}
                      </>
                    ) : (
                      ""
                    )}{" "}
                  </h6>
                </div>
                <Row>
                  {!showconnect && (
                    <>
                      <Col lg={12} md={12}>
                        <Card>
                          <Card.Body>
                            <h2 className="main-content-label mg-b-5 text-center">
                              เชื่อมต่อ Agent
                            </h2>

                            <div className="">
                              <Row className="row-xs formgroup-wrapper">
                                <Col md={6} className="mx-auto">
                                  {show && (
                                    <>
                                      <div className="card-header border-bottom-0 ps-0 pt-0">
                                        <Alert
                                          className="alert alert-danger mb-0"
                                          role="alert"
                                        >
                                          <h3 className="text-center">
                                            {" "}
                                            เกิดข้อผิดพลาด!!!
                                          </h3>

                                          <hr />
                                          <h3 className="text-center text-info">
                                            {msg}
                                          </h3>
                                        </Alert>
                                      </div>
                                    </>
                                  )}
                                  {showloader2 && (
                                    <Button className="w-100 mb-2" disabled>
                                      <p className="text-center">
                                        กรุณารอสักครู่......
                                      </p>
                                      <img
                                        src="https://www.lady711.com/images/loaderNew.svg"
                                        alt=""
                                        width={50}
                                      />
                                    </Button>
                                  )}
                                  <Form
                                    className="form-horizontal"
                                    onSubmit={post_connet}
                                  >
                                    <FormGroup className="form-group">
                                      <Form.Label className="form-label">
                                        username
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        placeholder="agent"
                                        name="agent"
                                        value={agent}
                                        onChange={inputvalue("agent")}
                                      />
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                      <Form.Label className="form-label">
                                        password
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        placeholder="key"
                                        name="key"
                                        value={key}
                                        onChange={inputvalue("key")}
                                        defaultValue="key"
                                      />
                                    </FormGroup>

                                    <FormGroup className="form-group mb-0 mt-3 justify-content-end">
                                      <div>
                                        <Button
                                          variant=""
                                          type="submit"
                                          className="btn btn-secondary btn-block w-50 mx-auto"
                                        >
                                          เชื่อมต่อ Agent
                                        </Button>
                                      </div>
                                    </FormGroup>
                                  </Form>
                                </Col>
                              </Row>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </>
                  )}

                  {showconnect && (
                    <>
                      <Col xl={12} lg={12} md={12} xs={12}>
                        <Card>
                          <Card.Body>
                            <Row>
                              <Col xl={9} lg={7} md={6} sm={12}>
                                <div className="text-justified align-items-center">
                                  {/* <p className="text-dark tx-14 mb-3 lh-3">
                          คุณสามารถ Download Ufabackwnd from PC ได้ที่ปุ่มด้านล่าง
                        </p> */}
                                  {/* <Button variant="" className="btn btn-primary shadow">
                        Download Now
                        </Button> */}
                                </div>
                              </Col>
                            </Row>
                            <Row className="row-sm">
                              <Col lg={6} xl={3} md={6} className="col-12">
                                <Card className=" bg-primary-gradient text-white ">
                                  <Card.Body>
                                    <Row>
                                      <div className="col-6">
                                        <div className="icon1 mt-2 text-center">
                                          <i className="fe fe-users tx-40"></i>
                                        </div>
                                      </div>
                                      <Col className="col-6">
                                        <div className="mt-0 text-center">
                                          <span className="text-white">
                                            username
                                          </span>
                                          <h2 className="text-white mb-0">
                                          {state.data.username}
                                          </h2>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col lg={6} xl={3} md={6} className="col-12">
                                <Card className=" bg-danger-gradient text-white">
                                  <Card.Body>
                                    <Row>
                                      <div className="col-6">
                                        <div className="icon1 mt-2 text-center">
                                        <i className="fe fe-dollar-sign tx-40" />
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="mt-0 text-center">
                                          <span className="text-white">
                                          Credit
                                          </span>
                                          <h2 className="text-white mb-0">
                                          $ {state.data.balance}{" "}
                                          
                                          </h2>
                                        </div>
                                      </div>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </Col>
                              {/* <Col lg={6} xl={3} md={6} className="col-12">
                                <Card className=" bg-success-gradient text-white">
                                  <Card.Body>
                                    <Row>
                                      <div className="col-6">
                                        <div className="icon1 mt-2 text-center">
                                          <i className="fe fe-bar-chart-2 tx-40"></i>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="mt-0 text-center">
                                          <span className="text-white">
                                            Profits
                                          </span>
                                          <h2 className="text-white mb-0">
                                            98K
                                          </h2>
                                        </div>
                                      </div>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col lg={6} xl={3} md={6} className="col-12">
                                <Card className=" bg-warning-gradient text-white">
                                  <Card.Body>
                                    <Row>
                                      <div className="col-6">
                                        <div className="icon1 mt-2 text-center">
                                          <i className="fe fe-pie-chart tx-40"></i>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="mt-0 text-center">
                                          <span className="text-white">
                                            Taxes
                                          </span>
                                          <h2 className="text-white mb-0">
                                            876
                                          </h2>
                                        </div>
                                      </div>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </Col> */}
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Card.Body>
      </Card>
      {/* <!-- End Row --> */}
    </div>
  );
};

Agent.propTypes = {};

Agent.defaultProps = {};

export default Agent;
