// interface.ts
import React, { useEffect, useMemo, useReducer, useState } from "react";
interface TableData {
    id: number;
    username: string;
    // ... อื่นๆ
    wl: number;
    totel_monney: number;
  }
  
  // component.tsx

 // import './styles.css'; // สำหรับ CSS
  
  interface Props {
    data: TableData[];
  }
  
  const SummaryTable: React.FC<Props> = ({ data }) => {
    const [totalWl, setTotalWl] = useState<number>(0);
    const [totalMoney, setTotalMoney] = useState<any>(0);
  
    // ฟังก์ชันคำนวณยอดรวม
    useEffect(() => {
      const calculateTotals = () => {
        const wlTotal = data.reduce((acc, item) => acc + item.wl, 0);
        const moneyTotal = data.reduce((acc, item) => acc + item.totel_monney, 0);
        setTotalWl(wlTotal);
        setTotalMoney(moneyTotal);
      };
  
      calculateTotals();
    }, [data]);
  
    return (
      <div>
      <h2 className="my-5 text-end">ยอดรวมเงิน: {parseFloat(totalMoney).toFixed(2)}</h2>
      </div>
    );
  };
  
  export default SummaryTable;