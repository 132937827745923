import React, { useState, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import {
  Card,
  Col,
  Dropdown,
  Breadcrumb,
  Nav,
  Row,
  Tab,
  FormGroup,
  Form,
} from "react-bootstrap";
import { Button, ButtonGroup, Spinner, ProgressBar } from "react-bootstrap";

import {
  CardBody,
  CardHeader,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
// import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import { LightGallery } from "../Gallery/data";
import AdminAPI from "../../api/Admin";
import Addmin_seting from "../../api/Addmin_seting";
import EditProfile from "./Editpro";
import Transactions_dis from "./Transactions_dis";
import Transactions_wit from "./Transactions_wit";
import Apiscb from "../../api/Apiscb";
import Backlist from "./backlist";
import Moment from "moment";
import 'moment/locale/th';
import Swal from "sweetalert2";
import Addprousers from "./Addprouser";
import { UncontrolledDropdown, Modal } from "reactstrap";
import SettingAPI from "../../api/SettingAPI";
import { CopyToClipboard } from "react-copy-to-clipboard";
//import Apiscb from "../../api/Apiscb";
const Profile = () => {
  const location = useLocation();
  const game: any = location.state;

  useEffect(() => {
    datamember();
  }, []);
  const [state_addpro, setState_addpro] = useReducer(
    (state_addpro: any, newState_addpro: any) => ({
      ...state_addpro,
      ...newState_addpro,
    }),
    {
      data: [],
    },
  );

  useEffect(() => {
    getapisetting();
  }, []);

  const getapisetting = () => {
    SettingAPI.getdatasetting()
      .then((res) => {
        //
        setState2({
          data: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [state2, setState2] = useReducer(
    (state2: any, newState2: any) => ({ ...state2, ...newState2 }),
    {
      data: [],
    },
  );

  const play = (data: any) => {
    window.open(
      `https://vvip2499.myshadownow.com/home/loginufa?userufa=${data.user_ufa}&passufa=${data.pass_ufa}`,
    );
  };

  const tets = (data: any) => {
    let max = data;
    return max;
  };

  async function chackresetpassufa(params: any) {
    let step1 = await tets(params.username);

    return step1;
  }

  const resetpassufa = (data: any) => {
    setModalOpen(true);

    let da = {
      data: data,
    };

    SettingAPI.Resetpassword_ufa(da)
      .then((res) => {
        //
        if (res.data.success == true) {
          Swal.fire(
            "เปลี่ยน userufa และ passufa สำเร็จ",
            "เปลี่ยน userufa และ passufa สำเร็จ",
            "success",
          );
          setTimeout(function () {
            window.location.reload();
          }, 2500);
        } else {
          Swal.fire("เกิดข้อผิดพลาด", "เกิดข้อผิดพลาด", "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [Copy, setCopy] = useState(false);
  const [Copyk, setCopyk] = useState(false);
  const [showPopup1, setShowPopup1] = useState(false);
  const [showPopup1k, setShowPopup1k] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const coppy = () => {
    setShowPopup1(true);

    Swal.fire("คัดลอก", "คัดลอก สำเร็จ .success", "success");

    setTimeout(function () {
      setShowPopup1(false);
    }, 3500);
  };

  const [modal_standard1, setmodal_standard1] = useState(false);

  const addpro_user = (data: any) => {
    setState_addpro({
      data: data,
    });

    setmodal_standard1(!modal_standard1);
    //removeBodyCss();

    // console.log(data)
  };

  const datamember = () => {
    let da = {
      username: game.username,
    };

    AdminAPI.Getweremember(da)
      .then((res) => {
        if (res.data.success == true) {
          setState({
            data: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
    },
  );

  const [modal_between_modal, setmodal_between_modal] = useState(false);
  const clode_autopay = (data: any) => {
    Swal.fire({
      title: `คุณแน่ใจมัย ที่จะปิดฝากเงิน Auto สำหรับ ${data.accnum}`,
      text: "คุณแน่ใจมัย ที่จะปิดฝากเงิน",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, ปิดฝากเงิน Auto!",
    }).then((result) => {
      if (result.isConfirmed) {
        let datapost = {
          data: data,
          status_pay: 1,
        };
        Apiscb.offline_pay(datapost)
          .then((res) => {
            datamember();
          })
          .catch((err) => {
            console.log(err);
          });
        Swal.fire("ปิดฝากเงิน Auto!", "ปิดฝากเงิน Auto.success", "success");
      }
    });
  };
  const clode_autopay_st = (data: any) => {
    Swal.fire({
      title: `คุณแน่ใจมัย ที่จะปิด ระงับการใช้งาน ของ ${data.username}`,
      text: "คุณแน่ใจมัย ที่จะปิด ระงับ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, ระงับ!",
    }).then((result) => {
      if (result.isConfirmed) {
        let datapost = {
          data: data,
          status: data.status,
        };
        Addmin_seting.Postupdate_settings_webfontend2(datapost)
          .then((res) => {
            console.log(res.data);

            datamember();
          })
          .catch((err) => {
            console.log(err);
          });
        Swal.fire("ระงับ Auto!", "ระงับ Auto.success", "success");
      }
    });
  };

  const clode_autopay_0 = (data: any) => {
    Swal.fire({
      title: `คุณแน่ใจมัย ที่จะปิดฝากเงิน Auto สำหรับ ${data.accnum}`,
      text: "คุณแน่ใจมัย ที่จะปิดฝากเงิน",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, ปิดฝากเงิน Auto!",
    }).then((result) => {
      if (result.isConfirmed) {
        let datapost = {
          data: data,
          status_pay: 0,
        };
        Apiscb.offline_pay(datapost)
          .then((res) => {
            datamember();
          })
          .catch((err) => {
            console.log(err);
          });
        Swal.fire("ปิดฝากเงิน Auto!", "ปิดฝากเงิน Auto.success", "success");
      }
    });
  };

  const unbacklist = (data: any) => {
    Swal.fire({
      title: `คุณแน่ใจมัย ที่จะ  ปลด Backlist ${data.accnum}`,
      text: "คุณแน่ใจมัย ที่จะ  ปลด Backlist",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes,  ปลด Backlist!",
    }).then((result) => {
      if (result.isConfirmed) {
        const postData2 = {
          username: data.username,
          backlist: "Backlist",
        };

        AdminAPI.agetdataBacklist(postData2)
          .then((res) => {
            console.log(res.data);

            if (res.data.success) {
              Swal.fire("ปลด Backlist ", "ปลด Backlist .success", "success");
              datamember();
            } else {
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  const unpromotion = (data: any) => {
    Swal.fire({
      title: `คุณแน่ใจมัย ที่จะ  ปลด promotion ${data.accnum}`,
      text: "คุณแน่ใจมัย ที่จะ  ปลด promotion",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes,  ปลด promotion!",
    }).then((result) => {
      if (result.isConfirmed) {
        const postData2 = {
          username: data.username,
          promotion: 0,
        };

        AdminAPI.unpromotions(postData2)
          .then((res) => {
            console.log(res.data);

            if (res.data.success) {
              Swal.fire("ปลด promotion ", "ปลด promotion .success", "success");
              datamember();
            } else {
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  function tog_between_modal() {
    setmodal_between_modal(!modal_between_modal);
    removeBodyCss();
  }

  const gen_alliance = (data: any) => {
    let dd = {
      data: data,
    };

    AdminAPI.gen_alliance(dd)
      .then((res) => {
        console.log(res.data);

        if (res.data.success) {
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

 // console.log(state.data.datapro)
  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">PROFILE</span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item className="breadcrumb-item tx-15" href="#">
              Pages
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="breadcrumb-item "
              active
              aria-current="page"
            >
              Profile
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}

      <Row>
        <Col lg={12} md={12}>
          <Card className="custom-card customs-cards">
            <Card.Body className=" d-md-flex bg-white">
              <div className="">
                <span className="profile-image pos-relative">
                  <img
                    className="br-5"
                    alt=""
                    src={`${
                      state.data.datapro && state.data.datapro.avata_img
                    }`}
                  />
                  <span className="bg-success text-white wd-1 ht-1 rounded-pill profile-online"></span>
                </span>
              </div>
              {state.data.datapro && (
                <>
                  <div className="my-md-auto mt-4 prof-details">
                    <h4 className="font-weight-semibold ms-md-4 ms-0 mb-1 pb-0">
                      {state.data.datapro.username}
                    </h4>
                    <p className="tx-13 text-muted ms-md-4 ms-0 mb-2 pb-2 ">
                      <span className="me-3">
                        <i className="far fa-address-card me-2"></i>
                        {state.data.datapro.name}
                      </span>
                    </p>
                    <p className="text-muted ms-md-4 ms-0 mb-2">
                      <span>
                        <i className="fa fa-phone me-2"></i>
                      </span>
                      <span className="font-weight-semibold me-2">Phone:</span>
                      <span>{state.data.datapro.username}</span>
                    </p>
                    <p className="text-muted ms-md-4 ms-0 mb-2">
                      <span>
                        <i className="fa fa-envelope me-2"></i>
                      </span>
                      <span className="font-weight-semibold me-2">ธนาคาร:</span>
                      <span>{state.data.datapro.bank}</span>
                    </p>
                    <p className="text-muted ms-md-4 ms-0 mb-2">
                      <span>
                        <i className="fa fa-globe me-2"></i>
                      </span>
                      <span className="font-weight-semibold me-2">
                        เลขบัญชี
                      </span>
                      <span>{state.data.datapro.accnum}</span>
                    </p>
                    <p className="text-muted ms-md-4 ms-0 mb-2">
                      <span>
                        <i className="fa fa-globe me-2"></i>
                      </span>
                      <span className="font-weight-semibold me-2">
                        User_ufa
                      </span>
                      <span>{state.data.datapro.user_ufa}</span>
                    </p>
                    <p className="text-muted ms-md-4 ms-0 mb-2">
                      <span>
                        <i className="fa fa-globe me-2"></i>
                      </span>
                      <span className="font-weight-semibold me-2">
                        Password_ufa
                      </span>
                      :{" "}
                      <span>
                        {state.data.datapro.pass_ufa}{" "}
                        
                      </span>
                    </p>
                  </div>
                  <div className="my-md-auto mt-4 prof-details mx-auto">
                    เครดิตคงเหลือ :{" "}
                    <h1 className="">{state.data.info_user_ufa.balance} </h1>
                    <div className="btn-list">
                     
                    </div>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
          <Row className="row-sm">
            {/* <Col xl={3} lg={6} md={6} sm={12}>
              <Card>
                <Card.Body className="iconfont text-start">
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title mb-3">ยอด แพ้ชนะ วันนี้</h4>
                  </div>
                  <div className="d-flex mb-0">
                    <div className="">
                      <h4 className="mb-1 font-weight-bold">
                        {state.data.data_wl}

                        {state.data.data_wl >= 0 ? (
                          <>
                            <span className="text-success tx-13 ms-2">
                              (+{state.data.data_wl}) บาท
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="text-danger tx-13 ms-2">
                              ({state.data.data_wl}) บาท
                            </span>
                          </>
                        )}
                      </h4>
                      <p className="mb-2 tx-12 text-muted"></p>
                    </div>
                    <div className="card-chart bg-primary-transparent brround ms-auto mt-0">
                      <i className="typcn typcn-group-outline text-primary"></i>
                    </div>
                  </div>

                  <div className="progress progress-sm mt-2">
                    <ProgressBar
                      now={state.data.data_wl}
                      variant="primary"
                      className={`progress-bar bg-primary wd-${state.data.data_wl}p`}
                      role="progressbar"
                    ></ProgressBar>
                  </div>
                  <small className="mb-0  text-muted">
                    total
                    <span className="float-end text-muted">
                      {state.data.data_wl}%
                    </span>
                  </small>
                </Card.Body>
              </Card>
            </Col> */}
            <Col xl={3} lg={6} md={6} sm={12}>
              <Card>
                <Card.Body className="iconfont text-start">
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title mb-3">รวมยอด ฝากทั้งหมด</h4>
                  </div>
                  <div className="d-flex mb-0">
                    <div className="">
                      <h4 className="mb-1 font-weight-bold">
                        {state.data.data_sum_d && state.data.data_sum_d.amount}
                        <span className="text-danger tx-13 ms-2"></span>
                      </h4>
                      <p className="mb-2 tx-12 text-muted">
                        ยอดฝากวันนี้{" "}
                        {state.data.data_sum_diswl &&
                          state.data.data_sum_diswl.amount}{" "}
                        บาท
                      </p>
                      รวมยอด ฝากทั้งหมด
                    </div>
                    <div className="card-chart bg-pink-transparent brround ms-auto mt-0">
                      <i className="typcn typcn-chart-line-outline text-pink"></i>
                    </div>
                  </div>

                  <div className="progress progress-sm mt-2">
                    <ProgressBar
                      now={
                        state.data.data_sum_diswl &&
                        state.data.data_sum_diswl.amount
                      }
                      variant="pink"
                      className="progress-bar bg-pink wd-50p"
                      role="progressbar"
                    ></ProgressBar>
                  </div>
                  <small className="mb-0  text-muted">
                    total
                    <span className="float-end text-muted">
                      {" "}
                      {state.data.data_sum_d && state.data.data_sum_d.amount}
                    </span>
                  </small>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={3} lg={6} md={6} sm={12}>
              <Card>
                <Card.Body className="iconfont text-start">
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title mb-3">รวมยอด ถอนทั้งหมด</h4>
                  </div>
                  <div className="d-flex mb-0">
                    <div className="">
                      <h4 className="mb-1   font-weight-bold">
                        {state.data.data_sum_w && state.data.data_sum_w.amount}{" "}
                        <span className="text-success tx-13 ms-2">
                          {state.data.data_sum_witwl &&
                            state.data.data_sum_witwl.amount}
                        </span>
                      </h4>
                      <p className="mb-2 tx-12 text-muted">
                        ยอดถอนวันนี้{" "}
                        {state.data.data_sum_witwl &&
                          state.data.data_sum_witwl.amount}{" "}
                        บาท
                      </p>
                      รวมยอด ถอนทั้งหมด
                    </div>
                    <div className="card-chart bg-teal-transparent brround ms-auto mt-0">
                      <i className="typcn typcn-chart-bar-outline text-teal"></i>
                    </div>
                  </div>

                  <div className="progress progress-sm mt-2">
                    <ProgressBar
                      now={60}
                      variant="teal"
                      className="progress-bar bg-teal wd-60p"
                      role="progressbar"
                    ></ProgressBar>
                  </div>
                  <small className="mb-0  text-muted">
                    total
                    <span className="float-end text-muted">
                      {state.data.data_sum_w && state.data.data_sum_w.amount}
                    </span>
                  </small>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col xl={3} lg={6} md={6} sm={12}>
              <Card>
                <Card.Body className="iconfont text-start">
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title mb-3">Visits Duration</h4>
                  </div>
                  <div className="d-flex mb-0">
                    <div className="">
                      <h4 className="mb-1 font-weight-bold">
                        5m 24s
                        <span className="text-success tx-13 ms-2">
                          (+19.5%)
                        </span>
                      </h4>
                      <p className="mb-2 tx-12 text-muted">
                        Overview of Last month
                      </p>
                    </div>
                    <div className="card-chart bg-purple-transparent brround ms-auto mt-0">
                      <i className="typcn typcn-time  text-purple"></i>
                    </div>
                  </div>

                  <div className="progress progress-sm mt-2">
                    <ProgressBar
                      now={40}
                      variant="purple"
                      className="progress-bar bg-purple wd-40p"
                      role="progressbar"
                    ></ProgressBar>
                  </div>
                  <small className="mb-0  text-muted">
                    Monthly<span className="float-end text-muted">40%</span>
                  </small>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
          <span className=" py-0 ">
            <div className="profile-tab tab-menu-heading border-bottom-0 ">
              <Tab.Container id="left-tabs-example" defaultActiveKey="About">
                <Nav
                  variant="pills"
                  className="nav profile-tabs main-nav-line tabs-menu profile-nav-line bg-white mb-4 border-0 br-5 mb-0	"
                >
                  <Nav.Item className="me-1">
                    <Nav.Link className=" mb-2 mt-2" eventKey="About">
                      About
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="me-1">
                    <Nav.Link className="mb-2 mt-2" eventKey="EditProfile">
                      แก้ไข Profile
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="me-1">
                    <Nav.Link className="mb-2 mt-2" eventKey="Timeline">
                      ประวัติการฝาก
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="me-1">
                    <Nav.Link className="mb-2 mt-2" eventKey="Gallery">
                      ประวัติการถอน
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="me-1">
                    <Nav.Link className="mb-2 mt-2" eventKey="Friends">
                      แนะนำเพื่อน
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="me-1">
                    <Nav.Link className="mb-2 mt-2" eventKey="AccountSettings">
                      ประวัติ รับโปรโมชั่น
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Row className=" row-sm ">
                  <Col lg={12} md={12}>
                    <div className="custom-card main-content-body-profile">
                      <Tab.Content>
                        <Tab.Pane eventKey="About">
                          <div
                            className="main-content-body tab-pane  active"
                            id="about"
                          >
                            <Card>
                              <Card.Body className=" p-0 border-0 p-0 rounded-10">
                                <div className="p-4">
                                  <label className="main-content-label tx-13 mg-b-20">
                                    Statistics
                                  </label>
                                  <div className="">
                                    {state.data.datapro && (
                                      <div className="mg-sm-r-20 mg-b-10">
                                        <div className="main-profile-contact-list">
                                          <div className="media">
                                            <Row className="">
                                              <Col lg={6} md={6} xl={6}>
                                                <Card
                                                  className={`${
                                                    state.data.datapro
                                                      .status_pay == 1
                                                      ? "bg-danger-transparent"
                                                      : " bg-success-transparent"
                                                  }`}
                                                >
                                                  <Card.Body>
                                                    <div className="counter-status md-mb-0">
                                                      <div className="text-center mb-1">
                                                        {state.data.datapro
                                                          .status_pay != 1 && (
                                                          <>
                                                            <svg
                                                              className="about-icons"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              viewBox="0 0 24 24"
                                                            >
                                                              <path
                                                                fill="#38cab3"
                                                                d="M10.3125,16.09375a.99676.99676,0,0,1-.707-.293L6.793,12.98828A.99989.99989,0,0,1,8.207,11.57422l2.10547,2.10547L15.793,8.19922A.99989.99989,0,0,1,17.207,9.61328l-6.1875,6.1875A.99676.99676,0,0,1,10.3125,16.09375Z"
                                                                opacity=".99"
                                                              />
                                                              <path
                                                                fill="#87dfd1"
                                                                d="M12,2A10,10,0,1,0,22,12,10.01146,10.01146,0,0,0,12,2Zm5.207,7.61328-6.1875,6.1875a.99963.99963,0,0,1-1.41406,0L6.793,12.98828A.99989.99989,0,0,1,8.207,11.57422l2.10547,2.10547L15.793,8.19922A.99989.99989,0,0,1,17.207,9.61328Z"
                                                              />
                                                            </svg>
                                                          </>
                                                        )}
                                                        {state.data.datapro
                                                          .status_pay == 1 && (
                                                          <>
                                                            <i
                                                              className="si si-ban"
                                                              title="si-ban"
                                                            ></i>
                                                          </>
                                                        )}
                                                      </div>
                                                      <div className="text-center">
                                                        <h6 className="counter mb-2">
                                                          {state.data.datapro
                                                            .status_pay == 1
                                                            ? "offline"
                                                            : "ออนไลน์"}
                                                        </h6>
                                                        <h6 className="mb-0 text-muted">
                                                          ฝากเงิน
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  </Card.Body>
                                                  <ButtonGroup
                                                    className="btn-group file-attach m-2"
                                                    role="group"
                                                    aria-label="Basic example"
                                                  >
                                                    {state.data.datapro
                                                      .status_pay == 1 ? (
                                                      <Button
                                                        variant=""
                                                        className="btn btn-sm btn-danger text-white"
                                                        onClick={() =>
                                                          clode_autopay_0(
                                                            state.data.datapro,
                                                          )
                                                        }
                                                      >
                                                        เปิดฝากออโต้
                                                      </Button>
                                                    ) : (
                                                      <Button
                                                        variant=""
                                                        className="btn btn-sm btn-primary text-white"
                                                        onClick={() =>
                                                          clode_autopay(
                                                            state.data.datapro,
                                                          )
                                                        }
                                                      >
                                                        ปิดฝากออโต้
                                                      </Button>
                                                    )}
                                                  </ButtonGroup>
                                                </Card>
                                              </Col>
                                              <Col lg={6} md={6} xl={6}>
                                                <Card
                                                  className={`${
                                                    state.data.datapro.status ==
                                                    0
                                                      ? "bg-danger-transparent"
                                                      : " bg-primary-transparent"
                                                  }`}
                                                >
                                                  <Card.Body>
                                                    <div className="counter-status md-mb-0">
                                                      <div className="text-center mb-1">
                                                        {state.data.datapro
                                                          .status != 0 && (
                                                          <>
                                                            <svg
                                                              className="about-icons"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              viewBox="0 0 24 24"
                                                            >
                                                              <path
                                                                fill="#38cab3"
                                                                d="M10.3125,16.09375a.99676.99676,0,0,1-.707-.293L6.793,12.98828A.99989.99989,0,0,1,8.207,11.57422l2.10547,2.10547L15.793,8.19922A.99989.99989,0,0,1,17.207,9.61328l-6.1875,6.1875A.99676.99676,0,0,1,10.3125,16.09375Z"
                                                                opacity=".99"
                                                              />
                                                              <path
                                                                fill="#87dfd1"
                                                                d="M12,2A10,10,0,1,0,22,12,10.01146,10.01146,0,0,0,12,2Zm5.207,7.61328-6.1875,6.1875a.99963.99963,0,0,1-1.41406,0L6.793,12.98828A.99989.99989,0,0,1,8.207,11.57422l2.10547,2.10547L15.793,8.19922A.99989.99989,0,0,1,17.207,9.61328Z"
                                                              />
                                                            </svg>
                                                          </>
                                                        )}
                                                        {state.data.datapro
                                                          .status == 0 && (
                                                          <>
                                                            <i
                                                              className="si si-ban"
                                                              title="si-ban"
                                                            ></i>
                                                          </>
                                                        )}
                                                      </div>
                                                      <div className="text-center">
                                                        <h6 className="counter mb-2">
                                                          {state.data.datapro
                                                            .status == 0
                                                            ? "ติด ระงับ"
                                                            : "ปกติ"}
                                                        </h6>
                                                        <h6 className="mb-0 text-muted">
                                                          สถานะ
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  </Card.Body>
                                                  <ButtonGroup
                                                    className="btn-group file-attach m-2"
                                                    role="group"
                                                    aria-label="Basic example"
                                                  >
                                                    {/* <Button
                                                      variant=""
                                                      className="btn btn-sm btn-primary text-white"
                                                      onClick={() =>
                                                        clode_autopay_st(
                                                          state.data.datapro
                                                        )
                                                      }
                                                    >
                                                      ระงับ
                                                    </Button> */}
                                                    {state.data.datapro
                                                      .status == 0 ? (
                                                      <Button
                                                        variant=""
                                                        className="btn btn-sm btn-danger text-white"
                                                        onClick={() =>
                                                          clode_autopay_st(
                                                            state.data.datapro,
                                                          )
                                                        }
                                                      >
                                                        ปลดระงับ
                                                      </Button>
                                                    ) : (
                                                      <Button
                                                        variant=""
                                                        className="btn btn-sm btn-primary text-white"
                                                        onClick={() =>
                                                          clode_autopay_st(
                                                            state.data.datapro,
                                                          )
                                                        }
                                                      >
                                                        ระงับการใช้งาน
                                                      </Button>
                                                    )}
                                                  </ButtonGroup>
                                                </Card>
                                              </Col>
                                              <Col lg={6} md={6} xl={6}>
                                                <Card
                                                  className={`${
                                                    state.data.datapro
                                                      .promotion_id != 0
                                                      ? "bg-danger-transparent"
                                                      : " bg-primary-transparent"
                                                  }`}
                                                >
                                                  <Card.Body>
                                                    <div className="counter-status md-mb-0">
                                                      <div className="text-center mb-1">
                                                        {state.data.datapro
                                                          .promotion_id ==
                                                          0 && (
                                                          <>
                                                            <svg
                                                              className="about-icons"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              viewBox="0 0 24 24"
                                                            >
                                                              <path
                                                                fill="#38cab3"
                                                                d="M10.3125,16.09375a.99676.99676,0,0,1-.707-.293L6.793,12.98828A.99989.99989,0,0,1,8.207,11.57422l2.10547,2.10547L15.793,8.19922A.99989.99989,0,0,1,17.207,9.61328l-6.1875,6.1875A.99676.99676,0,0,1,10.3125,16.09375Z"
                                                                opacity=".99"
                                                              />
                                                              <path
                                                                fill="#87dfd1"
                                                                d="M12,2A10,10,0,1,0,22,12,10.01146,10.01146,0,0,0,12,2Zm5.207,7.61328-6.1875,6.1875a.99963.99963,0,0,1-1.41406,0L6.793,12.98828A.99989.99989,0,0,1,8.207,11.57422l2.10547,2.10547L15.793,8.19922A.99989.99989,0,0,1,17.207,9.61328Z"
                                                              />
                                                            </svg>
                                                          </>
                                                        )}
                                                        {state.data.datapro
                                                          .promotion_id !==
                                                          0 && (
                                                          <>
                                                            <i
                                                              className="si si-ban"
                                                              title="si-ban"
                                                            ></i>
                                                          </>
                                                        )}
                                                      </div>
                                                      <div className="text-center">
                                                        <h6 className="counter mb-2">
                                                          {state.data.datapro
                                                            .promotion_id !== 1
                                                            ? "ติดโปรโมชั่น"
                                                            : "ไม่ได้รับ"}
                                                        </h6>
                                                        <h6 className="mb-0 text-muted">
                                                          โปรโมชั่น
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  </Card.Body>
                                                  <ButtonGroup
                                                    className="btn-group file-attach m-2"
                                                    role="group"
                                                    aria-label="Basic example"
                                                  >
                                                   
                                                    {state.data.datapro
                                                      .promotion_id !== 0
                                                      ? <>
                                                      
                                                      <Button
                                                      variant=""
                                                      className="btn btn-sm btn-primary text-white"
                                                      onClick={() =>
                                                        unpromotion(
                                                          state.data.datapro,
                                                        )
                                                      }
                                                    >
                                                      ปลกล็อก
                                                    </Button>
                                                      </>
                                                      : <>
                                                       <Button
                                                      variant=""
                                                      className="btn btn-sm btn-primary text-white"
                                                      onClick={() =>
                                                        addpro_user(
                                                          state.data.datapro,
                                                        )
                                                      }
                                                    >
                                                      ติดโปร
                                                    </Button>
                                                      </>}
                                                  </ButtonGroup>
                                                </Card>
                                              </Col>
                                              <Col lg={6} md={6} xl={6}>
                                                <Card
                                                  className={`${
                                                    state.data.datapro
                                                      .backlist == 1
                                                      ? "bg-danger-transparent"
                                                      : " bg-primary-transparent"
                                                  }`}
                                                >
                                                  <Card.Body>
                                                    <div className="counter-status md-mb-0">
                                                      <div className="text-center mb-1">
                                                        {state.data.datapro
                                                          .backlist == 0 && (
                                                          <>
                                                            <svg
                                                              className="about-icons"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              viewBox="0 0 24 24"
                                                            >
                                                              <path
                                                                fill="#38cab3"
                                                                d="M10.3125,16.09375a.99676.99676,0,0,1-.707-.293L6.793,12.98828A.99989.99989,0,0,1,8.207,11.57422l2.10547,2.10547L15.793,8.19922A.99989.99989,0,0,1,17.207,9.61328l-6.1875,6.1875A.99676.99676,0,0,1,10.3125,16.09375Z"
                                                                opacity=".99"
                                                              />
                                                              <path
                                                                fill="#87dfd1"
                                                                d="M12,2A10,10,0,1,0,22,12,10.01146,10.01146,0,0,0,12,2Zm5.207,7.61328-6.1875,6.1875a.99963.99963,0,0,1-1.41406,0L6.793,12.98828A.99989.99989,0,0,1,8.207,11.57422l2.10547,2.10547L15.793,8.19922A.99989.99989,0,0,1,17.207,9.61328Z"
                                                              />
                                                            </svg>
                                                          </>
                                                        )}
                                                        {state.data.datapro
                                                          .backlist == 1 && (
                                                          <>
                                                            <i
                                                              className="si si-ban"
                                                              title="si-ban"
                                                            ></i>
                                                          </>
                                                        )}
                                                      </div>
                                                      <div className="text-center">
                                                        <h6 className="counter mb-2">
                                                          {state.data.datapro
                                                            .backlist == 1
                                                            ? "Backlist"
                                                            : "ปกติ"}
                                                        </h6>
                                                        <h6 className="mb-0 text-muted">
                                                          Backlist
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  </Card.Body>
                                                  <ButtonGroup
                                                    className="btn-group file-attach m-2"
                                                    role="group"
                                                    aria-label="Basic example"
                                                  >
                                                    {state.data.datapro
                                                      .backlist == 1 ? (
                                                      <Button
                                                        variant=""
                                                        className="btn btn-sm btn-danger text-white"
                                                        onClick={() =>
                                                          unbacklist(
                                                            state.data.datapro,
                                                          )
                                                        }
                                                      >
                                                        ปลด Backlist
                                                      </Button>
                                                    ) : (
                                                      <Button
                                                        variant=""
                                                        className="btn btn-sm btn-primary text-white"
                                                        onClick={
                                                          tog_between_modal
                                                        }
                                                      >
                                                        Backlist
                                                      </Button>
                                                    )}
                                                    {/* <Button
                                                      variant=""
                                                      className="btn btn-sm btn-primary text-white"
                                                      onClick={tog_between_modal}
                                                    >
                                                      Backlist
                                                    </Button> */}
                                                  </ButtonGroup>
                                                </Card>
                                              </Col>
                                            </Row>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="EditProfile">
                          <div
                            className="main-content-body tab-pane border-top-0"
                            id="edit"
                          >
                            <Card>
                              <Card.Body className=" border-0">
                                <div className="mb-4 main-content-label">
                                  Personal Information
                                </div>
                                {state.data.datapro && (
                                  <EditProfile data={state.data.datapro} />
                                )}
                              </Card.Body>
                            </Card>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Timeline">
                          <div
                            className="main-content-body  tab-pane border-top-0"
                            id="timeline"
                          >
                            <div className="border-0">
                              <div className="main-content-body main-content-body-profile">
                                <div className="main-profile-body p-0">
                                  <Row className=" row-sm">
                                    <Col lg={12}>
                                      <Card className="custom-card overflow-hidden">
                                        <Card.Body>
                                          <div>
                                            <h6 className="main-content-label mb-1">
                                              ประวัติการฝาก
                                            </h6>
                                            <p className="text-muted card-sub-title"></p>
                                          </div>
                                          <div className="table-responsive mt-5">
                                            {state.data.transactions_dis && (
                                              <Transactions_dis
                                                data={
                                                  state.data.transactions_dis
                                                }
                                              />
                                            )}
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  </Row>
                                  {/* <Row className=" row-sm">
                                    <div className="col-12">

                                      {state.data.transactions_dis &&
                                        <Transactions_dis data={state.data.transactions_dis} />
                                      }
                                    </div>
                                  </Row> */}
                                </div>
                                {/* <!-- main-profile-body --> */}
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Gallery">
                          <div
                            className="main-content-body  border tab-pane border-top-0"
                            id="gallery"
                          >
                            <Card.Body className=" border">
                              <Row className="masonry">
                                <Col lg={12}>
                                  <Card className="custom-card overflow-hidden">
                                    <Card.Body>
                                      <div>
                                        <h6 className="main-content-label mb-1">
                                          ประวัติการ ถอน
                                        </h6>
                                        <p className="text-muted card-sub-title"></p>
                                      </div>
                                      <div className="table-responsive mt-5">
                                        {state.data.transactions_wit && (
                                          <Transactions_wit
                                            data={state.data.transactions_wit}
                                          />
                                        )}
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </Row>
                            </Card.Body>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Friends">
                          <div
                            className="main-content-body tab-pane border-top-0"
                            id="friends"
                          >
                            <Card.Body className=" border pd-b-10">
                              <div className="mb-4 main-content-label">
                                <p> LINKแนะนำ </p>

                                <Row className=" row-sm">
                                  <Col md={6}>
                                    <Form.Label className="form-label"></Form.Label>
                                  </Col>
                                </Row>
                                <div className="baba">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={`${
                                      state2.data && state2.data.perfix
                                    }signuppage?alliance=${
                                      state.data.datapro &&
                                      state.data.datapro.alliance
                                    }`}
                                  />
                                </div>
                                <Col md={6}></Col>
                                <CopyToClipboard
                                  onCopy={coppy}
                                  text={`${
                                    state2.data && state2.data.perfix
                                  }signuppage?alliance=${
                                    state.data.datapro &&
                                    state.data.datapro.alliance
                                  }`}
                                >
                                  <button
                                    className="btn btn-primary mt-5"
                                    onClick={coppy}
                                  >
                                    คัดลอก link
                                  </button>
                                </CopyToClipboard>
                              </div>
                            </Card.Body>
                            <Card.Body className=" border pd-b-10">
                              <h4 className="text-center">
                                รายชื่อเพื่อนที่แนะนำ
                              </h4>

                              <div className="col-lg-12">
                                <div className="card">
                                  <div className="card-body">
                                    <h4 className="card-title text-center">
                                      จำนวนเพื่อนที่ แนะนำ
                                    </h4>
                                    <p className="card-text">
                                      {state.data.aff && (
                                        <>
                                          <h1 className="text-center">
                                            {state.data.aff.length} คน{" "}
                                          </h1>{" "}
                                        </>
                                      )}
                                    </p>

                                    <Card>
                                      <CardBody>
                                        <CardTitle className="mb-3">
                                          รายชื่อที่แนะนำ
                                        </CardTitle>
                                        <div className="table-responsive">
                                          <table className="table table-bordered border-success mb-0">
                                            <thead>
                                              <tr>
                                                <th>Username</th>
                                                <th>ชื่อเพื่อนที่แนะนำ</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {state.data.aff &&
                                                state.data.aff.map(
                                                  (item: any, index: any) => {
                                                    return (
                                                      <>
                                                        <tr>
                                                          <th scope="row">
                                                            {item.username}
                                                          </th>
                                                          <td>{item.name}</td>
                                                        </tr>
                                                      </>
                                                    );
                                                  },
                                                )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="AccountSettings">
                          <div
                            className="main-content-body tab-pane  border-0"
                            id="settings"
                          >
                            <Card>
                              <CardBody>
                                <CardTitle className="mb-3">
                                  รายชื่อที่แนะนำ
                                </CardTitle>
                                <div className="table-responsive">
                                  <table className="table table-bordered border-success mb-0">
                                    <thead>
                                      <tr>
                                        <th>Username</th>
                                        <th>ชื่อ โปรโมชั่น</th>
                                        <th>จำนวนเครดิตที่ได้รับ</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {state.data.pro_history &&
                                        state.data.pro_history.map(
                                          (item: any, index: any) => {
                                            return (
                                              <>
                                                <tr>
                                                  <th scope="row">
                                                    {item.level_pro}
                                                  </th>
                                                  <td>{item.namepro}</td>
                                                  <td>{item.amount_d}</td>
                                                </tr>
                                              </>
                                            );
                                          },
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </span>
        </Col>
      </Row>

      <Modal
        isOpen={modal_between_modal}
        className="modal-xl"
        toggle={() => {
          tog_between_modal();
        }}
        scrollable={true}
        id="staticBackdrop"
      >
        <div className="modal-header ">
          <h5 className="modal-title">Backlist Usaers แบ็คลิสสมาชิก</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_between_modal(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <Backlist data={state.data.datapro} />
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setmodal_between_modal(false);
            }}
          >
            ปิด
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={modal_standard1}
        toggle={() => {
          addpro_user(null);
          //  getGames();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            ติดโปรโมชั่น
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard1(false);
              // getGames();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Addprousers
            isOpen={state_addpro.data}
            setUnLink={setmodal_standard1}
          />
        </div>
      </Modal>
      {/* <!-- Row --> */}
      <Row className=" row-sm">
        <Col lg={12} md={12}>
          <div className="tab-content"></div>
          {/* </div> */}
        </Col>
      </Row>
      {/* <!-- row closed --> */}
    </div>
  );
};

Profile.propTypes = {};

Profile.defaultProps = {};

export default Profile;
