import {
    Card,
    Col,
    Dropdown,
    Breadcrumb,
    Nav,
    Row,
    Tab,
    FormGroup,
    Form,
    Button,
  } from "react-bootstrap";
const Loaders = () => {
    return(
        <>
        <Card className="custom-card" id="loaders1">
                <Card.Body>
                  <div>
                   
                    <p className="text-muted card-sub-title text-center">
                     กำลังประมวนผล กรุณารอสักครู่..
                    </p>
                  </div>
                  <div className="text-center">
                    <div className="lds-dual-ring"></div>
                  </div>
                </Card.Body>
              </Card>
        </>
    )
}
export default Loaders