import React, { useState, useEffect, useReducer } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  FormGroup,
  InputGroup,
  Alert,
  Table,
} from "react-bootstrap";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Data_Parner } from "./data";
import { ExtralargeModal } from "./addparner";
import Apisetting from "../../api/Apisetting";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SettingAPI from "../api/SettingAPI";
import MaskInput from "react-maskinput";
import Pickr from "@simonwep/pickr";
import ApiAuth from "../../api/db";
import DatePicker from "react-datepicker";
import Moment from "moment";
import "moment/locale/th";
import Loader from "../../shade/Loaders/Loaders";
import { Data_Parnerdata_sumall } from "./data_sumall";
const Index_partner = () => {
  const [basic, setbasic] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const [smShowt, setSmShowt] = useState("");
  const [lgShow, setLgShow] = useState(false);
  const [gridshow, setGridshow] = useState(false);
  const [gridshow1, setGridshow1] = useState(false);

  const [loader_maxky, setLoader_maxky] = useState(false);
  const viewDemoClose = () => {
    setbasic(!basic);
  };

  const viewDemoClose2 = () => {
    return 1;
  };

  useEffect(() => {
    getapisetting();
    get_datapraner();
  }, []);

  const getapisetting = () => {
    SettingAPI.getdatasetting_fontend()
      .then((res) => {
        //console.log(res.data);
        //
        setState2({
          data: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [state2, setState2] = useReducer(
    (state2: any, newState2: any) => ({ ...state2, ...newState2 }),
    {
      data: [],
    }
  );

  async function chackdata(params: any) {
    let datapost = {
      partner_user: params,
    };
    ApiAuth.cHack_partner_emBer(datapost)
      .then((res) => {
        //console.log(res.data);
        //
        setState21({
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // Postcreate_partner
  const [data_ty, setdData_ty] = useState("ยอดเสีย");


  const get_datapraner = () => {



    let datap = {
      start_date: startDate,
      end_date: startDate,
      data_ty:data_ty
    };


    ApiAuth.post_datapartner_sumryv1(datap)
      .then((res) => {
        //console.log(res.data);
        //
        setState21({
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [state21, setState21] = useReducer(
    (state21: any, newState21: any) => ({ ...state21, ...newState21 }),
    {
      data: [],
    }
  );

  const [statepost, setStatepost] = useState({
    username: "",
    password: "",
    password2: "",
    phone: "",
    partner_ref: "",
    partner_x: "",
    name: "",
    linkpartner: "",
  });

  const {
    username,
    password,
    password2,
    phone,
    partner_ref,
    partner_x,
    name,
    linkpartner,
  } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  const addPostcreate_partner = (e: any) => {
    e.preventDefault();

    const postData = {
      username: username,
      password: password,
      password2: password2,
      phone: phone,
      partner_ref: linkpartner,
      partner_x: partner_x,
      name: name,
      linkpartner: linkpartner,
    };

    SettingAPI.Postcreate_partner(postData)
      .then((res) => {
        if (res.data.success == true) {
          setGridshow1(true);
          setSmShowt(res.data.msg);
          get_datapraner();
          setTimeout(function () {
            viewDemoClose();
          }, 1500);
        } else {
          setLgShow(true);
          setSmShowt(res.data.msg);
          setTimeout(function () {
            setLgShow(false);
          }, 4500);
        }
        console.log(res.data);
        //
        // setState2({
        //   data: res.data.data,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const coppy = () => {
    setGridshow(true);
    setTimeout(function () {
      setGridshow(false);
    }, 1500);
  };
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [weekstartDate, setWeekstartDate] = useState(new Date());

  const succ = (e: any) => {
    e.preventDefault();
  };

  const testconnect = () => {
    setLoader_maxky(true);

    let datap = {
      start_date: startDate,
      end_date: startDate,
      weekstartDate: weekstartDate,
      weekendDate: weekstartDate,
      data_ty:data_ty
    };

    ApiAuth.cHack_partner_emBer1(datap)
      .then((res) => {
        //console.log(res.data);
        //

        // console.log(res.data);
        setState21({
          data: res.data,
        });
        setTimeout(function () {
          setLoader_maxky(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Monthrange = () => {
    const handleChange = ([newStartDate, newEndDate]: any) => {
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    };
    return (
      <DatePicker
        selected={startDate}
        onChange={handleChange}
        selectsRange
        startDate={startDate}
        endDate={endDate}
        className="form-control"
        //showMonthYearPicker
      />
    );
  };

  const Yearrange = () => {
    return (
      <DatePicker
        className="form-control"
        selected={weekstartDate}
        onChange={(date: any) => setWeekstartDate(date)}
        showYearPicker
        dateFormat="yyyy"
      />
    );
  };

  return (
    <>
      <div>
        {/* <!-- breadcrumb --> */}
        <div className="breadcrumb-header justify-content-between">
          <div className="left-content">
            <span className="main-content-title mg-b-0 mg-b-lg-1">
              พันธมิตร
            </span>
          </div>
          <div className="justify-content-center mt-2">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item className="breadcrumb-item tx-15" href="#">
                พันธมิตร
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="breadcrumb-item "
                active
                aria-current="page"
              >
                พันธมิตร
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        {/* <Col lg={4} md={6} sm={4}>
          <Card className="custom-card">
            <Card.Body>
              <div>
                <h6 className="card-title">Extra-large</h6>
              </div>
              <ExtralargeModal setdata={viewDemoClose2} />
            </Card.Body>
          </Card>
        </Col> */}
        {/* <!-- /breadcrumb -->

					<!-- row --> */}
        <Row>
          <Col md={12} xl={12} xs={12} sm={12}>
            <Card>
              <Card.Body>
                <Row className=" row-sm">
                  <Col lg={4} className=" mg-t-20 mg-lg-t-0">
                    <h6>เริ่มจากวันที่ - สิ้นสุดวันที่</h6>
                    {/* <!-- input-group --> */}
                  </Col>
                </Row>
                <Row className=" row-sm">
                  <Col lg={4} className=" mg-t-20 mg-lg-t-0">
                    <InputGroup className="input-group reactdate-pic">
                      <InputGroup.Text className="input-group-text">
                        <i className="typcn typcn-calendar-outline tx-24 lh--9 op-6"></i>
                      </InputGroup.Text>
                      <Monthrange />
                    </InputGroup>
                    {/* <!-- input-group --> */}
                  </Col>
                  
                  <Col lg={4} className=" mg-t-20 mg-lg-t-0">
                    <div className="row">
                      <div className="col-6">
                        <select className="form-control" name="ty" onChange={e => setdData_ty(e.target.value)}>
                   
                          <option value="ยอดเสีย">ยอดเสีย</option>
                          <option value="ฝากครั้งแรก">ฝากครั้งแรก</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <button
                          className="btn btn-primary  btn-block w-50 "
                          onClick={() => {
                            testconnect();
                          }}
                        >
                          <i className="fa fa-search me-2"></i>ค้นหา
                        </button>
                      </div>
                    </div>
                  </Col>
                  {loader_maxky && (
                    <>
                      <Loader />
                    </>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className=" row-sm">
          <Col lg={12}>
            <Card className="custom-card overflow-hidden">
              <Card.Body>
                <div>
                  <h6 className="main-content-label mb-1">
                    รายงาน สรุปยอด partner
                  </h6>
                  <p className="text-muted card-sub-title"></p>
                </div>
                <div className="table-responsive stickyheader export-table">
                  {/* {state21.data.length != 0 && (
                    <>
                      <Data_Parner
                        datas={state21.data}
                        setdata={get_datapraner()}
                      />
                    </>
                  )} */}
                  {state21?.data?.data && (
                    <Data_Parnerdata_sumall data={state21?.data?.data} />
                  )}
                  <Col lg={4} className=" mg-t-20 mg-lg-t-0 float-end">
                    <Card className=" card-success card">
                      {/* <Card.Body>{state21?.data?.data && <>
                      
                      {state21?.data?.data[0].total_earning}
                      
                      </>}</Card.Body> */}
                    </Card>
                  </Col>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal
          show={basic}
          size="lg"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Button
              variant=""
              className="btn btn-close"
              onClick={() => {
                viewDemoClose();
                getapisetting();
                get_datapraner();
              }}
            >
              x
            </Button>
          </Modal.Header>
          <Modal.Body>
            <form className="" onSubmit={addPostcreate_partner}>
              <div className="tx-center">
                {" "}
                <i className="fab fa-pied-piper-alt tx-50 tx-info lh-1 mg-t-20 d-inline-block"></i>
                <h4 className="tx-danger mg-b-20">เพิ่มรายชื่อ พันธมิตร</h4>{" "}
                <div className="row">
                  <div className="col-10 mx-auto">
                    <label className="form-label float-start">
                      <span>*</span> Username
                    </label>
                    <InputGroup className="input-group mb-3">
                      <InputGroup.Text
                        className="input-group-text"
                        id="basic-addon1"
                      >
                        <i className="fa fa-address-book"></i>
                      </InputGroup.Text>
                      <Form.Control
                        aria-describedby="basic-addon1"
                        value={username}
                        onChange={inputvalue("username")}
                        required
                        name="username"
                        className="form-control"
                        placeholder="Username"
                        type="text"
                      />
                    </InputGroup>

                    <label className="form-label float-start">
                      <span>*</span> Password
                    </label>
                    <InputGroup className="input-group mb-3">
                      <InputGroup.Text
                        className="input-group-text"
                        id="basic-addon1"
                      >
                        <i className="fa fa-address-book"></i>
                      </InputGroup.Text>
                      <Form.Control
                        aria-describedby="basic-addon1"
                        aria-label="Password"
                        value={password}
                        onChange={inputvalue("password")}
                        required
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        type="password"
                      />
                    </InputGroup>

                    <label className="form-label float-start">
                      <span>*</span> ชื่อ
                    </label>
                    <InputGroup className="input-group mb-3">
                      <InputGroup.Text
                        className="input-group-text"
                        id="basic-addon1"
                      >
                        <i className="fa fa-address-book"></i>
                      </InputGroup.Text>
                      <Form.Control
                        aria-describedby="basic-addon1"
                        aria-label="ชื่อ"
                        value={name}
                        onChange={inputvalue("name")}
                        required
                        name="name"
                        className="form-control"
                        placeholder="ชื่อ"
                        type="text"
                      />
                    </InputGroup>

                    <label className="form-label float-start">
                      <span>*</span> เบอร์โทร
                    </label>
                    <InputGroup className="input-group mb-3">
                      <InputGroup.Text
                        className="input-group-text"
                        id="basic-addon1"
                      >
                        <i className="fa fa-address-book"></i>
                      </InputGroup.Text>
                      <Form.Control
                        aria-describedby="basic-addon1"
                        aria-label="เบอร์โทร"
                        value={phone}
                        onChange={inputvalue("phone")}
                        required
                        name="phone"
                        className="form-control"
                        placeholder="เบอร์โทร"
                        type="number"
                      />
                    </InputGroup>
                    
                    <label className="form-label float-start">
                      <span>*</span> เลขบัญชี
                    </label>
                    <InputGroup className="input-group mb-3">
                      <InputGroup.Text
                        className="input-group-text"
                        id="basic-addon1"
                      >
                        <i className="fa fa-address-book"></i>
                      </InputGroup.Text>
                      <Form.Control
                        aria-describedby="basic-addon1"
                        aria-label="เบอร์โทร"
                        value={phone}
                        onChange={inputvalue("phone")}
                        required
                        name="phone"
                        className="form-control"
                        placeholder="เบอร์โทร"
                        type="number"
                      />
                    </InputGroup>
                    <label className="form-label float-start">
                      <span>*</span> เบอร์โทร
                    </label>
                    <InputGroup className="input-group mb-3">
                      <InputGroup.Text
                        className="input-group-text"
                        id="basic-addon1"
                      >
                        <i className="fa fa-address-book"></i>
                      </InputGroup.Text>
                      <Form.Control
                        aria-describedby="basic-addon1"
                        aria-label="เบอร์โทร"
                        value={phone}
                        onChange={inputvalue("phone")}
                        required
                        name="phone"
                        className="form-control"
                        placeholder="เบอร์โทร"
                        type="number"
                      />
                    </InputGroup>
                    <div className="alert alert-secondary">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Partner_ref</label>{" "}
                            <div className="input-group">
                              <span className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fa fa-user" />
                                </span>
                              </span>{" "}
                              <input
                                type="text"
                                value={linkpartner}
                                onChange={inputvalue("linkpartner")}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>{" "}
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>ส่วนแบ่งที่ได้รับ %</label>{" "}
                            <div className="input-group">
                              <span className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fa fa-percent" />
                                </span>
                              </span>{" "}
                              <input
                                type="number"
                                step="0.1"
                                value={partner_x}
                                onChange={inputvalue("partner_x")}
                                required
                                name="partner_x"
                                className="form-control"
                              />
                            </div>
                          </div>{" "}
                          <small className="text-white-50">
                            พันธมิตรสามารถ login เพื่อดูสถิติของตนเองได้
                          </small>
                        </div>{" "}
                        {/**/}
                      </div>
                    </div>

                    {linkpartner != "" && (
                      <>
                        <div className="col-12">
                          <div className="input-group">
                            <input
                              type="url"
                              value={`${state2.data.bg_treem}/signuppage?partner=${linkpartner}`}
                              id="customer-url"
                              required
                              className="form-control"
                            />{" "}
                            <span className="input-group-append">
                              <CopyToClipboard
                                onCopy={coppy}
                                text={`${state2.data.bg_treem}/signuppage?partner=${linkpartner}`}
                              >
                                <button
                                  type="button"
                                  data-clipboard-target="#customer-url"
                                  className="btn btn-secondary customer-copy"
                                >
                                  <i className="fa fa-copy" />
                                </button>
                              </CopyToClipboard>
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    {gridshow && (
                      <>
                        <Alert className="fade alert fade show alert-dismissible alert-success alert show mt-3">
                          คัดลอกลิงค์ สำเร็จ
                        </Alert>
                      </>
                    )}
                    {lgShow && (
                      <>
                        <Alert className="fade alert fade show alert-dismissible alert-danger alert show mt-3">
                          <h2 className="text-center">เกิดข้อผิดพลาด</h2>
                          <h4>{smShowt}</h4>
                        </Alert>
                      </>
                    )}
                    {gridshow1 && (
                      <>
                        <Alert className="fade alert fade show alert-dismissible alert-success alert show mt-3">
                          <h2 className="text-center">ทำรายการสำเร็จ</h2>
                          <h4>{smShowt}</h4>
                        </Alert>
                      </>
                    )}
                  </div>
                </div>
                <hr />
                <Button
                  variant=""
                  aria-label="Close"
                  className="btn ripple btn-danger pd-x-25 my-2"
                  type="submit"
                >
                  บันทึก เพิ่มรายชื่อ พันธมิตร
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

Index_partner.propTypes = {};

Index_partner.defaultProps = {};

export default Index_partner;
