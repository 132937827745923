import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import Apisetting from "../../../api/Apisetting";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Data_con = (data: any) => {
  const [statepost, setStatepost] = useState({
    perfix: "",
    name_web: "",
    line: "",
    re_sitekey: "",
    re_secretkey: "",
    total_withdraw_amount: "",
    total_withdraw: "",
    total_timewithdraw: "",
  });

  // console.log(data.data.data);

  const {
    perfix,
    name_web,
    line,
    re_sitekey,
    re_secretkey,
    total_withdraw_amount,
    total_timewithdraw,
    total_withdraw,
  } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  const post_settingweb = (e: any) => {
    e.preventDefault();

    let datapost = {
      perfix: perfix,
      name_web: name_web,
      line: line,
      re_sitekey: re_sitekey,
      re_secretkey: re_secretkey,
      total_withdraw_amount: total_withdraw_amount,
      total_timewithdraw: total_timewithdraw,
      total_withdraw: total_withdraw,
    };

    Apisetting.Postupdate_settings(datapost)
      .then((res) => {
        toast.success("แก้ไข เกี่ยวกับเว็บ สำเร็จ ... ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // setTimeout(function () {

        // }, 5500);

        //
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Table className="table mg-b-0 text-md-nowrap">
        <thead>
          <tr>
            <th>ID</th>
            <th>หัวข้อหลัก กฎระเบียบ</th>
            <th>ข้อกำหนด</th>
            <th>แก้ไข/ลบ</th>
          </tr>
        </thead>
        <tbody>
          {/* {data.data.data && data.data.data.map((items:any,index:any)=> {

return(
  <>
  
  <button className="btn btn-primary"> {items.title} </button>
  
  </>
)

})} */}
          {data.data.data &&
            data.data.data.map((list: any, index: any) => (
              <tr key={index}>
                <th scope="row">{list.id}</th>
                <td>{list.title}</td>
                <td>
                  <Button variant="" type="button" className="btn  btn-primary">
                    ข้อกำหนด
                  </Button>
                </td>
                <td>
                  <div className="btn-icon-list btn-list">
                    <Button
                      variant=""
                      type="button"
                      className="btn btn-icon btn-info me-1"
                    >
                      <i className="fa fa-wrench"></i>
                    </Button>
                    <Button
                      variant=""
                      type="button"
                      className="btn btn-icon btn-danger me-1"
                    >
                      <i className="fa fa-trash"></i>
                    </Button>{" "}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {/* <Form className="form-horizontal" onSubmit={post_settingweb}>
        <FormGroup className="form-group">
          <Form.Label className="form-label">ชื่อเว็บไซต์</Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            placeholder="ชื่อเว็บไซต์"
            name="name_web"
            value={name_web}
            onChange={inputvalue("name_web")}
            defaultValue="ชื่อเว็บไซต์"
          />
        </FormGroup>
        <FormGroup className="form-group">
          <Form.Label className="form-label">Url Website</Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            placeholder="perfix"
            name="perfix"
            value={perfix}
            onChange={inputvalue("perfix")}
            defaultValue="perfix"
          />
        </FormGroup>
        <FormGroup className="form-group">
          <Form.Label className="form-label">Token Line@</Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            placeholder="ชื่อเว็บไซต์"
            name="re_sitekey"
            value={re_sitekey}
            onChange={inputvalue("re_sitekey")}
            defaultValue="Token Line@"
          />
        </FormGroup>
        <FormGroup className="form-group">
          <Form.Label className="form-label">Token re_secretkey</Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            name="re_secretkey"
            placeholder="re_secretkey"
            value={re_secretkey}
            onChange={inputvalue("re_secretkey")}
            defaultValue="re_secretkey"
          />
        </FormGroup>
        <FormGroup className="form-group">
          <Form.Label className="form-label">Line ติดต่อ</Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            name="line"
            placeholder="line ติดต่อ"
            value={line}
            onChange={inputvalue("line")}
            defaultValue="line ติดต่อ"
          />
        </FormGroup>
        <em className="mb-2 text-warning">
                <i className='fas fa-exclamation-circle'></i>  เป็นการตั้งค่า เกี่ยวกับ การถอนเงิน
              </em>
        <FormGroup className="form-group">
          <Form.Label className="form-label">กำหนดยอด ถอนเงินสูงสุดต่อ 1 User </Form.Label>
          <Form.Control
            type="number"
            className="form-control"
            name="total_withdraw_amount"
            placeholder="total_withdraw_amount"
            value={total_withdraw_amount}
            onChange={inputvalue("total_withdraw_amount")}
           // defaultValue="total_withdraw_amount"
          />
        </FormGroup>
        <FormGroup className="form-group">
          <Form.Label className="form-label">กำหนด จำนวน ถอนเงินสูงสุดต่อ 1 User </Form.Label>
          <Form.Control
            type="number"
            className="form-control"
            name="total_withdraw"
            placeholder="total_withdraw"
            value={total_withdraw}
            onChange={inputvalue("total_withdraw")}
          //  defaultValue="line ติดต่อ"
          />
        </FormGroup>
        <FormGroup className="form-group">
        <em className="mb-2 text-warning">
                <i className='fas fa-exclamation-circle'></i>  โดยจะนับจำนวน กี่นาที ลูกค้าถึงจะกดถอนได้ อีกครั้ง
              </em>
          <Form.Label className="form-label">กำหนด เวลา ถอนเงินต่อ1ครั้ง  </Form.Label> 
          <Form.Control
            type="text"
            className="form-control"
            name="total_timewithdraw"
            placeholder="กำหนด ถอนเงินต่อ1ครั้ง"
            value={total_timewithdraw}
            onChange={inputvalue("total_timewithdraw")}
           // defaultValue="line ติดต่อ"
          />
        </FormGroup>
        <FormGroup className="form-group mb-0 mt-3 justify-content-end">
          <div>
            <Button
              variant=""
              type="submit"
              className="btn btn-secondary btn-block w-50 mx-auto"
            >
              บันทึก
            </Button>
          </div>
        </FormGroup>
      </Form> */}

      <ToastContainer />
    </>
  );
};
export default Data_con;
