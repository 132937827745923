import axios from "axios";
import config from "../../config/app.config"
import { setAuthorizationToken } from "../../helpers/axios";

setAuthorizationToken();

//console.log(setAuthorizationToken())

const Apidatamember = {
    getProfile: async () => {
      
        var axiosConfig :any= {
          method: "get",
          url: `${config.apiURL}/api/v5/amb/getrpofiles`,
          headers: {
           
            "Content-Type": "application/json",
          },
         
        };
    
        //console.log(axiosConfig)
    
        return await axios(axiosConfig);
      },
      getProfilemy: async (data:any) => {
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/user/chack_getMember`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        //console.log(axiosConfig)
    
        return await axios(axiosConfig);
      },
      
    
      getUserDetail: async (data:any) => {
      
        var axiosConfig :any= {
          method: "get",
          url: `${config.apiURL}/api/v5/user/getuserdetail/${data}`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        return await axios(axiosConfig);
      },
      getdatabank: async () => {
      
        var axiosConfig :any= {
          method: "get",
          url: `${config.apiURL}/api/v5/amb/getdatabank`,
          headers: {
           
            "Content-Type": "application/json",
          },
         
        };
        return await axios(axiosConfig);
      },
      getDatatranferms: async () => {
      
        var axiosConfig :any= {
          method: "get",
          url: `${config.apiURL}/api/v5/amb/getDatatranferms`,
          headers: {
           
            "Content-Type": "application/json",
          },
         
        };
        return await axios(axiosConfig);
      },
      getdatatran_success: async () => {
      
        var axiosConfig :any= {
          method: "get",
          url: `${config.apiURL}/api/v5/amb/getdatatran_success`,
          headers: {
           
            "Content-Type": "application/json",
          },
         
        };
        return await axios(axiosConfig);
      },
      
      postchacktranscb: async (data:any) => {
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/scb/postchacktranscb`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        //console.log(axiosConfig)
    
        return await axios(axiosConfig);
      },

      post_transferconfirmation: async (data:any) => {
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/scb/post_transferconfirmation`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        //console.log(axiosConfig)
    
        return await axios(axiosConfig);
      },
    
      withdraw_amb: async (data:any) => {
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/amb/withdraw_amb`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        //console.log(axiosConfig)
    
        return await axios(axiosConfig);
      },
      deposit_amb: async (data:any) => {
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/amb/deposit_amb`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        //console.log(axiosConfig)
    
        return await axios(axiosConfig);
      },
      datapromotion: async () => {
      //  const token = getToken();
        var axiosConfig:any = {
          method: "get",
          url: `${config.apiURL}/api/v5/user/datapromotion`,
          headers: {
          
            "Content-Type": "application/json",
          }
        };
    
        //console.log(axiosConfig)
    
        return await axios(axiosConfig);
      },
      get_tranchack: async (data:any) => {
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/scb/get_tranchack`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        //console.log(axiosConfig)
    
        return await axios(axiosConfig);
      },
      get_tranchack_list: async (data:any) => {
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/scb/get_tranchack`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        //console.log(axiosConfig)
    
        return await axios(axiosConfig);
      },
      
      editPromotion: async (data:any) => {
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/user/addpro_user`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        //console.log(axiosConfig)
    
        return await axios(axiosConfig);
      },
      posttranscb: async (data:any) => {
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/scb/posttranscb`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        //console.log(axiosConfig)
    
        return await axios(axiosConfig);
      },
      posttrankbank: async (data:any) => {
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/scb/posttrankbank`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        //console.log(axiosConfig)
    
        return await axios(axiosConfig);
      },
    
    
      getbaba_kbank: async () => {
        
        
      },
      getbabascbnew: async (data:any) => {
        
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/scb/getbabascbnew`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        //console.log(axiosConfig)
    
        return await axios(axiosConfig);
      },


      post_login_scbnew: async (data:any) => {
        
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/scb/login_bank`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        //console.log(axiosConfig)
    
        return await axios(axiosConfig);
      },
      
      Addbankbeting: async (data:any) => {
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/amb/addbank`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        //console.log(axiosConfig)
    
        return await axios(axiosConfig);
    
       
      },
      withdraw_autos: async (data:any) => {
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/amb/withdraw_auto`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        //console.log(axiosConfig)
    
        return await axios(axiosConfig);
    
       
      },
      withdraw_auto_confirm: async (data:any) => {
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/amb/withdraw_auto_confirm`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        //console.log(axiosConfig)
    
        return await axios(axiosConfig);
    
       
      },
      deleteRolebank: async (data:any) => {
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/amb/deleteRolebank`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        return await axios(axiosConfig);
    
       
      },
      swap_online: async (data:any) => {
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/amb/swap_online`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        return await axios(axiosConfig);
    
       
      },
      Editoptionbank: async (data:any) => {
      
        var axiosConfig :any= {
          method: "post",
          url: `${config.apiURL}/api/v5/user/Editoptionbank`,
          headers: {
           
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        return await axios(axiosConfig);
    
       
      },
    
      
      gettran_wit: async () => {
      
        var axiosConfig :any= {
          method: "get",
          url: `${config.apiURL}/api/v5/amb/gettranwit`,
          headers: {
           
            "Content-Type": "application/json",
          },
         
        };
        return await axios(axiosConfig);
      },
      
      // posttranscb: async (data:any) => {
        
      //   return await axios.post(`${config.apiURL}/api/v3/scb/postchacktranscb`, data);
      // },
      noTiline: async (data:any) => {
        var axiosConfig :any= {
          method: "POST",
          url: `https://notify-api.line.me/api/notify`,
          headers: {
            Authorization: `Bearer XiXkTRvovU0lMhrbRYhs0tyhGWy9BIpC4zhhciTlh8y`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: data,
        };
    
        return await axios(axiosConfig);
      },
    
      loginufa1: async (data:any) => {
      
        var axiosConfig :any= {
          method: "post",
          url: `https://digital.isme99.com/HomeSmart.aspx?lang=EN-GB`,
          headers: {
           
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: data,
        };
    
        return await axios(axiosConfig);
      },
    
      postlogin: async (data:any) => {
        
        var axiosConfig:any = {
          method: "post",
          url: `${config.apiURL}/api/v5/user/loginadmin`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data
        };
    
        return await axios(axiosConfig);
      },

}


export default Apidatamember