import React, { useEffect, useMemo, useReducer, useState, useRef } from "react";
import { Breadcrumb, Card, Col, Row, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { VerticallyCenteredModal } from "./bnamk_option/add_bank";
import { Fixedheader } from "./bnamk_option/data_bankonline";
import DatagettranKbank from "./data_trankbank";
import Apibank from "../../../api/APIbank";
import AdminAPI from "../../api/Admin";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Form,
  Collapse,
  Alert,
  InputGroup,
} from "react-bootstrap";
import Swal from "sweetalert2";
import Edit_bank from "./editbank";
import Moment from "moment";
import "moment/locale/th";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Transfer_information from "./Transfer_information";
import Apidatamember from "../../api/Apidatamember";
import Loaders from "../../../components/loads/loaders";
import Dropzone from "react-dropzone";

const Account_deposit_withdraw = () => {
  // console.log(data);
  const [loadser_staus, setLoadser_staus] = useState(false);

  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
    }
  );
  const [state11, setState11] = useReducer(
    (state11: any, newState11: any) => ({ ...state11, ...newState11 }),
    {
      data: [],
    }
  );

  const [state2, setState2] = useReducer(
    (state2: any, newState2: any) => ({ ...state2, ...newState2 }),
    {
      data: [],
    }
  );

  const [statetranscb, setStatetranscb] = useReducer(
    (statetranscb: any, newStatetranscb: any) => ({
      ...statetranscb,
      ...newStatetranscb,
    }),
    {
      data: [],
    }
  );
  const [statekbank, setStatekbank] = useReducer(
    (statekbank: any, newStatekbank: any) => ({
      ...statekbank,
      ...newStatekbank,
    }),
    {
      data: [],
    }
  );
  const [statetrank, setStatetrank] = useReducer(
    (statetrank: any, newStatetrank: any) => ({
      ...statetrank,
      ...newStatetrank,
    }),
    {
      data: [],
    }
  );
  const [statetran, setStatetran] = useReducer(
    (statetran: any, newStatetran: any) => ({
      ...statetran,
      ...newStatetran,
    }),
    {
      data: [],
    }
  );
  const [statetranscc, setStatetranscc] = useReducer(
    (statetranscc: any, newStatetranscc: any) => ({
      ...statetranscc,
      ...newStatetranscc,
    }),
    {
      data: [],
    }
  );
  const [statetranscck, setStatetranscck] = useReducer(
    (statetranscck: any, newStatetranscck: any) => ({
      ...statetranscck,
      ...newStatetranscck,
    }),
    {
      data: [],
    }
  );
  //console.log(state2);
  let [Switcher, setSwitcher] = useState("on");
  let [Switcher2, setSwitcher2] = useState("on");
  let [Switcher3, setSwitcher3] = useState("on");
  let [Switcher4, setSwitcher4] = useState("on");
  let [showcon, setShowcon] = useState(false);
  let [showbabawit, setShowbabawit] = useState(false);
  let [showsuccess, setShowsuccess] = useState(false);
  let [showsuccessk, setShowsuccessk] = useState(false);
  let [showcon2, setShowcon2] = useState(false);
  let [showconk, setShowconk] = useState(false);
  let [showcon3, setShowcon3] = useState(false);
  let [showcon5, setShowcon5] = useState(false);
  let [showerrortext, setShowerrortext] = useState("");
  let [showerrortextsuccess, setShowerrortextsuccess] = useState("");
  let [showerror, setShowerror] = useState(false);
  let [showloader, setShowloader] = useState(false);
  let [showloader2, setShowloader2] = useState(false);
  let [show_alltrankbankedit, setShow_alltrankbankedit] = useState(false);
  let [show_alltrankbankedit1, setShow_alltrankbankedit1] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [showk, setShowk] = useState(false);

  let [show_datababa, setShow_datababa]: any = useState([]);

  const [show_modeledit, setShowk_modeledit] = useState(false);

  let viewDemoClose_modeledit = (modal: any) => {
    setShowk_modeledit(false);
  };
  const [show_alltrankbank, setShow_alltrankbank] = useState(false);

  const [showbaba, setShowbaba] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  useEffect(() => {
    getdatabanksss();
    databank_list_f();
  }, []);
  const databank_list_f = () => {
    AdminAPI.databank_list()
      .then((res) => {
        // console.log(res.data)

        setState111({ data: res.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [state111, setState111] = useReducer(
    (state111: any, newState111: any) => ({ ...state111, ...newState111 }),
    {
      data: [],
    }
  );

  let viewDemoShow = (modal: any) => {
    //console.log(modal)

    if (modal.from_b == "scb" || modal.from_b == "kbank") {
      setState2({
        data: modal,
      });
      setShow3(true);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "เกิดข้อผิดพลาด ธนาคาร นี้ไม่สามารถ โอนเงินได้",
        //footer: '<a href="">Why do I have this issue?</a>'
      });
    }
  };

  let [show_true, setShow_true] = useState(false);
  let [show_QRCODE, setShow_QRCODE] = useState(false);

  const [stat_t, setStat_t] = useReducer(
    (stat_t: any, newStat_t: any) => ({
      ...stat_t,
      ...newStat_t,
    }),
    {
      data: [],
    }
  );

  const tran_true = (data: any) => {
    setStat_t({ data: data });
    setShow_true(true);
  };

  let viewtran_trueClose = (modal: any) => {
    setShow_QRCODE(false);
  };
  let viewtran_trueClose2 = (modal: any) => {
    setShow_QRCODE(false);
  }

  const connect_online = (data: any) => {
    Apidatamember.swap_online(data)
      .then((res) => {
        if (res.data.success == true) {
          toast.success(`🦄 ทำรายการ ${res.data.message}!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          getdatabanksss();
        } else {
          toast.error(`🦄 ทำรายการ ${res.data.message}!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          getdatabanksss();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [state_e, setState_e] = useReducer(
    (state_e: any, newState_e: any) => ({ ...state_e, ...newState_e }),
    {
      data: [],
    }
  );

  let view_delist_bank = (modal: any) => {
    Swal.fire({
      title: "คุณแน่ใจหรือป่าว ที่จะลบบัญชี นี้ออกจากระบบ",
      text: "คุณไม่สามารถเปลี่ยนกลับได้!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Apidatamember.deleteRolebank(modal)
          .then((res) => {
            toast.success("🦄 ลบบัญชีธนาคารสำเร็จ!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            getdatabanksss();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
    // setState2({
    //   data: modal,
    // });
    // setShow3(true);
  };

  const swap_option_b = (data: any) => {
    Apidatamember.Editoptionbank(data)
      .then((res) => {
        // console.log(res.data);

        if (res.data.success) {
          Swal.fire("เปลี่ยนสำเร็จ!", "เปลี่ยนสำเร็จ สำเร็จ", "success");
          getdatabanksss();
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let viewkbank_Hitallclose = (modal: any) => {
    setShow_alltrankbank(false);
  };
  let viewkbank_HEditallclose = (modal: any) => {
    setShow_alltrankbankedit(false);
  };

  let viewkbank_Hitallclose1 = (modal: any) => {
    setShow_alltrankbankedit1(false);
  };
  const edit_bank = (data: any) => {
    // console.log(data)
    setState11({
      data: data,
    });
    setShow_alltrankbankedit1(true);
  };

  let viewDemoClose = (modal: any) => {

    setShowcon(false);

    setShowsuccess(false);


    setShow3(false);
  };

  let viewDemoClose2 = (modal: any) => {
    setShow4(false);
  };

  let viewDemoClose5 = (modal: any) => {
    setShow5(false);
  };

  let viewDemoClosek = (modal: any) => {
    setShowk(false);
  };
  async function getdatabanksss() {
    Apidatamember.getdatabank()
      .then((res) => {
        //console.log(res.data);
        setState({
          data: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // const url = `/api/req/acc_bank`;
    // const response = await axios.get(url);
    // setState({ data: response.data });
  }

  const tranferkbank = (data: any) => {
    //console.log(data);
    const datapost2 = {
      accnum: data.data2.accnum,
      pin: data.data2.password,
      de: data.data2.username,
      acc_to: data.data2.acc_to,
      monny: data.data2.monny,
      kbankInternalSessionId: data.data.kbankInternalSessionId,
      bank_to: data.data2.bank_to,
      data: data,
    };

    setShowloader2(true);
    Apidatamember.posttrankbank(datapost2)
      .then((res) => {
        if (res.data.success == true) {
          setShowcon(true);
          setShowcon2(false);
          setShowconk(false);
          setShowsuccessk(true);
          setStatetranscck({
            data: res.data,
          });
          setShowloader2(false);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const tranferscb = (data: any) => {

    var navigator_info = window.navigator;
    var screen_info = window.screen;
    var uid: any = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, "");
    uid += navigator_info.plugins.length;
    uid += screen_info.height || "";
    uid += screen_info.width || "";
    uid += screen_info.pixelDepth || "";

    let user_device_id = uid;

    // console.log(user_device_id);
    const datapost2 = {
      accnum: data.data.data2.accountFrom,
      pin: data.data.data2.password,
      de: data.data.data2.username,
      acc_to: data.data.data2.accountTo,
      monny: data.data.data2.amount,
      nodere: "",
      bank_to: data.data.data2.accountToBankCode,
      data: data,
      user_device_id: user_device_id,
    };
    // console.log(datapost2);
    // setShowloader2(true);
    Apidatamember.post_transferconfirmation(datapost2)
      .then((res) => {
        //console.log(res.data);
        setShowloader2(false);
        if (res.data.success == true) {
          getdatabanksss();
          databank_list_f();

          setShowcon(true);
          setShowcon2(false);
          setShowcon3(false);
          setShowsuccess(true);
          setStatetranscc({
            data: res.data,
          });
          setShowloader2(false);
        } else {
          setShowloader2(false);
        }
      })
      .catch((err) => {
        toast.error(`เกิดข้อผิดพลาด กรุณาลองใหม่ ในภายหลัง`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
    //console.log(data);
  };

  const chacktranscb = (data: any) => {
    setLoadser_staus(true);

    const datapost2 = {
      id: data.id,
    };
    Apidatamember.get_tranchack(datapost2)
      .then((res) => {
        if (res.data.success == true) {
          setLoadser_staus(false);

          if (res.data.from == "scb") {
            setStatetranscb({
              data: res.data.data,
            });

            setShowbabawit(false);
            setShow5(true);
          } else if (res.data.from == "kbank") {
            setStatekbank({
              data: res.data.data,
            });
            setShowbabawit(false);
            setShow_alltrankbank(true);
          } else {
            setShowbabawit(false);
          }
        } else {
          if (res.data.from == "scb") {
            setShowbabawit(false);
          } else {
            toast.error(`${res.data.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setShowbabawit(false);
          }
        }

        //console.log(res.data);
      })
      .catch((err) => {
        toast.error(`เกิดข้อผิดพลาด กรุณาลองใหม่ ในภายหลัง`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const [statepost, setStatepost] = useState({
    id_from: "",
    acc_to: "",
    date_time: "",
    monny: "",
    nodere: "",
    bank_to: "",
  });

  const { id_from, acc_to, monny, date_time, nodere, bank_to } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  // console.log(state2.data)
  const tranBank = (e: any) => {
    e.preventDefault();
    var navigator_info = window.navigator;
    var screen_info = window.screen;
    var uid: any = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, "");
    uid += navigator_info.plugins.length;
    uid += screen_info.height || "";
    uid += screen_info.width || "";
    uid += screen_info.pixelDepth || "";
    let user_device_id = uid;
    setShowloader(true);
    const datapost = {
      accnum: state2.data.accnum,
      pin: state2.data.password,
      de: state2.data.username,
      id: state2.data.id,
      acc_to: acc_to,
      monny: monny,
      nodere: "",
      bank_to: bank_to,
      user_device_id: user_device_id,
    };
    if (datapost.monny == "") {
      setShowerror(true);
      setShowerrortext("กรุณาระบุจำนวนเงินที่ต้องการโอน");
      setTimeout(function () {
        setShowerror(false);
      }, 3500);
    } else if (datapost.acc_to == "") {
      setShowerror(true);
      setShowerrortext("กรุณาระบุ เลขบัญชีปลายทาง ที่ต้องการโอน");
      setTimeout(function () {
        setShowerror(false);
      }, 3500);
    } else if (datapost.bank_to == "") {
      setShowerror(true);
      setShowerrortext("กรุณาระบุ ธนาคาร ที่ต้องการโอน");
      setTimeout(function () {
        setShowerror(false);
      }, 3500);
    } else {
      Apidatamember.postchacktranscb(datapost)
        .then((res) => {
          if (res.data.success == true) {
            getdatabanksss();
            databank_list_f();


            if (res.data.from == "scb") {



              if (res.data.data.status.code == 1000) {

                setShowcon(true);
                setShowcon3(true);
                setShowerrortextsuccess(`${res.data.data.status.description}`);
                setStatetran({
                  data: res.data,
                });
                getdatabanksss();
                setTimeout(function () {
                  setShowloader(false);
                  setShowerrortextsuccess("");
                }, 3500);
              } else if (res.data.data.status.code !== 1000) {
                setShowerror(true);
                setShowerrortext(`${res.data.data.status.description}`);

                setTimeout(function () {
                  setShowloader(false);
                  setShowerrortext("");
                }, 3500);
              } else {
                setShowerror(true);
                setShowerrortext(`${res.data.data.status.description}`);

                setTimeout(function () {
                  setShowloader(false);
                  setShowerrortext("");
                }, 3500);
              }
            } else {
              // if (res.data.data.freeText == "Success") {
              //   setShowloader(false);
              //   setShowcon(true);
              //   setShowconk(true);
              //   setStatetrank({
              //     data: res.data,
              //   });
              //   //getdatabanksss();
              // } else {
              //   setShowerror(true);
              //   //setShowerrortext(`${res.data.data.status.description}`);
              //   setTimeout(function () {
              //     setShowloader(false);
              //   }, 3500);
              // }
            }
          } else {
            // setShowerror(true);
            // setShowerrortext(`${res.data.message}`);
            // setTimeout(function () {
            //   setShowloader(false);
            // }, 3500);
          }
          // console.log(res.data.data);
        })
        .catch((err) => {
          toast.error(`เกิดข้อผิดพลาด กรุณาลองใหม่ ในภายหลัง`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });

      // console.log(datapost);
    }
  };

  //console.log(statetran);

  const [srcw, setSrcw] = useState("");
  const [success, setSuccess] = useState(false);



  const setupdate = (data: any) => {

    setSuccess(data)
    getdatabanksss();
    databank_list_f();

    //console.log(data  )

  }

  const [appshow2, setAppshow2] = useState(false);
  const [appshow, setAppshow] = useState(false);

  // ฟั่งชั่นเช็คยอดเงิน
  const [stateposttran, setStateposttran] = useState({
    otp: "",
    monny: "",
    bank_id: "",
  });
  const { otp } = stateposttran;
  const inputvalue2 = (name: any) => (event: any) => {
    setStateposttran({ ...stateposttran, [name]: event.target.value });
  };

  useEffect(() => {
    getGames();
  }, []);

  const [successMessage1, setSuccessMessage1] = useState(false);
  const getGames = () => {
    AdminAPI.getdata_AdminModule()
      .then((res) => {
        // console.log(res.data);

        if (res.data.success) {
          if (res.data.datapuper) {
            // console.log(res.data);

            setState2({
              data: res.data.dataadmin,
            });
            setSuccessMessage1(true);
          }

          // setState({
          //   data: res.data.users,
          // });
        } else {
        }
      })
      .catch((err) => {
        toast.error(`เกิดข้อผิดพลาด กรุณาลองใหม่ ในภายหลัง`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  function Approve_witconfrem(data: any) {
    const dapost = {
      otp: otp,
    };
    if (dapost.otp == "") {
      setSrcw("กรุณาระบุ OTP ");

      setTimeout(function () {
        setAppshow2(false);
      }, 5500);
    } else {
      AdminAPI.verifyOtp_tran(dapost)
        .then((res) => {
          //  console.log(res.data);
          if (res.data.success == true) {
            setAppshow(true);

            //   console.log(data);
            //setAppshow(true);
            const postData2 = {
              id: data.tran_id,
              tid: data.data_tran_id,
              accnum: data.accnum,
              // otp:""
              // otp:""
            };

            //console.log(postData2);
            AdminAPI.withdraw_auto_confirm(postData2)
              .then((res) => {
                // console.log(res.data);
                if (res.data.success) {
                } else {
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            setSrcw(res.data.msg);

            setTimeout(function () {
              setAppshow2(false);
            }, 5500);

            //setAppshow2(false)
            // console.log(res.data);
          }
        })
        .catch((err) => {
          toast.error(`เกิดข้อผิดพลาด กรุณาลองใหม่ ในภายหลัง`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  }

  const handleClick = (data: any) => {
    const datapost2 = {
      data: data,
    };
    setShowbabawit(true);
    Apidatamember.post_login_scbnew(datapost2)
      .then((res) => {
        //console.log(res.data);
        setTimeout(function () {
          setShowbabawit(false);
        }, 8500);
        if (res.data.success == true) {
          toast.success(`ยอดเงินคงเหลือ ในบัญชี ${res.data.data} . บาท`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          getdatabanksss();
        } else {
          toast.error(`${res.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        toast.error(`เกิดข้อผิดพลาด กรุณาลองใหม่ ในภายหลัง`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
    // console.log(data);
  };

  const handleClick_chack_balance = (data: any) => {
    setLoadser_staus(true);

    let datapost = {
      data: data,
    };

    Apibank.postchack_balance(datapost)
      .then((res) => {
        if (res.data.success == true) {
          setSuccess(true);

          setLoadser_staus(false);
          setShow_datababa(res.data);



        } else {
          setLoadser_staus(false);
        }

        // console.log(res.data);
      })
      .catch((e) => {
        toast.error(`เกิดข้อผิดพลาด กรุณาลองใหม่ ในภายหลัง`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

      });

    //console.log(statetran)
  };

  const reload = () => {
    getdatabanksss();
  };
  const [dataAccTrue, setDataAccTrue] = useState<any>([]);
  const ModeluploadQRcode = (e: any) => {
    setDataAccTrue({
      data: e
    })
    setShow_QRCODE(true)
    // 
  }
 // console.log(dataAccTrue.data.id)

  const [selectedFiles, setselectedFiles] = useState<any>([]);
  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),

        formattedSize: formatBytes(file.size),
      })
    );

    setselectedFiles(files);
  }

  //console.log(selectedFiles)
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const validationContactForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      img_url: [],
    },
    validationSchema: Yup.object({

    }),
    onSubmit: (values) => {
      const data = new FormData();


      data.append("img_url", selectedFiles[0]);
      data.append("id", dataAccTrue.data.id);
      Apibank.uploadImgTrue(data)
        .then((res) => {
          console.log(res.data)
          if (res.data.success) {
            setTimeout(function () {
              //setShowloadingw(false);
            }, 6500);
          } else {
            //   setSuccessMessage("");
          }
        })
        .catch(function (error) {
          console.log("error ", error);
        });
    },
  });

  // console.log(show_datababa);
  return (
    <>
      {loadser_staus ? (
        <Loaders />
      ) : (
        <>
          <div className="breadcrumb-header justify-content-between">
            <div className="left-content">
              <span className="main-content-title mg-b-0 mg-b-lg-1">
                ตั้งค่าบัญชีฝาก-ถอน
              </span>
            </div>
            <div className="justify-content-center mt-2">
              <Breadcrumb>
                <Breadcrumb.Item className="tx-15" href="#">
                  บัญชีธนาคาร
                </Breadcrumb.Item>
                <Breadcrumb.Item active aria-current="page">
                  ตั้งค่าบัญชีฝาก-ถอน
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <Row className="row-sm">
            <Col sm={12} md={12} lg={12} xl={12}>
              <Card>
                <Card.Body>
                  <Modal show={success}>
                    <Modal.Header>
                      <p>{show_datababa && <>ยอดคงเหลือในบัญชี</>}</p>
                      <Button
                        variant=""
                        className="btn btn-close"
                        onClick={() => {
                          setupdate(false);
                        }}
                      >
                        x
                      </Button>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="tx-center ">
                        <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-success lh-1 mg-t-20 d-inline-block"></i>{" "}
                        <h4 className="tx-success tx-semibold mg-b-20">
                          {show_datababa && <> {show_datababa?.message}</>}
                        </h4>{" "}
                        <h2>ยอดคงเหลือในบัญชี</h2>
                        <h2>
                          {show_datababa?.data?.totalAvailableBalance} บาท
                        </h2>
                        <p className="mg-b-20 mg-x-20">
                          อัพเดทเมื่อ :{" "}
                          {Moment(show_datababa?.data?.currentDatetime).format(
                            "lll"
                          )}
                        </p>
                        <Button
                          variant=""
                          aria-label="Close"
                          className="btn ripple btn-danger pd-x-25"
                          type="button"
                          onClick={() => {
                            setupdate(false);
                          }}
                        >
                          ปิด
                        </Button>{" "}
                      </div>
                    </Modal.Body>
                  </Modal>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="row-sm">
            <Col sm={12} md={12} lg={12} xl={12}>
              <Card>
                <Card.Body>
                  <VerticallyCenteredModal setdata={reload} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
          <Row>
            {state.data &&
              state.data.map((row: any, index: any) => {
                return (
                  <>
                    {row.from_b != "bonus" && (
                      <>
                        <Col sm={12} lg={4} md={12} key={index}>
                          <Card className="card custom-card our-team">
                            <Card.Body>
                              <div className="contact-info mb-0 text-center">
                                {row.status_connact != 1 && (
                                  <>
                                    <div className="avatar-xl mx-auto mb-4 ">
                                      <span className="avatar-title bg-soft-light text-light display-4 m-0 rounded-circle">
                                        <img
                                          alt="avatar"
                                          src={require(`../../../assets/img/bank3/${row.from_b}.png`)}
                                          className=""
                                        />
                                      </span>
                                    </div>
                                    <Button
                                      className="btn me-2 btn-danger-gradient"
                                      title="ลบบัญชี"
                                      onClick={() => view_delist_bank(row)}
                                    >
                                      <i className="far fa-trash-alt"></i>
                                    </Button>
                                  </>
                                )}
                                {row.status_connact == 1 ? (
                                  <>
                                    <div className="avatar-xl mx-auto mb-4 ">
                                      <span className="avatar-title bg-soft-light text-light display-4 m-0 rounded-circle">
                                        <img
                                          alt="avatar"
                                          src={require(`../../../assets/img/bank3/${row.from_b}.png`)}
                                          className=""
                                        />
                                      </span>
                                    </div>
                                    {/* <Button
                                  className="btn me-2 btn-danger-gradient"
                                  title="ลบบัญชี"
                                  onClick={() =>
                                    view_delist_bank(row)
                                  }
                                >
                                  <i className="far fa-trash-alt"></i>
                                </Button> */}
                                    <div className="text-center mt-3">
                                      <h5 className="pro-user-username text-dark mt-2 mb-0">
                                        {row.fullname}
                                      </h5>
                                      <h5 className="pro-user-username text-dark mt-2 mb-0">
                                        {row.accnum}
                                      </h5>
                                      <p className="pro-user-desc text-muted mb-1">
                                        {row.name}
                                      </p>
                                      <p className="user-info-rating text-center"></p>
                                      {showbabawit && (
                                        <>
                                          <Button
                                            className="w-100 mb-2"
                                            onClick={() => handleClick(row)}
                                            disabled
                                          >
                                            <p className="text-center">
                                              กรุณารอสักครู่......
                                            </p>
                                            <img
                                              src="https://www.lady711.com/images/loaderNew.svg"
                                              alt=""
                                              width={50}
                                            />
                                          </Button>
                                        </>
                                      )}

                                      {successMessage1 && (
                                        <>
                                          <div className="font-weight-semibold">
                                            <div className="d-flex flex-wrap gap-2">
                                              {row.from_b != "bonus" && (
                                                <>
                                                  <Button
                                                    className="btn me-2 btn-danger-gradient"
                                                    title="ลบบัญชี"
                                                    onClick={() =>
                                                      view_delist_bank(row)
                                                    }
                                                  >
                                                    <i className="far fa-trash-alt"></i>
                                                  </Button>
                                                  {/* ประวัติเข้า-ออก เงิน */}
                                                  <Button

                                                    className={`btn btn-primary-gradient ${row.from_b == "truewallet" && 'd-none'}`}
                                                    title="ประวัติเข้า-ออก เงิน"
                                                    onClick={() =>
                                                      chacktranscb(row)
                                                    }
                                                  >
                                                    <i className="fas fa-history"></i>
                                                  </Button>
                                                  {/* โอนเงิน */}
                                                  <Button
                                                    className={`btn btn-success-gradient ${row.from_b == "truewallet" && 'd-none'}`}
                                                    title="โอนเงิน"
                                                    onClick={() =>
                                                      viewDemoShow(row)
                                                    }
                                                  >
                                                    <i className="far fa-share-square"></i>
                                                  </Button>
                                                  <Button
                                                    className="btn btn-info-gradient"
                                                    onClick={() =>
                                                      edit_bank(row)
                                                    }
                                                  >
                                                    <i
                                                      className="far fa-edit"
                                                      title="แก้ไขบัญชี"
                                                    ></i>
                                                  </Button>

                                                  <button
                                                    type="button"
                                                    className="btn btn-primary-gradient waves-effect waves-light d-none"
                                                    onClick={() =>
                                                      edit_bank(row)
                                                    }
                                                  >
                                                    <i className="fas fa-credit-card label-icon" />
                                                  </button>
                                                  {/* สลับบัญชี */}
                                                  {row.from_b == "truewallet" ? <><Button
                                                    className="btn btn-info"
                                                    onClick={() =>
                                                      ModeluploadQRcode(row)
                                                    }
                                                  >
                                                    อัพโหลด QRcode
                                                  </Button></> : <><Button
                                                    className="btn btn-info"
                                                    onClick={() =>
                                                      swap_option_b(row)
                                                    }
                                                  >
                                                    สลับบัญชี
                                                  </Button></>}

                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}

                                <Col
                                  sm={12}
                                  lg={12}
                                  className={`${row.option_b == "ฝาก"
                                    ? "bg-success-gradient"
                                    : "bg-danger-gradient"
                                    } mt-5 `}
                                >
                                  <Card.Body className="iconfont text-center">
                                    {row.status_scb != 1 ? (
                                      <>
                                        {" "}
                                        <h3> *** ธนาคาร ถูกปิดทำงาน </h3>
                                        <h3> </h3>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {!showbabawit && (
                                      <>
                                        {row.from_b != "bonus" && (
                                          <>
                                            <Button
                                              className="btn me-2 btn-warning-gradient "
                                              onClick={() =>
                                                handleClick_chack_balance(row)
                                              }
                                            >
                                              {`${row.status_connact == 1
                                                ? "ยอดคงเหลือ"
                                                : "connact Api"
                                                }`}
                                            </Button>
                                          </>
                                        )}
                                      </>
                                    )}
                                    <h2 className="mt-1 text-center">
                                      {" "}
                                      {row.option_b}
                                    </h2>
                                    {row.option_b == "ฝาก" && (
                                      <>
                                        <h5 className="text-danger ">{`${row.status_connact == 1
                                          ? `สถานะ บัญชี  ${row.option_b}  :  ออนไลน์`
                                          : `สถานะ บัญชี :  ออฟไลน์`
                                          }`}</h5>
                                      </>
                                    )}

                                    {row.option_b == "ถอน" && (
                                      <>
                                        <h5 className="text-success">{`${row.status_connact == 1
                                          ? `สถานะ บัญชี ${row.option_b} : ออนไลน์`
                                          : `สถานะ บัญชี : ออฟไลน์`
                                          }`}</h5>
                                      </>
                                    )}
                                    <div className="text-center"></div>
                                  </Card.Body>
                                </Col>

                                <hr></hr>

                                {/* <div className="my-2">
                          <Button
                            variant={`${
                              row.option_b == "ถอน"
                                ? "success-gradient"
                                : "danger-gradient"
                            }`}
                            title="สลับ เปลี่ยนเป็น ประเภท"
                            onClick={() => swap_option_b(row)}
                          >
                            สลับเป็น{" "}
                            {`${row.option_b == "ถอน" ? "ฝาก" : "ถอน"}`}
                          </Button>
                        </div> */}
                                <Col
                                  sm={12}
                                  lg={12}
                                  className={`${row.status_connact == 1
                                    ? "bg-success-gradient"
                                    : "bg-danger-gradient"
                                    }`}
                                >
                                  <Card.Body className="iconfont text-center">
                                    {row.status_scb != 1 ? (
                                      <>
                                        {" "}
                                        <Button
                                          className="btn btn-btn btn-info"
                                          title="เปิดการใช้งาน"
                                          onClick={() => connect_online(row)}
                                        >
                                          เปิดหน้าฝาก-ถอน
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        {row.from_b != "bonus" && (
                                          <>
                                            <Button
                                              className="btn btn-danger"
                                              title="ปิดการใช้งาน"
                                              onClick={() =>
                                                connect_online(row)
                                              }
                                            >
                                              ปิดการใช้งาน
                                            </Button>
                                          </>
                                        )}
                                      </>
                                    )}
                                    {row.from_b != "bonus" && (
                                      <>
                                        <h2 className="mt-1 text-center">
                                          {" "}
                                          ยอดคงเหลือ {row.baba}
                                        </h2>
                                      </>
                                    )}

                                    <div className="text-center"></div>
                                  </Card.Body>
                                </Col>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </>
                    )}
                  </>
                );
              })}

            {/* โอนเงินออนไลน์ */}
            <Modal show={show3} size="lg" backdrop="static">
              <Modal.Header
                closeButton
                onClick={() => {
                  viewDemoClose("show3");
                }}
              >
                <Modal.Title>โอนเงินออนไลน์</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Modal.Title>แบบฟรอม การโอนเงิน</Modal.Title>
                <Form onSubmit={tranBank}>
                  <Col lg={12} xl={12} md={12}>
                    <Card className=" custom-card  crypto-buysell-card">
                      <Card.Body>
                        <div className="card-header border-bottom-0 ps-0 pt-0">
                          {showerrortext && (
                            <>
                              <Alert
                                className="alert alert-danger mb-0"
                                role="alert"
                              >
                                <Alert className="alert-heading alert-danger text-center">
                                  เกิดข้อผิดพลาด!!!
                                </Alert>

                                <hr />
                                <h3 className="text-center text-info">
                                  {showerrortext}
                                </h3>
                              </Alert>
                            </>
                          )}

                          {showerrortextsuccess && (
                            <>
                              <Alert
                                className="alert alert-success mb-0"
                                role="alert"
                              >
                                <Alert className="alert-heading alert-success text-center">
                                  Success!!!
                                </Alert>

                                <hr />
                                <h3 className="text-center text-info">
                                  {showerrortext}
                                </h3>
                              </Alert>
                            </>
                          )}
                        </div>

                        {!showcon && (
                          <>
                            <label className="main-content-label my-auto">
                              บัญชีต้นทาง
                            </label>
                            <div className="form-group">
                              <label className="font-weight-semibold tx-16 mt-3 mb-2"></label>
                              <div className="p-3 border d-flex">
                                <div className="d-flex">
                                  <span className="crypto-icon bg-primary-transparent me-3">
                                    <img
                                      alt="avatar"
                                      src={require(`../../../assets/img/bank3/${state2.data.from_b}.png`)}
                                    />
                                  </span>
                                  {/* <span className="my-auto tx-18 font-weight-semibold">
                                                                {
                                                                    state2.data
                                                                        .accnum
                                                                }
                                                            </span> */}
                                </div>
                                <div className="d-flex ms-auto">
                                  <div className="card-item-stat d-none d-md-block">
                                    <h4 className="font-weight-semibold mb-0 tx-15">
                                      {state2.data.name}
                                    </h4>
                                    <h4 className="font-weight-semibold mb-0 tx-15">
                                      {state2.data.accnum}
                                    </h4>
                                    <h6 className="font-weight-semibold mb-0 tx-15 mt-3 text-info">
                                      ยอดเงินคงเหลือ {state2.data.baba}
                                    </h6>
                                    {/* <small className="tx-13 float-end text-end text-muted">
                                                                    {
                                                                        state2
                                                                            .data
                                                                            .option_b
                                                                    }
                                                                </small> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="font-weight-semibold tx-16 mt-2 mb-2">
                                เลือก ธนาคารปลายทาง
                              </label>
                              <div className="p-3 border">
                                <span className="crypto-icon bg-primary-transparent">
                                  <i className="fa fa-bank text-primary"></i>
                                </span>
                                <div className="my-auto ms-3">
                                  <select
                                    className="form-control"
                                    // name="choices-single-no-search"
                                    id="choices-single-no-search"
                                    name="tobank_bank"
                                    placeholder={bank_to}
                                    value={bank_to}
                                    onChange={inputvalue("bank_to")}
                                  >
                                    {state111.data &&
                                      state111.data.map(
                                        (item: any, index: any) => {
                                          return (
                                            <>
                                              <option
                                                value={item.bank_id}
                                                key={index}
                                              >
                                                {item.bankNameTh}{" "}
                                                <img
                                                  alt="avatar"
                                                  src={`https://easyapi.scorpion168.com/assets/method/4.png`}
                                                />
                                              </option>
                                            </>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>
                              </div>
                            </div>

                            <hr className="my-2" />
                            <div className="d-flex mt-3 mb-3">
                              <p className="tx-16 mb-0 text-muted">
                                ระบุเลขบัญชีปลายทาง
                              </p>
                            </div>
                            <div className="row">
                              <div className="form-group col-xl-12 col-lg-12 col-md-12 mb-0">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control input-lg wd-80p"
                                    defaultValue=""
                                    name="acc_to"
                                    value={acc_to}
                                    onChange={inputvalue("acc_to")}
                                  />
                                </div>
                              </div>
                              <Col
                                xl={12}
                                lg={12}
                                md={12}
                                className="form-group text-center my-auto"
                              >
                                <div className="d-flex mt-3 mb-3">
                                  <p className="tx-16 mb-0 text-muted">
                                    ระบุจำนวนเงิน
                                  </p>
                                </div>
                              </Col>
                              <Col
                                xl={12}
                                lg={12}
                                md={12}
                                className="form-group mb-0"
                              >
                                <InputGroup className="input-group">
                                  <input
                                    name="monny"
                                    type="text"
                                    value={monny}
                                    onChange={inputvalue("monny")}
                                    className="form-control input-lg wd-80p"
                                  //defaultValue="50"
                                  />
                                </InputGroup>
                              </Col>
                            </div>
                            <div className="max mt-3">
                              {showloader ? (
                                <Button className="w-100 mb-2" disabled>
                                  <p className="text-center">
                                    กรุณารอสักครู่......
                                  </p>
                                  <img
                                    src="https://www.lady711.com/images/loaderNew.svg"
                                    alt=""
                                    width={50}
                                  />
                                </Button>
                              ) : (
                                <>
                                  {!showloader && (
                                    <>
                                      <button
                                        className="btn btn-primary btn-lg btn-block mt-4"
                                        type="submit"
                                      >
                                        ยืนยันโอนเงิน
                                      </button>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Form>
                {showcon3 && (
                  <>
                    <Col className="col-md-12 mx-auto ">
                      <Card className="card alert-message ">
                        <Card.Body className="flex-1 bg-gray-700 ht-100p p-3  max-bgscb">
                          <div className="text-center">
                            {/* <img
                                      alt="avatar"
                                      src={`https://friend420-support.com//assets/img/bank3/${statetran.data.data2.bank_to}.png`}
                                    />

                                    <h3 className="mt-4 mb-3">
                                      {statetran.data.data.data.accountTo}
                                    </h3> */}
                            <div className="col-md ">
                              <div
                                className={`overflow-hidden card-blog-overlay1 custom-card card max-bg${state2?.data?.bank_to}`}
                              >
                                <div className=" z-index2 card-body">
                                  <h4 className="text-danger maxbg-danger my-3">
                                    ข้อมูลบัญชีรับโอน
                                  </h4>
                                  <img
                                    alt="avatar"
                                    src={require(`../../../assets/img/bank3/${state2?.data?.bank_to}.png`)}
                                  />
                                  <h3>
                                    {statetran?.data?.data?.data?.accountTo}
                                  </h3>
                                  <h3 className=" mb-0">
                                    {statetran?.data?.data?.data?.accountToName}
                                  </h3>
                                  <h2>
                                    จำนวนเงินที่โอน :{" "}
                                    {statetran?.data?.data2?.amount} บาท{" "}
                                  </h2>
                                  ****************
                                  <h5 className="text-danger mb-0">
                                    {statetran?.data?.data?.data?.warningHeader}
                                  </h5>
                                  <h5 className="text-danger mb-0">
                                    {statetran?.data?.data?.data?.warningMsg}
                                  </h5>
                                </div>
                              </div>
                            </div>

                            {!showloader2 && (
                              <>
                                <button
                                  className="btn btn-primary w-50 mx-auto"
                                  onClick={() => tranferscb(statetran)}
                                >
                                  {" "}
                                  ยืนยันโอนเงิน
                                </button>
                              </>
                            )}
                            {showloader2 && (
                              <Button className="w-100 mb-2" disabled>
                                <p className="text-center">
                                  กรุณารอสักครู่......
                                </p>
                                <img
                                  src="https://www.lady711.com/images/loaderNew.svg"
                                  alt=""
                                  width={50}
                                />
                              </Button>
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </>
                )}
                {showconk && (
                  <>
                    <Col className="col-md-12 mx-auto ">
                      <Card className="card alert-message ">
                        <Card.Body className="flex-1 bg-gray-700 ht-100p p-3  max-bgscb">
                          <div className="text-center">
                            {/* <img
                                      alt="avatar"
                                      src={`https://friend420-support.com//assets/img/bank3/${statetran.data.data2.bank_to}.png`}
                                    />

                                    <h3 className="mt-4 mb-3">
                                      {statetran.data.data.data.accountTo}
                                    </h3> */}
                            <div className="col-md ">
                              <div
                                className={`overflow-hidden card-blog-overlay1 custom-card card max-bg${state2.data.from_b}`}
                              >
                                <div className=" z-index2 card-body">
                                  <img
                                    alt="avatar"
                                    src={require(`../../../assets/img/bank3/${state2.data.from_b}.png`)}
                                  />
                                  <h3>{statetrank.data.data2.accnum}</h3>
                                  <p className=" tx-13 mb-0">
                                    {state2.data.name}
                                  </p>
                                </div>
                                <div className=" maxbg-danger">
                                  บัญชีโอนเงิน
                                </div>
                              </div>
                            </div>
                            <div className="col-md">
                              <div
                                className={`overflow-hidden card-blog-overlay1 custom-card card max-bg${statetrank.data.data2.bank_to}`}
                              >
                                <div className=" z-index2 card-body">
                                  <img
                                    alt="avatar"
                                    src={require(`../../../assets/img/bank3/${statetrank.data.data2.bank_to}.png`)}
                                  />
                                  <h3>{statetrank.data.data.toAccountNo}</h3>
                                  <p className=" tx-13 mb-0">
                                    {statetrank.data.data.toAccountName}
                                  </p>
                                </div>
                                <div className=" maxbg-success ">
                                  บัญชีรับเงิน
                                </div>
                              </div>
                            </div>

                            <h2>
                              จำนวนเงินที่โอน : {statetrank.data.data2.monny}{" "}
                              บาท{" "}
                            </h2>

                            {!showloader2 && (
                              <>
                                <button
                                  className="btn btn-primary w-50 mx-auto"
                                  onClick={() => tranferkbank(statetrank.data)}
                                >
                                  {" "}
                                  ยืนยันโอนเงิน
                                </button>
                              </>
                            )}
                            {showloader2 && (
                              <button
                                type="button"
                                className="btn btn-dark waves-effect waves-light"
                              >
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />{" "}
                                Loading
                              </button>
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </>
                )}
                {showsuccess && (
                  <>
                    <Col xl={12} lg={12} md={12}>
                      <Card className="custom-card wallet">
                        <Card.Body>
                          <h2 className="text-center">
                            {" "}
                            โอนเงิน {statetranscc.data.data.status.description}
                          </h2>
                          <p className="text-center">
                            {Moment(
                              statetranscc.data.data.transactionDateTime
                            ).format("lll")}
                          </p>
                          <section className="text-center pt-3">
                            <div>
                              <h5 className="text-center mb-4">
                                เลขอ้างอิง{" "}
                                {statetranscc.data.data.data.transactionId}
                              </h5>
                            </div>
                            <i
                              className="mdi mdi-check-all label-icon"
                              style={{
                                color: "green",
                                fontSize: "90px",
                              }}
                            ></i>
                            <div className=" row-sm px-4 row">
                              <div className="col-xl-12 col-lg-12">
                                <div className=" border custom-card card">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <span className="crypto-icon bg-primary-transparent me-3 my-auto">
                                        <i className="fas fa-wallet text-primary" />
                                      </span>
                                      <div>
                                        <p className="text-uppercase tx-13 text-muted mb-1">
                                          ยอดเงินคงเหลือ
                                        </p>
                                        <h5>
                                          {
                                            statetranscc.data.data.data
                                              .remainingBalance
                                          }{" "}
                                          บาท
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>

                          <br />
                          <span className="text-muted"></span>
                          <div className="d-flex mt-4">
                            <div className="wd-240 ht-240 me-3 mx-auto">
                              <img
                                src={statetranscc.data.qrcode}
                                alt="cureency"
                              />
                            </div>
                          </div>
                          <div className="input-group my-4">
                            <span className="input-group-addon-left bg-light">
                              {/* <i className={`cf cf-${items.bitcoin}`}></i> */}
                            </span>
                            <input
                              type="text"
                              className="form-control input-lg"
                              defaultValue={statetranscc.data.qrcode}
                              id="ethereum-wallet"
                            />
                            <span
                              className="input-group-addon-right bg-light clipboard-icon"
                              data-clipboard-target="#ethereum-wallet"
                            >
                              <i className="fe fe-copy"></i>
                            </span>
                          </div>

                          {/* <Row className=" mt-4">
                            <div className="col-6">
                              <button className="btn btn-block btn-primary"  onClick={() => tranferscb(statetran.data)}>
                               โอนเงินอีกครั้ง
                              </button>
                            </div>
                            <div className="col-6">
                              <button className="btn btn-block btn-info">
                               โอนเงินบัญชีอื่น
                              </button>
                            </div>
                          </Row> */}
                        </Card.Body>
                      </Card>
                    </Col>
                  </>
                )}
                {showsuccessk && (
                  <>
                    <Col xl={12} lg={12} md={12}>
                      <Card className="custom-card wallet">
                        <Card.Body>
                          <h2 className="text-center">
                            {" "}
                            โอนเงิน {statetranscck.data.data.freeText}
                          </h2>
                          <p className="text-center">
                            {/* {Moment(
                              statetranscck.data.data.uxDate,
                            ).format("lll")} */}
                            {Moment.unix(statetranscck.data.data.uxDate).format(
                              "lll"
                            )}
                          </p>
                          <section className="text-center pt-3">
                            <div>
                              <h5 className="text-center mb-4">
                                เลขอ้างอิง{" "}
                                {statetranscck.data.data.transactionReference}
                              </h5>
                            </div>
                            <svg
                              className="wd-80 ht-80 mx-auto justify-content-center mb-3 text-center"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 130.2 130.2"
                            >
                              <circle
                                className="path circle"
                                fill="none"
                                stroke="#73AF55"
                                strokeWidth={6}
                                strokeMiterlimit={10}
                                cx="65.1"
                                cy="65.1"
                                r="62.1"
                              />
                              <polyline
                                className="path check"
                                fill="none"
                                stroke="#73AF55"
                                strokeWidth={6}
                                strokeLinecap="round"
                                strokeMiterlimit={10}
                                points="100.2,40.2 51.5,88.8 29.8,67.5 "
                              />
                            </svg>
                            <div className=" row-sm px-4 row">
                              <div className="col-xl-12 col-lg-12">
                                <div className=" border custom-card card">
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <span className="crypto-icon bg-primary-transparent me-3 my-auto">
                                        <i className="fas fa-wallet text-primary" />
                                      </span>
                                      <div>
                                        <p className="text-uppercase tx-13 text-muted mb-1">
                                          ยอดเงินคงเหลือ
                                        </p>
                                        <h5>
                                          {
                                            statetranscck.data.data
                                              .availableBalance
                                          }{" "}
                                          บาท
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>

                          <br />
                          <span className="text-muted"></span>
                          <div className="d-flex mt-4">
                            <div className="wd-240 ht-240 me-3 mx-auto">
                              <img
                                src={statetranscck.data.imgqr}
                                alt="cureency"
                              />
                            </div>
                          </div>
                          <div className="input-group my-4">
                            <span className="input-group-addon-left bg-light">
                              {/* <i className={`cf cf-${items.bitcoin}`}></i> */}
                            </span>
                            <input
                              type="text"
                              className="form-control input-lg"
                              defaultValue={statetranscck.data.imgqr}
                              id="ethereum-wallet"
                            />
                            <span
                              className="input-group-addon-right bg-light clipboard-icon"
                              data-clipboard-target="#ethereum-wallet"
                            >
                              <i className="fe fe-copy"></i>
                            </span>
                          </div>

                          {/* <Row className=" mt-4">
                            <div className="col-6">
                              <button className="btn btn-block btn-primary"  onClick={() => tranferscb(statetran.data)}>
                               โอนเงินอีกครั้ง
                              </button>
                            </div>
                            <div className="col-6">
                              <button className="btn btn-block btn-info">
                               โอนเงินบัญชีอื่น
                              </button>
                            </div>
                          </Row> */}
                        </Card.Body>
                      </Card>
                    </Col>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    viewDemoClose("show3");
                  }}
                >
                  โอนเงินบัญชีอื่น
                </Button>
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    viewDemoClose("show3");
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            {/* เพิ่มบัญชีธนาคาร */}
            <Modal show={show4} size="lg" backdrop="static">
              <Modal.Header
                closeButton
                onClick={() => {
                  viewDemoClose2("show4");
                }}
              >
                <Modal.Title>เพิ่มบัญชีธนาคาร</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Modal.Title>เลือกประเภทของบัญชี</Modal.Title>
                {/* <Addbank /> */}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn btn-primary"
                  onClick={() => {
                    viewDemoClose2("show4");
                  }}
                >
                  Save Changes
                </Button>
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    viewDemoClose2("show4");
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            {/* โมดเดลแก้ไข */}
            <Modal show={show5} size="lg" backdrop="static">
              <Modal.Header
                closeButton
                onClick={() => {
                  viewDemoClose5("show5");
                }}
              >
                <Modal.Title>แก้ไขบัญชี</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Modal.Title>เลือกประเภทของบัญชี</Modal.Title>
                <Transfer_information datatran={statetranscb?.data} />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn btn-primary"
                  onClick={() => {
                    viewDemoClose5("show5");
                  }}
                >
                  Save Changes
                </Button>
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    viewDemoClose5("show5");
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            {/* จบแก้ */}

            {/* โมเดล kbank */}

            <Modal show={showk} size="lg" backdrop="static">
              <Modal.Header
                closeButton
                onClick={() => {
                  viewDemoClosek("showk");
                }}
              >
                <Modal.Title>แก้ไขบัญชี</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Modal.Title>เลือกประเภทของบัญชี</Modal.Title>
                {/* <Editbank /> */}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn btn-primary"
                  onClick={() => {
                    viewDemoClosek("showk");
                  }}
                >
                  Save Changes
                </Button>
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    viewDemoClosek("showk");
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            {/* ประวัติขิง kbank */}
            <Modal
              show={show_alltrankbank}
              size="lg"
            //   backdrop="show_alltrankbank"
            >
              <Modal.Header
                closeButton
                onClick={() => {
                  viewkbank_Hitallclose("show_alltrankbank");
                }}
              >
                <Modal.Title>แก้ไขบัญชี</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Modal.Title>เลือกประเภทของบัญชี</Modal.Title>
                <DatagettranKbank datatran={statekbank.data} />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn btn-primary"
                  onClick={() => {
                    viewkbank_Hitallclose("show_alltrankbank");
                  }}
                >
                  Save Changes
                </Button>
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    viewkbank_Hitallclose("show_alltrankbank");
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            {/* แก้ไขบัญชี */}

            <Modal show={show_alltrankbankedit1} size="lg">
              <Modal.Header
                closeButton
                onClick={() => {
                  viewkbank_Hitallclose1("show_alltrankbankedit1");
                }}
              >
                <Modal.Title>โอนเงินทรูวอเลท</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Edit_bank data={state11.data} />
              </Modal.Body>
              <Modal.Footer>
                {/* <Button
                  className="btn btn-primary"
                  onClick={() => {
                    viewkbank_Hitallclose1("show_alltrankbankedit1");
                  }}
                >
                  Save Changes
                </Button> */}
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    viewkbank_Hitallclose1("show_alltrankbankedit1");
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            {/* โอนเงินทรูวอเลท */}
            <Modal show={show_true} size="lg">
              <Modal.Header
                closeButton
                onClick={() => {
                  viewtran_trueClose("show_true");
                }}
              >
                <Modal.Title>โอนเงินทรูวอเลท</Modal.Title>
              </Modal.Header>
              <Modal.Body>{/* <Tran_true data={stat_t.data} /> */}</Modal.Body>
              <Modal.Footer>
                {/* <Button
                  className="btn btn-primary"
                  onClick={() => {
                    viewkbank_Hitallclose1("show_alltrankbankedit1");
                  }}
                >
                  Save Changes
                </Button> */}
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    viewtran_trueClose("show_true");
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            {/* อัพโหลด QRCODE */}
            <Modal show={show_QRCODE} size="lg">
              <Modal.Header
                closeButton
                onClick={() => {
                  viewtran_trueClose2("show_true");
                }}
              >
                <Modal.Title>อัพโหลดรูป QRCODE</Modal.Title>
              </Modal.Header>
              <Modal.Body>{/* <Tran_true data={stat_t.data} /> */}</Modal.Body>
              <Modal.Footer>
                <div className="col-12">
                  <div className="md-3">
                    <div className="">
                      <div className="product-image">
                        <div className="d-flex flex-row justify-content-center mg-b-20 ">
                          <div className="pd-10 bg-gray-200">
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                validationContactForm.handleSubmit();
                                return false;
                              }}
                            >
                              <Dropzone
                                onDrop={(acceptedFiles) => {
                                  handleAcceptedFiles(acceptedFiles);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                  >
                                    <div className="mx-auto">
                                      {" "}
                                      <div className="file-image-1 file-image-lg">
                                        <div className="product-image">

                                          {selectedFiles.map((f: any, i: number) => {
                                            return (
                                              <Card
                                                className="mt-1 mb-0 shadow-none  dz-processing dz-image-preview dz-success dz-complete"
                                                key={i + "-file"}
                                              >
                                                <div className="p-2">
                                                  <Row className="align-items-center">
                                                    <Col className="col-auto ">
                                                      <img
                                                        data-dz-thumbnail=""
                                                        height=""
                                                        className="file-image-1 file-image-lg mx-auto"
                                                        alt={f.name}
                                                        src={f.preview}
                                                      />
                                                    </Col>
                                                    <Col>

                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Card>
                                            );
                                          })}

                                        </div>
                                      </div>
                                    </div>


                                  </div>
                                )}
                              </Dropzone>
                              <div className="grid-example mt-2 mt-sm-0">
                                <button className="btn btn-info w-100" type="submit">
                                  อัพโหลด
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    viewtran_trueClose2("show_true");
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Row>
        </>
      )}
    </>
  );
};

Account_deposit_withdraw.propTypes = {};

Account_deposit_withdraw.defaultProps = {};

export default Account_deposit_withdraw;
