import React from "react";
import { useEffect, useReducer, useState, useMemo } from "react";
import {
  Breadcrumb,
  Card,
  Col,
  Row,
  Button,
  Tabs,
  Form,
  Tab,
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import { Modal, FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Apiscb from "../../api/Apiscb";
import Lightbox from "react-image-lightbox";
import Add_manual from "./deposit_manual";
import Swal from "sweetalert2";
import { VerticallyCenteredModal } from "./data_add";
import {
  ToastContainer,
  toast,
  Bounce,
  Slide,
  Flip,
  Zoom,
} from "react-toastify";
import Modal_msg from "./modal_msg";
import { Teble_slip } from "./teble_slip";
import Apiufa from "../../api/AgentUfaapi";
import Dropzone from "react-dropzone";
import Loaders from "@Components/loads/loaders";
//import Model_img from "shade/model_img/model_img";
import { socket } from "@Utils/socket";
import Model_img from "@shade/model_img/model_img";
import Apiscbs from "@api/Apiscb";
const Check_slip = () => {
  const [show, setShow] = React.useState(true);
  let [isFirstCollapsed, setisFirstCollapsed] = React.useState(true);

  useEffect(() => {
    getGames();
    //  chackon();
  }, []);

  useEffect(() => {
    socket.on("create_sendNotification_linebot", (data) => {
      getGames();
    });
  }, [socket]);

  const getGames = () => {
    const postdata = {
      startdaty: "panding",
    };
    Apiufa.get_qrapikbank_img(postdata)
      .then((res) => {
        // console.log(res.data);

        if (res.data.success) {
          // console.log(res.data.daauser)
          setState({
            data: res.data.data,
          });
          setmodal_center2(res.data.panding);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
    }
  );
  const [state2, setState2] = useReducer(
    (state2: any, newState2: any) => ({ ...state2, ...newState2 }),
    {
      data: [],
    }
  );
  const [modal_center, setmodal_center] = useState(false);
  const [modal_center2, setmodal_center2]: any = useState([]);

  const [remove, Setremove] = React.useState(true);
  const [remove_show, SetRemove_show] = React.useState(false);
  const [remove_mo, Setremove_mo] = React.useState(false);
  const [remove_msg, Setremove_msg] = React.useState(false);
  const [url_img, SetUrl_img] = React.useState("");
  const [remove_msgdata, Setremove_msgdata] = React.useState("");
  const [dataimg, SetDataimg] = React.useState("");

  const [fullscreen, setFullscreen] = useState(false);
  const [showLoaders, setShowLoaders] = React.useState(false);

  const [shows, setShows] = React.useState(false);
  const [showmodel_slib, setShowmodel_slib] = React.useState(false);
  const [datasbip, setDatasbip]: any = React.useState("");
  const [err, setErr]: any = React.useState("");

  function tog_center2() {
    setFullscreen(!fullscreen);
    removeBodyCss();
  }

  const onchang_imgurl = (data: any) => {
    SetUrl_img(data);
  };
  const onchang_imgurl_sow = (data: any) => {
    SetDataimg(data);
    SetRemove_show(true);
  };
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  const desposit_app = (data: any) => {
    setState2({
      data: data,
    });
    tog_center();
    //console.log(data)
  };
  const setmodel_msg = (data: any) => {
    Setremove_msgdata(data);
    Setremove_msg(true);
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const chackqr = (data: any) => {
    const postdata = {
      id: data.id,
    };
    Apiscb.chack_qrapikbankby(postdata)
      .then((res) => {
        // console.log(res.data);

        if (res.data.success) {
          // console.log(res.data.daauser)
          // setState({
          //   data: res.data.data,
          getGames();
          // });
        } else {
          getGames();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const delrit_qr = (data: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "คุณแน่ใจมัย ที่จะลบโปรโมชั่นนี้",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let datap = {
          id: data,
        };

        Apiscb.delit_qr(data)
          .then((res) => {
            // console.log(res.data);

            if (res.data.success) {
              // console.log(res.data)
              toast.success(
                <p className="text-white tx-16 mb-0">{`ลบ Successfully`}</p>,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  hideProgressBar: true,
                  autoClose: 5000,
                  theme: "colored",
                }
              );
              getGames();
            } else {
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const Export = ({ onExport }: any) => (
    <VerticallyCenteredModal pp={() => onExport} />
  );

  const [selectedFiles, setselectedFiles] = useState<any>([]);

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),

        formattedSize: formatBytes(file.size),
      })
    );

    setselectedFiles(files);
  }
  const chack_dataslib = (data: any) => {
    setShowmodel_slib(true);
    setShowLoaders(true);

    Apiscbs.checkSlipTransfer(data)
      .then((res) => {
        // console.log(res.data);

        if (res.data.success) {
          // console.log(res.data)
          setShowLoaders(false);
          setDatasbip({ data: res.data.data });
          getGames();
        } else {
          setShowLoaders(false);
          setErr(res.data.msg);
          getGames();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    //console.log(data)
  };

  //console.log(selectedFiles)
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const actionsMemo = React.useMemo(() => <Export onExport={() => {}} />, []);

  const uploadimg_utl = () => {
    // Apiufa.uploadimgqr_url
    console.log(url_img);
  };
  const codessetdata_allsbip = (data: any) => {
    //console.log(data);
    //setDatasbip({ data: data });
    setDatasbip("");
    setShowmodel_slib(false);
  };

  const uploadimg_nomol = () => {
    const data = new FormData();
    data.append("user_member", "");
    data.append("img_url", selectedFiles[0]);
    Apiscb.upload_imgqrcodeadmin(data)
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => {});
    // console.log(url_img);
  };
  const setdata_all = () => {
    getGames();
  };
  const setdata_allsbip = (data: any) => {
    //console.log(data);
    setDatasbip({ data: data });
    setShowmodel_slib(true);
  };
  //console.log(datasbip);
  return (
    <div>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">เพิ่ม รูปภาพ</h5>

          <button
            type="button"
            onClick={() => {
              setmodal_center(false);
              getGames();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {/* <Add_manual data={state2.data} /> */}
      </Modal>
      <Modal
        show={fullscreen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            as="h6"
            className="modal-title"
            id="contained-modal-title-vcenter"
          ></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                <Tabs
                  defaultActiveKey="Tab 01"
                  className=" panel-tabs main-nav-line "
                >
                  <Tab eventKey="Tab 01" title="อัพโหลดแบบ url">
                    <div
                      className="panel-body tabs-menu-body main-content-body-right"
                      id="tab4"
                    >
                      <Col lg={12} xl={12} md={12} sm={12}>
                        <Card className="box-shadow-0">
                          <Card.Header>
                            {url_img && (
                              <>
                                <img
                                  src={url_img}
                                  alt=""
                                  className="card-img mb-3"
                                />
                              </>
                            )}

                            <h4 className="card-title mb-1">
                              ลิงค์ไฟล์รูปภาพที่ต้องการอัพโหลด
                            </h4>
                          </Card.Header>
                          <Card.Body className=" pt-0">
                            <Form className="form-horizontal">
                              <FormGroup className="form-group">
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="inputName"
                                  onChange={(e) =>
                                    onchang_imgurl(e.target.value)
                                  }
                                  placeholder="Url รูปภาพ"
                                />
                              </FormGroup>

                              <FormGroup className="form-group mb-0 mt-3 justify-content-end">
                                <div>
                                  <Button
                                    variant=""
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => uploadimg_utl()}
                                  >
                                    ยืนยันการอัพำโหลด
                                  </Button>
                                </div>
                              </FormGroup>
                            </Form>
                          </Card.Body>
                        </Card>
                      </Col>
                    </div>
                  </Tab>
                  <Tab eventKey="Tab 02" title="อัพโหลด แบบไฟล์รูป">
                    <div
                      className="panel-body tabs-menu-body main-content-body-right "
                      id="tab5"
                    >
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className="dz-message needsclick mt-2 mx-auto"
                            {...getRootProps()}
                          >
                            <div className="mx-auto">
                              {" "}
                              <div className="">
                                <div className="product-image mx-auto">
                                  {/* <img
                                      data-dz-thumbnail=""
                                      height=""
                                      className=""
                                      alt={validationContactForm.values.img_url}
                                      src={validationContactForm.values.img_url}
                                    /> */}
                                  {selectedFiles.length > 0 ? (
                                    ""
                                  ) : (
                                    <div className="btn btn-success btn-block w-50 mx-auto">
                                      อัพไฟล์ไฟล์รูป
                                    </div>
                                  )}

                                  {selectedFiles.map((f: any, i: number) => {
                                    return (
                                      <Col
                                        className="col-auto maxky-auto"
                                        style={{ margin: "0 auto" }}
                                      >
                                        <img
                                          data-dz-thumbnail=""
                                          //width={30}
                                          className=""
                                          alt={f.name}
                                          src={f.preview}
                                          style={{ maxWidth: "50%" }}
                                        />
                                      </Col>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      {selectedFiles.length > 0 ? (
                        <>
                          <FormGroup className="form-group">
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="inputName"
                              onChange={(e) => onchang_imgurl(e.target.value)}
                              placeholder="Url รูปภาพ"
                            />
                          </FormGroup>
                        </>
                      ) : (
                        <>
                          <div
                            className="btn btn-success btn-block w-50 mx-auto"
                            onClick={() => uploadimg_nomol()}
                          >
                            ยืนยันอัพไฟล์ไฟล์รูป
                          </div>
                        </>
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setFullscreen(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showmodel_slib}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            as="h6"
            className="modal-title"
            id="contained-modal-title-vcenter"
          ></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showLoaders ? <Loaders /> : <></>}
          {datasbip && (
            <>
              <Col lg={12} xl={12} md={12} sm={12}>
                <div
                  role="alert"
                  className="fade alert alert alert-success show text-center mt-5"
                >
                  {" "}
                  หมายเลขสลิป : {datasbip.data.pullSlip_transRef}
                </div>
              </Col>
              <Col xl={12} md={12} lg={12}>
                <Card className="mg-b-20 text-center">
                  <div className="row">
                    <Col xl={6} md={12} lg={6}>
                      <div className="danger-widget">
                        <h3 className="mt-3 text-danger">ธนาคาร</h3>
                        <p className="mt-3 mb-0">
                          <img
                            src={datasbip.data.sender_bankLogo}
                            className="float-sm-center wd-200p wd-sm-100 br-5"
                            alt=""
                          />
                        </p>
                        {datasbip.data.sender_accountNumber}
                        <p>ชื่อผู้โอน</p>
                        <h4 className="">{datasbip.data.sender_name}</h4>
                      </div>
                    </Col>

                    <Col xl={6} md={12} lg={6}>
                      <div className="danger-widget">
                        <h3 className="mt-3 text-danger">ธนาคาร</h3>
                        <p className="mt-3 mb-0">
                          <img
                            src={datasbip.data.receiver_bankLogo}
                            className="float-sm-center wd-200p wd-sm-100 br-5"
                            alt=""
                          />
                        </p>
                        {datasbip.data.receiver_accountNumber}
                        <p>ชื่อผู้รับโอน</p>
                        <h4>{datasbip.data.receiver_name}</h4>
                      </div>
                    </Col>
                    <Col xl={12} md={12} lg={12}>
                      <h4>
                        เวลาดำเดินการ : {datasbip.data.pullSlip_dateTime}{" "}
                      </h4>
                      <h4>จำนวนเงิน {datasbip.data.amount} บาท </h4>
                    </Col>
                  </div>
                </Card>
              </Col>
            </>
          )}

          {err && (
            <>
              <div className="bg-danger text-white card">
                <div className="card-body">
                  <div className="text-white card-body">
                    <div className="main-error-wrapper">
                      <i className="si si-close mg-b-20 tx-50" />
                      <h4 className="mg-b-0">{err}</h4>
                      <h4 className="mg-b-0">เกิดข้อผิดพลาด</h4>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => codessetdata_allsbip(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            ตรวจสอบสลิป
          </span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb>
            <Breadcrumb.Item className="tx-15" href="#">
              บัญชีธนาคาร
            </Breadcrumb.Item>
            <Breadcrumb.Item active aria-current="page">
              ตรวจสอบสลิป
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <Card className="mg-b-20">
        <Card.Body>
          <Col lg={12}>
            <Card className="custom-card">
              <Card.Header>
                {/* <div className="d-flex   p-3 br-5">
                  <div className="pd-10"></div>
                  <div className="pd-10 ">
                    {" "}
                    <h4 className="card-title mb-1">รายการรอดำเนินการ</h4>
                    <p className="mb-2 text-danger">
                      <i className="fas fa-exclamation-circle"></i>{" "}
                      สลิปสามารถเช็คได้ย้อนหลัง 7 วัน !!!
                    </p>
                  </div>
                  <div className="pd-10 mg-l-auto">
                    <Button variant="primary" onClick={() => tog_center2()}>
                      เพิ่มรูปภาพสลิป
                    </Button>
                  </div>
                </div> */}
              </Card.Header>
              <Card.Body>
                <div className="mx-auto text-center">
                  <div className="table-responsive">
                    <table className="table text-nowrap table-bordered border-success">
                      <thead>
                        <tr>
                          <th scope="col">รูปโปรไฟล์</th>
                          <th scope="col">ชื่อผู้ส่ง</th>
                          <th scope="col">รูปภาพที่ส่ง</th>
                          <th scope="col">Status</th>
                          <th scope="col">ตรวจสอบ</th>
                          <th scope="col">จัดการ</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {modal_center2.length > 0 &&
                          modal_center2.map((item: any, index: number) => {
                            return (
                              <>
                                <tr className="">
                                  <td>
                                    <div
                                      onClick={() =>
                                        onchang_imgurl_sow(item.line_pictureUrl)
                                      }
                                    >
                                      <img
                                        className="rounded float-sm-right"
                                        src={item.line_pictureUrl}
                                        width={50}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td scope="row">{item.line_displayName}</td>
                                  <td
                                    className=""
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Link
                                      className="main-msg-send"
                                      to="#"
                                      onClick={() =>
                                        onchang_imgurl_sow(item.line_images)
                                      }
                                    >
                                      <img
                                        className="rounded float-sm-right"
                                        src={item.line_images}
                                        width={50}
                                      />
                                    </Link>
                                  </td>

                                  <td>
                                    {item.status_header == "panding" && (
                                      <>
                                        <h5 className=" text-warning mt-1 mb-1 me-3">
                                          รายการรอดำเนินการ
                                        </h5>
                                      </>
                                    )}{" "}
                                    {item.status_header == "success" && (
                                      <>
                                        <h4 className="text-success mt-1 mb-1 me-3">
                                          ทำรายการสำเร็จ
                                        </h4>
                                      </>
                                    )}{" "}
                                    {item.status_header == "topupalready" && (
                                      <p className="text-danger">
                                        เติมเครดิตไปแล้ว
                                      </p>
                                    )}{" "}
                                    {item.status_header == "notaccnum" && (
                                      <>
                                        <p className="text-warning">
                                          ไม่พบบัญชีฝาก
                                        </p>
                                      </>
                                    )}{" "}
                                  </td>
                                  <td>
                                    {item.status_header == "panding" && (
                                      <>
                                        <button
                                          className="btn btn-warning mt-1 mb-1 me-3"
                                          onClick={() => chack_dataslib(item)}
                                        >
                                          ตรวจสอบ
                                        </button>
                                      </>
                                    )}{" "}
                                    {item.status_header == "success" && (
                                      <>
                                        <button
                                          className="btn btn-success mt-1 mb-1 me-3"
                                          onClick={() => setdata_allsbip(item)}
                                        >
                                          ดูรายละเอียดสลิป
                                        </button>
                                      </>
                                    )}{" "}
                                    {item.status_header == "notaccnum" && (
                                      <>
                                        <p className="text-warning">
                                          ไม่พบบัญชีฝาก
                                        </p>
                                      </>
                                    )}{" "}
                                    {item.status_header == "topupalready" && (
                                      <p className="text-danger">
                                        เติมเครดิตไปแล้ว
                                      </p>
                                    )}{" "}
                                  </td>
                                  <td>
                                    {item.status_header == "success" && (
                                      <>
                                        <Button
                                          variant="danger"
                                          className="btn me-2 btn-info-gradient  mx-auto"
                                          onClick={() => setmodel_msg(item)}
                                        >
                                          ส่งแจ้งลูกค้า
                                        </Button>
                                      </>
                                    )}{" "}
                                    {item.status_header == "notaccnum" && (
                                      <>
                                        <Button
                                          variant="danger"
                                          className="btn me-2 btn-info-gradient  mx-auto"
                                          onClick={() => setmodel_msg(item)}
                                        >
                                          ส่งแจ้งลูกค้า
                                        </Button>
                                      </>
                                    )}{" "}
                                    {item.status_header == "panding" && (
                                      <>รอดำเนินการ</>
                                    )}{" "}
                                    {item.status_header == "topupalready" && (
                                      <p className="text-danger">
                                        เติมเครดิตไปแล้ว
                                      </p>
                                    )}{" "}
                                    {/* <button className="btn btn-info btn-block w-50 mx-auto">แจ้งการทำรายการลูกค้า</button> */}
                                  </td>
                                  <td>
                                    {/* <button className="btn btn-info btn-block w-50 mx-auto">แจ้งการทำรายการลูกค้า</button> */}
                                    <button
                                      type="button"
                                      className="btn btn-icon btn-block btn-danger me-1 btn mx-auto"
                                      onClick={() => delrit_qr({ id: item.id })}
                                    >
                                      <i className="fe fe-trash"></i>{" "}
                                    </button>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Card.Body>
      </Card>

      <Card className="mg-b-20">
        <Card.Body>
          <Col lg={12}>
            <Card className="custom-card">
              <Card.Header>
                <h4 className="card-title mb-1">สลิปการโอน</h4>
                <p className="mb-2 text-danger">
                  <i className="fas fa-exclamation-circle"></i>{" "}
                  สลิปสามารถเช็คได้ย้อนหลัง 7 วัน !!!
                </p>
              </Card.Header>
              <Card.Body>
                <div className="table-responsive">
                  {state.data && <Teble_slip data={state.data} />}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Card.Body>
      </Card>

      <Modal_msg
        remove_msg={remove_msg}
        setFullscreen={Setremove_msg}
        dataall={remove_msgdata}
      />
      <Model_img
        remove_show={remove_show}
        setRemove_show={SetRemove_show}
        dataimg={dataimg}
      />
      <ToastContainer />
    </div>
  );
};
Check_slip.propTypes = {};

Check_slip.defaultProps = {};

export default Check_slip;
