import React, { useEffect, useMemo, useReducer, useState } from "react";
import Moment from "moment";
import "moment/locale/th";
import { Button, Form } from "react-bootstrap";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import {
  Breadcrumb,

  Card,
  Col,
 
  FormGroup,
  InputGroup,
  Row,
  Table,
  Modal,
} from "react-bootstrap";
//import Moment from "moment";
//import "moment/locale/th";
import ProMotionAPI from "../api/ProMotion";
import Edit_pro from "./editpro";
import { Setdataall } from "./index";
import Swal from "sweetalert2";


export const COLUMNS: any = [
  //   { Header: "รหัส", accessor: "id" },
  { Header: "ชื่อสมาชิก", accessor: "username" },
  { Header: "เทริน", accessor: "x" },
  { Header: "โปรโมชั่น", accessor: "namepro" },
  { Header: "เงินฝาก", accessor: "amount_d" },
  { Header: "วิธีรับ", accessor: "level_pro" },
  { Header: "เครดิตที่รับ", accessor: "pro_amount" },
  { Header: "วันที่รับ", accessor: "limit_w" ,
    Cell: ({ row }: any) => {
      const { values } = row;
      return (
        <>
         {Moment(values.created_at).format('llll')}
        </>
      )
    } },
  // {
  //   Header: "รูปภาพ",
  //   accessor: "img_name",
  //   Cell: ({ row }: any) => {
  //     const { values } = row;
  //     return (
  //       <>
  //         <img
  //           className="img-sm"
  //           src={values.img_name}
  //           alt="thumb images"
  //         ></img>
  //       </>
  //     );
  //   },
  // },

  // {
  //   Header: "สถานะ",
  //   accessor: "status_online",
  //   Cell: ({ row }: any) => {
  //     const { values } = row;
  //     return values.status_online === "online" ? (
  //       <>
  //         <p className="badge bg-success">เปิดใช้งาน</p>{" "}
  //       </>
  //     ) : (
  //       <p className="badge bg-danger">ปิด</p>
  //     );
  //   },
  // },

  //   { Header: "วันที่เริ่มต้น", accessor: "start_date" },
  // {
  //   Header: "ลบ",
  //   accessor: "id",
  //   Cell: ({ row }: any) => {
  //     const { values } = row;
  //     return (
  //       <>
  //         <button onClick={() => DlitPro(values.id)} className="btn btn-danger">
  //           ลบ
  //         </button>
  //       </>
  //     );
  //   },
  // },
  // {
  //   Header: "แก้ไข",
  //   accessor: "end_date",
  //   Cell: ({ row }: any) => {
  //     const { values } = row;
  //     return (
  //       <>
  //         <Edit_Promotion data={values} />
  //       </>
  //     );
  //   },
  // },
  //   { Header: "แก้ไขเมื่อ", accessor: "updated_at" },
];



const DlitPro = (props: any) => {
  // console.log(props)
  Swal.fire({
    title: "Are you sure?",
    text: "คุณแน่ใจมัย ที่จะลบโปรโมชั่นนี้",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      let datap = {
        //id: data.id,
      };

      ProMotionAPI.delit_pro(datap)
        .then((res) => {
          //  console.log(res.data);

          if (res.data.success) {

          
          } else {
            //  getGames();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });


  return (
    <>  
    
   
    
     </>
  )
};

const Edit_Promotion = (props: any) => {
  //console.log(props);
  const [show_add, SetShow_add] = useState(false);
  const [modal_between_modal, setmodal_between_modal] = useState(false);
  const [modal_between_modal2, setmodal_between_modal2] = useState("");

  function tog_between_modal(data: any) {
    setmodal_between_modal2(data);
    setmodal_between_modal(!modal_between_modal);
    // removeBodyCss();
  }

  return (
    <>
      <button
        className="btn btn-info w-100"
        onClick={() => tog_between_modal(props?.data)}
      >
        แก้ไข
      </button>

      <Modal show={modal_between_modal} size="lg">
        <Modal.Header>
          <Modal.Title>Basic Modal</Modal.Title>
          <Button
            variant=""
            className="btn btn-close"
            onClick={() => {
              setmodal_between_modal(false);
            }}
          >
            x
          </Button>
        </Modal.Header>
        <Modal.Body>
          <h6>Modal Body</h6>
          <Edit_pro data={modal_between_modal2} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setmodal_between_modal(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Edit_Promotion;

export const Teble_promotion = (props: any) => {



 // console.log(props)


  const tableInstance = useTable(
    {
      columns: COLUMNS,
      data: props?.data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps, // table props from react-table
    headerGroups, // headerGroups, if your table has groupings
    getTableBodyProps, // table body props from react-table
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    state,
    setGlobalFilter,
    page, // use, page or rows
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  }: any = tableInstance;

  const { globalFilter, pageIndex, pageSize } = state;


  const DlitProre = (props: any) => {
  

  }
  





  return (
    <>
      <div className="d-flex">
        <select
          className=" mb-4 selectpage border me-1"
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[10, 25, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>
      <table {...getTableProps()} className="table table-hover mb-0">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={column.className}
                >
                  <span className="tabletitle">{column.render("Header")}</span>
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <i className="fa fa-angle-down"></i>
                      ) : (
                        <i className="fa fa-angle-up"></i>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return (
                   <>
                    <td className="borderrigth" {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                    
                   
                   </>
                  );
                })}
                 
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="d-block d-sm-flex mt-4 ">
        <span className="">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span className="ms-sm-auto ">
          <Button
            variant=""
            className="btn-default tablebutton me-2 d-sm-inline d-block my-1"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {" Previous "}
          </Button>
          <Button
            variant=""
            className="btn-default tablebutton me-2 my-1"
            onClick={() => {
              previousPage();
            }}
            disabled={!canPreviousPage}
          >
            {" << "}
          </Button>
          <Button
            variant=""
            className="btn-default tablebutton me-2 my-1"
            onClick={() => {
              previousPage();
            }}
            disabled={!canPreviousPage}
          >
            {" < "}
          </Button>
          <Button
            variant=""
            className="btn-default tablebutton me-2 my-1"
            onClick={() => {
              nextPage();
            }}
            disabled={!canNextPage}
          >
            {" > "}
          </Button>
          <Button
            variant=""
            className="btn-default tablebutton me-2 my-1"
            onClick={() => {
              nextPage();
            }}
            disabled={!canNextPage}
          >
            {" >> "}
          </Button>
          <Button
            variant=""
            className="btn-default tablebutton me-2 d-sm-inline d-block my-1"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {" Next "}
          </Button>
        </span>
      </div>
    </>
  );
};
const GlobalFilter = ({ filter, setFilter }: any) => {
  return (
    <span className="d-flex ms-auto">
      <Form.Control
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        className="form-control mb-4"
        placeholder="Search..."
      />
    </span>
  );
};
