import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Row,
} from "react-bootstrap";

import { Link } from "react-router-dom";
import SettingAPI from "../../api/SettingAPI";
//import Apisetting from "../../../api/Apisetting";
import Data_setting from "./data_setting";
import Data_setting2 from "./data_setting2";
import Data_con from "./data_con";
const Web = () => {
  useEffect(() => {
    getapisetting();
    getapi_con();
  }, []);

  const getapisetting = () => {
    SettingAPI.getdatasetting()
      .then((res) => {
        //
        setState({
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getapi_con = () => {
    SettingAPI.getdatasettingcon()
      .then((res) => {

        //console.log(res.data)
        //
        setState1({
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };



  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
    },
  );
const [state1, setState1] = useReducer(
    (state1: any, newState1: any) => ({ ...state1, ...newState1 }),
    {
      data: [],
    },
  );
  // console.log(state.data.perfix)

  const [show, setShow] = React.useState(true);
  let [isFirstCollapsed, setisFirstCollapsed] = React.useState(true);

  const [remove, Setremove] = React.useState(true);
  const values = [true];
  const [fullscreen, setFullscreen] = React.useState<any>(true);
  const [shows, setShows] = React.useState(false);

  function handleShow(breakpoint: any) {
    setFullscreen(breakpoint);
    setShows(true);
  }
  let [isFirstCollapseds, setisFirstCollapseds] = React.useState(true);
  let firsts = () => {
    if (isFirstCollapseds === false) {
      setisFirstCollapseds(true);
    } else if (isFirstCollapseds === true) {
      setisFirstCollapseds(false);
    }
  };

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            ตั้งค่าการใช้งานเว็บ
          </span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb>
            <Breadcrumb.Item className="tx-15" href="#">
              ตั้งค่าทั่วไป
            </Breadcrumb.Item>
            <Breadcrumb.Item active aria-current="page">
              ตั้งค่าการใช้งานเว็บ
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}

      <Card className="mg-b-20">
        <Card.Body>
          <Col lg={12}>
            <Card className="custom-card">
              <Card.Body>
                <div className="d-none">
                  <h6 className="main-content-label mb-1"></h6>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Card.Body>
      </Card>
      <Row className=" row-sm">
        <Col lg={6} xl={6} md={12} sm={12}>
          <Card className="box-shadow-0">
            <Card.Header>
              <h4 className="card-title mb-1">เกี่ยวกับเว็บไซต์</h4>
              <em className="mb-2 text-warning">
                <i className="fas fa-exclamation-circle"></i> เป็นการตั้งค่า
                แสดงข้อมูงต่างๆ เกี่ยวกับเว็บไซต์
              </em>
            </Card.Header>
            <Card.Body className=" pt-0">
              {state.data.length != 0 && (
                <>
                  <Data_setting data={state.data} />
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} xl={6} md={12} sm={12}>
          <Card className="box-shadow-0 ">
            <Card.Header>
              <h4 className="card-title mb-1">ตั้งค่าแจ้งเตือนไลน์</h4>
              <p className="mb-2">ระบุโทเค้นที่ได้จากไลน์แจ้งเตือน</p>
            </Card.Header>
            <Card.Body className=" pt-0">
              {state.data.length != 0 && (
                <>
                  <Data_setting2 data={state.data} />
                </>
              )}
            </Card.Body>
          </Card>
          <Card className="box-shadow-0 ">
            <Card.Header>
              <h4 className="card-title mb-1">ตั้งค่า กฎระเบียบ</h4>
              <p className="mb-2">ตั้งค่า กฎระเบียบทีแสดงหน้าเว็บไซต์</p>
            </Card.Header>
            <Card.Body className=" pt-0">
              {state.data.length != 0 && (
                <>
                  <Data_con data={state1.data} />
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <!-- End Row --> */}
    </div>
  );
};

Web.propTypes = {};

Web.defaultProps = {};

export default Web;
