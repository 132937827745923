import React from "react";
import { Breadcrumb, Card, Col, Row, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  FileSystemNavigator1,
  FileSystemNavigator2,
  BasicTreeviewexample1,
  BasicTreeviewexample2,
  BasicTreeview1,
  BasicTreeview2,
  TreeviewStyle,
  TreeviewStylecolord,
  TreeViewwithbutton,
} from "./data";
import { ExportCSV } from './Exportcvs'
const Dataplay = () => {
  const [show, setShow] = React.useState(true);
  let [isFirstCollapsed, setisFirstCollapsed] = React.useState(true);
  let first = () => {
    if (isFirstCollapsed === false) {
      setisFirstCollapsed(true);
    } else if (isFirstCollapsed === true) {
      setisFirstCollapsed(false);
    }
  };

  const [remove, Setremove] = React.useState(true);
  const values = [true];
  const [fullscreen, setFullscreen] = React.useState<any>(true);
  const [shows, setShows] = React.useState(false);

  function handleShow(breakpoint: any) {
    setFullscreen(breakpoint);
    setShows(true);
  }
  let [isFirstCollapseds, setisFirstCollapseds] = React.useState(true);
  let firsts = () => {
    if (isFirstCollapseds === false) {
      setisFirstCollapseds(true);
    } else if (isFirstCollapseds === true) {
      setisFirstCollapseds(false);
    }
  };
  
  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1"><i className="fas fa-edit"></i> จัดการสมาชิก</span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb>
            <Breadcrumb.Item className="tx-15" href="#">
            จัดการสมาชิก
            </Breadcrumb.Item>
            <Breadcrumb.Item active aria-current="page">
            จัดการสมาชิก
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}

      <Card className="mg-b-20">
        <Card.Body>
        <Col lg={12}>
          <Card className="custom-card">
            <Card.Body>
              <div>
                <h6 className="main-content-label mb-1">
               สมาชิก
                </h6>
               
              </div>
              <div className="table-responsive fileexport pos-relative">
                <ExportCSV />
              </div>
            </Card.Body>
          </Card>
        </Col>
        </Card.Body>
      </Card>
      {/* <!-- End Row --> */}
    </div>
  );
};

Dataplay.propTypes = {};

Dataplay.defaultProps = {};

export default Dataplay;
