import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@mui/material";
import { Breadcrumb, Card, InputGroup } from "react-bootstrap";
import Draggable from "react-draggable";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Moment from "moment";
import Backlist from "./backlist";
import "moment/locale/th";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import ApiAuth from "../../../api/db";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function MyVerticallyCenteredModal(props: any) {


  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
   const Datepicker = () => {
   
    return (
      <DatePicker
        className="form-control"
        selected={startDate}
        onChange={(date: any) => setStartDate(date)}
      />
    );
  };
  
  const Datepicker2 = () => {
   
    return (
      <DatePicker
        className="form-control"
        selected={endDate}
        onChange={(date: any) => setEndDate(date)}
      />
    );
  };
  

  const [statepost, setStatepost] = useState({
    apply_to: "",
    price: "",
    start_date: "",
    end_date: "",
    details: "",
  });

  const { apply_to, price, start_date, end_date, details } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  const Setotp = (e: any) => {
    e.preventDefault();

    const datapost = {
      apply_to: apply_to,
      price: price,
      start_date: startDate,
      end_date: endDate,
      details: details,
    };


    if(datapost.apply_to == ""){

      toast.error(`${'ใส่ชื่อแนจ้าๆๆๆๆๆๆๆๆ'}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

    }else if(datapost.price == ""){
      toast.error(`${'ใส่ ราคาแนจ้าๆๆๆๆๆๆๆๆ'}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });


    }else if(datapost.details == ""){
      toast.error(`${'ใส่ หมายเหตุจักน่อย แนจ้าๆๆๆๆๆๆๆๆ'}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });


    }else{

      ApiAuth.Cratepay(datapost)
      .then(async (res) => {
        console.log(res.data);
        if (res.data.success) {
  
          toast.success(`${res.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(function () {
            window.location.reload();
          }, 2500);
          
        } else {
          toast.error(`${res.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
         
        }
      })
      .catch(function (error) {
        console.log("error ", error);
      });
    }

   
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>เพิ่มรายการ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Backlist/>
      
      </Modal.Body>
      {/* <ToastContainer /> */}
      <Modal.Footer>
        <Button className="modal-effect  d-grid mb-3" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function VerticallyCenteredModal() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button
        className="btn ripple btn-primary"
        variant="danger"
        onClick={() => setModalShow(true)}
      >
        เพิ่มรายการ
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
