import React, { useEffect, useState, useRef, useMemo, useReducer } from "react";
import CountUp from "react-countup";
import { logout, getUser } from "../../config/authsave";
import "./index.css";
import { Breadcrumb } from "react-bootstrap";

import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AdminAPI from "../api/Admin";
import Apiscb from "../api/Apiscb";
import FriendAPI from "../../components/api/Friend";
import TranAPI from "../api/TranAPI";
import Apichackconnect from "../api/Apichackconnect";
import Swal from "sweetalert2";
import Add_manual from "./deposit_manual.tsx";
import Withdraw_manual from "./withdraw_manual";
//import Loader, { LoaderType } from "react-loaders";
import DataTable from "react-data-table-component";
import Loader, { LoaderType } from "react-loaders";

import { CountdownCircleTimer } from "react-countdown-circle-timer";

import { Container } from "reactstrap";
import { useLocation } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";

import Live_wit from "./withdraw_manual/live_wit";
import { Timers } from "./live_time";

import { Table, Row, Col, Card, Modal } from "react-bootstrap";
import Transfer_information from "./transfer_information";
import Transferscb_information from "./tranfer_scbinfo";

const W_d_index = (options: any) => {
    // const [value, setValue] = useState(new Date());
    // const [count, setCount] = useState(1000000);
    // const [count2, setCount2] = useState(30);
    // const [count2w, setCount2w] = useState(0);
    // const [count2wst, setCount2wst] = useState(50);
    // const [count2wst2, setCount2wst2] = useState(50);
    const [modal_center_add, setModal_center_add] = useState(false);
    const [showmsg, setShowmsg] = useState(false);
    const [modal_center_withdraw, setModal_center_withdraw] = useState(false);
    const [modal_center_infotran, setModal_center_infotran] = useState(false);
    const [show_add, SetShow_add] = useState(false);
    const [modal_between_modal, setmodal_between_modal] = useState(false);
    const [modal_between_secondmodal, setmodal_between_secondmodal] =
        useState(false);
    useEffect(() => {
        getAcc_bank1();
    }, []);
    const [popovertop, setpopovertop] = useState(false);
    const [popoverleft, setpopoverleft] = useState(false);
    const [popoverright, setpopoverright] = useState(false);
    const [popoverbottom, setpopoverbottom] = useState(false);
    const [popoverdismiss, setpopoverdismiss] = useState(false);

    const [ttop, setttop] = useState(false);
    const [tbottom, settbottom] = useState(false);
    const [tleft, settleft] = useState(false);
    const [tright, settright] = useState(false);

    const getAcc_bank1 = () => {
        //let data = ""
        FriendAPI.getbank_omline()
            .then((res) => {
                //console.log(res.data);
                if (res.data.success) {
                    setStateauto({
                        data: res.data.data_d[0],
                    });
                    setStatebank({
                        data: res.data.data_w[0],
                    });
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    //

    const swapshow = () => {
        setShowmsg(!showmsg);
    };

    const add_manual = () => {
        setModal_center_add(!modal_center_add);
        removeBodyCss();
    };
    const withdraw_manual = () => {
        setModal_center_withdraw(!modal_center_withdraw);
        removeBodyCss();
    };
    const infotran_manual = () => {
        setModal_center_infotran(!modal_center_infotran);
        removeBodyCss();
    };
    const getdatawitlives = () => {
        // FriendAPI.getbank_chack_wit()
        //     .then((res) => {
        //         //console.log(res.data);
        //         if (res.data.success) {
        //             if (res.data.data.length >= 1) {
        //                 let datapost = res.data.data[0];
        //                 let da = statebank.data;
        //                 console.log(da);
        //                 console.log(datapost);
        //                 if (datapost.amount <= da.tobank_minmonny) {
        //                     if (datapost.bank_to == "kbank") {
        //                          const postdatato = {
        //                             amount:datapost.amount,
        //                             user_id:datapost.user_id,
        //                             accnum_from:da.accnum,
        //                             from_b:da.from_b,
        //                             tran_id:datapost.id
        //                         }
        //                         posttranauto(postdatato)
        //                     } else {
        //                         const postdatatos = {
        //                             monny: datapost.amount,
        //                             username: datapost.user_id,
        //                             id: datapost.id,
        //                         };
        //                         tog_centerwit(postdatatos);
        //                     }
        //                     //
        //                 }
        //                 // console.log(datapost);
        //             }
        //         }
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });
    };

    function tog_centerwit(data: any) {
        //console.log(data);

        const postData2 = {
            username: data.username,
            id: data.id,
            monny: data.monny,

            // otp:""
        };
        AdminAPI.Approve_wit(postData2)
            .then((res) => {
                // console.log(res.data);
                if (res.data.success == false) {
                    setmodal_center(false);
                    Swal.fire({
                        icon: "error",
                        title: res.data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            //
                        }
                    });
                } else if (res.data.success == true) {
                    Approve_witconfrem({
                        username: res.data.data_mem.username,
                        tran_id: res.data.data.kbankInternalSessionId,
                        data_tran_id: res.data.data_tran.id,
                    });

                    // setmodal_center(!modal_center);
                } else if (res.data.data.data.status.code == 7001) {
                    setmodal_center(false);
                    Swal.fire({
                        icon: "error",
                        title: res.data.data.data.status.description,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            //
                        }
                    });
                } else if (res.data.data.data.status.code == 1000) {
                    // setStatew({
                    //     data: res.data.data,
                    //     data3: res.data.data3,
                    //     data4: res.data.data4,
                    //     data_mem: res.data.data_mem,
                    //     data_tran: res.data.data_tran,
                    // });
                    setmodal_center(!modal_center);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    function Approve_witconfrem(data: any) {
        //  console.log(data);
        // setAppshow(true);
        const postData2 = {
            username: data.username,
            id: data.tran_id,
            data_tran_id: data.data_tran_id,

            // otp:""
        };
        //console.log(postData2);
        AdminAPI.Approve_wit_con(postData2)
            .then((res) => {
                // console.log(res.data);
                if (res.data.success) {
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const posttranauto = (data: any) => {
        // console.log(data);

        const postData2 = {
            username: data.user_id,
            id: data.tran_id,
            monny: data.monny,

            // otp:""
        };
        // console.log(postData2);

        AdminAPI.Approve_wit(postData2)
            .then((res) => {
                //console.log(res.data);
                if (res.data.success) {
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    async function chacktrue_Disposits() {
        Apiscb.gettranscbs()
            .then((res) => {
                // console.log(res.data);
                if (res.data.success) {
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    // const chacktrue_Disposits2 = () => {
    //     Apichackconnect.depositscbufa2()
    //         .then((res) => {
    //             // console.log(res.data);
    //             if (res.data.success) {
    //             } else {
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // };
    // const chacktrue_Disposits3 = () => {
    //     Apichackconnect.depositscbufa()
    //         .then((res) => {
    //             // console.log(res.data);
    //             if (res.data.success) {
    //             } else {
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // };
    // //

    async function databot1() {
        getAcc_bank1();

        // let databot1 = await chacktrue_Disposits();
        // let databot2 = await chacktrue_Disposits2();
        // let databot3 = await chacktrue_Disposits3();

        return databot1;
    }

    function tog_between_modal() {
        setmodal_between_modal(!modal_between_modal);
        removeBodyCss();
    }

    const [modal_center, setmodal_center] = useState(false);

    const [modal_centerscb, setmodal_centerscb] = useState(false);
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }
    function tog_center() {
        AdminAPI.getinfitrankbank()
            .then((res) => {
                // console.log(res.data);
                if (res.data.success) {
                    setStateauto2({
                        data: res.data.data,
                    });
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
            });

        setmodal_center(!modal_center);
        removeBodyCss();
    }

    const [all_rode, setAll_rode] = useState(true);
    const [all_rode_wit, setAll_rode_wit] = useState(true);
    const [showloading, setShowloading] = useState(false);
    const [showloadingw, setShowloadingw] = useState(false);

    const [showloadin, setShowloadin] = useState(
        <button type="button" className="btn btn-light waves-effect my-4">
            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2" />{" "}
            ระบบ API กำลังทำงาน
        </button>
    );

    const [showloading_msg, setShowloading_msg] = useState(
        <button type="button" className="btn btn-light waves-effect my-4">
            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2" />{" "}
            ระบบ API กำลังทำงาน
        </button>
    );

    const [stateauto, setStateauto] = useReducer(
        (stateauto: any, newStateauto: any) => ({
            ...stateauto,
            ...newStateauto,
        }),
        {
            data: [],
        }
    );

    const [stateauto2, setStateauto2] = useReducer(
        (stateauto2: any, newStateauto2: any) => ({
            ...stateauto2,
            ...newStateauto2,
        }),
        {
            data: [],
        }
    );

    const [statebank, setStatebank] = useReducer(
        (statebank: any, newStatebank: any) => ({
            ...statebank,
            ...newStatebank,
        }),
        {
            data: [],
        }
    );

    const [statescb, setStatescb] = useReducer(
        (statescb: any, newStatescb: any) => ({ ...statescb, ...newStatescb }),
        {
            data: [],
        }
    );
    //console.log(statescb);
    function timecut2(datatime2: any) { }
    function timecut(datatime: any) { }

    const gettranscb = (data: any) => {
        AdminAPI.getinfitranscb(data)
            .then((res) => {
                //console.log(res.data.data.data.result);
                if (res.data.success) {
                    setStatescb({
                        data: res.data.data.data.result,
                    });
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
            });

        setmodal_centerscb(!modal_center);
        removeBodyCss();

        // setShowloading(true);
    };
    const [timestart, setTimestart] = useState("50000");
    const [timestartw, setTimestartw] = useState("50000");

    return (
        <Row className="align-items-center">
            <Col md={12} xxl={12}>
                <Card>
                    <Card.Body>
                        <Timers min={0.1} dataoptions={options}/>
                    </Card.Body>
                </Card>
            </Col>

            <Modal
                isOpen={modal_centerscb}
                toggle={() => {
                    tog_center();
                }}
                centered={true}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">ข้อมูล รายการเดินบัญชี</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_centerscb(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <Transferscb_information datatran={statescb} />
            </Modal>
            {/* เพิ่มเครดิต */}
            <Modal
                className="modal-dialog modal-lg"
                isOpen={modal_center_add}
                toggle={() => {
                    add_manual();
                }}
                centered={true}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">เลือก รายการ เติมเงิน </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setModal_center_add(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <Add_manual />
            </Modal>
            {/* จบฟั่งชั่น เพิ่มเครดิต */}
            {/* ถอนเครดิต */}
            <Modal
                className="modal-dialog modal-lg"
                isOpen={modal_center_withdraw}
                toggle={() => {
                    withdraw_manual();
                }}
                centered={true}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">เลือก รายการ ถอนเครดิต </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setModal_center_withdraw(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <Withdraw_manual />
            </Modal>
            {/* จบถอนเครดิต */}
            <Modal
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered={true}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">ข้อมูล รายการเดินบัญชี</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_center(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <Transfer_information datatran={stateauto2.data} />
            </Modal>
            <Modal
                isOpen={modal_between_modal}
                toggle={() => {
                    tog_between_modal();
                }}
                scrollable={true}
                id="staticBackdrop"
            >
                <div className="modal-header">
                    <h5 className="modal-title">Modal 1</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setmodal_between_modal(false);
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body">
                    <p>Show a second modal and hide this one with the button below.</p>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            setmodal_between_modal(false);
                            setmodal_between_secondmodal(true);
                        }}
                    >
                        Open Second Modal
                    </button>
                </div>
            </Modal>
            {/* <Popover
                placement="top"
                isOpen={showmsg}
                target="Popovertop"
                toggle={() => {
                    setShowmsg(!showmsg);
                }}
            >
                <PopoverHeader>Popover Title</PopoverHeader>
                <PopoverBody>
                    Sed posuere consectetur est at lobortis. Aenean eu leo quam.
                    Pellentesque ornare sem lacinia quam venenatis vestibulum.
                </PopoverBody>
            </Popover>{" "} */}
        </Row>
    );
};

export default W_d_index;
