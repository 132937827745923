import React from "react";
import { Link } from "react-router-dom";
import {Tab, Nav } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
export default function RightSidebar() {
  function Remove() {
    document.querySelector(".sidebar-right")?.classList.remove("sidebar-open");
  }
  return (
    <div>
     
    </div>
  );
}
RightSidebar.propTypes = {};

RightSidebar.defaultProps = {};
