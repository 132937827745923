import axios from "axios";
import config from "../../config/app.config"
import { setAuthorizationToken } from "../../helpers/axios";

setAuthorizationToken();

const AdminAPI = {
    // เรียก api แบบ Get และส่ง พารามิเตอร์
    getProfile: async (uid: number) => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/profile/${uid}`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },

    chack_sumpass: async (data:any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/chackdata_summ`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
            
        };

        return await axios(resData);
    },
    verifyOtp: async (data: any) => {
        return await axios.post(`${config.apiURL}/api/v5/user/verify-otp`, data)
    },
    //เรียก api แบบ Get ธรรมดา
    getDashboard: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/dashboard`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    Getweremember: async (data:any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/datapro`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
            
        };

        return await axios(resData);
    },
    get_hitstaff: async (data:any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/get_hitstaff`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
            
        };

        return await axios(resData);
    },
    verifyOtp_tran: async (data:any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/verifyOtp_tran`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
            
        };

        return await axios(resData);
    },
      Posteditbankseting: async (data:any) => {

        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/amb/Posteditbankseting`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);


       
    },

    gen_alliance: async (data:any) => {

        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/gen_alliances`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);


       
    },
    databank_list: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/admin/databank-list`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
        };

        return await axios(resData);
    },
    
    getbankseting: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getbankseting`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    
    getbankseting_auto: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getbankseting_auto`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    getaff_member: async (data:any) => {

        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/getaff_member`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);


       
    },
    getAlluser: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getuserall`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    getBank_d: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getbankauto_deposit`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    getBank_withdraw: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getBank_withdraw`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    getall_dashboard: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getall_dashboard`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    bankauto_deposit: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/bankauto_deposit`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    getNoti: async (data: number) => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/noti/${data}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
           
        };

        return await axios(resData);
    },
    getdataeditBank_d: async (data: number) => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/editBank_d/${data}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
           
        };

        return await axios(resData);
    },
    getdataeditBank_code: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getdataeditBank_code`,
            headers: {
                'Content-Type': 'application/json'
            }
           // data: data
            
        };

        return await axios(resData);
    },
    getdepositkbank: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getbank_kbank`,
            headers: {
                'Content-Type': 'application/json'
            }
           // data: data
            
        };

        return await axios(resData);
    },
    getKbank: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getKbank`,
            headers: {
                'Content-Type': 'application/json'
            }
           // data: data
            
        };

        return await axios(resData);
    },
    getTran_hit: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getTran_hit`,
            headers: {
                'Content-Type': 'application/json'
            }
           // data: data
            
        };

        return await axios(resData);
    },
    postTran_hit: async (data: any) => {
        
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/getTran_hits`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
            
        };

        return await axios(resData);
    },
    getTran_scb: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getlogscb`,
            headers: {
                'Content-Type': 'application/json'
            }
           // data: data
            
        };

        return await axios(resData);
    },
    //เรียก api แบบ post และส่ง พารามิเตอร์
    Postlogin: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/login`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },

    Postscbtran: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/scbtran`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    upfate_wit: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/upfate_wit`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    
    Postscbtran3: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/scbtran3`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    addKbankapi: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/addKbankapi`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    Editoptionbank: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/Editoptionbank`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    chacktrannkbank: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/trankbank`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    appchacktrannkbank: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/apptrankbank`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    getAcc_bank1: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getAcc_bank1`,
            headers: {
                'Content-Type': 'application/json'
            }
           // data: data
            
        };

        return await axios(resData);
    },

    cancelwit: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/cancelwit`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    cancelwit_m: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/cancelwit_m`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    Approve_wit_con: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/Approve_wit_con`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    unpromotions: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/unpromotions`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    
    withdraw_auto_confirm: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/amb/withdraw_auto_confirm`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    Approve_wit: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/Approve_wit`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    getinfitranscb: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/getinfitranscb`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    
    Approve_wit_conscb: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/transcbByadmin`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    Updatebank_user: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/Updatebank_user`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    
    tokenplay: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/tokenplay`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    
    
    PosttranscbByadmin: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/transcbByadmin`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    
    postChackscb: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/apiscbckack`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    StopAutoeRolebank: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/stopAutoeRolebank`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    
    Posteditbankseting1234: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/amb/Posteditbankseting1234`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    autotran: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/amb/autotran`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    disautoscb: async (data: any) => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/amb/depositAll`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    disautokbank: async (data: any) => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/amb/deposit_dev`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    cHacktranscb: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/chacktranscb`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    cHacktranscb3: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/chacktranscb3`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    cHackkbank: async (data: any) => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/cHackkbank/${data}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
           
        };
        return await axios(resData);
    },
    chackBaBafromkbank: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/chackBaBafromkbank`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
           
        };
        return await axios(resData);
    },
    geTmemberbyid: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/sreadallmember`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
           
        };
        return await axios(resData);
    },
    getinfitrankbank: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getinfitrankbank`,
            headers: {
                'Content-Type': 'application/json'
            }
           
           
        };
        return await axios(resData);
    },
    
    geTmemberbyid2: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/sreadallmember2`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
           
        };
        return await axios(resData);
    },
    addbnakauto: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/getbankauto_deposit`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
           
        };
        return await axios(resData);
    },
    chackBaBa: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/chackBaBa`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    chackBaBafrom: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/chackBaBafrom`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    DeleteRolebank: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/deleteRolebank`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    Deletemember: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/deletemember`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    
    Addbankbeting: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/addbankbeting`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    setAgent: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/setAgent`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    get_datamenu: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/get_datamenu`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    get_datamenu2: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/get_datamenu2`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    getupdate_datamenu: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/getupdate_datamenu`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    
    
    Addmenu_staff: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/Addmenu_staff`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    eDitbankbeting: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/editbankbeting`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    get_datamenumy: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/get_datamenumy`,
            headers: {
                'Content-Type': 'application/json'
            },
           // data: data
        };

        return await axios(resData);
    },
    Postscb: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/auth/apiscb_deposit`,
            headers: {
                'Content-Type': 'application/json'
            },
           // data: data
        };

        return await axios(resData);
    },
    getdata_AdminModule: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getdata_AdminModule`,
            headers: {
                'Content-Type': 'application/json'
            },
           // data: data
        };

        return await axios(resData);
    },
    
    Getallmember: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getallmember`,
            headers: {
                'Content-Type': 'application/json'
            },
           // data: data
        };

        return await axios(resData);
    },
    Postregister: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/max/register`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },

    delistUserstaff: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/delistUserstaff`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    startbanadmin: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/startban`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    
    addmemBer: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/crateusers`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    addBacklist: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/addBacklist`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },


    post_searchsummerry: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/post_searchsummerry`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },



    agetdataBacklist: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/getdataBacklist`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    unrarub: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/unrarub`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    removeuser: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/removeuser`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    update_tranpro: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/update_tranpro`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },

    
    Getdatatran_nomember: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/Getdatatran_nomember`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    
    EditmemBer: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/editusers`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    
}
export default AdminAPI