import {
    Row,
    Col,
    Tab,
    Nav,
    Breadcrumb,
    Card,
    Button,
    ListGroup,
    Form,
    Table,
} from "react-bootstrap";
import {
    Container,
    Dropdown,
    Badge,
    Alert,
    Modal,
    Tooltip,
    Popover,
    OverlayTrigger,
    InputGroup,
    Overlay,
} from "react-bootstrap";
// import Link from "next/link";
import Apidatamember from "../../api/Apidatamember";
import { useState, useEffect, useReducer, forwardRef } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import "moment/locale/th";
import Swal from "sweetalert2";
// import Promotion from "../pages/api/res/Promotion";
//import Apidatamember from "src/components/api/Apidatamember";
const Addprousers = (data: any) => {
    //  console.log(data.isOpen.username)
    useEffect(() => {
        getdatapro();
    }, []);
    let [Switcher2, setSwitcher2] = useState("off");
    let [Switcher21, setSwitcher21] = useState("");
    let [Switcher211, setSwitcher211] = useState(false);

    const [state, setState] = useState({
        data: [],
    });

    const [state5, setState5] = useReducer(
        (state5: any, newState5: any) => ({ ...state5, ...newState5 }),
        {
            data: [],
        }
    );

    //  console.log(state)
    const getdatapro = () => {
        Apidatamember.datapromotion()
            .then((res) => {
                if (res.data.success) {
                    setState5({
                        data: res.data,
                    });
                } else {
                }

                // console.log(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const [statepost, setStatepost] = useState({
        monny: "",
        username: data.isOpen.username,
        pro_id: "",
        node: "",
    });
    const { monny, username, pro_id, node } = statepost;
    const inputvalue = (name: any) => (event: any) => {
        setStatepost({ ...statepost, [name]: event.target.value });
    };

    const addpro_user = (e: any) => {
        e.preventDefault();

        let datapost = {
            monny: monny,
            username: username,
            pro_id: pro_id,
            node: node,
            datauser: data.isOpen,
        };

        console.log(datapost);

        if (datapost.pro_id == " ") {
            setSwitcher211(true);
            setSwitcher21(
                "กรุณา เลือกโปรโมชั่นที่ต้องการ ติดให้ สมาชิกนี้ ค่ะ !@"
            );

            setTimeout(function () {
                // window.location.reload()
                setSwitcher211(false);
            }, 3500);
        } else if (datapost.monny == "") {
            setSwitcher211(true);
            setSwitcher21("กรุณา เลือก จำนวนที่เคร ติดให้ สมาชิกนี้ ค่ะ !@");

            setTimeout(function () {
                // window.location.reload()
                setSwitcher211(false);
            }, 3500);
        } else {
            Apidatamember.editPromotion(datapost)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.success) {
                        Swal.fire({
                            icon: "success",
                            title: "Okyyyyy...",
                            text: "ติดโปรโมชั่นสำเร็จ",
                            footer: '<a href="">Why do I have this issue?</a>',
                        });
                        setTimeout(function () {
                            window.location.reload();
                        }, 2500);

                        //   toast.success(
                        //     <p className="text-white tx-16 mb-0">
                        //       {`แก้ไขโปรโมชั่น  Successfully`}
                        //     </p>,
                        //     {
                        //       position: toast.POSITION.TOP_RIGHT,
                        //       hideProgressBar: true,
                        //       autoClose: 5000,
                        //       theme: "colored",
                        //     },
                        //   );
                        //   setTimeout(function () {
                        //     window.location.reload();
                        //   }, 2500);
                    } else {
                        //  setSuccessMessage("");
                    }
                })
                .catch(function (error) {
                    console.log("error ", error);
                });
        }

        // console.log(datapost);
    };
    return (
        <>
            {Switcher21 && (
                <>
                    <div className="example">
                        <Alert className="alert alert-danger mb-0" role="alert">
                            <Alert.Heading className="alert-heading">
                                เกิดข้อผิดพลาด!!!
                            </Alert.Heading>
                            <p>{Switcher211}</p>
                            <hr />
                            <p className="mb-0"></p>
                        </Alert>
                    </div>
                </>
            )}

            <form onSubmit={addpro_user}>
                <div className="mb-3">
                    <label htmlFor="exampleSelect" className="form-label">
                        เลือกโปรที่ต้องการติด
                    </label>
                    <select
                        name="pro_id"
                        id="pro_id"
                        className="form-select"
                        value={pro_id}
                        onChange={inputvalue("pro_id")}
                    >
                        {state5.data.datall &&
                            state5.data.datall.map(
                                (item: any, index: number) => {
                                    return (
                                        <>
                                             <option>
                                                เลือกโปร
                                            </option>
                                            <option value={item.id}>
                                                {item.level_pro} {item.name}
                                            </option>
                                        </>
                                    );
                                }
                            )}
                    </select>
                </div>
                {/* <div className="mb-3">
                    <label htmlFor="exampleSelect" className="form-label">
                        เลือกโปรที่ต้องการติด
                    </label>
                    <select
                        name="pro_id"
                        id="pro_id"
                        className="form-select"
                        value={pro_id}
                        onChange={inputvalue("pro_id")}
                    >
                        {state5.data.datall &&
                            state5.data.datall.map(
                                (item: any, index: number) => {
                                    return (
                                        <>
                                            {state5.data.datall &&
                                                state5.data.datall.map(
                                                    (
                                                        item: any,
                                                        index: number
                                                    ) => {
                                                        return (
                                                            <>
                                                                <option
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {item.text}
                                                                </option>
                                                            </>
                                                        );
                                                    }
                                                )}
                                        </>
                                    );
                                }
                            )}
                    </select>
                </div> */}

                <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                        จำนวนเครดิตร
                    </label>
                    <input
                        name="monny"
                        id="monny"
                        placeholder="จำนวนเครดิตร"
                        type="number"
                        className="form-control"
                        value={monny}
                        onChange={inputvalue("monny")}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                        หมายเหตุ
                    </label>
                    <textarea
                        name="node"
                        id="node"
                        placeholder="หมายเหตุ"
                        rows={5}
                        className="form-control"
                        value={node}
                        onChange={inputvalue("node")}
                    />
                </div>
                <button className="btn btn-success w-100 my-5">
                    {" "}
                    ติดโปรโมชั่น{" "}
                </button>
            </form>
        </>
    );
};
export default Addprousers;
