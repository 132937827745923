import React from "react";
import W_d_index from "./w_d_index";
import { useEffect, useState, useReducer } from "react";
import { logout, getUser } from "../../config/authsave";
import "./index.css";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AdminAPI from "../api/Admin";
import FriendAPI from "../../components/api/Friend";

import { Container } from "reactstrap";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Modal,
  Progress,
} from "reactstrap";
import Event_result from "./event_result";
import Event_live from "./event_live";
import Tran_noMember from "./tran_nomember";
import { socket } from "../../utils/socket";
const Resulttoday = (data: any) => {
  socket.on("create_live", (data) => {
    // console.log(data)

    getdatawitlive();
  });
  useEffect(() => {
    getdatawitlive();
  }, []);
  // useEffect(() => {
  //   const id = setInterval(() => {
  //     getdatawitlive();
  //   }, 9500);
  //   return () => clearInterval(id);
  // }, []);

  const [options, setOptions] = useState("");

  const setdata_options = () => {
    getdatawitlive();
  };

  const getdatawitlive = () => {
    FriendAPI.getbank_chack_wit()
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          setState({
            data: res.data.data,
            data3: res.data.data2,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
      data3: [],
    }
  );

  document.title = "ฝาก-ถอนรายวัน | ฝาก-ถอนรายวัน ";
  return (
    <React.Fragment>
      <W_d_index options={setdata_options} />

      {/* <Tran_noMember options={options} /> */}

      <Event_live options={state.data} set={getdatawitlive} />

      <Event_result options={state.data2} set={getdatawitlive} />
    </React.Fragment>
  );
};
Resulttoday.propTypes = {};

Resulttoday.defaultProps = {};

export default Resulttoday;
