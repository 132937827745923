import React, { useState, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import {
  Card,
  Col,
  Dropdown,
  Breadcrumb,
  Nav,
  Row,
  Tab,
  FormGroup,
  Form,
} from "react-bootstrap";
import AdminAPI from "../../api/Admin";
import DataTable from "react-data-table-component";
import Moment from "moment";
import "moment/locale/th";
const Transactions_dis = (data: any) => {
  const [allData, setAllData] = React.useState<any>(data.data);

  let allElement2: any[] = [];

  let myfunction = (InputData: any) => {
    for (let allElement of data.data) {
      if (InputData.length == 10) {
        if (allElement.username === InputData) {
          allElement2.push(allElement);
          setAllData(allElement2);
        }
      } else if (allElement.username.includes(InputData.toLowerCase())) {
        allElement2.push(allElement);

        if (allElement.username === InputData) {
          setAllData(allElement2);
        } else {
          setAllData(allElement2);
        }
      } else if (allElement.name.includes(InputData.toLowerCase())) {
        // console.log(allElement)

        allElement2.push(allElement);

        if (allElement.name === InputData) {
          setAllData(allElement2);
        } else {
          setAllData(allElement2);
        }
      } else if (allElement.accnum.includes(InputData.toLowerCase())) {
        // console.log(allElement)

        allElement2.push(allElement);

        if (allElement.accnum === InputData) {
          setAllData(allElement2);
        } else {
          setAllData(allElement2);
        }
      } else if (allElement.user_ufa.includes(InputData.toLowerCase())) {
        // console.log(allElement)

        allElement2.push(allElement);

        if (allElement.user_ufa === InputData) {
          setAllData(allElement2);
        } else {
          setAllData(allElement2);
        }
      } else {
        setAllData(allElement2);
      }

      // if (allElement.username.toLowerCase().includes(InputData.toLowerCase())) {
      //   if (allElement.username.toLowerCase().startsWith(InputData.toLowerCase())) {
      //     allElement2.push(allElement)
      //   }
      // }else {
      //  // allElement2.push(allElement)
      // }
    }
  };

  const columns = [
    {
      name: "สมาชิก",
      selector: (row: any) => row.user_id,
      sortable: true,
    },
    {
      name: "ประเภท",
      cell: (row: any) => (
        <div className={" -soft-" + row.color + " font-size-12 text-center"}>
          {row.type_option == "ถอน" ? (
            <span className="text-danger">{row.type_option}</span>
          ) : (
            <span className="text-success">{row.type_option}</span>
          )}
        </div>
      ),
    },

    {
      name: "เวลาดำเดินการ",
      cell: (row: any) => (
        <div className={" -soft-" + row.color + " font-size-12 text-center"}>
          <i className="mdi mdi-clock-outline"></i>{" "}
          {Moment(row.updated_at).fromNow()}
        </div>
      ),
    },
    {
      name: "จำนวนเงิน",
      cell: (row: any) => (
        <div className={" -soft-" + row.color + " font-size-12 text-center"}>
          {row.amount}
        </div>
      ),
    },
    {
      name: "สถานะ",
      cell: (row: any) => (
        <div className={" -soft-" + row.color + " font-size-12 text-center"}>
          {row.status == "rejected" && (
          
             <span className="badge bg-danger me-1">ยกเลิก</span>
          )}
          {row.status == "pending" && (
            <span className="badge badge-soft-warning font-size-12">
              รอดำเนินการ
            </span>
          )}
          {row.status == "successed" && (
            <span className="badge bg-success me-1">Success</span>
          )}
        </div>
      ),
    },
    {
      name: "ดำเนินการโดย",
      cell: (row: any) => (
        <div className={" -soft-" + row.color + " font-size-12 text-center"}>
          {!row.add_from && (
            <span className="text-success">
              <i className="fas fa-crosshairs"></i> ระบบออโต้
            </span>
          )}
          {row.add_from && (
            <span className="text-info">
              <i className="fas fa-hospital-user"></i> {row.add_from}
            </span>
          )}
        </div>
      ),
    },
    {
      name: "หมายเหตุ",
      cell: (row: any) => (
        <div className={" -soft-" + row.color + " font-size-12 text-center"}>
          {row.nodere && (
            <span className="text-success">
              <i className="fas fa-crosshairs"></i> {row.nodere}
            </span>
          )}
          {!row.nodere && (
            <span className="text-danger">
              ไม่กำหนด
            </span>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <label className="float-end">
        <input
          type="text"
          placeholder="Search..."
          className="mb-4 form-control-sm form-control"
          onChange={(ele) => {
            myfunction(ele.target.value);
          }}
        />
      </label>
      <DataTable
        columns={columns}
        data={allData}
        // contextActions={contextActions}
        // onSelectedRowsChange={handleRowSelected}
        // clearSelectedRows={toggleCleared}
        className="react-dataTable"
        pagination
        // title=""
        // columns={columns}
        // data={allData}
        // pagination
        // fixedHeader
        // fixedHeaderScrollHeight="400px"
      />
    </>
  );
};

export default Transactions_dis;
