import axios from "axios";
import config from "../config/app.config"
import { setAuthorizationToken } from "../helpers/axios";

setAuthorizationToken();

//console.log(setAuthorizationToken())

const Apiscb = {

    get_babascbnew: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/get_babascbnew`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    chack_qrapikbank: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/chack_qrapikbank`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    checkSlipTransfer: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/checkSlipTransfer`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    chack_qrapikbankby: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/chack_qrapikbankby`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
   

   
    delit_qr: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/delit_srip`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },

    
    getNews_all_server: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/get_Newsall`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    depositkbankget_app: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/depositkbankufa2`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    }, depositscb: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/depositscb`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    }, depositscb2: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/depositscb`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
        
    }, depositscbufa2: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/depositscbufa2`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    sendMessage: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/create-contact-support`,
            data: data
        };

        return await axios(resData);
    },
    sendlogo: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/sendlogo`,
            data: data
        };

        return await axios(resData);
    }, getlogo: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getlogo`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },chack_getMember: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/chack_getMember`,
            data: data
        };

        return await axios(resData);
    },
    chack_datatran_scb: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/scb/postchacktranscb`,
            headers: {
                'Content-Type': 'application/json'
            },
            data:data
        };

        return await axios(resData);
    },
    posttranscb: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/scb/posttranscb`,
            headers: {
                'Content-Type': 'application/json'
            },
            data:data
        };

        return await axios(resData);
    },
    getdataeditBank_code: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getdataeditBank_code`,
            headers: {
                'Content-Type': 'application/json'
            }
           // data: data
            
        };

        return await axios(resData);
    },
    getdata_tran_by: async  (data: any) => {
        
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/scb/data_tran_by`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
            
        };

        return await axios(resData);
    },
    
}
export default Apiscb