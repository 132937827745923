import React, { useState, useRef, useEffect, useReducer } from "react";
import TimeFormat from "hh-mm-ss";
import Countdown from "react-countdown-now";
//import "./index.css";
import Apiscb from "../../api/Apiscb";
import AdminAPI from "../../api/Admin";
import CountUp from "react-countup";
import Apibank from "../../../api/APIbank";
import {useNavigate} from "react-router-dom";
import {
  Breadcrumb,
  InputGroup,
  Alert,
  FormGroup,
  Form,
} from "react-bootstrap";
//import BasicPills from "./deposit_manual.tsx/BasicPills";
//import BasicPillss from "./withdraw_manual/BasicPills";
import { Table, Row, Col, Card, Button, Modal } from "react-bootstrap";
//import Setting_witautos from "./setting_witauto";

const Setting_witautos =({ data,setUnLink }:any) => {
 //console.log(data);

  const [show_add, SetShow_add] = useState(false);
  const [show_wit, SetShow_wit] = useState(false);
  const [show_option, SetShow_option] = useState(false);
  const [show_option1, SetShow_option1] = useState(false);
  const [show_option3, SetShow_option3] = useState(false);
  let [Switcher2, setSwitcher2] = useState("off");
  let [Switcher21, setSwitcher21] = useState("");
  let [Switcher211, setSwitcher211] = useState(false);
  const navigate = useNavigate();


  const [statepost, setStatepost] = useState({
    accnum: data.accnum,
    name: data.name,
    username: data.username,
    password: data.password,
    bankname: data.bankname,
    edit_id: data.id,
    option_b: data.option_b,
    level: data.level,
    tobank_accnum: data.tobank_accnum,
    tobank_bank: data.tobank_bank,
    tobank_monney: data.tobank_monney,
    limit_wit: data.limit_wit,
    tobank_stust: data.tobank_stust,
    tobank_minmonny: data.tobank_minmonny,
    autowit_status: data.autowit_status,
    autowit_minmony: data.autowit_minmony,
    autowit_maxmonny: data.autowit_maxmonny,
  });
  const {
    option_b,
    accnum,
    password,
    username,
    name,
    edit_id,
    level,
    tobank_accnum,
    tobank_stust,
    limit_wit,
    tobank_monney,
    tobank_minmonny,
    tobank_bank,
    autowit_status,
    autowit_minmony,
    autowit_maxmonny,
  } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  const addBank = (e: any) => {
    e.preventDefault();
    const postData = {
      accnum: accnum,
      name: name,
      username: username,
      password: password,
      id: edit_id,
      level: level,
      tobank_monney: tobank_monney,
      tobank_minmonny: tobank_minmonny,
      tobank_bank: tobank_bank,
      tobank_stust: tobank_stust,
      tobank_accnum: tobank_accnum,
      autowit_status: autowit_status,
      autowit_minmony: autowit_minmony,
      autowit_maxmonny: autowit_maxmonny,
      // level: level,
      // otp:""
    };

    // 
    Apibank.Posteditbankseting1234(postData)
      .then((res) => {

       // console.log(res)
        if (res.data.success) {
          //setmagdatas(true);
          setSwitcher211(true);
          setSwitcher21(res.data.msg);
          setUnLink(false) 
        } else {
          setSwitcher21(res.data.msg);
          // 103.253.75.245  183.89.213.199  console.log(res.data)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onShowsetting = () => {
    SetShow_add(!show_add);
  };

  return (
    <>
      {data && (
        <>
          <div className="row">
            <div className=" p-2 col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      
                        <div className="settings-main-icon me-4 mt-1">
                          <i className="fe fe-home" />
                        </div>
                        <div>
                          <p className="tx-20 font-weight-semibold d-flex mb-0">
                            ถอนเงินออโต้
                          </p>
                          <form className="" onSubmit={addBank}>
                            <FormGroup className="form-group ">
                              <Row className=" row-sm">
                                <Col md={3}></Col>
                              </Row>
                            </FormGroup>
                            <div className="p-3 card-footer">
                              <h4 className="text-danger">
                                สถานะ{" "}
                                {autowit_status == 0 ? (
                                  <>
                                    {" "}
                                    <span className="text-danger">
                                      ยังไม่เปิดใช้งาน
                                    </span>{" "}
                                  </>
                                ) : (
                                  <>
                                    <span className="text-success">
                                      เปิดใช้งานอยู่
                                    </span>
                                  </>
                                )}
                              </h4>
                              <p></p>
                              <Form.Label className="d-block mg-b-15-f">
                                {autowit_status != 1 ? (
                                  <input
                                    type="checkbox"
                                    name="autowit_status"
                                    onChange={inputvalue("autowit_status")}
                                    value={1}
                                    className="custom-switch-input"
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    name="autowit_status"
                                    onChange={inputvalue("autowit_status")}
                                    value={0}
                                    className="custom-switch-input"
                                    defaultChecked
                                  />
                                )}

                                <span className="custom-switch-indicator"></span>
                                <span className="text-muted ms-2">
                                  เปิด/ปิดใช้งาน
                                </span>
                              </Form.Label>
                            </div>
                            {autowit_status && (
                              <>
                                <div className="col-md-12 text-center">
                                  {Switcher211 && (
                                    <>
                                      <Alert
                                        className="alert alert-success"
                                        role="alert"
                                      >
                                        
                                        <h3>{Switcher21}</h3>
                                      </Alert>
                                    </>
                                  )}
                                 
                                  <div className="mb-3">
                                    <label
                                      htmlFor="tobank_accnum"
                                      className="form-label"
                                    >
                                      จำนวนเงิน ไม่เกินเท่าไหร่จะให้ถอน ออโต้
                                    </label>
                                    <input
                                      name="autowit_maxmonny"
                                      id="autowit_maxmonny"
                                      type="autowit_maxmonny"
                                      className="form-control"
                                      value={autowit_maxmonny}
                                      onChange={inputvalue("autowit_maxmonny")}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="tobank_accnum"
                                      className="form-label"
                                    >
                                      ถอนขั้นต่ำ
                                    </label>
                                    <input
                                      name="autowit_minmony"
                                      id="autowit_minmony"
                                      type="number"
                                      className="form-control"
                                      value={autowit_minmony}
                                      onChange={inputvalue("autowit_minmony")}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-info mt-5"
                                    type="submit"
                                  >
                                    บันทึกการแก้ไข
                                  </button>
                                </div>
                              </>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          

          {data.tobank_stust != null && <></>}
        </>
      )}
    </>
  );
};
export default Setting_witautos;
