import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  Alert,
  CardBody,
  CardHeader,
  CardTitle,
  UncontrolledAlert,
} from "reactstrap";
import { Link } from "react-router-dom";
//import Breadcrumbs

import { Container } from "reactstrap";
import Moment from "moment";
import Gauge from "./gaugechart";

import {
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

import {
  Breadcrumb,
  Card,
  Carousel,
  Col,
  ProgressBar,
  Row,
} from "react-bootstrap";

import UrlImageDownloader from "react-url-image-downloader";
import { useSelector, useDispatch } from "react-redux";
import AdminAPI from "../api/Admin";
import FriendAPI from "../api/Friend";

const Approve_wit = (data: any) => {
  const [app, setApp] = useState(true);
  const [appshow, setAppshow] = useState(false);
  const [appdow, setAppdow] = useState(false);
  const [appdow2, setAppdow2] = useState(false);
  const [src, setSrc] = useState("");
  const [srcw, setSrcw] = useState("");
  const [srcwType, setSrcwType] = useState(false);
  const [appshow2, setAppshow2] = useState(false);

  //console.log(data)

  const downloadImage = (src: any) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = src;
    img.onload = () => {
      // create Canvas
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      // ctx.drawImage(img, 400, 400);
      // create <a> tag
      const a = document.createElement("a");
      a.download = "download.png";
      a.href = canvas.toDataURL("image/png");
      a.click();
    };
  };
  const [stateposttran, setStateposttran] = useState({
    otp: "",
    monny: "",
    bank_id: "",
  });
  const { bank_id, monny, otp } = stateposttran;
  const inputvalue2 = (name: any) => (event: any) => {
    setStateposttran({ ...stateposttran, [name]: event.target.value });
  };

  function Approve_witconfrem(data: any) {
    const dapost = {
      otp: otp,
    };
    if (dapost.otp == "") {
      setSrcw("กรุณาระบุ OTP ");

      setTimeout(function () {
        setAppshow2(false);
      }, 5500);
    } else {
      AdminAPI.verifyOtp_tran(dapost)
        .then((res) => {
          //  console.log(res.data);
          if (res.data.success == true) {
            setAppshow(true);

            //   console.log(data);
            //setAppshow(true);
            const postData2 = {
              id: data.tran_id,
              tid: data.data_tran_id,
              accnum: data.accnum,
              // otp:""
              // otp:""
            };

            //console.log(postData2);
            AdminAPI.withdraw_auto_confirm(postData2)
              .then((res) => {
                // console.log(res.data);
                if (res.data.success) {
                  setApp(false);
                  setAppdow(true);
                  setSrc(res.data.imgqr);
                } else {
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            setSrcw(res.data.msg);

            setTimeout(function () {
              setAppshow2(false);
            }, 5500);

            //setAppshow2(false)
            console.log(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function Approve_witconfrem2(data: any) {
    setAppshow2(true);

    const dapost = {
      otp: otp,
    };

    if (dapost.otp == "") {
      setSrcw("กรุณาระบุ OTP ");

      setTimeout(function () {
        setAppshow2(false);
      }, 5500);
    } else {
      AdminAPI.verifyOtp_tran(dapost)
        .then((res) => {
          //  console.log(res.data);
          if (res.data.success == true) {
            setAppshow(true);

            //   console.log(data);
            //setAppshow(true);
            const postData2 = {
              tid: data.data_tran_id,
              accnum: data.accnum,
              // otp:""
            };

            //console.log(postData2);
            AdminAPI.withdraw_auto_confirm(postData2)
              .then((res) => {
                // console.log(res.data);
                if (res.data.success) {
                  setApp(false);
                  setAppdow(true);
                  setSrc(res.data.imgqr);
                } else {
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            setSrcw(res.data.msg);

            setTimeout(function () {
              setAppshow2(false);
            }, 5500);

            //setAppshow2(false)
            console.log(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  //console.log(data.data)
  return (
    <React.Fragment>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-12 col-xl-12">
            {appdow && (
              <>
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title text-center text-success">
                      โอนเงินสำเร็จ
                    </h4>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <h3 className="card-title">สลิปโอนเงินสำเร็จ</h3>
                          <div id="gauge-chart" className="e-chart">
                            <Col xl="12" xs="12">
                              <Card className="border shadow-none">
                                <img
                                  className="card-img-top "
                                  src={src}
                                  alt="Samply"
                                />
                                <div className="py-2 text-center"></div>
                              </Card>
                            </Col>
                          </div>
                          <a
                            download="QR.png"
                            href={src}
                            className="fw-medium btn btn-primary w-100"
                          >
                            Download
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <h3 className="card-title">QR CODE ตรวจสอบการโอน</h3>
                          <div id="gauge-chart" className="e-chart">
                            <Col xl="12" xs="12">
                              <Card className="border shadow-none">
                                <img
                                  className="card-img-top "
                                  src={src}
                                  alt="Samply"
                                />
                                <div className="py-2 text-center"></div>
                              </Card>
                            </Col>
                          </div>
                          <a
                            download="QR.png"
                            href={src}
                            className="fw-medium btn btn-primary w-100"
                          >
                            Download QR CODE
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {appdow2 && (
              <>
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title text-center text-success">
                      โอนเงินสำเร็จ
                    </h4>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <h3 className="card-title">สลิปโอนเงินสำเร็จ</h3>
                          <div id="gauge-chart" className="e-chart">
                            <Col xl="12" xs="12">
                              <Card className="border shadow-none">
                                <img
                                  className="card-img-top "
                                  src={src}
                                  alt="Samply"
                                />
                                <div className="py-2 text-center"></div>
                              </Card>
                            </Col>
                          </div>
                          <a
                            download="QR.png"
                            href={src}
                            className="fw-medium btn btn-primary w-100"
                          >
                            Download
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <h3 className="card-title">QR CODE ตรวจสอบการโอน</h3>
                          <div id="gauge-chart" className="e-chart">
                            <Col xl="12" xs="12">
                              <Card className="border shadow-none">
                                <img
                                  className="card-img-top "
                                  src={src}
                                  alt="Samply"
                                />
                                <div className="py-2 text-center"></div>
                              </Card>
                            </Col>
                          </div>
                          <a
                            download="QR.png"
                            href={src}
                            className="fw-medium btn btn-primary w-100"
                          >
                            Download QR CODE
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {app && (
              <>
                {data.data.data4 == "kbank" && (
                  <>
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title text-center">
                          ข้อมูล ยืนยันการโอนเงิน
                        </h4>
                      </div>
                      <Row className="row-sm">
                        <Col lg={6} xl={6} md={6} className="col-12 mx-auto">
                          <Card className=" bg-primary-gradient text-white ">
                            <Card.Body>
                              <Row>
                                <div className="col-12">
                                  <div className="icon1 mt-2 text-center">
                                    <img
                                      src={`https://ufaapiv1.myshadownow.com/images/bank3/${data.data.data_member.bank}.png`}
                                      alt=""
                                      width={60}
                                    />
                                  </div>
                                </div>
                                <Col className="col-12">
                                  <div className="mt-0 text-center">
                                    <span className="text-white">
                                      {" "}
                                      {data.data.data_scb.toAccountName}
                                    </span>
                                    <h2 className="text-white">
                                      {" "}
                                      {data.data.data_member.accnum}
                                    </h2>
                                    <h2 className="text-white mb-0">
                                      {data.data.datatran.amount} ฿
                                    </h2>
                                  </div>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      <p className="text-center">
                        บัญชีที่ใช้โอน {data.data.data_scb.fromAccountNo}
                      </p>
                      {/* <li className="list-group-item">
                    เครดิตก่อนถอน :{" "}
                    <span className="text-info">
                      {data.data.datatran.c_after}{" "}
                    </span>
                  </li>
                  <li className="list-group-item">
                    เครดิตหลังถอน :{" "}
                    <span className="text-info">
                      {data.data.datatran.c_before}{" "}
                    </span>
                  </li> */}
                      <div className="row">
                        <Col xl={6} lg={6} sm={6} md={6}>
                          <Card className=" text-center">
                            <Card.Body className="">
                              <div className="feature widget-2 text-center mt-0 mb-3">
                                <i className="ti-bar-chart project bg-primary-transparent mx-auto text-primary "></i>
                              </div>
                              <h6 className="mb-1 text-muted">เครดิตก่อนถอน</h6>
                              <h3 className="font-weight-semibold">
                                $ {data.data.datatran.c_after}
                              </h3>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col xl={6} lg={6} sm={6} md={6}>
                          <Card className=" text-center">
                            <Card.Body className="">
                              <div className="feature widget-2 text-center mt-0 mb-3">
                                <i className="ti-pie-chart project bg-pink-transparent mx-auto text-pink "></i>
                              </div>
                              <h6 className="mb-1 text-muted">เครดิตหลังถอน</h6>
                              <h3 className="font-weight-semibold">
                                $ {data.data.datatran.c_before}
                              </h3>
                            </Card.Body>
                          </Card>
                        </Col>
                      </div>
                      <div className="main-signin-header">
                        {appshow2 ? (
                          <></>
                        ) : (
                          <>
                            <div className="mx-auto text-center mt-4 mg-b-20">
                              <h5 className="mg-b-10 tx-16 text-success">
                                เราได้ส่ง รหัสอนุมัติถอนเงิน ให้คุณเรียบร้อยแล้ว
                                กรุณาเช็ค Line Notify ของท่าน{" "}
                              </h5>
                              <p className="tx-13 text-danger">
                                **** Do not send withdrawal authorization codes.
                                Check Line to inform you
                              </p>
                            </div>
                          </>
                        )}

                        {appshow2 ? (
                          <>
                            {" "}
                            <div className="fade alert fade show alert-dismissible alert-solid-danger alert show">
                              {" "}
                              <h1 className="text-center">{srcw}</h1>
                            </div>{" "}
                          </>
                        ) : (
                          <></>
                        )}

                        <form action="#">
                          <div className="form-group">
                            <input
                              placeholder="กรุณายืนยัน OTP ของท่าน"
                              type="password"
                              onChange={inputvalue2("otp")}
                              name="monny"
                              value={otp}
                              className="form-control form-control"
                            />
                          </div>

                          {appshow2 ? (
                            <> </>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-primary btn-block btn btn-primary"
                                onClick={() =>
                                  Approve_witconfrem({
                                    accnum: data.data.data_scb.fromAccountNo,
                                    tran_id:
                                      data.data.data_scb.kbankInternalSessionId,
                                    data_tran_id: data.data.datatran.id,
                                  })
                                }
                              >
                                ยืนยันการโอนเงิน
                              </button>
                            </>
                          )}
                        </form>
                      </div>

                      {srcwType && (
                        <>
                          <ul className="list-group list-group-flush">
                            {/* {data.data.data4 != "kbank" && <>
                    <h3 className="text-center text-info">กำลังโหลด...</h3>
                      <img
                        className="img-thumbnail"
                        src={`https://demo.kickoff289.football/images/loaderNew.svg`}
                        alt=""
                        width={300}
                      />
                    </>} */}
                            {data.data.data4 == "kbank" && (
                              <>
                                <li className="list-group-item">
                                  ชื่อผู้รับโอน :{" "}
                                  <span className="text-info">
                                    {data.data.data_scb.toAccountName}
                                  </span>{" "}
                                </li>
                                <li className="list-group-item">
                                  จำนวนเงิน :{" "}
                                  <span className="text-info">
                                    {data.data.data_scb.amountFormat}{" "}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  ธนาคาร :{" "}
                                  <span className="text-info">
                                    {data.data.data_scb.bankName}{" "}
                                  </span>{" "}
                                </li>
                                <li className="list-group-item">
                                  เลขอ้างอิง :{" "}
                                  <span className="text-info">
                                    {data.data.data_scb.kbankInternalSessionId}{" "}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  โอนจากบัญชี :{" "}
                                  <span className="text-success">
                                    {data.data.data_scb.fromAccountNo} ธนาคาร
                                    กสิกรไทย{" "}
                                    <img
                                      src={`https://ufaapiv1.myshadownow.com/images/bank3/kbank.png`}
                                      alt=""
                                      width={20}
                                    />{" "}
                                  </span>
                                </li>
                                {/* <li className="list-group-item">
                          เครดิต สมาชิก :{" "}
                          <span className="text-info">
                            {data.data.data3.data}{" "}
                          </span>
                        </li> */}
                              </>
                            )}

                            {data.data.data4 == "scb" && (
                              <>
                                <li className="list-group-item">
                                  ชื่อผู้รับโอน :{" "}
                                  <span className="text-info">
                                    {data.data.data_scb.data.accountToName}
                                  </span>{" "}
                                </li>
                                <li className="list-group-item">
                                  จำนวนเงิน :{" "}
                                  <span className="text-info">
                                    {data.data.datatran.amount}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  ธนาคาร :{" "}
                                  <span className="text-info">
                                    {data.data.datatran.bank_to}{" "}
                                  </span>{" "}
                                </li>
                                <li className="list-group-item">
                                  เลขอ้างอิง :{" "}
                                  <span className="text-info">
                                    {data.data.data_scb.data.transactionToken}{" "}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  โอนจากบัญชี :{" "}
                                  <span className="text-success">
                                    {data.data.data_scb.data.accountFromName}{" "}
                                    ธนาคาร ไทยพาณิชย์{" "}
                                    <img
                                      src={`https://ufaapiv1.myshadownow.com/images/bank3/scb.png`}
                                      alt=""
                                      width={20}
                                    />{" "}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  เครดิตก่อนถอน :{" "}
                                  <span className="text-info">
                                    {data.data.datatran.c_after}{" "}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  เครดิตหลังถอน :{" "}
                                  <span className="text-info">
                                    {data.data.datatran.c_before}{" "}
                                  </span>
                                </li>
                              </>
                            )}
                          </ul>
                          <div className="card-body">
                            {!appshow && data.data.data4 == "kbank" && (
                              <>
                                <a
                                  className="btn btn-success waves-effect waves-light"
                                  onClick={() =>
                                    Approve_witconfrem({
                                      accnum: data.data.data_scb.fromAccountNo,
                                      tran_id:
                                        data.data.data_scb
                                          .kbankInternalSessionId,
                                      data_tran_id: data.data.datatran.id,
                                    })
                                  }
                                >
                                  ยืนยันการโอนเงิน
                                </a>{" "}
                              </>
                            )}

                            {!appshow && data.data.data4 == "scb" && (
                              <>
                                <a
                                  className="btn btn-success waves-effect waves-light"
                                  onClick={() =>
                                    Approve_witconfrem2({
                                      accnum: data.data.acc_from,
                                      data_tran_id: data.data.datatran.id,
                                    })
                                  }
                                >
                                  ยืนยันการโอนเงิน
                                </a>{" "}
                              </>
                            )}

                            {appshow && (
                              <button
                                type="button"
                                className="btn btn-dark waves-effect waves-light"
                              >
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />{" "}
                                Loading
                              </button>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
                {data.data.data4 == "scb" && (
                  <>
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title text-center">
                          ข้อมูล ยืนยันการโอนเงิน
                        </h4>
                      </div>
                      <Row className="row-sm">
                        <Col lg={6} xl={6} md={6} className="col-12 mx-auto">
                          <Card className=" bg-primary-gradient text-white ">
                            <Card.Body>
                              <Row>
                                <div className="col-12">
                                  <div className="icon1 mt-2 text-center">
                                    <img
                                      src={`https://ufaapiv1.myshadownow.com/images/bank3/${data.data.data_member.bank}.png`}
                                      alt=""
                                      width={60}
                                    />
                                  </div>
                                </div>
                                <Col className="col-12">
                                  <div className="mt-0 text-center">
                                    <span className="text-white">
                                      {" "}
                                      {data.data.data_scb.data.accountToName}
                                    </span>
                                    <h2 className="text-white">
                                      {" "}
                                      {data.data.data_member.accnum}
                                    </h2>
                                    <h2 className="text-white mb-0">
                                      {data.data.datatran.amount} ฿
                                    </h2>
                                  </div>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      <p className="text-center">
                        บัญชีที่ใช้โอน {data.data.data_scb.data.accountFromName}
                      </p>
                      {/* <li className="list-group-item">
                    เครดิตก่อนถอน :{" "}
                    <span className="text-info">
                      {data.data.datatran.c_after}{" "}
                    </span>
                  </li>
                  <li className="list-group-item">
                    เครดิตหลังถอน :{" "}
                    <span className="text-info">
                      {data.data.datatran.c_before}{" "}
                    </span>
                  </li> */}
                      <div className="row">
                        <Col xl={6} lg={6} sm={6} md={6}>
                          <Card className=" text-center">
                            <Card.Body className="">
                              <div className="feature widget-2 text-center mt-0 mb-3">
                                <i className="ti-bar-chart project bg-primary-transparent mx-auto text-primary "></i>
                              </div>
                              <h6 className="mb-1 text-muted">เครดิตก่อนถอน</h6>
                              <h3 className="font-weight-semibold">
                                $ {data.data.datatran.c_after}
                              </h3>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col xl={6} lg={6} sm={6} md={6}>
                          <Card className=" text-center">
                            <Card.Body className="">
                              <div className="feature widget-2 text-center mt-0 mb-3">
                                <i className="ti-pie-chart project bg-pink-transparent mx-auto text-pink "></i>
                              </div>
                              <h6 className="mb-1 text-muted">เครดิตหลังถอน</h6>
                              <h3 className="font-weight-semibold">
                                $ {data.data.datatran.c_before}
                              </h3>
                            </Card.Body>
                          </Card>
                        </Col>
                      </div>
                      <div className="main-signin-header">
                        {appshow2 ? (
                          <></>
                        ) : (
                          <>
                            <div className="mx-auto text-center mt-4 mg-b-20">
                              <h5 className="mg-b-10 tx-16 text-success">
                                เราได้ส่ง รหัสอนุมัติถอนเงิน ให้คุณเรียบร้อยแล้ว
                                กรุณาเช็ค Line Notify ของท่าน{" "}
                              </h5>
                              <p className="tx-13 text-danger">
                                **** Do not send withdrawal authorization codes.
                                Check Line to inform you
                              </p>
                            </div>
                          </>
                        )}

                        {appshow2 ? (
                          <>
                            {" "}
                            <div className="fade alert fade show alert-dismissible alert-solid-danger alert show">
                              {" "}
                              <h1 className="text-center">{srcw}</h1>
                            </div>{" "}
                          </>
                        ) : (
                          <></>
                        )}

                        <form action="#">
                          <div className="form-group">
                            <input
                              placeholder="กรุณายืนยัน OTP ของท่าน"
                              type="password"
                              onChange={inputvalue2("otp")}
                              name="monny"
                              value={otp}
                              className="form-control form-control"
                            />
                          </div>

                          {appshow2 ? (
                            <> </>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-primary btn-block btn btn-primary"
                                onClick={() =>
                                  Approve_witconfrem2({
                                    accnum: data.data.acc_from,
                                    data_tran_id: data.data.datatran.id,
                                  })
                                }
                              >
                                ยืนยันการโอนเงิน
                              </button>
                            </>
                          )}
                        </form>
                      </div>

                      {srcwType && (
                        <>
                          <ul className="list-group list-group-flush">
                            {/* {data.data.data4 != "kbank" && <>
                    <h3 className="text-center text-info">กำลังโหลด...</h3>
                      <img
                        className="img-thumbnail"
                        src={`https://demo.kickoff289.football/images/loaderNew.svg`}
                        alt=""
                        width={300}
                      />
                    </>} */}
                            {data.data.data4 == "kbank" && (
                              <>
                                <li className="list-group-item">
                                  ชื่อผู้รับโอน :{" "}
                                  <span className="text-info">
                                    {data.data.data_scb.toAccountName}
                                  </span>{" "}
                                </li>
                                <li className="list-group-item">
                                  จำนวนเงิน :{" "}
                                  <span className="text-info">
                                    {data.data.data_scb.amountFormat}{" "}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  ธนาคาร :{" "}
                                  <span className="text-info">
                                    {data.data.data_scb.bankName}{" "}
                                  </span>{" "}
                                </li>
                                <li className="list-group-item">
                                  เลขอ้างอิง :{" "}
                                  <span className="text-info">
                                    {data.data.data_scb.kbankInternalSessionId}{" "}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  โอนจากบัญชี :{" "}
                                  <span className="text-success">
                                    {data.data.data_scb.fromAccountNo} ธนาคาร
                                    กสิกรไทย{" "}
                                    <img
                                      src={`https://ufaapiv1.myshadownow.com/images/bank3/kbank.png`}
                                      alt=""
                                      width={20}
                                    />{" "}
                                  </span>
                                </li>
                                {/* <li className="list-group-item">
                          เครดิต สมาชิก :{" "}
                          <span className="text-info">
                            {data.data.data3.data}{" "}
                          </span>
                        </li> */}
                              </>
                            )}

                            {data.data.data4 == "scb" && (
                              <>
                                <li className="list-group-item">
                                  ชื่อผู้รับโอน :{" "}
                                  <span className="text-info">
                                    {data.data.data_scb.data.accountToName}
                                  </span>{" "}
                                </li>
                                <li className="list-group-item">
                                  จำนวนเงิน :{" "}
                                  <span className="text-info">
                                    {data.data.datatran.amount}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  ธนาคาร :{" "}
                                  <span className="text-info">
                                    {data.data.datatran.bank_to}{" "}
                                  </span>{" "}
                                </li>
                                <li className="list-group-item">
                                  เลขอ้างอิง :{" "}
                                  <span className="text-info">
                                    {data.data.data_scb.data.transactionToken}{" "}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  โอนจากบัญชี :{" "}
                                  <span className="text-success">
                                    {data.data.data_scb.data.accountFromName}{" "}
                                    ธนาคาร ไทยพาณิชย์{" "}
                                    <img
                                      src={`https://ufaapiv1.myshadownow.com/images/bank3/scb.png`}
                                      alt=""
                                      width={20}
                                    />{" "}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  เครดิตก่อนถอน :{" "}
                                  <span className="text-info">
                                    {data.data.datatran.c_after}{" "}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  เครดิตหลังถอน :{" "}
                                  <span className="text-info">
                                    {data.data.datatran.c_before}{" "}
                                  </span>
                                </li>
                              </>
                            )}
                          </ul>
                          <div className="card-body">
                            {!appshow && data.data.data4 == "kbank" && (
                              <>
                                <a
                                  className="btn btn-success waves-effect waves-light"
                                  onClick={() =>
                                    Approve_witconfrem({
                                      accnum: data.data.data_scb.fromAccountNo,
                                      tran_id:
                                        data.data.data_scb
                                          .kbankInternalSessionId,
                                      data_tran_id: data.data.datatran.id,
                                    })
                                  }
                                >
                                  ยืนยันการโอนเงิน
                                </a>{" "}
                              </>
                            )}

                            {!appshow && data.data.data4 == "scb" && (
                              <>
                                <a
                                  className="btn btn-success waves-effect waves-light"
                                  onClick={() =>
                                    Approve_witconfrem2({
                                      accnum: data.data.acc_from,
                                      data_tran_id: data.data.datatran.id,
                                    })
                                  }
                                >
                                  ยืนยันการโอนเงิน
                                </a>{" "}
                              </>
                            )}

                            {appshow && (
                              <button
                                type="button"
                                className="btn btn-dark waves-effect waves-light"
                              >
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />{" "}
                                Loading
                              </button>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Approve_wit;
