import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Row,
} from "react-bootstrap";
import Apisetting from "../../../api/Apisetting";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Data_setting2 = (data: any) => {
  const [statepost, setStatepost] = useState({
    token_line: data.data.data.token_line,
    token_line_game: data.data.data.token_line_game,
    line: data.data.data.line,
    token_line_depo: data.data.data.token_line_depo,
    token_line_with: data.data.data.token_line_with,
  });

  //  console.log(statepost)

  const {
    token_line,
    token_line_game,
    line,
    token_line_depo,
    token_line_with,
  } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  const post_settingweb = (e: any) => {
    e.preventDefault();
    let datapost = {
      token_line: token_line,
      token_line_game: token_line_game,
      line: line,
      token_line_depo: token_line_depo,
      token_line_with: token_line_with,
    };

    Apisetting.Postupdate_settings(datapost)
      .then((res) => {
        toast.success("แก้ไข ตั้งค่าแจ้งเตือนไลน์ สำเร็จ ... ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // setTimeout(function () {

        // }, 5500);

        //
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Form className="form-horizontal" onSubmit={post_settingweb}>
        <FormGroup className="form-group">
          <Form.Label className="form-label">แจ้งเตือนหลัก</Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            placeholder="token_line"
            name="token_line"
            value={token_line}
            onChange={inputvalue("token_line")}
            defaultValue="token_line"
          />
        </FormGroup>
        <FormGroup className="form-group">
          <Form.Label className="form-label">
            Token แจ้งเตือน รับโบนัส
          </Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            placeholder="ชื่อเว็บไซต์"
            name="token_line_game"
            value={token_line_game}
            onChange={inputvalue("token_line_game")}
            defaultValue="ชื่อเว็บไซต์"
          />
        </FormGroup>
        <FormGroup className="form-group">
          <Form.Label className="form-label">
            Token แจ้งเตือน เข้าสู่ระบบ+สมัคร
          </Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            placeholder="ชื่อเว็บไซต์"
            name="token_line_depo"
            value={token_line_depo}
            onChange={inputvalue("token_line_depo")}
            defaultValue="Token Line@"
          />
        </FormGroup>
        <FormGroup className="form-group">
          <Form.Label className="form-label">
            Token แจ้งเตือน แจ้งเตือนเติมเงิน
          </Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            name="token_line_with"
            placeholder="token_line_with"
            value={token_line_with}
            onChange={inputvalue("token_line_with")}
            defaultValue="token_line_with"
          />
        </FormGroup>

        <FormGroup className="form-group mb-0 mt-3 justify-content-end">
          <div>
            <Button
              variant=""
              type="submit"
              className="btn btn-secondary btn-block w-50 mx-auto"
            >
              บันทึก
            </Button>
          </div>
        </FormGroup>
      </Form>
    </>
  );
};
export default Data_setting2;
