import React, { useState, useEffect, useReducer } from "react";
import { Breadcrumb, Card, Col, Form, Nav, Row, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SettingAPI from "../../api/SettingAPI";
const Settings = (prop: any) => {
  const location = useLocation();
  const game: any = location.state;
  const [checked, setChecked] = React.useState(true);
  //console.log(game);
  async function toggleChange(data: any) {
    let datapost = {
        data: data,
      };
      SettingAPI.create_gamelists1(datapost)
        .then((res) => {
            setState1({
                data: res.data.data,
              });
          
          //
        })
        .catch((err) => {
          console.log(err);
        });
  }

  const datas = (data: any) => {
    let datapost = {
      data: data.games,
    };
    SettingAPI.create_gamelists(datapost)
      .then((res) => {
        //onsole.log(res.data);
        setState1({
          data: res.data.data,
        });
        //
      })
      .catch((err) => {
        console.log(err);
      });
    setState({
      data: data.games,
    });
    //
  };
  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
    },
  );
  const [state1, setState1] = useReducer(
    (state1: any, newState1: any) => ({ ...state1, ...newState1 }),
    {
      data: [],
    },
  );
  const [Copy, setCopy] = useState(false);
  const [Copyk, setCopyk] = useState(false);
  const [showPopup1, setShowPopup1] = useState(false);
  const [showPopup1k, setShowPopup1k] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <div>
        {/* <!-- breadcrumb --> */}
        <div className="breadcrumb-header justify-content-between">
          <div className="left-content">
            <span className="main-content-title mg-b-0 mg-b-lg-1">
              เปิด-ปิด เกมส์หน้าเว็บ
            </span>
          </div>
          <div className="justify-content-center mt-2">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item
                className="breadcrumb-item "
                active
                aria-current="page"
              >
                เปิด-ปิด เกมส์หน้าเว็บ
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        {/* <!-- /breadcrumb --> */}

        {/* <!-- row --> */}
        <Row>
          <Col lg={4} xl={3}>
            <Card className="card custom-card">
              <Card.Header className="card-header">
                <Card.Title>เปิด-ปิด เกมส์หน้าเว็บ</Card.Title>
              </Card.Header>
              <Card.Body className="main-content-left main-content-left-mail card-body">
                <div className="main-settings-menu">
                  <Nav className="nav main-nav-column" defaultActiveKey="Main">
                    {game.username &&
                      game.username.map((items: any, index: any) => {
                        return (
                          <>
                            <Nav.Item as="li">
                              <Nav.Link
                                className="nav-link thumb  mb-2"
                                eventKey={items.providerName}
                                onClick={() => datas(items)}
                              >
                                <img
                                  src={items.logoURL}
                                  alt=""
                                  width={30}
                                  className="me-2"
                                />{" "}
                                {items.providerName}{" "}
                              </Nav.Link>
                            </Nav.Item>
                          </>
                        );
                      })}
                    {/* <Nav.Item as="li">
                  <Nav.Link className="nav-link thumb mb-2" eventKey="WebApps">
                    <i className="fe fe-grid"></i> Web Apps
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link className="nav-link thumb mb-2" eventKey="General">
                    <i className="fe fe-server"></i> General
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    className="nav-link thumb mb-2"
                    eventKey="Components"
                  >
                    <i className="fe fe-globe"></i> Components
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link className="nav-link thumb mb-2" eventKey="Pages">
                    <i className="fe fe-layers"></i> Pages
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link className="nav-link thumb mb-2" eventKey="Language">
                    <i className="fe fe-flag"></i> Language & Region
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    className="nav-link thumb mb-2"
                    eventKey="Notifications"
                  >
                    <i className="fe fe-bell"></i> Notifications
                  </Nav.Link>
                </Nav.Item> */}
                  </Nav>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={8} xl={9}>
            <Card className="card custom-card">
              <Card.Header className="card-header">
                <Card.Title>เลือกเกมส์</Card.Title>
              </Card.Header>
            </Card>
            <Row>
              {state.data &&
                state.data.map((itemss: any, index: any) => {
                  return (
                    <>
                      
                      <Col
                        lg={12}
                        xl={6}
                        md={12}
                        sm={12}
                        className=" p-2 bg-gray-600"
                      >
                        {state1.data &&
                        state1.data.map((it: any, indexs: any) => {
                          return (
                            <>{itemss.gameName == it.gameName && <>
                            
                            
                            
                            
                            <Card>
                          <Card.Body>
                            <Row>
                              <Col lg={12}>
                                <div className="d-flex ">
                                  <div className="settings-main-icon me-4 mt-1">
                                    <img
                                      src={itemss.image.horizontal}
                                      width={150}
                                      alt=""
                                    />
                                  </div>
                                  <div className="">
                                    <p className="tx-20 font-weight-semibold d-flex mb-0 ">
                                      {itemss.gameName}
                                    </p>
                                    <p className="tx-13 text-muted mb-0 "></p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                          <Card.Footer className={`${it.status == "ACTIVE" ? "p-3 bg-success-gradient": "p-3 bg-danger-gradient" }`}>
                            <Link to="#" className="tx-14 mb-0">
                              {it.status == "ACTIVE" ? 'online': "offline" } 
                            </Link>
                            <Form.Label className="custom-switch float-end mb-0">
                              <Link to="#" className="tx-14 mb-0 me-2">
                                ปิด-เปิด
                              </Link>
                              <input
                                type="checkbox"
                                name="custom-switch-checkbox3"
                                className="custom-switch-input"
                                onChange={() =>
                                  toggleChange({
                                    status:it.status,
                                    id:itemss.id,
                                    name: it.gameName,
                                    item: it.provider,
                                  })
                                }
                              />
                              <span className="custom-switch-indicator custom-radius"></span>
                            </Form.Label>
                          </Card.Footer>
                        </Card>
                            
                            
                            
                            
                            </>}
                            
                            </>
                          );
                        })}
                      </Col>
                    </>
                  );
                })}
            </Row>
          </Col>
        </Row>
        {/* <!--/ row --> */}
      </div>
    </>
  );
};

Settings.propTypes = {};

Settings.defaultProps = {};

export default Settings;
