import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Row,
} from "react-bootstrap";
import Apisetting from "../../api/Apisetting";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Data_setting_wl = (data: any) => {
  const [statepost, setStatepost] = useState({
    aff_w: data?.data?.data?.aff_w,
    wl_wit: data?.data?.data?.wl_wit,
    line: data?.data?.data?.line,
    wl_online: data?.data?.data?.wl_online,
    token_line_with: data?.data?.data?.token_line_with,
  });

  //  console.log(statepost)

  const {
    aff_w,
    wl_wit,
    line,
    wl_online,
    token_line_with,
  } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  const post_settingweb = (e: any) => {
    e.preventDefault();
    let datapost = {
      aff_w: aff_w,
      wl_wit: wl_wit,
      line: line,
      wl_online: wl_online,
      token_line_with: token_line_with,
    };

    Apisetting.Postupdate_settings(datapost)
      .then((res) => {
        toast.success("แก้ไข ตั้งค่าแจ้งเตือนไลน์ สำเร็จ ... ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // setTimeout(function () {

        // }, 5500);

        //
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Form className="form-horizontal" onSubmit={post_settingweb}>
        <FormGroup className="form-group">
          <Form.Label className="form-label">ยอดขึ้นต่ำที่จะให้กดถอน</Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            placeholder="ชื่อเว็บไซต์"
            name="wl_wit"
            value={wl_wit}
            onChange={inputvalue("wl_wit")}
            defaultValue="ชื่อเว็บไซต์"
          />
        </FormGroup>
        <FormGroup className="form-group">
          <Form.Label className="form-label">
            % ที่จะให้เพื่อนที่แนะนำ
          </Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            placeholder="aff_w"
            name="aff_w"
            value={aff_w}
            onChange={inputvalue("aff_w")}
            defaultValue="aff_w"
          />
          
        </FormGroup>
        <FormGroup className="form-group">
          <Form.Label className="form-label">
            ปุ่มกดรับ {wl_online == 1 ? <> (  <em className="mb-2 text-success">
                <i className='fas fa-exclamation-circle'></i> กำลังเปิดแสดปุ่มกดรับ
              </em>  ) </> : <> <em className="mb-2 text-danger">
                <i className='fas fa-exclamation-circle'></i> ปิดแสดปุ่มกดรับแล้วet
              </em></>}
          </Form.Label>
          {/* <Form.Control
            type="text"
            className="form-control"
            placeholder="ชื่อเว็บไซต์"
            name="wl_online"
            value={wl_online}
            onChange={inputvalue("wl_online")}
            defaultValue="Token Line@"
          /> */}
          <select
            name="wl_online"
            id="wl_online"
            className="form-select"
            value={
              wl_online
            }
            onChange={inputvalue(
              "wl_online"
            )}
          >
            <option value="1">
              online
            </option>
            <option value="0">
              offonline
            </option>

          </select>
        </FormGroup>
        {/* <FormGroup className="form-group">
          <Form.Label className="form-label">
            Token แจ้งเตือน แจ้งเตือนเติมเงิน
          </Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            name="token_line_with"
            placeholder="token_line_with"
            value={token_line_with}
            onChange={inputvalue("token_line_with")}
            defaultValue="token_line_with"
          />
        </FormGroup> */}

        <FormGroup className="form-group mb-0 mt-3 justify-content-end">
          <div>
            <Button
              variant=""
              type="submit"
              className="btn btn-secondary btn-block w-50 mx-auto"
            >
              บันทึก
            </Button>
          </div>
        </FormGroup>
      </Form>
    </>
  );
};
export default Data_setting_wl;
