import React, { useEffect, useMemo, useReducer, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Card,
  Button,
  Col,
  Row,
  //  DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
} from "react-bootstrap";
import Apiufa from "../api/AgentUfaapi";
//import { Badge, Breadcrumb, Button, Form, FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
//import { useSelector, useDispatch } from "react-redux";
import AdminAPI from "../api/Admin";
import FriendAPI from "../../components/api/Friend";
import TranAPI from "../api/TranAPI";
import { useNavigate, useLocation } from "react-router-dom";
//import Breadcrumbs
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  Table,
  //CardHeader,
  Modal,
  //Card
  // Progress,
  // UncontrolledAlert,
} from "react-bootstrap";
import Tran_none from "./tran_none";
import Approve_wit from "./approve_wit";
import Swal from "sweetalert2";
import Loaders from "@Components/loads/loaders";
import { Container } from "reactstrap";

import App_new from "./app_new";
import Moment from "moment";
import "moment/locale/th";
const Event_live = (data: any) => {
  useEffect(() => {
    getdatawitlive();
  }, []);
  useEffect(() => {
    const id = setInterval(() => {
      getdatawitlive();
    }, 9500);
    return () => clearInterval(id);
  }, []);

  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
      data3: [],
    }
  );
  const [state4, setState4] = useReducer(
    (state4: any, newState4: any) => ({ ...state4, ...newState4 }),
    {
      data: [],
      data3: [],
    }
  );

  const [statecon, setStatecon] = useReducer(
    (statecon: any, newStatecon: any) => ({ ...statecon, ...newStatecon }),
    {
      data: [],
    }
  );

  const [statew, setStatew] = useReducer(
    (statew: any, newStatew: any) => ({ ...statew, ...newStatew }),
    {
      data: [],
      data3: [],
      data4: [],
      data_mem: [],
      data_tran: [],
    }
  );
  let history = useNavigate();

  const descriptionElementRef: any = React.useRef(null);

  const gopro = (data: any) => {
    history("/pages/profile/", { state: { username: data } });
  };
  const getdatawitlive = () => {
    FriendAPI.getbank_chack_wit()
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          setState({
            data: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [modal_center, setmodal_center] = useState(false);
  const [modal_center1, setmodal_center1] = useState(false);
  const [modal_centerload, setmodal_centerload] = useState(false);

  const handleClose = () => {
    setmodal_center(false);
  };

  const [Scroll, setScroll] = useState(false);
  const [modal_centerch, setmodal_centerch] = useState(false);
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_center_ch(data: any) {
    setState4({
      data: data,
    });
    // console.log(data);
    setmodal_centerch(!modal_centerch);
  }
  function cancelwit_m(data: any) {
    Swal.fire({
      title: "คุณแน่ใจที่จะ ตัดเครดิตยอดนี้?",
      text: `ตัดเครดิต สมาชิก ${data.item.name_member}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง, ยกเลิกถอนเงิน!",
    }).then((result) => {
      if (result.isConfirmed) {
        const postData6 = {
          tid: data.item.id,
        };
        // console.log(postData6);
        AdminAPI.cancelwit_m(postData6)
          .then((res) => {
            //console.log(res.data);

            if (res.data.success) {
              getdatawitlive();
            } else {
            }
          })
          .catch((err) => {
            console.log(err);
          });
        //  Swal.fire("ยกเลิกถอนเงิน!", "ยกเลิกถอนเงิน สำเร็จ", "success");
      }
    });
  }
  const de_all = (data: any) => {
    Swal.fire({
      title: "คุณแน่ใจหรือไม่?",
      text: `คุณแน่ใจหรือไม่ ที่จะลบ ${data.item.id} !`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const datap = {
          id: data.item.id,
        };

        Apiufa.update_tranallnone(datap)
          .then((res) => {
            // console.log(res.data);

            if (res.data.success) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              getdatawitlive();
            } else {
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  function tog_center(data: any) {
    setmodal_centerload(true);
    setmodal_center(!modal_center);
    //console.log(data);

    // setStatecon({ data: data });
    // setmodal_center(!modal_center);
    const postData2 = {
      tran_id: data.item.id,
    };

    TranAPI.chackall_Disposit(postData2)
      .then((res) => {
        //
        if (res.data.success) {
          setmodal_centerload(false);
          if (res.data.data4 == "scb") {


            if (res.data.data_scb.status.code == 1000) {

              setStatecon({
                data: res.data,
              });
            } else {
              Swal.fire(
                "เกิดข้อผิดพลาด",
                `${res.data.data_scb.status.description}`,
                "error"
              ).then((result) => {
                if (result.isConfirmed) {
                }
              });
            }
          } else if (res.data.data4 == "kbank") {
            setStatecon({
              data: res.data,
            });
          //  setmodal_center(!modal_center);
          }
        } else {
         // setmodal_centerload(false);
          Swal.fire("Error", "เกิดข้อผิดพลาด", "error").then((result) => {
            if (result.isConfirmed) {
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const cancelwit = (data_idstop: any) => {
    Swal.fire({
      title: "คุณแน่ใจที่จะ ยกเลิกถอนเงิน?",
      text: `ยกเลิกถอนเงิน สมาชิก ${data_idstop.acc}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง, ยกเลิกถอนเงิน!",
    }).then((result) => {
      if (result.isConfirmed) {
        const postData6 = {
          id: data_idstop.tran_id,
        };
        //console.log(postData6);
        AdminAPI.cancelwit(postData6)
          .then((res) => {
            //  console.log(res.data);

            if (res.data.success) {
              getdatawitlive();
            } else {
            }
          })
          .catch((err) => {
            console.log(err);
          });
        Swal.fire("ยกเลิกถอนเงิน!", "ยกเลิกถอนเงิน สำเร็จ", "success");
      }
    });
  };

  const approve_wit = (data_idstop: any) => {};
  const [modal_center_add, setModal_center_add] = useState(false);
  const data3 = state.data;

  const [stateauto2, setStateauto2] = useReducer(
    (stateauto2: any, newStateauto2: any) => ({
      ...stateauto2,
      ...newStateauto2,
    }),
    {
      data: [],
      data_auto: [],
    }
  );

  const add_manual = (data: any) => {
    setStateauto2({ data: data });
    setModal_center_add(true);
    removeBodyCss();
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          {state.data.map((item: any, index: number) => {
            return <></>;
          })}

          <div className="table-responsive">
            <table className="table table-bordered table-danger mb-0">
              {state.data.length > 0 && (
                <>
                  <thead>
                    <tr>
                      <th scope="col" />
                      <th scope="col" className="text-center">
                        <span className="fw-normal">สมาชิก</span>
                      </th>
                      <th scope="col" className="text-center">
                        <span className="fw-normal">ประเภท</span>
                      </th>
                      <th scope="col" className="text-center">
                        <span className="fw-normal">เวลาแจ้งดำเนินการ</span>
                      </th>
                      <th scope="col" className="text-center">
                        <span className="fw-normal">จำนวนเงิน</span>
                      </th>
                      <th scope="col" className="text-center">
                        <span className="fw-normal">สถานะ</span>
                      </th>
                      <th scope="col" className="text-center">
                        <span className="fw-normal">ข้อมูลสมาชิก</span>
                      </th>
                      <th scope="col" className="text-center">
                        <span className="fw-normal">ตรวจสอบ</span>
                      </th>
                      <th scope="col" className="text-center">
                        <span className="fw-normal">ดำเนินการ</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.data.map((item: any, index: number) => {
                      return (
                        <>
                          {item.type_option == "ถอน" && (
                            <>
                              <tr
                                className="btn-danger text-danger"
                                key={index}
                              >
                                <td>1</td>
                                <td className="text-center">
                                  {" "}
                                  <button
                                    className="btn btn-warning bg-secondary me-1"
                                    onClick={() => gopro(item.user_id)}
                                  >
                                    {" "}
                                    {item.user_id}
                                  </button>
                                </td>
                                <td className="text-center">
                                  {" "}
                                  <p className="">
                                    {item.remark} <br />
                                  </p>{" "}
                                </td>
                                <td className="text-center">
                                  <h6> {Moment(item.updated_at).fromNow()}</h6>
                                </td>
                                <td className="text-center">
                                  {" "}
                                  <h2 className="mb-1 tx-13 font-weight-sembold">
                                    {item.amount}{" "}
                                    <span className="text-danger">.00</span>
                                  </h2>{" "}
                                </td>
                                <td className="text-center">
                                  {" "}
                                  {item.status == "pending" && (
                                    <>
                                      <span className="btn btn-warning waves-effect waves-light">
                                        {" "}
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                        รอดำเนินการ
                                      </span>
                                    </>
                                  )}{" "}
                                </td>
                                <td className="text-center">
                                  <img
                                    src={`https://ufaapiv1.myshadownow.com/images/bank3/${item.bank_to}.png`}
                                    alt=""
                                    width={40}
                                  />
                                  <br />
                                  {item.name_member}
                                </td>
                                <td className="text-center">
                                  <div className="d-flex flex-wrap gap-3">
                                    <button
                                      type="button"
                                      title="ตรวจสอบ"
                                      className="btn btn-info-gradient me-2"
                                      onClick={() => gopro(item.user_id)}
                                    >
                                      <i className="fas fa-credit-card label-icon me-2"></i>
                                      Profile
                                    </button>
                                  </div>
                                </td>

                                <td className="text-center">
                                  <div className="btn-group btn-group-sm mb-1 ms-auto float-sm-right mt-1">
                                    <Button
                                      variant=""
                                      className="btn btn-outline-danger me-2"
                                      onClick={() => {
                                        tog_center({
                                          item,
                                        });
                                      }}
                                    >
                                      <span className="fa fa-check tx-16 me-2"></span>
                                      อนุมัติถอนเงิน
                                    </Button>
                                    <Button
                                      variant=""
                                      className="btn btn-outline-danger  btn me-2"
                                      onClick={() =>
                                        cancelwit_m({
                                          item,
                                        })
                                      }
                                    >
                                      <span className="fa fa-check tx-16 me-2"></span>
                                      ตัดเครดิต
                                    </Button>
                                    <Button
                                      variant=""
                                      className="btn btn-danger-gradient me-2"
                                      onClick={() =>
                                        cancelwit({
                                          acc: item.user_id,
                                          tran_id: item.id,
                                        })
                                      }
                                    >
                                      <span className="far fa-trash-alt tx-16 me-2"></span>
                                      ยกเลิก
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}

                          {/* ฝาก */}

                          {item.type_option == "ฝาก" && (
                            <>
                              <tr className="table-success">
                                <td>1</td>
                                <td className="text-center">
                                  <button
                                    className="btn btn-warning bg-secondary me-1"
                                    onClick={() => gopro(item.user_id)}
                                  >
                                    {" "}
                                    {item.user_id}
                                  </button>
                                </td>
                                <td className="text-center">
                                  {" "}
                                  <p>
                                    <u>{item.remark}</u> <br />
                                  </p>{" "}
                                </td>
                                <td className="text-center">
                                  <h6> {Moment(item.updated_at).fromNow()}</h6>
                                </td>
                                <td className="text-center">
                                  {" "}
                                  {item.amount}.00{" "}
                                </td>
                                <td className="text-center">
                                  {" "}
                                  {item.status == "pending" && (
                                    <>
                                      <span className="btn btn-warning waves-effect waves-light">
                                        {" "}
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                        รอดำเนินการ
                                      </span>
                                    </>
                                  )}{" "}
                                </td>
                                <td className="text-center">
                                  <img
                                    src={`https://ufaapiv1.myshadownow.com/images/bank3/${item.bank_to}.png`}
                                    alt=""
                                    width={40}
                                  />
                                  <br />
                                  {item.name_member}
                                </td>
                                <td className="text-center">
                                  <div className="d-flex flex-wrap gap-3">
                                    <button
                                      type="button"
                                      title="ตรวจสอบ"
                                      className="btn btn-info-gradient me-2"
                                      onClick={() => gopro(item.user_id)}
                                    >
                                      <i className="fas fa-credit-card label-icon me-2"></i>
                                      Profile
                                    </button>
                                  </div>
                                </td>

                                <td className="text-center">
                                  <div className="btn-group btn-group-sm mb-1 ms-auto float-sm-right mt-1">
                                    <Button
                                      variant=""
                                      className="btn btn-success-gradient me-2"
                                      onClick={() => {
                                        add_manual(item);
                                      }}
                                    >
                                      <span className="fa fa-check tx-16 me-2"></span>
                                      ปรับเครดิต
                                    </Button>

                                    {/* <Button
                                      variant=""
                                      className="btn btn-info-gradient me-2"
                                      onClick={() =>
                                        add_manual({
                                          item,
                                        })
                                      }
                                    >
                                      <span className="fa fa-cog tx-16 me-2"></span>
                                      ปรับเครดิต และ ตั้งค่าฝากออโต้ครั้งถัดไป
                                    </Button> */}
                                    <Button
                                      variant=""
                                      className="btn btn-danger-gradient me-2"
                                      onClick={() =>
                                        de_all({
                                          item,
                                        })
                                      }
                                    >
                                      <span className="far fa-trash-alt tx-16 me-2"></span>
                                      ยกเลิก
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}

                          {/* จบฝาก */}

                          {/* ฝากโบ นัส */}

                          {item.type_option == "โบนัส" && (
                            <>
                              <tr className="table-warning">
                                <td>1</td>
                                <td className="text-center">
                                  <button
                                    className="btn btn-warning bg-secondary me-1"
                                    onClick={() => gopro(item.user_id)}
                                  >
                                    {" "}
                                    {item.user_id}
                                  </button>
                                </td>
                                <td className="text-center">
                                  {" "}
                                  <p>
                                    <u>{item.remark}</u> <br />
                                  </p>{" "}
                                </td>
                                <td className="text-center">
                                  <h6> {Moment(item.updated_at).fromNow()}</h6>
                                </td>
                                <td className="text-center">
                                  {" "}
                                  {item.amount} .00
                                </td>
                                <td className="text-center">
                                  {" "}
                                  {item.status == "pending" && (
                                    <>
                                      <span className="btn btn-warning waves-effect waves-light">
                                        {" "}
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                        รอดำเนินการ
                                      </span>
                                    </>
                                  )}{" "}
                                </td>
                                <td className="text-center">
                                  <img
                                    src={`https://ufaapiv1.myshadownow.com/images/bank3/${item.bank_to}.png`}
                                    alt=""
                                    width={40}
                                  />
                                  <br />
                                  {item.name_member}
                                </td>
                                <td className="text-center">
                                  <div className="d-flex flex-wrap gap-3">
                                    <button
                                      type="button"
                                      title="ตรวจสอบ"
                                      className="btn btn-info-gradient me-2"
                                      onClick={() => gopro(item.user_id)}
                                    >
                                      <i className="fas fa-credit-card label-icon me-2"></i>
                                      Profile
                                    </button>
                                  </div>
                                </td>

                                <td className="text-center">
                                  <div className="btn-group btn-group-sm mb-1 ms-auto float-sm-right mt-1">
                                    <Button
                                      variant=""
                                      className="btn btn-outline-success  btn btn me-2"
                                      onClick={() => {
                                        add_manual(item);
                                      }}
                                    >
                                      <span className="fa fa-check tx-16 me-2"></span>
                                      ปรับเครดิต
                                    </Button>

                                    {/* <Button
                                      variant=""
                                      className="btn btn-info-gradient me-2"
                                      onClick={() =>
                                        add_manual(item)
                                      }
                                    >
                                      <span className="fa fa-cog tx-16 me-2"></span>
                                      ปรับเครดิต แบบ ไม่ติดโปร
                                    </Button> */}
                                    <Button
                                      variant=""
                                      className="btn btn-danger-gradient me-2"
                                      onClick={() =>
                                        de_all({
                                          item,
                                        })
                                      }
                                    >
                                      <span className="far fa-trash-alt tx-16 me-2"></span>
                                      ยกเลิก
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}

                          {/* จบฝาก */}
                        </>
                      );
                    })}
                  </tbody>
                </>
              )}
            </table>
          </div>
        </Card.Body>
      </Card>


      {/* <Modal
        className="modal-dialog modal-lg"
        show={modal_center_add}
        toggle={() => {
          add_manual(stateauto2);
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">เลิอก รายการ เติมเงิน </h5>
          <button
            type="button"
            onClick={() => {
              setModal_center_add(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        
      </Modal> */}

      <Modal
        show={modal_centerch}
        toggle={() => {
          tog_center_ch(data);
        }}
        centered={true}
        className="modal-dialog modal-lg"
      >
        <div className="modal-header ">
          <h5 className="modal-title mt-0">
            {" "}
            <i className="fas fa-credit-card"></i> อนุมัติถอนเงิน
          </h5>

          <button
            type="button"
            onClick={() => {
              setmodal_centerch(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

       
      </Modal>

      {/* <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center(data);
        }}
        centered={true}
        className="modal-dialog modal-lg"
      >
        <div className="modal-header ">
          <h5 className="modal-title mt-0">
            {" "}
            <i className="fas fa-credit-card"></i> อนุมัติถอนเงิน
          </h5>

          <button
            type="button"
            onClick={() => {
              setmodal_center(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        
        {modal_centerload && (
          <>
            <Approve_wit data={statecon.data} />
          </>
        )}
      </Modal> */}

<Modal
        size="lg"
        show={modal_center_add}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title>อนุมัติถอนเงิน โดยแอดมิน</Modal.Title>
          <Button
            variant=""
            className="btn btn-close ms-auto"
            onClick={() =>  setModal_center_add(false)}
          >
            x
          </Button>
        </Modal.Header>
        <Modal.Body>

        <Tran_none
          data={stateauto2.data}
          setModal_center_add={setModal_center_add}
        />

        


        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="primary"
            onClick={() => {
              handleClose();
            }}
            className="btn btn-danger btn-w-sm mb-1 mx-auto"
          >
            Save Changes
          </Button> */}
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            ปิดหน้าต่างนี้
          </Button>
        </Modal.Footer>
      </Modal>




<Modal
        size="lg"
        show={modal_centerch}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title>อนุมัติถอนเงิน โดยแอดมิน</Modal.Title>
          <Button
            variant=""
            className="btn btn-close ms-auto"
            onClick={() =>  setmodal_centerch(false)}
          >
            x
          </Button>
        </Modal.Header>
        <Modal.Body>

        <App_new data={state4} />

        


        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="primary"
            onClick={() => {
              handleClose();
            }}
            className="btn btn-danger btn-w-sm mb-1 mx-auto"
          >
            Save Changes
          </Button> */}
          <Button
            variant="secondary"
            onClick={() =>  setmodal_centerch(false)}
          >
            ปิดหน้าต่างนี้
          </Button>
        </Modal.Footer>
      </Modal>




      
      <Modal
        size="lg"
        show={modal_center}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title>อนุมัติถอนเงิน โดยแอดมิน</Modal.Title>
          <Button
            variant=""
            className="btn btn-close ms-auto"
            onClick={() => {
              handleClose();
            }}
          >
            x
          </Button>
        </Modal.Header>
        <Modal.Body>

          {modal_centerload ? <><Loaders /></>  : <> <Approve_wit data={statecon.data} /></> }

        


        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="primary"
            onClick={() => {
              handleClose();
            }}
            className="btn btn-danger btn-w-sm mb-1 mx-auto"
          >
            Save Changes
          </Button> */}
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            ปิดหน้าต่างนี้
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Dialog
      
        open={modal_center}
        
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Modal title</DialogTitle>
        <DialogContent >
        <Loaders />
        </DialogContent>
        <DialogActions>
          <Button variant="secondary" className="me-1" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" className="me-1" onClick={handleClose}>
            Subscribe
          </Button>
        </DialogActions>
      </Dialog> */}
    </React.Fragment>
  );
};

export default Event_live;
