import React, { useEffect, useMemo, useReducer, useState } from "react";
import { Breadcrumb, Card, Col, Form, Nav, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Collapse, Tab, Tabs } from "react-bootstrap";
import AdminAPI from "../../api/Admin";
import {
  ToastContainer,
  toast,
  Bounce,
  Slide,
  Flip,
  Zoom,
} from "react-toastify";
import { useNavigate } from "react-router-dom";
const Settings = () => {
  const location = useLocation();
  const game: any = location.state;

  const maindata = game;
  // console.log(maindata);
  const [checked, setChecked] = React.useState(true);
  let history = useNavigate();

  useEffect(() => {
    getdatamenu();
  }, []);

  const getdatamenu = () => {
    const dadapost = {
      id: maindata,
    };
    AdminAPI.get_datamenu(dadapost)
      .then((res) => {
        //console.log(res.data);

        if (res.data.success) {
          setState2({
            data: res.data.data,
          });
        } else {
          toast.success(
            <p className="text-white tx-16 mb-0">
              {`Create Nenu Successfully`}
            </p>,
            {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
              autoClose: 5000,
              theme: "colored",
            },
          );
          getdatamenu();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [state2, setState2] = useReducer(
    (state2: any, newState2: any) => ({ ...state2, ...newState2 }),
    {
      data: [],
    },
  );

  async function toggleChange(data: any) {
    // if (data.item == true) {
    //  await setChecked(false);
    // } else {
    //   await setChecked(true);
    // }
    //
    const dadapost = {
      id: data.data,
      name: data.name,
      item: data.item,
    };

    //console.log(data);
    AdminAPI.getupdate_datamenu(dadapost)
      .then((res) => {
        // console.log(res.data);
        if (res.data.success == true) {
          toast.success("ทำรายการสำเร็จ", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          getdatamenu();
          // setTimeout(function () {
          //   navigate("/datapay/datapay/");
          // }, 5500);
        } else {
          toast.error(<p className="text-white tx-16 mb-0">{res.data.msg}</p>, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 5000,
            theme: "colored",
          });
          getdatamenu();
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  return (
    <>
      <div>
        {/* <!-- breadcrumb --> */}
        <div className="breadcrumb-header justify-content-between">
          <div className="left-content">
            <span className="main-content-title mg-b-0 mg-b-lg-1">
              SETTINGS
            </span>
          </div>
          <div className="justify-content-center mt-2">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item
                className="breadcrumb-item "
                active
                aria-current="page"
              >
                Settings
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        {/* <!-- /breadcrumb --> */}

        {/* <!-- row --> */}
        <Row>
          {/* <Col lg={4}xl={3}>
        <Card className="card custom-card">
          <Card.Header className="card-header">
            <Card.Title>Settings</Card.Title>
          </Card.Header>
          <Card.Body className="main-content-left main-content-left-mail card-body">
            <div className="main-settings-menu">
              <Nav className="nav main-nav-column" defaultActiveKey="Main">
                <Nav.Item as="li">
                  <Nav.Link className="nav-link thumb  mb-2" eventKey="Main">
                    <i className="fe fe-home"></i> Main{" "}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link className="nav-link thumb mb-2" eventKey="WebApps">
                    <i className="fe fe-grid"></i> Web Apps
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link className="nav-link thumb mb-2" eventKey="General">
                    <i className="fe fe-server"></i> General
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    className="nav-link thumb mb-2"
                    eventKey="Components"
                  >
                    <i className="fe fe-globe"></i> Components
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link className="nav-link thumb mb-2" eventKey="Pages">
                    <i className="fe fe-layers"></i> Pages
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link className="nav-link thumb mb-2" eventKey="Language">
                    <i className="fe fe-flag"></i> Language & Region
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    className="nav-link thumb mb-2"
                    eventKey="Notifications"
                  >
                    <i className="fe fe-bell"></i> Notifications
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </Card.Body>
        </Card>
      </Col> */}
          {/*  */}
          <ToastContainer />
          <Col lg={12} xl={12}>
            <div className="">
              <div className="">
                <div className="panel panel-primary ">
                  <div className="tab-menu-heading tabs-style-4">
                    <div className="tabs-menu ">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="TabStyle01"
                      >
                        <Row>
                          <Col sm={2}>
                            <Nav variant="pills" className="flex-column">
                              <Nav.Item>
                                <Nav.Link eventKey="TabStyle01">
                                  ตั้งค่าสิทเข้าถึงเมนู
                                </Nav.Link>
                              </Nav.Item>
                              {/* <Nav.Item>
                            <Nav.Link eventKey="TabStyle02">
                              Tab Style 02
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="TabStyle03">
                              Tab Style 03
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="TabStyle04">
                              Tab Style 04
                            </Nav.Link>
                          </Nav.Item> */}
                            </Nav>
                          </Col>
                          <Col sm={8}>
                            <Tab.Content className="tabs-style-4">
                              {/* <Tab.Pane eventKey="TabStyle01">
                            <div
                              className="panel-body tabs-menu-body"
                              id="tab21"
                            >
                              <p>
                                Cupiditate non provident voluptatum
                                deleniti atque corrupti quos dolores et
                                quas praesentium voluptatum deleniti
                              </p>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="TabStyle02">
                            <div
                              className="panel-body tabs-menu-body"
                              id="tab22"
                            >
                              <p>
                                Cupiditate non provident voluptatum
                                deleniti atque corrupti quos dolores et
                                quas, cum soluta nobis est eligendi
                              </p>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="TabStyle03">
                            <div
                              className="panel-body tabs-menu-body"
                              id="tab23"
                            >
                              <p>
                                Cupiditate non provident voluptatum
                                deleniti atque corrupti quos dolores et
                                quas, cum soluta nobis est eligendi

                              </p>
                            </div>
                          </Tab.Pane> */}
                              <Col lg={12} xl={12} md={12} sm={12}>
                                <Tab.Pane eventKey="TabStyle01">
                                  <div className="row">
                                    {/* <Col lg={4} xl={4} md={4} sm={4} className=" p-2">
                                      <Card>
                                        <Card.Body>
                                          <Row>

                                          </Row>
                                        </Card.Body>
                                        <Card.Footer className="p-3">
                                          <Link to="#" className="tx-14 mb-0">
                                            Go to Settings
                                          </Link>
                                          <Form.Label className="custom-switch float-end mb-0">
                                            <Link to="#" className="tx-14 mb-0 me-2">
                                              Restore default
                                            </Link>
                                            <input
                                              type="checkbox"
                                              name="custom-switch-checkbox3"
                                              className="custom-switch-input"
                                            />
                                            <span className="custom-switch-indicator custom-radius"></span>
                                          </Form.Label>
                                        </Card.Footer>
                                      </Card>
                                    </Col> */}

                                    {state2.data &&
                                      state2.data.map(
                                        (items: any, index: number) => {
                                          return (
                                            <>
                                              <Col
                                                lg={4}
                                                xl={4}
                                                md={4}
                                                sm={4}
                                                className={`p2 `}
                                              >
                                                <Card
                                                  className={`${
                                                    items.is_readonly == 1
                                                      ? "bg-success"
                                                      : "bg-danger"
                                                  }`}
                                                >
                                                  <Card.Body>
                                                    <Row></Row>
                                                  </Card.Body>
                                                  <h5 className="text-center">
                                                    {items.name}
                                                  </h5>
                                                  <Card.Footer className="p-3">
                                                    <p className="tx-14 mb-0">
                                                      {items.is_readonly ==
                                                      0 ? (
                                                        <>
                                                          <span className="text-danger">
                                                            ปิดการใช้งาน
                                                          </span>{" "}
                                                        </>
                                                      ) : (
                                                        <>
                                                          <span className="text-success">
                                                            เปิดการใช้งาน
                                                          </span>{" "}
                                                        </>
                                                      )}
                                                    </p>
                                                    <Form.Label className="custom-switch float-end mb-0">
                                                      {/* <p className="tx-14 mb-0 me-2">ปิดการใช้งาน เมนูนี้</p> */}
                                                      <input
                                                        type="checkbox"
                                                        name="custom-switch-checkbox3"
                                                        className="custom-switch-input"
                                                        onChange={() =>
                                                          toggleChange({
                                                            data: items.id,
                                                            name: items.name,
                                                            item: items.is_readonly,
                                                          })
                                                        }
                                                      />
                                                      <span className="custom-switch-indicator custom-radius"></span>
                                                    </Form.Label>
                                                  </Card.Footer>
                                                </Card>
                                              </Col>
                                            </>
                                          );
                                        },
                                      )}

                                    {/* <Col lg={4} xl={4} md={4} sm={4} className=" p-2">
                                      <Card>
                                        <Card.Body>
                                          <Row>

                                          </Row>
                                        </Card.Body>
                                        <Card.Footer className="p-3">
                                          <Link to="#" className="tx-14 mb-0">
                                            Go to Settings
                                          </Link>
                                          <Form.Label className="custom-switch float-end mb-0">
                                            <Link to="#" className="tx-14 mb-0 me-2">
                                              Restore default
                                            </Link>
                                            <input
                                              type="checkbox"
                                              name="custom-switch-checkbox3"
                                              className="custom-switch-input"
                                            />
                                            <span className="custom-switch-indicator custom-radius"></span>
                                          </Form.Label>
                                        </Card.Footer>
                                      </Card>
                                    </Col> */}
                                  </div>
                                </Tab.Pane>
                              </Col>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/*  */}
        </Row>
        {/* <!--/ row --> */}
      </div>
    </>
  );
};

Settings.propTypes = {};

Settings.defaultProps = {};

export default Settings;
