import axios from "axios";
import config from "../../config/app.config";
import { setAuthorizationToken } from "../../helpers/axios";

setAuthorizationToken();

//console.log(setAuthorizationToken())

const ProMotionAPI = {
  Getdatapromotion: async () => {
    const resData: any = {
      method: "get",
      url: `${config.apiURL}/api/v5/user/datapromotion2ss`,
      headers: {
        "Content-Type": "application/json",
      },
      //data: data
    };

    return await axios(resData);
  },
  prohit:  async (data: any) => {
    const resData: any = {
        method: "post",
        url: `${config.apiURL}/api/v5/user/prohit`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      //data: data
    };

    return await axios(resData);
  },
  unprohit:  async (data: any) => {
    const resData: any = {
        method: "post",
        url: `${config.apiURL}/api/v5/user/unprohit`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      //data: data
    };

    return await axios(resData);
  },
  data_aff: async () => {
    const resData: any = {
      method: "get",
      url: `${config.apiURL}/api/v5/user/data_aff`,
      headers: {
        "Content-Type": "application/json",
      },
      //data: data
    };

    return await axios(resData);
  },
  data_wl: async () => {
    const resData: any = {
      method: "get",
      url: `${config.apiURL}/api/v5/user/data_wl`,
      headers: {
        "Content-Type": "application/json",
      },
      //data: data
    };

    return await axios(resData);
  },
  addpro: async (data: any) => {
    const resData: any = {
      method: "post",
      url: `${config.apiURL}/api/v5/user/addPromotion`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(resData);
  },
  cronto_pro: async (data: any) => {
    const resData: any = {
      method: "post",
      url: `${config.apiURL}/api/v5/user/cronto_pro`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(resData);
  },
  edit_aff: async (data: any) => {
    const resData: any = {
      method: "post",
      url: `${config.apiURL}/api/v5/user/edit_aff`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(resData);
  },
  edit_wl: async (data: any) => {
    const resData: any = {
      method: "post",
      url: `${config.apiURL}/api/v5/user/edit_wl`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(resData);
  },

  editPromotion: async (data: any) => {
    const resData: any = {
      method: "post",
      url: `${config.apiURL}/api/v5/user/editPromotion`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(resData);
  },
  delit_pro: async (data: any) => {
    const resData: any = {
      method: "post",
      url: `${config.apiURL}/api/v5/user/delit_pro`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(resData);
  },
};
export default ProMotionAPI;
