import React, { Fragment, useState, useEffect, useReducer } from "react";
import Scrollbars from "react-custom-scrollbars";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SettingAPI from "../../components/api/SettingAPI";
import AdminAPI from "../../components/api/Admin";

let history: any = [];

const Sidebar = () => {
  let location = useLocation();

  useEffect(() => {
    getdatamenus();
  }, []);

  const getdatamenus = () => {
    SettingAPI.gatdatamenu()
      .then((res) => {
        if (res.data.success) {
          setMenuitems(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [menuitems, setMenuitems]: any = useState([]);

  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
    }
  );

  // initial loading
  useEffect(() => {
    history.push(location.pathname);
    if (history.length > 2) {
      history.shift();
    }
    if (history[0] !== history[1]) {
      setSidemenu();
    }
    let mainContent: any = document.querySelector(".main-content");

    mainContent.addEventListener("click", mainContentClickFn);
    return () => {
      mainContent.removeEventListener("click", mainContentClickFn);
    };
  }, [location]);

  useEffect(() => {
    if (
      document.body.classList.contains("horizontal") &&
      window.innerWidth >= 992
    ) {
      clearMenuActive();
    }
  }, []);

  useEffect(() => {
    getdatamenu();
    getapisetting();
  }, []);

  const getapisetting = () => {
    SettingAPI.getdatasetting_fontend()
      .then((res) => {
        setState2({
          data: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [state2, setState2] = useReducer(
    (state2: any, newState2: any) => ({ ...state2, ...newState2 }),
    {
      data: [],
    }
  );

  const getdatamenu = () => {
    const dadapost = {
      id: 0,
    };
    AdminAPI.get_datamenu2(dadapost)
      .then((res) => {
        if (res.data.success) {
          setState({
            data: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function mainContentClickFn() {
    if (
      document.body.classList.contains("horizontal") &&
      window.innerWidth >= 992
    ) {
      clearMenuActive();
    }
  }

  function clearMenuActive() {
    setMenuitems((prevItems: any) =>
      prevItems.map((mainlevel: any) => ({
        ...mainlevel,
        Items: mainlevel.Items.map((item: any) => ({
          ...item,
          active: false,
          selected: false,
          ...(item.children && {
            children: item.children.map((child: any) => ({
              ...child,
              active: false,
              selected: false,
            })),
          }),
        })),
      }))
    );
  }

  function setSidemenu() {
    if (menuitems) {
      const updatedMenuitems = menuitems.map((mainlevel: any) => {
        if (mainlevel.Items) {
          mainlevel.Items = mainlevel.Items.map((item: any) => {
            item.active = false;
            item.selected = false;

            if (
              location.pathname === "/nowa/preview/" ||
              location.pathname === "/nowa/preview/"
            ) {
              location.pathname = "/nowa/preview/dashboard/dashboard";
            }

            if (location.pathname === item.path + "/") {
              item.active = true;
              item.selected = true;
            }

            if (item.children) {
              item.children = item.children.map((submenu: any) => {
                submenu.active = false;
                submenu.selected = false;

                if (location.pathname === submenu.path + "/") {
                  item.active = true;
                  item.selected = true;
                  submenu.active = true;
                  submenu.selected = true;
                }

                return submenu;
              });
            }

            return item;
          });
        }

        return mainlevel;
      });

      setMenuitems(updatedMenuitems);
    }
  }

  function toggleSidemenu(item: any) {
    if (
      !document.body.classList.contains("horizontalmenu-hover") ||
      window.innerWidth < 992
    ) {
      setMenuitems((prevItems: any) =>
        prevItems.map((mainlevel: any) => ({
          ...mainlevel,
          Items: mainlevel.Items.map((sublevel: any) => {
            let isActive = sublevel === item;

            if (sublevel.children) {
              sublevel.children.forEach((child: any) => {
                if (child === item) {
                  isActive = true;
                }
              });
            }

            return {
              ...sublevel,
              active: isActive,
            };
          }),
        }))
      );
    }
  }

  function Onhover() {
    if (document.querySelector(".app")) {
      if (document.querySelector(".app")?.classList.contains("sidenav-toggled"))
        document.querySelector(".app")?.classList.add("sidenav-toggled-open");
    }
  }

  function Outhover() {
    if (document.querySelector(".app")) {
      document.querySelector(".app")?.classList.remove("sidenav-toggled-open");
    }
  }

  return (
    <>
      <div className="sticky">
        <aside
          className="app-sidebar "
          onMouseOver={Onhover}
          onMouseOut={Outhover}
        >
          <Scrollbars className="hor-scroll" style={{ position: "absolute" }}>
            <div className="main-sidebar-header active">
              <NavLink
                className="header-logo active"
                to={`${process.env.PUBLIC_URL}/dashboard/dashboard`}
              >
                {state2.data && (
                  <>
                    <img
                      className="main-logo  desktop-dark"
                      alt="logo"
                      src={state2.data.loggo_url}
                    />
                    <img
                      className="main-logo  desktop-logo"
                      alt="logo"
                      src={state2.data.loggo_url}
                    />
                    <img
                      className="main-logo  mobile-logo"
                      alt="logo"
                      src={state2.data.loggo_url}
                    />
                    <img
                      className="main-logo  mobile-dark"
                      alt="logo"
                      src={state2.data.loggo_url}
                    />
                  </>
                )}
              </NavLink>
            </div>
            <div className="main-sidemenu">
              <div className="slide-left disabled" id="slide-left">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#7b8191"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                </svg>
              </div>
              {/* <ul className="side-menu">
                {menuitems.map((Item: any, itemi: any) => (
                  <Fragment key={itemi + Math.random() * 100}>
                    <li className="side-item side-item-category">
                      {Item.menutitle}
                    </li>
                    {Item.Items.map((menuItem: any, i: any) => (
                      <li
                        key={i}
                        className={`slide ${
                          menuItem.selected || menuItem.active
                            ? "is-expanded"
                            : ""
                        }`}
                      >
                        {menuItem.type === "link" && (
                          <NavLink
                            to={menuItem.path + "/"}
                            className={`side-menu__item ${
                              menuItem.selected ? "active" : ""
                            }`}
                          >
                            {menuItem.icon}
                            <span className="side-menu__label">
                              {menuItem.title}
                            </span>
                            <i className="angle fe fe-chevron-right"></i>
                            {menuItem.badge && (
                              <label className={menuItem.badge}>
                                {menuItem.badgetxt}
                              </label>
                            )}
                          </NavLink>
                        )}
                        {menuItem.type === "sub" && (
                          <a
                            href="javascript"
                            onClick={(event) => {
                              event.preventDefault();
                              toggleSidemenu(menuItem);
                            }}
                            className={`side-menu__item ${
                              menuItem.selected ? "active is-expanded" : ""
                            }`}
                          >
                            {menuItem.icon}
                            <span className="side-menu__label">
                              {menuItem.title}
                            </span>
                            {menuItem.badge && (
                              <label className={`${menuItem.badge} side-badge`}>
                                {menuItem.badgetxt}
                              </label>
                            )}
                          </a>
                        )}
                        {menuItem.children && (
                          <ul
                            className={`slide-menu ${
                              menuItem.active ? "open" : ""
                            }`}
                            style={menuItem.active ? { display: "block" } : {}}
                          >
                            {menuItem.children.map(
                              (childrenItem: any, index: any) => (
                                <li
                                  key={index}
                                  className={`sub-slide ${
                                    childrenItem.selected || childrenItem.active
                                      ? "is-expanded"
                                      : ""
                                  }`}
                                >
                                  {childrenItem.type === "sub" ? (
                                    <a
                                      href="javascript"
                                      className={`slide-item ${
                                        childrenItem.selected
                                          ? "active is-expanded"
                                          : ""
                                      }`}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        toggleSidemenu(childrenItem);
                                      }}
                                    >
                                      <span className="sub-side-menu__label">
                                        {childrenItem.title}
                                      </span>
                                      <i className="sub-angle fe fe-chevron-right"></i>
                                    </a>
                                  ) : (
                                    <NavLink
                                      to={childrenItem.path + "/"}
                                      className="slide-item"
                                    >
                                      {childrenItem.title}
                                    </NavLink>
                                  )}
                                </li>
                              )
                            )}
                          </ul>
                        )}
                      </li>
                    ))}
                  </Fragment>
                ))}
              </ul> */}
              <ul className="side-menu">
                {menuitems.map((Item: any, itemi: any) => (
                  <Fragment key={itemi + Math.random() * 100}>
                    <li className="side-item side-item-category">
                      {Item.menutitle}
                    </li>
                    {Item.Items.map((menuItem: any, i: any) => (
                      <li
                        className={`slide ${
                          menuItem.selected ? "is-expanded" : ""
                        }  ${menuItem.active ? "is-expanded" : ""}`}
                        key={i}
                      >
                        {menuItem.type === "link" ? (
                          <NavLink
                            to={menuItem.path + "/"}
                            className={`side-menu__item ${
                              menuItem.selected ? " active" : ""
                            }`}
                          >
                            {menuItem.icon}
                            <span className="side-menu__label">
                              {menuItem.title}
                            </span>
                            {menuItem.badge ? (
                              <label className={menuItem.badge}>
                                {menuItem.badgetxt}
                              </label>
                            ) : (
                              ""
                            )}
                          </NavLink>
                        ) : (
                          ""
                        )}
                        {menuItem.type === "sub" ? (
                          <a
                            href="javascript"
                            onClick={(event) => {
                              event.preventDefault();
                              toggleSidemenu(menuItem);
                            }}
                            className={`side-menu__item ${
                              menuItem.selected ? "active is-expanded" : ""
                            }`}
                          >
                           
                           <svg className="side-menu__icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M6.5 10h-2v7h2v-7zm6 0h-2v7h2v-7zm8.5 9H2v2h19v-2zm-2.5-9h-2v7h2v-7zm-7-6.74L16.71 6H6.29l5.21-2.74m0-2.26L2 6v2h19V6l-9.5-5z"></path></svg>

                            <span className="side-menu__label">
                              {menuItem.title}
                              {menuItem.active}
                            </span>
                            {menuItem.badge ? (
                              <label className={`${menuItem.badge} side-badge`}>
                                {menuItem.badgetxt}
                              </label>
                            ) : (
                              ""
                            )}
                            <i className="angle fe fe-chevron-right"></i>
                          </a>
                        ) : (
                          ""
                        )}

                        {menuItem.children ? (
                          <ul
                            className={`slide-menu ${
                              menuItem.active ? "open" : ""
                            }`}
                            style={
                              menuItem.active
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            {menuItem.children.map(
                              (childrenItem: any, index: any) => {
                                return (
                                  <li
                                    key={index}
                                    className={`sub-slide ${
                                      childrenItem.selected ? "is-expanded" : ""
                                    } ${
                                      childrenItem.active ? "is-expanded" : ""
                                    }`}
                                  >
                                    {childrenItem.type === "sub" ? (
                                      <a
                                        href="javascript"
                                        className={`slide-item ${
                                          childrenItem.selected
                                            ? "active is-expanded"
                                            : ""
                                        }`}
                                        onClick={(event) => {
                                          event.preventDefault();
                                          toggleSidemenu(childrenItem);
                                        }}
                                      >
                                        <span className="sub-side-menu__label">
                                          {childrenItem.title}
                                          {childrenItem.active}
                                        </span>

                                        <i className="sub-angle fe fe-chevron-right"></i>
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                    {childrenItem.type === "link" ? (
                                      <span style={{ background: "red" }}>
                                        <NavLink
                                          to={childrenItem.path + "/"}
                                          className="slide-item"
                                        >
                                          {childrenItem.title}
                                        </NavLink>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {childrenItem.children ? (
                                      <ul
                                        className={`sub-slide-menu ${
                                          childrenItem.selected ? "open" : ""
                                        }`}
                                        style={
                                          childrenItem.active
                                            ? { display: "block" }
                                            : { display: "none" }
                                        }
                                      >
                                        {childrenItem.children.map(
                                          (childrenSubItem: any, key: any) => (
                                            <li key={key}>
                                              {childrenSubItem.type ===
                                              "link" ? (
                                                <NavLink
                                                  to={
                                                    childrenSubItem.path + "/"
                                                  }
                                                  className="sub-side-menu__item"
                                                >
                                                  <span className="sub-side-menu__label">
                                                    {childrenSubItem.title}
                                                    {childrenSubItem.active}
                                                  </span>
                                                </NavLink>
                                              ) : (
                                                ""
                                              )}
                                              {childrenSubItem.type ===
                                              "sub" ? (
                                                <span
                                                  className={`sub-slide2 ${
                                                    childrenSubItem.selected
                                                      ? "is-expanded"
                                                      : ""
                                                  } ${
                                                    childrenSubItem.active
                                                      ? "is-expanded"
                                                      : ""
                                                  }`}
                                                >
                                                  <NavLink
                                                    to="#"
                                                    className="sub-side-menu__item"
                                                    onClick={(event) => {
                                                      event.preventDefault();
                                                      toggleSidemenu(
                                                        childrenSubItem
                                                      );
                                                    }}
                                                  >
                                                    <span className="sub-side-menu__label">
                                                      {childrenSubItem.title}
                                                      {childrenSubItem.active}
                                                    </span>
                                                    <i className="sub-angle2 fe fe-chevron-down"></i>
                                                  </NavLink>
                                                  {childrenItem.children.map(
                                                    (
                                                      childrenSubItemsub: any,
                                                      key: any
                                                    ) => (
                                                      <ul
                                                        key={key}
                                                        className={`sub-slide-menu1 ${
                                                          childrenSubItemsub.selected
                                                            ? "open"
                                                            : ""
                                                        }`}
                                                        style={
                                                          childrenSubItemsub.active
                                                            ? {
                                                                display:
                                                                  "block",
                                                              }
                                                            : {
                                                                display: "none",
                                                              }
                                                        }
                                                      >
                                                        {childrenItem.children.map(
                                                          (
                                                            childrenSubItemsubs: any,
                                                            key: any
                                                          ) => (
                                                            <li key={key}>
                                                              <NavLink
                                                                className="sub-slide-item2"
                                                                to="#"
                                                              >
                                                                {
                                                                  childrenSubItemsubs.title
                                                                }
                                                                {
                                                                  childrenSubItemsubs.active
                                                                }
                                                              </NavLink>
                                                            </li>
                                                          )
                                                        )}
                                                      </ul>
                                                    )
                                                  )}
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </Fragment>
                ))}
              </ul>
              <div className="slide-right" id="slide-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#7b8191"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                </svg>
              </div>
            </div>
          </Scrollbars>
        </aside>
      </div>
    </>
  );
};

Sidebar.propTypes = {};

Sidebar.defaultProps = {};
export default Sidebar;
