import React from "react";
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import { Link } from "react-router-dom";
//import Breadcrumbs

import { Container } from "reactstrap";
import Moment from 'moment';
const Transfer_information = (data: any) => {
  //console.log(data.datatran.activityList)

  

  return (
    <React.Fragment>
      <div className="table-responsive text-center">
        <table className="table table-bordered mb-0 table-centered">
          <tbody>
            <tr>
              <td style={{ width: "20%" }}>
                <div className="py-3">
                  <h5 className="font-size-16 mb-0">รายการเดินบัญชี ทั้งหมด <span className="text-success">{data.datatran.totalOfRecord}</span> รายการ</h5>
                </div>
              </td>
            </tr>

            {data.datatran.activityList &&
              data.datatran.activityList.map((item: any, index: number) => {
                return (
                  <>
                    {item.transactionDescription == "รับโอนเงิน" && (
                      <tr className="table-success">
                        <td>
                          <div >
                            <span >
                              {item.transactionDescription}
                              <span className="text-success">
                                {item.amount} บาท
                              </span>
                              ช่องทาง :  {item.channel} 
                             
                            </span>
                          </div>
                        </td>
                      </tr>
                    )}
                    <>
                    {item.transactionDescription == "โอนเงิน" && (
                      <tr className="table-danger">
                        <td>
                          <div >
                            <span >
                              {item.transactionDescription}
                              <span className="text-danger">
                                {item.amount} บาท
                              </span>
                              ไปยัง {item.toAccountNo} 
                              ช่องทาง : {item.channel} 
                              
                            </span>
                          </div>
                        </td>
                      </tr>
                    )}
                    </>
                  </>
                  
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="modal-body"></div>
    </React.Fragment>
  );
};

export default Transfer_information;
