import React, { useState, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { Card, Col, Dropdown, Breadcrumb, Nav, Row, Tab, FormGroup, Form } from "react-bootstrap";

import Swal from "sweetalert2";
import AdminAPI from "../../api/Admin";
const EditProfile = (data: any) => {
    useEffect(() => {
       
        databank_list_f();
      }, []);

    //console.log(data.data)
    const [statepost, setStatepost] = useState({
        username: data.data.username,
        password: data.data.password,
        password2: "",
        line: data.data.line,
        accnum: data.data.accnum,
        bank: data.data.bank,
        user_ufa: data.data.user_ufa,
        pass_ufa: data.data.pass_ufa,
        name: data.data.name,
        aff: "",
        edit_id: data.data.id
    });
    const { username, password, password2, line, accnum, bank, name, aff, edit_id, user_ufa, pass_ufa } =
        statepost;
    const inputvalue = (name: any) => (event: any) => {
        setStatepost({ ...statepost, [name]: event.target.value });
    };
    const edit = (e: any) => {
        e.preventDefault();


        const postData2 = {
            accnum: accnum,
            bank: bank,
            line: line,
            name: name,
            username: username,
            password: password,
            user_ufa: user_ufa,
            pass_ufa: pass_ufa,
            edit_id: edit_id,
            // otp:""
        };
        AdminAPI.Updatebank_user(postData2)
            .then((res) => {
                //  console.log(res.data);

                if (res.data.success) {
                    Swal.fire({
                        icon: "success",
                        title: res.data.msg,
                    }).then((result) => {
                        if (result.isConfirmed) {

                        }
                    });


                } else {
                }
            })
            .catch((err) => {
                console.log(err);
            });


    }


    const databank_list_f = () => {
        AdminAPI.databank_list()
          .then((res) => {
            setState111({ data: res.data.data });
            //console.log(res.data)
          })
          .catch((err) => {
            console.log(err);
          });
      };
      const [state111, setState111] = useReducer(
        (state111: any, newState111: any) => ({ ...state111, ...newState111 }),
        {
          data: [],
        },
      );
    return (
        <>
            <Form className="form-horizontal" onSubmit={edit}>
                <div className="mb-4 main-content-label">
                    Name
                </div>
                <FormGroup className="form-group ">
                    <Row className=" row-sm">
                        <Col md={3}>
                            <Form.Label className="form-label">
                                User Name
                            </Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control
                                type="text"
                                className="form-control"
                                value={username}
                                name="username"
                                onChange={inputvalue("username")}
                                placeholder="User Name"
                                defaultValue={data.data.username}
                                disabled
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className="form-group ">
                    <Row className=" row-sm">
                        <Col md={3}>
                            <Form.Label className="form-label">
                                ชื่อ - นามสกุล
                            </Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                value={name}
                                name="name"
                                onChange={inputvalue("name")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className="form-group ">
                    <Row className=" row-sm">
                        <Col md={3}>
                            <Form.Label className="form-label">
                                ธนาคาร
                            </Form.Label>
                        </Col>
                        <Col md={9}>
                            <select
                                className="form-select"
                                value={bank}
                                onChange={inputvalue("bank")}
                            >
                                {state111.data &&
                                  state111.data.map((item: any, index: any) => {
                                    return (
                                      <>
                                        <option value={item.bank_id}>
                                          {item.bankNameTh}{" "}
                                          <img
                                            alt="avatar"
                                            src={`https://easyapi.scorpion168.com/assets/method/4.png`}
                                          />
                                        </option>
                                      </>
                                    );
                                  })}
                            </select>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className="form-group ">
                    <Row className=" row-sm">
                        <Col md={3}>
                            <Form.Label className="form-label">
                                รหัสผ่าน
                            </Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control
                                type="text"
                                className="form-control"
                                placeholder="Nick Name"
                                value={password}
                                name="password"
                                onChange={inputvalue("password")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className="form-group ">
                    <Row className=" row-sm">
                        <Col md={3}>
                            <Form.Label className="form-label">
                                line
                            </Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control
                                type="text"
                                className="form-control"
                                placeholder="Designation"
                                value={line}
                                name="line"
                                onChange={inputvalue("line")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className="form-group ">
                    <Row className=" row-sm">
                        <Col md={3}>
                            <Form.Label className="form-label">
                                pass_ufa
                            </Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control
                                type="text"
                                className="form-control"
                                placeholder="Designation"
                                value={pass_ufa}
                                name="pass_ufa"
                                onChange={inputvalue("pass_ufa")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className="form-group ">
                    <Row className=" row-sm">
                        <Col md={3}>
                            <Form.Label className="form-label">
                                user_ufa
                            </Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control
                                type="text"
                                className="form-control"
                                placeholder="Designation"
                                value={user_ufa}
                                name="user_ufa"
                                onChange={inputvalue("user_ufa")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className="form-group ">
                    <Row className=" row-sm">
                        <Col md={3}>
                            <Form.Label className="form-label">
                                เลขบัญชี
                            </Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control
                                type="text"
                                className="form-control"
                                placeholder="accnum"
                                value={accnum}
                                name="accnum"
                                onChange={inputvalue("accnum")}
                            />
                        </Col>
                    </Row>
                </FormGroup>



                <button type="submit" className="btn btn-primary w-50 mx-auto">แก้ไข</button>
            </Form>


        </>
    )
}

export default EditProfile;
