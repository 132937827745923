import { useEffect, useReducer, useState } from "react";
import Multiselect from "react-select";
import Apibank from "../../../api/APIbank";

import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Row,
  InputGroup,
  Alert,
  FormGroup,
  Form,
} from "react-bootstrap";
const Edit_bank = (data: any) => {
  //   console.log(data)
  let [Switcher2, setSwitcher2] = useState("off");
  let [Switcher21, setSwitcher21] = useState("");
  let [Switcher211, setSwitcher211] = useState(false);

  const [statepost, setStatepost] = useState({
    accnum: data.data.accnum,
    name: data.data.name,
    username: data.data.username,
    password: data.data.password,
    bankname: data.data.bankname,
    edit_id: data.data.id,
    option_b: data.data.option_b,
    level: data.data.level,
    tobank_accnum: data.data.tobank_accnum,
    tobank_bank: data.data.tobank_bank,
    tobank_monney: data.data.tobank_monney,
    limit_wit: data.data.limit_wit,
    tobank_stust: data.data.tobank_stust,
    tobank_minmonny: data.data.tobank_minmonny,
  });

  // if(data.data.d_id) {
  //   setMagdata(data.data.d_id)
  // }
  const {
    option_b,
    accnum,
    password,
    username,
    name,
    edit_id,
    level,
    tobank_accnum,
    tobank_stust,
    limit_wit,
    tobank_monney,
    tobank_minmonny,
    tobank_bank,
  } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  const addBank = (e: any) => {
    e.preventDefault();
    const postData = {
      accnum: accnum,
      name: name,
      username: username,
      password: password,
      id: edit_id,
      level: level,
      tobank_monney: tobank_monney,
      tobank_minmonny: tobank_minmonny,
      tobank_bank: tobank_bank,
      tobank_stust: tobank_stust,
      tobank_accnum: tobank_accnum,
      // level: level,
      // otp:""
    };

    //  console.log(postData)
    Apibank.Posteditbankseting1234(postData)
      .then((res) => {
        if (res.data.success) {
          //setmagdatas(true);
          setSwitcher211(true);
          setSwitcher21(res.data.msg);
          setTimeout(function () {
            window.location.reload();
            // setSwitcher211(false)
          }, 1500);
          // Swal.fire("ลบบัญชีนี้!", "ลบบัญชีนี้ สำเร็จ", "success");
          // setTimeout(function () {}, 2500);
        } else {
          setSwitcher21(res.data.msg);
          // 103.253.75.245  183.89.213.199  console.log(res.data)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //console.log(data)
  return (
    <>
      {data.data && (
        <>
          <form className="" onSubmit={addBank}>
            <div className="row mt-5">
              <div className="col-sm-12 text-center"></div>
            </div>
            <div className="col-md-12 ">
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  เลขบัญชี
                </label>
                <input
                  name="accnum"
                  id="accnum"
                  placeholder="เลขบัญชี"
                  type="text"
                  className="form-control"
                  value={accnum}
                  onChange={inputvalue("accnum")}
                />
                <input
                  name="edit_id"
                  id="edit_id"
                  type="hidden"
                  className="form-control"
                  value={"edit_id"}
                />
              </div>
            </div>
            <div className="col-md-12 ">
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  ชื่อบัญชี
                </label>
                <input
                  name="name"
                  id="name"
                  placeholder="ชื่อบัญชี"
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={inputvalue("name")}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  password
                </label>
                <input
                  name="password"
                  id="password"
                  placeholder="password"
                  type="password"
                  className="form-control"
                  value={password}
                  onChange={inputvalue("password")}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  DeviceID
                </label>
              </div>
            </div>
            <Col lg={12}>
              <Form.Group className=" has-success mg-b-0">
                <textarea
                  className="form-control mg-t-20"
                  placeholder="Textarea (success state)"
                  value={username}
                  onChange={inputvalue("username")}
                  name="username"
                ></textarea>
              </Form.Group>
            </Col>
            {/* <div className="col-md-12">
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                                Pin
                            </label>
                            <input
                                name="password"
                                id="password"

                                type="password"
                                className="form-control"
                                value={password}
                                onChange={inputvalue("password")}
                            />

                        </div>
                    </div> */}
            <Col lg={12} className="">
              <p className="mg-b-10">ประเภท</p>

              <select
                className="form-control"
                id="choices-single-no-search"
                name="tobank_bank"
                placeholder={level}
                value={level}
                onChange={inputvalue("level")}
              >
                <option value="1">หลัก</option>
                <option value="2">สำรอง</option>
              </select>
            </Col>

            <p className="text-center my-2">ตั้งค่าการโยกเงิน</p>
            <FormGroup className="form-group ">
              <Row className=" row-sm">
                <Col md={3}>
                  <Form.Label className="d-block mg-b-15-f">
                    {tobank_stust != 1 ? (
                      <input
                        type="checkbox"
                        name="tobank_stust"
                        onChange={inputvalue("tobank_stust")}
                        value={1}
                        className="custom-switch-input"
                      />
                    ) : (
                      <input
                        type="checkbox"
                        name="tobank_stust"
                        onChange={inputvalue("tobank_stust")}
                        value={0}
                        className="custom-switch-input"
                        defaultChecked
                      />
                    )}

                    <span className="custom-switch-indicator"></span>
                    <span className="text-muted ms-2">เปิด/ปิดใช้งาน</span>
                  </Form.Label>
                </Col>
              </Row>
            </FormGroup>
            <div className="col-md-12 text-center">
              {Switcher211 && (
                <>
                  <Alert className="alert alert-success" role="alert">
                    <p>เกิดข้อผิดพลาด</p>
                    <h3>{Switcher21}</h3>
                  </Alert>
                </>
              )}
              <div className="mb-3 mt-5">
                <label htmlFor="tobank_accnum" className="form-label">
                  เลขบัญชีปลายทาง
                </label>
                <input
                  name="tobank_accnum"
                  id="tobank_accnum"
                  type="number"
                  className="form-control"
                  value={tobank_accnum}
                  onChange={inputvalue("tobank_accnum")}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="tobank_accnum" className="form-label">
                  ธนาคารปลายทาง
                </label>
                {/* <input
                                name="tobank_bank"
                                id="tobank_bank"

                                type="text"
                                className="form-control"
                                value={tobank_bank}
                                onChange={inputvalue("tobank_bank")}
                            /> */}
                <select
                  className="form-control"
                  id="choices-single-no-search"
                  name="tobank_bank"
                  placeholder={tobank_bank}
                  value={tobank_bank}
                  onChange={inputvalue("tobank_bank")}
                >
                  <option value="bbl">กรุงเทพ</option>
                  <option value="kbank">กสิกรไทย</option>
                  <option value="ktb">กรุงไทย</option>
                  <option value="ttb">ทหารไทยธนชาติ</option>
                  <option value="scb">ไทยพาณิชย์</option>

                  <option value="cimb">ซีไอเอ็มบีไทย</option>
                  <option value="uob">ยูโอบี</option>
                  <option value="bay">กรุงศรีอยุธยา</option>
                  <option value="gsb">ออมสิน</option>
                  <option value="">อาคารสงเคราะห์</option>
                  <option value="baac">เพื่อการเกษตรและสหกรณ์การเกษตร</option>
                  <option value="tisco">ทิสโก้</option>
                  <option value="tbank">ธนาคารไอซีบีซี</option>
                  <option value="lhb">แลนด์ แอนด์ เฮาส์</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="tobank_accnum" className="form-label">
                  จำนวนเงินที่จะโยก
                </label>
                <input
                  name="tobank_monney"
                  id="tobank_monney"
                  type="number"
                  className="form-control"
                  value={tobank_monney}
                  onChange={inputvalue("tobank_monney")}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="tobank_accnum" className="form-label">
                  จำนวน เหลือติดบัญชี
                </label>
                <input
                  name="tobank_minmonny"
                  id="tobank_minmonny"
                  type="number"
                  className="form-control"
                  value={tobank_minmonny}
                  onChange={inputvalue("tobank_minmonny")}
                />
              </div>
              <button className="btn btn-info mt-5" type="submit">
                บันทึกการแก้ไข
              </button>
            </div>
          </form>
        </>
      )}
    </>
  );
};
export default Edit_bank;

// tobank_accnum:data.data.tobank_accnum,
// tobank_bank:data.data.tobank_bank,
// tobank_monney:data.data.tobank_monney,
// limit_wit:data.data.limit_wit,
// tobank_stust:data.data.tobank_stust,
// tobank_minmonny:data.data.tobank_minmonny,
