import axios from "axios";
import config from "../config/app.config";
import { setAuthorizationToken_Scb } from "../helpers/axios_SCB";

setAuthorizationToken_Scb();

const Get_Playload = {
  get_Playload_server: async (data: any) => {
    return await axios.get(`${config.url_scb}${data.deviceId}`)
  },
};

export default Get_Playload;
