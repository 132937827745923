import React from "react";
//import W_d_index from "./w_d_index";
import { useEffect, useState, useReducer } from "react";
import { logout, getUser } from "../../../config/authsave";

import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import Data_wit from "./data_wit";

import { Container } from "reactstrap";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Modal,
  Progress,
} from "reactstrap";

const Withdraw_manual = (data: any) => {
  const [options, setOptions] = useState("");

  document.title = "ฝาก-ถอนรายวัน | ฝาก-ถอนรายวัน ";
  return (
    <React.Fragment>
     <Data_wit />
    </React.Fragment>
  );
};

export default Withdraw_manual;
