import React from 'react'
import './style.css'

const Loader = () => {
    return(
        <div id="global-loader">
            <img src={require("../../assets/img/loader.svg").default} className="loader-img" alt="Loader"/>
           <h1 className='text-danger mt-5 maa'> กำลังประมวนผล.... ใจเย็นๆ นะครับ</h1>
        </div>
    )
}

export default Loader;