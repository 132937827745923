import axios from "axios";
import config from "../config/app.config"
import { setAuthorizationToken } from "../helpers/axios";

setAuthorizationToken();
const Apibank = {


    getdatabank: async () => {

        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/admin/getdatabank`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    getdatabank_byid: async (data: any) => {

        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/getdatabank_byidx`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    CrateBankonline: async (data: any) => {

        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/crateBankonline`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    sendMessage_return: async (data: any) => {

        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/sendMessage_return`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    deldatapays: async (data: any) => {

        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/deldatapays`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    connect_Api: async (data: any) => {

        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/connect_Api`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    connect_ฺbankonline_v1: async (data: any) => {

        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/connect_bankonlines`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },

    getbabascbnew: async (data: any) => {

        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/getbabascbnew`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    postchacktranscb: async (data: any) => {

        var axiosConfig: any = {
            method: "post",
            url: `${config.apiURL}/api/v5/admin/postchacktranscb`,
            headers: {

                "Content-Type": "application/json",
            },
            data: data,
        };

        //console.log(axiosConfig)

        return await axios(axiosConfig);
    },
    posttranscb: async (data: any) => {

        var axiosConfig: any = {
            method: "post",
            url: `${config.apiURL}/api/v5/admin/posttranscb`,
            headers: {

                "Content-Type": "application/json",
            },
            data: data,
        };

        //console.log(axiosConfig)

        return await axios(axiosConfig);
    },
    posttrankbank: async (data: any) => {

        var axiosConfig: any = {
            method: "post",
            url: `${config.apiURL}/api/v5/admin/posttrankbank`,
            headers: {

                "Content-Type": "application/json",
            },
            data: data,
        };

        //console.log(axiosConfig)

        return await axios(axiosConfig);
    },

    postchack_balance: async (data: any) => {
        var axiosConfig: any = {
            method: "post",
            url: `${config.apiURL}/api/v5/admin/postchack_balance`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        //console.log(axiosConfig)

        return await axios(axiosConfig);
    },
    uploadImgTrue: async (data: any) => {
        var axiosConfig: any = {
            method: "post",
            url: `${config.apiURL}/api/v5/admin/editbank_qr`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        //console.log(axiosConfig)

        return await axios(axiosConfig);
    },


    get_tranchack: async (data: any) => {

        var axiosConfig: any = {
            method: "post",
            url: `${config.apiURL}/api/v5/admin/get_tranchack`,
            headers: {

                "Content-Type": "application/json",
            },
            data: data,
        };

        //console.log(axiosConfig)

        return await axios(axiosConfig);
    },
    Posteditbankseting1234: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/Posteditbankseting1234`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    edit_banksetting: async (data: any) => {

        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/edit_banksetting`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },

    chack_balanceKbank: async (data: any) => {

        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/chack_balancekbank`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    chack_balanceScb: async (data: any) => {

        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/chack_balancescb`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    removesBankall: async (data: any) => {

        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/removesBankall`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    onlineBankall: async (data: any) => {

        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/onlineBankall`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
}
export default Apibank