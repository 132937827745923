import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import { isEmpty, map } from "lodash";
import Moment from "moment";
import 'moment/locale/th';
import { useEffect, useReducer, useState } from "react";
//import Breadcrumbs
import Swal from "sweetalert2";
//import Breadcrumbs

const options: Object = {
  chart: {
    height: 50,
    type: "line",
    toolbar: { show: false },
  },
  colors: ["#5156be"],
  stroke: {
    curve: "smooth",
    width: 2,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: false,
    },
    y: {
      title: {
        formatter: function (seriesName: any) {
          return "";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};
const Info_tran = (data: any) => {
  //console.log(data.data)

 // console.log(data.data.data.qr)
  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
    },
  );
  const [state4, setState4] = useReducer(
    (state4: any, newState4: any) => ({ ...state4, ...newState4 }),
    {
      data: [],
    },
  );

  


  //Print the Invoice
  const printInvoice = () => {
    window.print();
  };





  document.title = "สรุปยอด ฝาก-ถอน | สรุปยอด ฝาก-ถอน ";
  return (
    <React.Fragment>
      <Row>
        <div className="col-12">
      
          {/* Render Breadcrumbs */}
          

          {!isEmpty(data.data.data) && (
            <Row>
              <Col lg="12" className="align-self-center ">
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <div className="mb-4">
                            {/* <img src={logo} alt="" height="24" /> */}
                            <span className="logo-txt"></span>

                            <img src={data.data.data.qr} alt="" />
                          </div>
                        </div>
                        <div className="flex-shrink-0">
                          <div className="mb-4">
                            <p className="float-end font-size-16">
                              สมาชิก :  {data.data.data.name_member}
                            </p>
                           
                          </div>
                          
                          <div className="mb-4">
                            <p className="float-end font-size-16">
                              Username :  {data.data.data.user_id}
                            </p>
                           
                          </div>
                          <div className="mb-4">
                            <p className="float-end font-size-16">
                              รายการ :  {data.data.data.remark}
                            </p>
                           
                          </div>
                          <div className="mb-4">
                            <p className="float-end font-size-16">
                            ประเภท :  {data.data.data.type_option}
                            </p>
                           
                          </div>
                          <div className="mb-4">
                            <p className="float-end font-size-16">
                              เวลาดำเดินการ : {Moment(data.data.data.created_at).format("MMMM Do YYYY, h:mm:ss") }
                            </p>
                           
                          </div>
                        </div>
                      </div>
                      <address>
                        {/* {map(
                          state.data.shippingAddress.split(","),
                          (item, key) => (
                            <React.Fragment key={key}>
                              <span>{item}</span>
                              <br />
                            </React.Fragment>
                          )
                        )} */}
                      </address>
                    </div>
                    <hr className="my-4" />
                    <Row>
                      <Col sm="12">
                        <div>
                          <div className="row">
                            <div className="col-lg-8">
                            <h5 className="font-size-15 mb-3">ประเภท : {data.data.data.remark != 'ฝาก' && <><span className="text-danger">{data.data.data.remark}</span></>} 
                          
                          {data.data.data.type_option == 'ถอน' && <><span className="text-danger">{data.data.data.type_option}</span></>}
                          
                           </h5>
                            </div>
                            
                          </div>
                          {/* {map(
                            state.data.billingAddress.split(","),
                            (item, key) => (
                              <React.Fragment key={key}>
                                <span>{item}</span>
                                <br />
                              </React.Fragment>
                            )
                          )} */}
                        </div>
                      </Col>
                      {/* <Col sm="6">
                        <div>
                          <div>
                            <h5 className="font-size-15">สถานะ: {data.data.data.status == "successed" && <span className="text-success">ทำรายการสำเร็จ</span>} </h5>
                            <p>{state.data.orderDate}</p>
                          </div>
                        </div>
                        <div className="mt-4">
                          <h5 className="font-size-15">ทำรายการโดย: {data.data.data.add_from}</h5>
                          <h5 className="font-size-15">** หมายเหตุ: {data.data.data.nodere == null && <span className="text-info"> !ไม่มี</span>}</h5>
                          
                        </div>
                      </Col> */}
                    </Row>
                   
                    <div className="p-4 border rounded">
                      <div className="table-responsive">
                      <li className="list-group-item">ข้อมูลสมาชิก : {data.data.data.datamember} </li>
                      <li className="list-group-item">บัญชีระบบ : {data.data.data.bank_from} </li>
                      <li className="list-group-item">จำนวน : {data.data.data.amount} </li>
                      <li className="list-group-item">สถานะ : {data.data.data.status} </li>
                      <li className="list-group-item">remark : {data.data.data.remark}  </li>
                      <li className="list-group-item">จำนวน : {data.data.data.amount}  เครดิต</li>
                      <li className="list-group-item">ยอดก่อนทำรายการ : {data.data.data.c_before} เครดิต</li>
                      <li className="list-group-item">ยอดหลังทำรายการ : {data.data.data.c_after} เครดิต</li>
                      <li className="list-group-item">ref : {data.data.data.ref} </li>
              
                      <li className="list-group-item">เวลา :  {Moment(data.data.data.updated_at).format("MMMM Do YYYY, h:mm:ss") } </li>
                      
                      <li className="list-group-item">ธนาคารลูกค้า : {data.data.data.bank_to} </li>
                      <li className="list-group-item">หมายเหตุ : {data.data.data.nodere} </li>
                      {!isEmpty(data.data.data2) && <>

                      <h4 className="text-center my-2 text-danger">การแจ้งเตือน</h4>
                         <li className="list-group-item">แจ้งเตือน : {data.data.data2.notification_title} </li>
                         <li className="list-group-item">แจ้งเตือน : {data.data.data2.notification_text} </li>
                         <li className="list-group-item"># transaction_id : {data.data.data2.transaction_id} </li>
                      </>}

                   
                   
                      </div>
                    </div>
                    <div className="d-print-none mt-4">
                      <div className="float-end">
                        {/* <Link
                          to="#"
                          onClick={printInvoice}
                          className="btn btn-success me-2"
                        >
                          <i className="fa fa-print" />
                        </Link> */}
                        {/* <Link to="#" className="btn btn-primary w-md ">
                          Send
                        </Link> */}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
       
        </div>
      </Row>
    </React.Fragment>
  );
};

export default Info_tran;
