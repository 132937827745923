import React, { Component, useState, useReducer } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Progress,
  FormGroup,
  InputGroup,
  Label,
  UncontrolledAlert,
} from "reactstrap";
import { Button, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import {
  ToastContainer,
  toast,
  Bounce,
  Slide,
  Flip,
  Zoom,
} from "react-toastify";
import SettingAPI from "../../../api/SettingAPI";

import classnames from "classnames";
import { Link } from "react-router-dom";

// type WizardState = {
//   breadcrumbItems: Object;
//   activeTabProgress: number;
//   progressValue: number;
// };

const ProgressbarWizard = ({ setUnLink, onHide }: any) => {
  const [statepost, setStatepost] = useState({
    email: "",
    password: "",
    user_level: "",
    phone: "",
    name: "",
  });

  const [texterror, setTexterror] = useState("");
  const [stexterror, setSexterror] = useState(false);
  const [stexterror_1, setSexterror_1] = useState(true);
  // email: email,
  // password: password,
  // user_level:user_level,
  // phone: phone,
  // name: name,
  const [state5, setState5] = useReducer(
    (state5: any, newState5: any) => ({ ...state5, ...newState5 }),
    {
      data: [
        {
          text: "",
        },
      ],
      //  data_tok: [],
    },
  );
  const { email, password, user_level, phone, name } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  const addBank = (e: any) => {
    e.preventDefault();
    const postData = {
      email: email,
      password: password,
      user_level: user_level,
      phone: phone,
      name: name,
    };
    if (
      postData.email == "" ||
      postData.password == "" ||
      postData.user_level == "" ||
      postData.phone == "" ||
      postData.name == ""
    ) {
      setSexterror(true);
      setTexterror("กรุณากรอกข้อมูลให้ครบครับ");

      setTimeout(function () {
        setSexterror(false);
      }, 4500);
    } else {
      SettingAPI.Postcreate_addmins(postData)
        .then((res) => {
          if (res.data.success) {
            // console.log(res.data);

            setSexterror_1(false);
            setState5({
              //data:res.data.data2
              data: [
                {
                  text: res.data.data2,
                },
              ],
            });
            toast.success(
              <p className="text-white tx-16 mb-0">
                {`${res.data.data.notification_title} ${res.data.data.notification_text}  สำเร็จ`}
              </p>,
              {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
                autoClose: 5000,
                theme: "colored",
              },
            );
          } else {
            toast.error(
              <p className="text-white tx-16 mb-0">{res.data.msg}</p>,
              {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
                autoClose: 5000,
                theme: "colored",
              },
            );
            //  setOpenck(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // console.log(postData);
    //  setOpenck(true);
  };

  const constructor = (props: any) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < props; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    setStatepost({ ...statepost, password: result });
  };

  const handleClick = () => {
    const min = 1;
    const max = 100;
    const rand = min + Math.random() * (max - min);
    // this.setState({ random: this.state.random + rand });
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">เพิ่มพนักงาน</h4>

              {stexterror && (
                <>
                  <UncontrolledAlert color="danger">
                    {texterror}
                  </UncontrolledAlert>
                </>
              )}

              {!stexterror_1 && (
                <>
                  <CardBody>
                    <UncontrolledAlert
                      color="success"
                      className="alert-outline"
                    >
                      <strong>Success</strong> - ทำรายการ เพิ่มพนักงาน สำเร็จ
                    </UncontrolledAlert>
                    {/* <textarea
                      id="progresspill-address-input"
                      className="form-control"
                      rows={2}
                      placeholder=""
                      value={`${state5.data && state5.data.text}`}
                    >
                      {" "}
                    </textarea> */}
                    <div className="d-flex flex-wrap gap-2">
                      {/* <button
                        onClick={() => {
                          setUnLink(false);
                        }}
                        className="btn btn-success w-lg waves-effect waves-light mx-auto mt-2"
                      >
                      คัดลอก
                      </button> */}
                      
                    </div>
                  </CardBody>
                </>
              )}

              {stexterror_1 && <>
                <TabPane tabId={1}>
                <form onSubmit={addBank}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label htmlFor="progresspill-firstname-input">
                          email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="progresspill-firstname-input"
                          placeholder="email"
                          value={email}
                          onChange={inputvalue("email")}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label htmlFor="progresspill-firstname-input">
                          ชิ่อ-นามสกุล
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="progresspill-firstname-input"
                          placeholder="ระบุ ชิ่อ-นามสกุล"
                          value={name}
                          onChange={inputvalue("name")}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label htmlFor="progresspill-lastname-input">
                          ระดับการทำงาน
                        </label>
                        <select
                          className="form-select"
                          value={user_level}
                          onChange={inputvalue("user_level")}
                        >
                          <option>เลือกระดับ</option>
                          <option value={"superadmin"}>เจ้าของเว็บ</option>
                          <option value={"programmer"}>โปรแกรมเมอร์</option>
                          <option value={"admin"}>พนักงาน</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label htmlFor="progresspill-phoneno-input">
                          เบอร์มือถือ
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="progresspill-phoneno-input"
                          placeholder="เบอร์มือถือ"
                          value={phone}
                          onChange={inputvalue("phone")}
                        />
                      </div>
                    </div>

                    <FormGroup className="">
                      <Label>รหัสผ่าน</Label>

                      <InputGroup>
                        <input
                          type="text"
                          className="form-control"
                          value={password}
                          // value={line}
                          onChange={inputvalue("password")}
                          placeholder="รหัสผ่าน"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <p
                              className="btn btn-warning btn-sm waves-effect waves-light"
                              onClick={() => constructor(15)}
                            >
                              Generate
                            </p>
                          </span>
                        </div>
                      </InputGroup>
                    </FormGroup>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <button className="btn btn-success waves-effect waves-light w-100 my-3">
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          เพิ่มพนักงาน
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </TabPane>
              
              
              </>}

              
            </CardBody>
            <ToastContainer />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ProgressbarWizard;
