import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
//import DataTableExtensions from "react-data-table-component-extensions";
// const DataTable = React.lazy(() => import("react-data-table-component"), {
//   ssr: false,
// });

import ApiAuth from "../../api/db";
import AdminAPI from "../api/Admin";
import { VerticallyCenteredModal } from "./data_add";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import DataTableExtensions from "react-data-table-component-extensions";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Dropdown,
  Form,
  Collapse,
} from "react-bootstrap";
import Moment from "moment";
import "moment/locale/th";
function convertArrayOfObjectsToCSV(array: any) {
  let result: any;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(data[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item: any) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
function downloadCSV(array: any) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

const addpay = () => {};

const Export = ({ onExport }: any) => (
  <VerticallyCenteredModal pp={() => onExport} />
);

const data = [
  {
    id: "1",
    SNO: 1,
    NAME: "Yonna",
    LASTNAME: "Qond",
    POSITION: "Financial Controller",
    DATE: "2012/02/21",
    SALARY: "$143,654",
    EMAIL: "i.bond@datatables.net",
  },
  {
    id: "2",
    SNO: 2,
    NAME: "Zonna",
    LASTNAME: "Jond",
    POSITION: "Accountant",
    DATE: "2012/02/21",
    SALARY: "$343,654",
    EMAIL: "a.bond@datatables.net",
  },
  {
    id: "3",
    SNO: 3,
    NAME: "Nonna",
    LASTNAME: "Tond",
    POSITION: "Chief Executive Officer",
    DATE: "2012/02/21",
    SALARY: "$743,654",
    EMAIL: "s.bond@datatables.net",
  },
  {
    id: "4",
    SNO: 4,
    NAME: "Bonna",
    LASTNAME: "Oond",
    POSITION: "Chief Operating Officer",
    DATE: "2012/02/21",
    SALARY: "$643,654",
    EMAIL: "w.bond@datatables.net",
  },
  {
    id: "5",
    SNO: 5,
    NAME: "Honna",
    LASTNAME: "Pond",
    POSITION: "Data Coordinator",
    DATE: "2012/02/21",
    SALARY: "$243,654",
    EMAIL: "e.bond@datatables.net",
  },
  {
    id: "6",
    SNO: 6,
    NAME: "Fonna",
    LASTNAME: "Nond",
    POSITION: "Developer",
    DATE: "2012/02/21",
    SALARY: "$543,654",
    EMAIL: "r.bond@datatables.net",
  },
  {
    id: "7",
    SNO: 7,
    NAME: "Aonna",
    LASTNAME: "Xond",
    POSITION: "Development lead",
    DATE: "2012/02/21",
    SALARY: "$843,654",
    EMAIL: "g.bond@datatables.net",
  },
  {
    id: "8",
    SNO: 8,
    NAME: "Qonna",
    LASTNAME: "Vond",
    POSITION: "Director",
    DATE: "2012/02/21",
    SALARY: "$743,654",
    EMAIL: "x.bond@datatables.net",
  },
  {
    id: "9",
    SNO: 9,
    NAME: "Jond",
    LASTNAME: "Zonna",
    POSITION: "Marketing Officer",
    DATE: "2012/02/21",
    SALARY: "$543,654",
    EMAIL: "k.bond@datatables.net",
  },
  {
    id: "10",
    SNO: 10,
    NAME: "Yonna",
    LASTNAME: "Qond",
    POSITION: "Financial Controller",
    DATE: "2012/02/21",
    SALARY: "$143,654",
    EMAIL: "s.bond@datatables.net",
  },
  {
    id: "11",
    SNO: 11,
    NAME: "Yonna",
    LASTNAME: "Qond",
    POSITION: "Financial Controller",
    DATE: "2012/02/21",
    SALARY: "$143,654",
    EMAIL: "b.bond@datatables.net",
  },
  {
    id: "12",
    SNO: 12,
    NAME: "Yonna",
    LASTNAME: "Qond",
    POSITION: "Financial Controller",
    DATE: "2012/02/21",
    SALARY: "$143,654",
    EMAIL: "o.bond@datatables.net",
  },
  {
    id: "13",
    SNO: 13,
    NAME: "Qonna",
    LASTNAME: "Pond",
    POSITION: "Data Coordinator",
    DATE: "2012/02/21",
    SALARY: "$243,654",
    EMAIL: "q.bond@datatables.net",
  },
  {
    id: "14",
    SNO: 14,
    NAME: "Yonna",
    LASTNAME: "Qond",
    POSITION: "Financial Controller",
    DATE: "2012/02/21",
    SALARY: "$143,654",
    EMAIL: "m.bond@datatables.net",
  },
];
export const ExportCSV = () => {
  useEffect(() => {
    getdatapay();
  }, []);

  const [state, setState] = React.useState<any>("");

  // const [state, setState] = useReducer(
  //   (state: any, newState: any) => ({ ...state, ...newState }),
  //   {
  //     data: [],
  //   },
  // );
  let history = useNavigate();
  const gopro = (data: any) => {
    history("/pages/profile", { state: { username: data } });
  };

  const getdatapay = () => {
    AdminAPI.Getallmember()
      .then((res) => {
        setState(res.data.users);
        setAllData(res.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const columns = [
    {
      name: "username",
      selector: (row: any) => row.username,
      sortable: true,
    },
    {
      name: "ชื่อ-นามสกุล",
      id: "name",
      selector: (row: any) => row.name,
      sortable: true,
    },

    {
      name: "เลขบัญชี",
      selector: (row: any) => row.accnum,
      sortable: true,
    },
    {
      name: "ธนาคาร",
      cell: (row: any) => row?.Bank?.bankNameTh,
      ignoreRowClick: true,
      allowOverflow: true,
    },

    {
      name: "user_ufa",
      cell: (row: any) => row.user_ufa,
      ignoreRowClick: true,
      allowOverflow: true,
    },

    {
      name: "สถานะ",
      selector: (row: any) =>
        row.status == 1 ? (
          <p className="text-success">ปกติ</p>
        ) : (
          <p className="text-danger">ระงับการใช้งาน</p>
        ),
      sortable: true,
    },
    // {
    //   name: "แนะนำโดย",
    //   selector: (row: any) => row.aff,
    //   sortable: true,
    // },
    {
      name: "Userufa",
      //selector: (row: any) => row.user_ufa,
      cell: (row: any) => (
        <button
          type="button"
          className="btn btn-primary btn-sm waves-effect waves-primary"
          onClick={() => gopro(row.username)}
        >
          Profile
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const deldatapay = (data: any) => {
    Swal.fire({
      title: `!!แพ๊ปปปปปปปปปปป?`,
      text: `เจ้าแน่ใจบ่ ที่สิลบ ${data.apply_to}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "เอ่า จ๊วดโลด!",
    }).then((result) => {
      if (result.isConfirmed) {
        let datap = {
          id: data.id,
        };

        ApiAuth.deldatapays(datap)
          .then((res) => {
            toast.success(`${res.data.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            getdatapay();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  const actionsMemo = React.useMemo(
    () => (
      <Export
        onExport={() => {
          downloadCSV(data);
        }}
      />
    ),
    [],
  );
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  let selectdata: any = [];
  const handleRowSelected = React.useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const contextActions = React.useMemo(() => {
    const Selectdata = () => {
      if (
        window.confirm(`download:\r ${selectedRows.map((r: any) => r.SNO)}?`)
      ) {
        setToggleCleared(!toggleCleared);
        data.map((e) => {
          selectedRows.map((sr: any) => {
            if (e.id === sr.id) {
              selectdata.push(e);
            }
            return selectedRows;
          });
          return data;
        });
        downloadCSV(selectdata);
      }
    };

    return <Export onExport={() => Selectdata()} icon="true" />;
  }, [data, selectdata, selectedRows]);
  const [allData, setAllData] = React.useState<any>(state);

  let allElement2: any[] = [];

  let myfunction = (InputData: any) => {
    for (let allElement of state) {
      if (InputData.length == 10) {
        if (allElement.username === InputData) {
          
          allElement2.push(allElement);
          setAllData(allElement2);
        }
      } else if (allElement.username.includes(InputData.toLowerCase())) {
        allElement2.push(allElement);

        if (allElement.username === InputData) {
          setAllData(allElement2);
        } else {
          setAllData(allElement2);
        }
      } else if (allElement.name.includes(InputData.toLowerCase())) {
        // console.log(allElement)

        allElement2.push(allElement);

        if (allElement.name === InputData) {
          setAllData(allElement2);
        } else {
          setAllData(allElement2);
        }
      }else if (allElement.accnum.includes(InputData.toLowerCase())) {
        // console.log(allElement)

        allElement2.push(allElement);

        if (allElement.accnum === InputData) {
          setAllData(allElement2);
        } else {
          setAllData(allElement2);
        }
      } else if (allElement.user_ufa.includes(InputData.toLowerCase())) {
        // console.log(allElement)

        allElement2.push(allElement);

        if (allElement.user_ufa === InputData) {
          setAllData(allElement2);
        } else {
          setAllData(allElement2);
        }
      }else {
        setAllData(allElement2);
      }

      // if (allElement.username.toLowerCase().includes(InputData.toLowerCase())) {
      //   if (allElement.username.toLowerCase().startsWith(InputData.toLowerCase())) {
      //     allElement2.push(allElement)
      //   }
      // }else {
      //  // allElement2.push(allElement)
      // }
    }
  };

  return (
    <span className="datatable">
      <label className="float-end">
        <input
          type="text"
          placeholder="Search..."
          className="mb-4 form-control-sm form-control"
          onChange={(ele) => {
            myfunction(ele.target.value);
          }}
        />
      </label>

      <DataTable
        columns={columns}
        data={allData}
        actions={actionsMemo}
        contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        className="react-dataTable"
        pagination
        // title=""
        // columns={columns}
        // data={allData}
        // pagination
        // fixedHeader
        // fixedHeaderScrollHeight="400px"
      />

      <ToastContainer />
    </span>
  );
};
