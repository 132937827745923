import React from "react";
import { useEffect, useReducer, useState } from "react";
import Apiufa from "../../api/AgentUfaapi";
import AdminAPI from "../../api/Admin";
import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
} from "reactstrap";
import {
  ToastContainer,
  toast,
} from "react-toastify";





const Backlist = (data: any) => {
  // console.log(data);
  const [statepost, setStatepost] = useState({
    username: data.username,
    district: data.name,
    level_backlist: "",
    type_back: "",
    userfrom_web: "",
    status: "",
    accnum: ""
  });
  const {
    username,
    district,
    level_backlist,
    type_back,
    userfrom_web,
    status,
    accnum
  } = statepost;

  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  const getGames = () => {
    let postData = {
      username: 1,
    };
    Apiufa.getBacklists(postData)
      .then((res) => {
        // console.log(res.data);

        if (res.data.success) {
          // console.log(res.data.daauser)
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addBank2 = (e: any) => {
    e.preventDefault();

    const postData2 = {
      username: username,
      district: district,
      accnum: accnum,
      level_backlist: level_backlist,
      type_back: type_back,
      userfrom_web: userfrom_web,
      status: status
    };


    AdminAPI.addBacklist(postData2)
      .then((res) => {
        //   console.log(res.data);

        if (res.data.success) {
          toast.success(
            <p className="text-white tx-16 mb-0">
              {`Backlist Successfully`}
            </p>,
            {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
              autoClose: 5000,
              theme: "colored",
            },
          );
          setTimeout(function () {
            window.location.reload()
          }, 1500);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });

  }






  return (
    <React.Fragment>
      <div className="page-content">

        <Form onSubmit={addBank2}>
          <div className="mb-3">
            <Label className="form-label">ชื่อ นามสกุล</Label>
            <Input
              name="district"
              type="text"
              required
              value={district}
              onChange={inputvalue("district")}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">username</Label>
            <Input
              name="username"
              label="username"
              type="text"
              value={username}
              onChange={inputvalue("username")}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">เลขบัญชี</Label>
            <Input
              required
              name="accnum"
              label="accnum"
              type="text"
              value={accnum}
              onChange={inputvalue("accnum")}
            />
          </div>
          <div className="mb-3">
            <label className="form-Label form-label">เลือกหัวข้อ backlist</label>
            <select className="form-select" name="type_back"
              onChange={inputvalue("type_back")}>
              <option>Select</option>
              <option value={'ก่อกวน'}>ก่อกวน</option>
              <option value={'สายตำรวจ'}>สายตำรวจ</option>
              <option value={'โกง'}>โกง</option>
            </select>
          </div>

          <div className="mb-3">
            <Label className="form-label">ลงรายละเอียด</Label>
            <textarea
              className="form-control mg-t-20"
              placeholder="Textarea (success state)"
              required
              name="level_backlist"
              value={level_backlist}
              onChange={inputvalue("level_backlist")}
              rows={5}
              defaultValue="This is textarea"
            ></textarea>
            {/* <Input
                  name="level_backlist"
                  label="username"
                  type="text"
                  value={level_backlist}
                  onChange={inputvalue("level_backlist")}
                /> */}
          </div>
          <button className="btn btn-primary"> ส่ง Backlist </button>
        </Form>

      </div>
    </React.Fragment>
  );
};




export default Backlist;
