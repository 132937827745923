import React, { useEffect, useReducer, useState } from "react";
import { Breadcrumb, Button, Card, Col, Row, Modal } from "react-bootstrap";
//import { CardBody, Container, Input, Modal } from "reactstrap";
//import Moment from "moment";
import Edit_pro from "./editpro";
import "moment/locale/th";
//import Dropzone from "react-dropzone";
import FriendAPI from "../api/Friend";
import Loaders from "@Components/loads/loaders";
import SettingAPI from "../api/SettingAPI";

import Addpronew from "./addpronew";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Model_img from "@shade/model_img/model_img";

//import Apisetting from "../../../api/Apisetting";
import ProMotionAPI from "../api/ProMotion";
import { Teble_promotion } from "./teble_promotion";
const Promotion = () => {
  const [show_edotpros, setShow_edotpros] = useState(false);

  useEffect(() => {
    getapisetting();
  }, []);
  const navigate = useNavigate();
  const onchang_imgurl_sow = (data: any) => {
    SetDataimg(data);
    SetRemove_show(true);
  };
  const getapisetting = () => {
    SettingAPI.getdatasetting()
      .then((res) => {
        //
        // setState({
        //   data: res.data
        // })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDataall();
  }, []);
  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
    }
  );
  useEffect(() => {
    getdatabank();
  }, []);
  const getdatabank = () => {
    FriendAPI.getbankseting_onpro()
      .then((res) => {
        // console.log(res.data);

        if (res.data.success) {
          // console.log(res.data);
          setData_bank({
            data: res.data.data,
          });
        } else {
          //  getGames();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [dataimg, SetDataimg] = React.useState("");
  const getDataall = () => {
    ProMotionAPI.Getdatapromotion()
      .then((res) => {
        // console.log(res.data);

        if (res.data.success) {
          setShow_edotpros(true);
          // console.log(res.data);
          setState({
            data: res.data.datall,
          });
          setselectedFiles(res.data.history);
        } else {
          //  getGames();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [successMessage, setSuccessMessage] = useState("");
  const [contactReason, setContactReason] = useState("");
  const [fileName, setFileName] = useState("");
  const [data_bank, setData_bank] = useState({
    data: [],
  });

  const [files, setFiles] = useState([] as any);

  const [remove, Setremove] = React.useState(true);
  const [remove_show, SetRemove_show] = React.useState(false);
  // const [selectedFiles, setselectedFiles] = useState<any>([]);

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setselectedFiles(files);
  }
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  // const preview = (file: any) => {
  //   setFiles(file);
  //   setFileName(file.name);
  // };

  const [statepost, setStatepost] = useState({
    percen: "",
    x: "",
    text: "",
    name: "",
    img_name: "",
    limit_d: "",
    limit_w: "",
    end_date: "",
    start_date: "",
    status_showpro: "",
    status_add: "",
    bank_dispodit: "",
    level_pro: "",
    status_sow: "",
    status_online: "",
  });
  const {
    percen,
    x,
    text,
    name,
    img_name,
    limit_d,
    limit_w,
    end_date,
    start_date,
    status_showpro,
    status_add,
    bank_dispodit,
    level_pro,
    status_online,
  } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show_edotpro, setShow_edotpro] = useState(false);
  const [showk, setShowk] = useState(false);

  const addpromotion = () => {
    setmodal_between_modal2(!modal_between_modal2);
    removeBodyCss();
  };

  // const validationContactForm = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {
  //     percen: percen,
  //     x: x,
  //     text: text,
  //     name: name,
  //     img_name: img_name,
  //     limit_d: limit_d,
  //     limit_w: limit_w,
  //     level_pro: level_pro,
  //     end_date: end_date,
  //     start_date: start_date,
  //     status_showpro: status_showpro,
  //     status_online: status_online,
  //     bank_dispodit: bank_dispodit,
  //     img_url: [],
  //   },
  //   validationSchema: Yup.object({
  //     percen: Yup.string().required("กรุณาตั้ง % ของยอดฝาก"),
  //     status_online: Yup.string().required("status_online"),
  //     level_pro: Yup.string().required("กรุณาเลือกประเภทของสมาชิก"),
  //     status_showpro: Yup.string().required("กรุณาเลือก ระบุ จะแสดงหรือไม่"),
  //     text: Yup.string().required("กรุณาระบุ รายละเอียดโปรโมชั่น"),
  //     x: Yup.string().required("กรุณาตั้ง เทรินโอเวอร์ ของยอดฝาก"),
  //     limit_w: Yup.string().required("กรุณา ตั้งยอดถอนสูงสุด"),
  //     name: Yup.string().required("กรุณา ตั้งชื่อโปรโมชั่น"),
  //   }),
  //   onSubmit: (values) => {
  //     const data = new FormData();
  //     data.append("percen", values.percen);
  //     data.append("x", values.x);
  //     data.append("text", values.text);
  //     data.append("name", values.name);
  //     data.append("limit_d", values.limit_d);
  //     data.append("limit_w", values.limit_w);
  //     data.append("level_pro", values.level_pro);
  //     data.append("bank_dispodit", values.bank_dispodit);

  //     data.append("img_url", files);

  //     //console.log(values);
  //     ProMotionAPI.addpro(data)
  //       .then((res) => {
  //         if (res.data.success) {
  //           toast.success(
  //             <p className="text-white tx-16 mb-0">
  //               {`เพิ่มโปรโมชั่น  Successfully`}
  //             </p>,
  //             {
  //               position: toast.POSITION.TOP_RIGHT,
  //               hideProgressBar: true,
  //               autoClose: 5000,
  //               theme: "colored",
  //             }
  //           );
  //           setTimeout(function () {
  //             window.location.reload();
  //           }, 2500);
  //         } else {
  //           //   setSuccessMessage("");
  //         }
  //       })
  //       .catch(function (error) {
  //         console.log("error ", error);
  //       });
  //   },
  // });
  // const [show_add, SetShow_add] = useState(false);
  const [modal_between_modal, setmodal_between_modal] = useState(false);
  const [modal_between_modal2, setmodal_between_modal2] = useState(false);

  const [state2, setState2] = useReducer(
    (state2: any, newState2: any) => ({ ...state2, ...newState2 }),
    {
      data: [],
    }
  );

  function tog_between_modal(data: any) {
    setState2({ data: data });
    setmodal_between_modal(!modal_between_modal);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const delistpro = (data: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "คุณแน่ใจมัย ที่จะลบโปรโมชั่นนี้",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let datap = {
          id: data,
        };

        ProMotionAPI.delit_pro(datap)
          .then((res) => {
            //  console.log(res.data);

            if (res.data.success) {
              toast.success(
                <p className="text-white tx-16 mb-0">
                  {`ลบโปรโมชั่น  Successfully`}
                </p>,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  hideProgressBar: true,
                  autoClose: 5000,
                  theme: "colored",
                }
              );
              getDataall();
            } else {
              //  getGames();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const setdata = () => {
    setmodal_between_modal(false);
    getDataall();
  };

  return (
    <div>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            ตั้งค่า Promotion
          </span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb>
            <Breadcrumb.Item className="tx-15" href="#">
              Promotion
            </Breadcrumb.Item>
            <Breadcrumb.Item active aria-current="page">
              ตั้งค่าการใช้งาน Promotion
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {show_edotpros ? (
        <>
          <Row className=" row-sm">
            <Col lg={12} xl={12} md={12} sm={12}>
              <Card className="box-shadow-0">
                <Card.Header>
                  <div className="d-flex  p-3 br-5">
                    <div className="pd-10">
                      {" "}
                      <h4 className="card-title mb-1">Promotion</h4>
                    </div>
                    <div className="pd-10"></div>
                    <div className="pd-10 mg-l-auto">
                      <button
                        className="btn btn-success"
                        onClick={() => addpromotion()}
                      >
                        เพิ่มโปรโมชั่น
                      </button>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className=" pt-0">
                  {state?.data?.length != 0 && (
                    <>
                      <table className="table table-bordered mg-b-0 text-md-nowrap table">
                        <thead>
                          <tr>
                            <th>เปอร์เซ็นต์</th>
                            <th>เทริน</th>
                            <th>ชื่อ</th>
                            <th>ข้อความ</th>
                            <th>วิธีรับ</th>
                            <th>จำกัดต่อวัน</th>
                            <th>ถอนไม่เกิน</th>
                            <th>รูปภาพ</th>
                            <th>สถานะ</th>
                            <th>ลบ</th>
                            <th>แก้ไข</th>
                          </tr>
                        </thead>
                        <tbody>
                          {state?.data?.map((items: any, rows: any) => {
                            return (
                              <>
                                <tr>
                                  <td>{items.percen}</td>
                                  <td>{items.x}</td>
                                  <td>{items.text}</td>
                                  <td>{items.name}</td>
                                  <td>{items.status_showpro}</td>
                                  <td>{items.limit_d}</td>
                                  <td>{items.limit_w}</td>
                                  <td>
                                    <button
                                      // href={items.img_name}
                                      // target="_blank"
                                      // rel="noopener noreferrer"
                                      onClick={() => onchang_imgurl_sow(items.img_name)}
                                    >
                                      <img
                                        className="img-sm"
                                        src={items.img_name}
                                        alt="thumb images"
                                      ></img>
                                    </button>
                                  </td>
                                  <td>{items.status_online}</td>
                                  <td>
                                    {" "}
                                    <button
                                      onClick={() => delistpro(items.id)}
                                      className="btn btn-danger"
                                    >
                                      ลบ
                                    </button>{" "}
                                  </td>
                                  <td>
                                    <button
                                      onClick={() => tog_between_modal(items)}
                                      className="btn btn-warning"
                                    >
                                      แก้ไข
                                    </button>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>

                      {/* <Teble_promotion data={state?.data} setdata={gotoset}/> */}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="row-sm">
            <Col lg={12} md={12}>
              <Card>
                <Card.Body>
                  <div className="main-content-label mg-b-5">
                    ประวัติ รับ Promotion ทั้งหมด
                  </div>
                  <p className="mg-b-20">ข้อมูลอัพเดท ล่าสุด</p>
                  <div id="">
                    <Teble_promotion data={selectedFiles} />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* เพิ่มโปร */}

          <Modal
            show={modal_between_modal2}
            size="lg"
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header>
              <Button
                variant=""
                className="btn btn-close"
                onClick={() => {
                  setmodal_between_modal2(false);
                }}
              >
                x
              </Button>
            </Modal.Header>
            <Modal.Body>
              <Addpronew />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setmodal_between_modal2(false);
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={modal_between_modal}
            size="xl"
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header>
              <Modal.Title>แก้ไขโปรโมชั่น</Modal.Title>
              <Button
                variant=""
                className="btn btn-close"
                onClick={() => {
                  setmodal_between_modal(false);
                }}
              >
                x
              </Button>
            </Modal.Header>
            <Modal.Body>
              {/* <div className="row">
                    {" "}
                    <div className="col-md-6">
                      {" "}
                      <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo.
                      </p>
                    </div>{" "}
                    <div className="col-md-6">
                      {" "}
                      <p>
                        But I must explain to you how all this mistaken idea of
                        denouncing pleasure and praising pain was born and I
                        will give you a complete account of the who loves toil
                        and pain can procureor sit aspernatur system.
                      </p>
                    </div>{" "}
                  </div>{" "}
                  <div className="row">
                    {" "}
                    <div className="col-md-6">
                      {" "}
                      <p>
                        expound the actual teachings of the great explorer of
                        the truth, the master-builder of human happiness. No one
                        rejects, dislikes, or avoids pleasure itself, because it
                        is pleasure desires to obtain pain.
                      </p>
                    </div>{" "}
                    <div className="col-md-6">
                      {" "}
                      <p>
                        Neque porro quisquam est, qui dolorem ipsum quia dolor
                        sit amet, consectetur, adipisci velit, sed quia non
                        numquam eius modi tempora incidunt ut labore et dolore
                        magnam aliquam quaerat aut odit voluptatem.
                      </p>
                    </div>{" "}
                  </div>{" "} */}
              <Edit_pro data={state2?.data} setdataall={setdata} />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setmodal_between_modal(false);
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* จบเพิ่มโปร */}

          {/* <Modal
        isOpen={modal_between_modal}
        className="modal-dialog modal-lg"
        toggle={() => {
          tog_between_modal(null);
        }}
        scrollable={true}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title">แก้ไขโปรโมชั่น</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_between_modal(false);
              getDataall();
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <Edit_pro data={state2.data} />
        </div>
        <div className="modal-footer">
          <button
            onClick={() => {
              setmodal_between_modal(false);
              getDataall();
            }}
            className="btn btn-danger w-lg waves-effect waves-light mx-auto mt-2"
          >
            ปิด
          </button>
        </div>
      </Modal> */}
          <Model_img
            remove_show={remove_show}
            setRemove_show={SetRemove_show}
            dataimg={dataimg}
          />
        </>
      ) : (
        <>
          {" "}
          <Loaders />
        </>
      )}

      {/* <!-- breadcrumb --> */}
    </div>
  );
};

Promotion.propTypes = {};

Promotion.defaultProps = {};

export default Promotion;

export const Setdataall = () => {};
