import React, { useState, useEffect, useRef, useReducer } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  FormGroup,
  InputGroup,
  Alert,
  Table,
  ProgressBar,
} from "react-bootstrap";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import DatePicker from "react-datepicker";
import Moment from "moment";
import "moment/locale/th";
import SettingAPI from "../../../components/api/SettingAPI";
import Loaders from "../../../components/loads/loaders";
import ShowSum from "./sowsum_v1";
import ShowSum_v2 from "./sowsum_v2";
//import * as echarts from "../../Charts/Echart/data";
import ReactEcharts from "echarts-for-react";

const Dashboard_all = () => {
  useEffect(() => {
    chack_selt();
  }, []);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [weekstartDate, setWeekstartDate] = useState(new Date());

  const [loader_maxky, setLoader_maxky] = useState(false);
  const [loader_maxky2, setLoader_maxky2] = useState(false);

  const [data_sumall, setData_sumall] : any = useState("");

  const Monthrange = () => {
    const handleChange = ([newStartDate, newEndDate]: any) => {
      setStartDate(newStartDate);
      setEndDate(newEndDate);

      //chack_selt(newEndDate);
    };

    return (
      <DatePicker
        selected={startDate}
        onChange={handleChange}
        selectsRange
        startDate={startDate}
        endDate={endDate}
        className="form-control"
        //showMonthYearPicker
      />
    );
  };
  const chack_selt = () => {
    setLoader_maxky(false);

    let datasum = {
      startDate: Moment(startDate).startOf("day").format("YYYY-MM-DD HH:mm"),
      endDate: Moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm"),
    };

    SettingAPI.datadashboard_s(datasum)
      .then((res) => {
        //

        if (res.data.success == true) {
          setLoader_maxky(true);
          setData_sumall(res.data);
        }
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // console.log(datasum);
  };


  const echart9 = {
  
    option: {
      tooltip: {
        trigger: "axis",
        position: ["35%", "32%"],
      },
      toolbox: {
        show: false,
      },
      calculable: false,
      categoryAxis: {
        axisLine: {
          lineStyle: {
            color: "#888180",
          },
        },
        splitLine: {
          lineStyle: {
            color: ["rgba(171, 167, 167,0.2)"],
          },
        },
      },
      grid: {
        x: 40,
        y: 20,
        x2: 40,
        y2: 20,
      },
      valueAxis: {
        axisLine: {
          lineStyle: {
            color: "#888180",
          },
        },
        splitArea: {
          show: true,
          areaStyle: {
            color: ["rgba(255,255,255,0.1)"],
          },
        },
        splitLine: {
          lineStyle: {
            color: ["rgba(171, 167, 167,0.2)"],
          },
        },
      },
      xAxis: [
        {
          type: "category",
          data: [
            "ม.ค.",
            "ก.พ.",
            "มี.ค.",
            "เม.ย.",
            "พ.ค.",
            "มิ.ย.",
            "ก.ค.",
            "ส.ค.",
            "ก.ย.",
            "ต.ค.",
            "พ.ย.",
            "ธ.ค.",
          ],
          
          axisLine: {
            lineStyle: {
              color: "rgba(171, 167, 167,0.2)",
            },
          },
          axisLabel: {
            fontSize: 10,
            color: "#5f6d7a",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          splitLine: {
            lineStyle: {
              color: "rgba(171, 167, 167,0.2)",
            },
          },
          axisLine: {
            lineStyle: {
              color: "rgba(171, 167, 167,0.2)",
            },
          },
          axisLabel: {
            fontSize: 10,
            color: "#5f6d7a",
          },
        },
      ],
      color: ["#38cab3", "#f74f75"],
      series: [
        
        {
          name: "ฝาก",
          type: "bar",
          data: [
            data_sumall?.month?.d01?.totalDeposit,
            data_sumall?.month?.d02?.totalDeposit,
            data_sumall?.month?.d03?.totalDeposit,
            data_sumall?.month?.d04?.totalDeposit,
            data_sumall?.month?.d05?.totalDeposit,
            data_sumall?.month?.d06?.totalDeposit,
            data_sumall?.month?.d07?.totalDeposit,
            data_sumall?.month?.d08?.totalDeposit,
            data_sumall?.month?.d09?.totalDeposit,
            data_sumall?.month?.d10?.totalDeposit,
            data_sumall?.month?.d11?.totalDeposit,
            data_sumall?.month?.d12?.totalDeposit,
          ],
          
          markPoint: {
            data: [
              [{'name': 'ฝาก', 'type': 'bar', 'data': [1]}, {'name': 'ถอน', 'type': 'bar', 'data': [100]}]
            ],
          },
          markLine: {
            data: [
              {
                type: "average",
                name: "",
              },
            ],
          },
        },
        {
          name: "ถอน",
          type: "bar",
          data: [
            data_sumall?.month?.d01?.totalWithdrawal,
            data_sumall?.month?.d02?.totalWithdrawal,
            data_sumall?.month?.d03?.totalWithdrawal,
            data_sumall?.month?.d04?.totalWithdrawal,
            data_sumall?.month?.d05?.totalWithdrawal,
            data_sumall?.month?.d06?.totalWithdrawal,
            data_sumall?.month?.d07?.totalWithdrawal,
            data_sumall?.month?.d08?.totalWithdrawal,
            data_sumall?.month?.d09?.totalWithdrawal,
            data_sumall?.month?.d10?.totalWithdrawal,
            data_sumall?.month?.d11?.totalWithdrawal,
            data_sumall?.month?.d12?.totalWithdrawal,
          ],
          

          markPoint: {
            data: [
              {'name': 'ฝาก', 'type': 'bar', 'data': [1]},
               {'name': 'ถอน', 'type': 'bar', 'data': [100]}
            ],
          },
          markLine: {
            data: [
              {
                type: "average",
                name: "",
              },
            ],
          },
        },
        {
          name: "ผลกำไร",
          type: "bar",
          data: [
            data_sumall?.month?.d01?.totalProfit,
            data_sumall?.month?.d02?.totalProfit,
            data_sumall?.month?.d03?.totalProfit,
            data_sumall?.month?.d04?.totalProfit,
            data_sumall?.month?.d05?.totalProfit,
            data_sumall?.month?.d06?.totalProfit,
            data_sumall?.month?.d07?.totalProfit,
            data_sumall?.month?.d08?.totalProfit,
            data_sumall?.month?.d09?.totalProfit,
            data_sumall?.month?.d10?.totalProfit,
            data_sumall?.month?.d11?.totalProfit,
            data_sumall?.month?.d12?.totalProfit,
          ],
          

          markPoint: {
            data: [
              {'name': 'ฝาก', 'type': 'bar', 'data': [1]},
               {'name': 'ถอน', 'type': 'bar', 'data': [100]}
            ],
          },
          markLine: {
            data: [
              {
                type: "average",
                name: "",
              },
            ],
          },
        },
        {
          name: "คอมมิชชั่น",
          type: "bar",
          data: [
            data_sumall?.month?.d01?.totalCommission,
            data_sumall?.month?.d02?.totalCommission,
            data_sumall?.month?.d03?.totalCommission,
            data_sumall?.month?.d04?.totalCommission,
            data_sumall?.month?.d05?.totalCommission,
            data_sumall?.month?.d06?.totalCommission,
            data_sumall?.month?.d07?.totalCommission,
            data_sumall?.month?.d08?.totalCommission,
            data_sumall?.month?.d09?.totalCommission,
            data_sumall?.month?.d10?.totalCommission,
            data_sumall?.month?.d11?.totalCommission,
            data_sumall?.month?.d12?.totalCommission,
          ],
          

          markPoint: {
            data: [
              {'name': 'ฝาก', 'type': 'bar', 'data': [1]},
               {'name': 'ถอน', 'type': 'bar', 'data': [100]}
            ],
          },
          markLine: {
            data: [
              {
                type: "average",
                name: "",
              },
            ],
          },
        },
      ],
    },
  };


  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Dashboard</span>{" "}
        </div>
        <div className="justify-content-center mt-2">
          <Col lg={12} className=" mg-t-20 mg-lg-t-0">
            <h3>{Moment(startDate).format("LLLL")}</h3>
            {/* <!-- input-group --> */}
          </Col>
        </div>
      </div>
      {loader_maxky ? <>
      
      
        <div className="d-flex flex-row justify-content-between mg-b-20   p-3 br-5">
        <div className="pd-10">
          <p>
            ข้อมูล{" "}
            {startDate && (
              <>{Moment(startDate).startOf("day").format("DD-MM-YYYY HH:mm")}</>
            )}{" "}
            -{" "}
            {endDate && (
              <>{Moment(endDate).endOf("day").format("DD-MM-YYYY HH:mm")}</>
            )}{" "}
          </p>
        </div>

        <div className="pd-15">
          <Row className=" row-sm">
            <Col lg={8} xxl={8} md={8} className=" mg-t-20 mg-lg-t-0">
              <InputGroup className="input-group reactdate-pic">
                <InputGroup.Text className="input-group-text">
                  <i className="typcn typcn-calendar-outline tx-24 lh--9 op-6"></i>
                </InputGroup.Text>
                <Monthrange />
              </InputGroup>
              {/* <!-- input-group --> */}
            </Col>
            <div className="col-4">
              <button className="btn btn-info" onClick={() => chack_selt()}>
                <i className="fas fa-search"></i> ค้นหา
              </button>
            </div>
          </Row>
        </div>
      </div>

      {/* <!-- /breadcrumb -->
      
                          <!-- row --> */}
      <Row>
        <Col md={12} xl={12} xs={12} sm={12}>
          {/* จำนวนยอด ฝากถอน แจก */}

          <div>
            <Row>
             
              {data_sumall && <ShowSum data={data_sumall} />}

              {data_sumall && <ShowSum_v2 data={data_sumall} />}
            </Row>
          </div>
        </Col>
        
      </Row>
      <Row className="row-sm">
          <Col lg={12} md={12}>
            <Card>
              <Card.Body>
                <div className="main-content-label mg-b-5">สรุปข้อมูล รายเดือน ของปีนี้</div>
                <p className="mg-b-20">
                  ข้อมูลอัพเดท ล่าสุด  {Moment(startDate).format("LLLL")}
                </p>
                <div id="">
                  <ReactEcharts
                    className="ht-300"
                    option={echart9.option}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      
      </> : <Loaders />}
      
      {/* <!-- row closed --> */}
    </div>
  );
};

Dashboard_all.propTypes = {};

Dashboard_all.defaultProps = {};

export default Dashboard_all;
