import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Nav,
  FormGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { DropzoneArea, DropzoneAreaBase } from "material-ui-dropzone";
import Box from "@mui/material/Box";
import Addmin_seting from "../../components/api/Addmin_seting";
import { Collapse, Select, Upload } from "antd";
import { createStyles, makeStyles } from "@material-ui/core";
import config from "../../config/app.config";
const TabToTop = () => {
  const [BacktoTop, setBacktopTop] = useState("");
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBacktopTop("d-block");
      } else setBacktopTop("");
    });
  }, []);

  const [Scroll, setScroll] = useState(false);
  const [Scroll1, setScroll1] = useState(false);
  const [Scroll2, setScroll2] = useState(false);
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const setsupport = () => {
    setScroll1(!Scroll1);
  };
  const setsupport2 = () => {
    setScroll1(true);
    setScroll2(!Scroll2);
  };
  const [Fileuploader, setFileuploader] = useState([]);
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setFileuploader(files);
  }
  //MUIdropzonebadge
  const useStyles = makeStyles((theme) =>
    createStyles({
      previewChip: {
        minWidth: 160,
        maxWidth: 210,
      },
    }),
  );
  const classes = useStyles();

  //Fileuploader2
  const [files, setFiles] = useState<any>([]);
  const [Scroll21, setScroll21] = useState(
    "",
  );
  const [Scroll22f, setScroll22f] = useState("");
  const [Scroll22, setScroll22] = useState(false);

  const handleAdd = (newFiles: any) => {
    newFiles = newFiles.filter(
      (file: any) => !files.find((f: any) => f.data === file.data),
    );
    setFiles([...files, ...newFiles]);
  };

 // console.log(files);

  const handleDelete = (deleted: any) => {
    setFiles(files.filter((f: any) => f !== deleted));
  };
  const [fileName, setFileName] = useState("");

  const [statepost, setStatepost] = useState({
    id_from: "",
    user_to: "",
    img_mig: "",
    no_value: "",
    text_sub: "",
  });
  const { id_from, user_to, no_value, img_mig, text_sub } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };
  const Add_textsubport = (e: any) => {
    e.preventDefault();
    const datapost = {
      id_from: id_from,
      user_to: user_to,
      img_mig: `${config.apiURL}/${Scroll21}`,
      no_value: no_value,
      text_sub: text_sub,
    }
    Addmin_seting.Postupdatesubportall(datapost)
      .then((res) => {

    if(res.data.success == true){
      setScroll1(files);
      setScroll2(!Scroll2);
      setScroll22(true)

    }

       // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const preview = (file: any) => {
    setFiles(file);
    setFileName(file.name);

    const data = new FormData();
    data.append("id_from", "img1");
    data.append("user_to", "user_to");
    data.append("no_value", "5");

    data.append("text_sub", "text_sub");
    data.append("img_url", files);

    Addmin_seting.Postupdatesubport(data)
      .then((response) => {
        setScroll21(response.data.data.img_url)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const screenup = () => {
    window.scrollTo({
      top: 10,
      behavior: "auto",
      //   smooth
    });
  };
  return (
    <div>
      <Modal show={Scroll}>
        <Modal.Header>
          <Modal.Title>แจ้งปัญหาการใช้งาน</Modal.Title>
          <Button
            variant=""
            className="btn btn-close"
            onClick={() => {
              setScroll(false);
            }}
          >
            x
          </Button>
        </Modal.Header>
        <Modal.Body>

          {Scroll22 && <>
          
          
          
          
          
          
          </>}
          {Scroll1 && <>
          
          
          
          
          
          
          
          
          </>}

          {Scroll2 && (
            <>
              <Row>
                <Col xl={12}>
                  <Card className=" mb-lg-0">
                    <Card.Header>
                      <h4>
                        <u>ระบบธนาคาร</u>
                      </h4>
                    </Card.Header>
                    <Card.Body>
                      <h6 className="text-danger">
                        ** ระบบธนาคารจะปิดปรับปรุงประจำวันในช่วงเวลา 23.00น. -
                        00.00น. และ 01.00น.- 02.00น.
                        แต่ลูกค้ายังสามารถทำการถอนเงินออกได้ปกตินะคะ
                        ลูกค้าที่ถอนเงินออกมาในช่วงเวลาดังกล่าวยอดถอนของท่านอาจจะล่าช้ากว่าปกตินะคะ
                        ขออภัยในความไม่สะดวกค่ะ
                      </h6>
                      <form onSubmit={Add_textsubport}>
                        <div className="pt-4">
                          <FormGroup className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="user_to"
                              value={user_to}
                              onChange={inputvalue("user_to")}
                              name="user_to"
                              required
                              placeholder="ชื่อผู้แจ้ง"
                            />
                            <input
                              type="hidden"
                              onChange={inputvalue("user_to")}
                              name="id_from"
                              value="ระบบธนาคาร"
                            />
                          </FormGroup>
                          <Upload
                            name="img_url"
                            accept={"image/*"}
                            showUploadList={false}
                            onChange={(e) => {
                              preview(e.file.originFileObj);
                            }}
                            maxCount={1}
                          >
                            <Card className="custom-card">
                              <Card.Body>
                                <div>
                                  <p className="text-muted card-sub-title"></p>
                                </div>
                               {Scroll21 && <>
                                <img
                                  className=" pic-1 pos-relative br-5 w-100"
                                  src={`${config.apiURL}/${Scroll21}`}
                                  alt="attached-file"
                                />
                               
                               </>}
                               <i className="fa fa-camera-retro"></i>
                               
                              </Card.Body>
                            </Card>
                          </Upload>
                          {selectedFiles.map((f: any, i: number) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                          ระดับความสำคัญ
                          <Box
                            sx={{
                              "& > legend": { mt: 2 },
                            }}
                          >
                            <Rating
                              name="no_value"
                              onChange={inputvalue("no_value")}
                              size="large"
                              max={5}
                            />
                          </Box>
                          <FormGroup className="form-group">
                            <textarea
                              required
                              className="form-control"
                              name="text_sub"
                              rows={4}
                              value={text_sub}
                              onChange={inputvalue("text_sub")}
                              placeholder=" ลงรายละเอียด"
                            ></textarea>
                          </FormGroup>
                          <button
                            type="submit"
                            className="btn me-2 btn-secondary-gradient btn-block w-50 mx-auto"
                          >
                            แจ้งปัญหา
                          </button>
                        </div>
                      </form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          )}

          {!Scroll1 && (
            <>
              <h4>
                <u>เลือกปัญหาที่ต้องการแจ้ง</u>
              </h4>
              <Col lg={12} md={12} xl={12}>
                {/* <!--Page Widget Error--> */}
                <Card className="bd-0 mg-b-20">
                  <Card.Body className="text-danger bg-danger-transparent br-5 bd bd-danger">
                    <div className="main-error-wrapper">
                      <i className="fa fa-university tx-50"></i>
                      <h4 className="mg-b-20">เกี่ยวกับ ระบบธนาคาร</h4>
                      <Link
                        className="btn btn-outline-danger btn-sm"
                        to="#"
                        onClick={setsupport2}
                      >
                        แจ้งปัญหา
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
                {/* <!--Page Widget Error--> */}
              </Col>
              <Col lg={12} md={12} xl={12}>
                {/* <!--Page Widget Error--> */}
                <Card className="bd-0 mg-b-20">
                  <Card.Body className="text-danger bg-danger-transparent br-5 bd bd-danger">
                    <div className="main-error-wrapper">
                      <i className="fab fa-cc-diners-club tx-50"></i>
                      <h4 className="mg-b-20">เกี่ยวกับ ระบบหลังบ้าน</h4>
                      <Link
                        className="btn btn-outline-danger btn-sm"
                        to="#"
                        onClick={setsupport}
                      >
                        แจ้งปัญหา
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
                {/* <!--Page Widget Error--> */}
              </Col>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setScroll(false);
            }}
          >
            ปิด
          </Button>
        </Modal.Footer>
      </Modal>
      <Link
        to="#"
        id="back-to-top"
        onClick={() => setScroll(true)}
        className={`${BacktoTop} btn btn-info`}
      >
        <span>แจ้งปัญหา</span>
        <i className="fa fa-comments"></i>
      </Link>
    </div>
  );
};

TabToTop.propTypes = {};

TabToTop.defaultProps = {};

export default TabToTop;
