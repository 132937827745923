import React from "react";
//import W_d_index from "./w_d_index";
import { useEffect, useState, useReducer } from "react";
import { logout, getUser } from "../../../config/authsave";

import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import Add_bank from "./add_bank";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardFooter,
  Button,
  ButtonGroup,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Container } from "reactstrap";
import { Table, Modal, Progress } from "reactstrap";
//import { Loaders, Types } from "react-loaders";
import Loader, { LoaderType } from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";

const Add_manual = (data: any) => {
  const [options, setOptions] = useState("");
  //console.log(data)
  document.title = "ฝาก-ถอนรายวัน | ฝาก-ถอนรายวัน ";
  return (
    <React.Fragment>
      <Add_bank data={data.data} />

     
    </React.Fragment>
  );
};

export default Add_manual;
