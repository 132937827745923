import React, { useEffect, useMemo, useReducer, useState } from "react";
import { FormGroup, InputGroup } from "react-bootstrap";
import "./index.css";
import {
  Breadcrumb,
  Card,
  Col,
  Collapse,
  Form,
  Row,
  Button,
  Popover,
  OverlayTrigger,
  Navbar,
  Dropdown,
} from "react-bootstrap";
import config from "../../config/app.config";
import { Scrollbars } from "react-custom-scrollbars";
import { Link, useParams, useNavigate } from "react-router-dom";
//import { logout } from "../../config/authsave";
import { useDispatch, useSelector } from "react-redux";
import { Delete } from "../../redux/actions/action";
import ProductService from "../../services/ProductService";
import Apisetting from "../../api/Apisetting";
import Swal from "sweetalert2";
import { getUser, logout } from "../../config/authsave";
import FriendAPI from "../../components/api/Friend";
import { ToastContainer } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";
import ReactAudioPlayer from "react-audio-player";
import { Slide, Flip, toast } from "react-toastify";
import SettingAPI from "../../components/api/SettingAPI";
import Setdatanoti from "../../components/Resulttoday/event_result";
import { socket } from "../../utils/socket";
import Marquee from "react-fast-marquee";
import Moment from "moment";
import "moment/locale/th";
export default function Header() {
  const [Lang, setLang] = React.useState(false);
  const [fullscreens, setFullscreen] = React.useState(true);

  useEffect(() => {
    chack_loginsuser();
    allsetting();
    getapisetting();
    
   // document.querySelector("body")?.classList.add("dark-menu");
    // chackmaintenance()
  }, []);
  // useEffect(() => {
  //   const id = setInterval(() => {
  //     getAcc_bank1();
  //    chack_loginsuser();
  //    // chackmaintenance()
  //   }, 2500);
  //   return () => clearInterval(id);
  // }, []);
  useEffect(() => {
   getAcc_bank1();
   // testss();
    testss2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    socket.on("create_msgadmin", (data) => {
      getAcc_bank1();
    });
  }, [socket]);


  const testss2 = () => {
    socket.on("get_sendNotification", (data) => {
      toast.success(
        <>
          <p className="maxtext">ข้อความ : {data}</p>
          <ReactAudioPlayer
            src={`${config.apiURL}/storage/1.mp3`}
            autoPlay
            controls
            className="maxhide"
          />
        </>,

        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: false,
          transition: Slide,
          closeOnClick: false,
          rtl: false,
          pauseOnFocusLoss: false,
          autoClose: 5000,
          theme: "colored",
        }
      );

      console.log(data);
    });
  };
  const chackmaintenance = () => {
    SettingAPI.listserver_mainss()
      .then((res) => {
        if (res.data.data.success == true) {
          if (res.data.data.data.server_status == "maintenance") {
            navigate("./maintenance");
          } else if (res.data.data.data.server_status == "offline") {
            navigate("./maintenance");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let data_admin = getUser();

  const getapisetting = () => {
  
   document.querySelector("body")?.classList.add("light-theme");
   document.querySelector("body")?.classList.add("dark-menu");
    SettingAPI.getdatasetting_fontend()
      .then((res) => {
        //
        setState2({
          data: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [state2, setState2] = useReducer(
    (state2: any, newState2: any) => ({ ...state2, ...newState2 }),
    {
      data: [],
    }
  );

  const update_andchack = async (notifications: any) => {
    if (!Array.isArray(notifications) || notifications.length === 0) {
      return <p>ไม่มีการแจ้งเตือนใหม่</p>;
    }

    for (const notification of notifications) {
      await toast.promise(
        new Promise((resolve: any) => {
          toast.success(
            <>
              <h4 className="maxtext">{notification.notification_title}</h4>
              <p className="maxtext">{notification.notification_text}</p>
              <ReactAudioPlayer
                src={`1.mp3`}
                autoPlay
                controls
                className="maxhide"
              />
            </>,
            {
              // ย้าย position และตัวเลือกอื่นๆ มาที่นี่
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: false,
              transition: Slide,
              closeOnClick: false,
              rtl: false,
              pauseOnFocusLoss: false,
              autoClose: 5000,
              theme: "colored",
              onClose: () => {
                // เรียก updatenoti เมื่อ toast ปิด
                updatenoti(notification.id);
              },
              onOpen: () => {
                setTimeout(resolve, 2000);
              },
            }
          );
        }),
        {
          // loading: 'กำลังโหลด...',
          // success: 'แสดงแล้ว!',
          // error: 'เกิดข้อผิดพลาด!'
        }
      );
    }
  };

  const getAcc_bank1 = () => {
    //let data = ""
    FriendAPI.gEtNotifytion()
      .then((res) => {
        if (res.data.success) {
          let item = res.data;
          let itemss = update_andchack(res.data.dataall);

       //   socket.emit("ready_dispro", item);

        } else {
          //   logout()
          //  navigate("./");
        }
      })
      .catch((err) => {
        //  logout()
        //  navigate("./");
        // console.log(err);
      });
  };
  const updatenoti = (data: any) => {
    const postData = {
      id: data,

      // otp:""
    };
    FriendAPI.PostUpdatenoti(postData)
      .then((res) => {
        //console.log(res.data);
        if (res.data.success) {
          // getAcc_bank1();
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
      data_aa: [],
    }
  );
  const update_notiallbyid = (data: any) => {
    let dataid = {
      id: data.id,
    };
    //console.log(dataid)
    SettingAPI.getnotifytion_allupbyid(dataid)
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data)
          allsetting();
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const allsetting = () => {
    SettingAPI.getnotifytion_all()
      .then((res) => {
        if (res.data.success) {
          //console.log(res.data)
          setState({
            data: res.data.data,
            data_aa: res.data.data_c,
          });

          // console.log(res.data)
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const logoutotp = () => {
    const postData = {
      id: 1,
      // otp:""
    };
    FriendAPI.PostUpdatelogout(postData)
      .then((res) => {
        //console.log(res.data);
        if (res.data.success) {
          // getAcc_bank1();
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const chack_loginsuser = () => {

    
    var navigator_info = window.navigator;
    var screen_info = window.screen;
    var uid: any = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, "");
    uid += navigator_info.plugins.length;
    uid += screen_info.height || "";
    uid += screen_info.width || "";
    uid += screen_info.pixelDepth || "";

    let user_device_id = uid;

    let datapost = {
      user_device_id: user_device_id,
    };

    // Apisetting.Postchack_settings(datapost)
    //   .then((res) => {
    //     // console.log(res.data);
    //     if (res.data.success == true) {
    //     } else {
    //       Swal.fire({
    //         icon: "error",
    //         title: "Oops...",
    //         text: res.data.msg,
    //       }).then((result) => {
    //         if (result.isConfirmed) {
    //           logoutotp();
    //           logout();
    //           navigate("./");
    //         }
    //       });
    //     }
    //     //
    //   })
    //   .catch((err) => {
        
    //     Swal.fire({
    //       icon: "error",
    //       title: "Oops...",
    //       text: "error",
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         // logoutotp();
    //         // logout();
    //         // navigate("./");
    //       }
    //     });
    //   });
  };

  // FullScreen
  var elem: any = document.documentElement;
  const Fullscreen: any = (vale: any) => {
    switch (vale) {
      case true:
        elem.requestFullscreen();
        setFullscreen(false);
        break;
      case false:
        document.exitFullscreen();
        setFullscreen(true);
        break;
    }
  };

  //leftsidemenu
  const openCloseSidebar = () => {
    document.querySelector("body")?.classList.toggle("sidenav-toggled");
  };
  //rightsidebar
 
  const Darkmode = () => {
    document.querySelector(".app")?.classList.toggle("dark-theme");
    document.querySelector(".app")?.classList.remove("dark-theme");
  };

  // responsivesearch
  const responsivesearch = () => {
    document.querySelector(".navbar-form")?.classList.toggle("active");
  };
  //swichermainright
  const swichermainright = () => {
    document.querySelector(".demo_changer")?.classList.toggle("active");
    let rightSidebar: any = document.querySelector(".demo_changer");
    rightSidebar.style.right = "0px";
  };
  const [price, setPrice] = React.useState(0);
  // console.log(price);

  let getdata: any = useSelector((state: any) => state.cartreducer.carts);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open: any = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    // console.log(open);
  };

  const [Data, setData] = React.useState([]);

  const { id } = useParams();

  // console.log(getdata);

  const compare = () => {
    let comparedata = getdata.map((e: any) => {
      //  console.log(e, id);
      return e.id === id;
    });
    setData(comparedata);
    //console.log(comparedata, Data);
  };

  React.useEffect(() => {
    compare();
    // eslint-disable-next-line
  }, [id]);
  const ondelete = (id: any) => {
    dispatch(Delete(id));
  };

  function total() {
    let price = 0;
    getdata.map((ele: any) => {
      price = ele.price * ele.qnty + price;
      return price;
    });
    setPrice(price);
  }

  React.useEffect(() => {
    total();
  });
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `${process.env.PUBLIC_URL}/`;
    navigate(path);
  };
  return (
    <Navbar className="main-header side-header sticky nav nav-item">
      <div className="main-container container-fluid">
        <div className="main-header-left ">
          <div className="responsive-logo">
            <Link
              to={`${process.env.PUBLIC_URL}/dashboard/dashboard`}
              className="header-logo"
            >
              <img
                src={state2.data.loggo_url}
                className="main-logo  mobile-logo"
                alt="logo"
              />
              {/* <img
                src={state2.data.loggo_url}
                className="main-logo  mobile-logo"
                alt="logo"
              /> */}
            </Link>
          </div>
          <div
            className="app-sidebar__toggle"
            data-bs-toggle="sidebar"
            onClick={() => openCloseSidebar()}
          >
            <Link className="open-toggle" to="#">
              <i className="header-icon fe fe-align-left"></i>
            </Link>
            <Link className="close-toggle" to="#">
              <i className="header-icon fe fe-x"></i>
            </Link>
          </div>
          <div className="logo-horizontal">
            <Link
              to={`${process.env.PUBLIC_URL}/dashboard/dashboard`}
              className="header-logo"
            >
              <img
                src={state2.data.loggo_url}
                className="main-logo  mobile-logo"
                alt="logo"
              />
              {/* <img
                src={state2.data.loggo_url}
                className="main-logo  mobile-logo"
                alt="logo"
              /> */}
            </Link>
          </div>
          <div className="main-header-center ms-4 d-sm-none d-md-none d-lg-block form-group">
            {/* <div className="row">
              <div className="col-6">
                <div className="max">
                  <Marquee>
                    <p>ห้ามให้ Username หรือ Password อื่นใดแก่ใครใน Line Official โดยเด็ดขาด ทางระบบไม่มีนโยบายขอ ยูสหรือพาสเวิร์ด หากสงสัยให้ติดต่อผู้ดูแลเว็บ  , กรุณาเช็คยอดเงินในสเตสเม้นการโอนเงินทุกครั้ง หากทำรายการแล้วไม่อัตโนมัติ</p>
                  </Marquee>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="col-8">
          <Marquee>
            <p>
              ห้ามให้ Username หรือ Password อื่นใดแก่ใครใน Line Official
              โดยเด็ดขาด ทางระบบไม่มีนโยบายขอ ยูสหรือพาสเวิร์ด
              หากสงสัยให้ติดต่อผู้ดูแลเว็บ ,
              กรุณาเช็คยอดเงินในสเตสเม้นการโอนเงินทุกครั้ง
              หากทำรายการแล้วไม่อัตโนมัติ
            </p>
          </Marquee>
        </div>

        <div className="main-header-right">
          <Navbar.Toggle
            className="navresponsive-toggler d-lg-none ms-auto"
            type="button"
          >
            <span className="navbar-toggler-icon fe fe-more-vertical"></span>
          </Navbar.Toggle>
          <div className="mb-0 navbar navbar-expand-lg   navbar-nav-right responsive-navbar navbar-dark p-0">
            <Navbar.Collapse className="collapse" id="navbarSupportedContent-4">
              <ul className="nav nav-item header-icons navbar-nav-right ">
                
                
                
               
                <Dropdown className=" nav-item main-header-notification d-flex">
                  <Dropdown.Toggle className="new nav-link" variant="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="header-icon-svgs"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z" />
                    </svg>
                    <span className=" pulse"></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="slid1">
                    <div className="menu-header-content text-start border-bottom">
                      <div className="d-flex">
                        <h6 className="dropdown-title mb-1 tx-15 font-weight-semibold">
                          Notifications
                        </h6>
                        <span className="badge badge-pill badge-warning ms-auto my-auto float-end">
                          <Col lg={3} sm={6}></Col>
                        </span>
                      </div>
                      <p className="dropdown-title-text subtext mb-0 op-6 pb-0 tx-12 ">
                        You have {state.data_aa} Notifications
                      </p>
                    </div>
                    <Scrollbars style={{ height: 280 }}>
                      <div className="main-notification-list Notification-scroll">
                        {state.data &&
                          state.data.map((item: any) => {
                            return (
                              <>
                                <Dropdown.Item className="d-flex p-3 border-bottom">
                                  <div className="notifyimg bg-pink">
                                    <i className="far fa-folder-open text-white"></i>
                                  </div>
                                  <div className="ms-3">
                                    <h5 className="notification-label mb-1">
                                      {item.notification_title}
                                    </h5>
                                    <div className="notification-subtext">
                                      {Moment(item.updated_at).fromNow()}
                                    </div>
                                  </div>
                                  <div className="ms-auto">
                                    <i className="las la-angle-right text-end text-muted"></i>
                                  </div>
                                </Dropdown.Item>
                              </>
                            );
                          })}
                      </div>
                    </Scrollbars>
                    <div className="dropdown-footer">
                      <Link
                        className="btn btn-primary btn-sm btn-block"
                        to={`${process.env.PUBLIC_URL}/pages/mail/mail`}
                      >
                        VIEW ALL
                      </Link>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <li
                  className="nav-item full-screen fullscreen-button"
                  onClick={() => Fullscreen(fullscreens)}
                >
                  <Link className="new nav-link full-screen-link" to="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="header-icon-svgs"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 5h5V3H3v7h2zm5 14H5v-5H3v7h7zm11-5h-2v5h-5v2h7zm-2-4h2V3h-7v2h5z" />
                    </svg>
                  </Link>
                </li>
                
                
                <Dropdown className=" main-profile-menu nav nav-item nav-link ps-lg-2">
                  <Dropdown.Toggle
                    className="new nav-link profile-user d-flex"
                    variant=""
                  >
                    <img
                      alt=""
                      src={
                        !data_admin.profile_photo
                          ? require(`../../assets/img/admin/avatar.png`)
                          : data_admin.profile_photo
                      }
                      className=""
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="menu-header-content p-3 border-bottom">
                      <div className="d-flex wd-100p">
                        <div className="main-img-user">
                          <img
                            alt=""
                            src={
                              !data_admin.profile_photo
                                ? require(`../../assets/img/admin/avatar.png`)
                                : data_admin.profile_photo
                            }
                            className=""
                          />
                        </div>
                        <div className="ms-3 my-auto">
                          <h6 className="tx-15 font-weight-semibold mb-0">
                            {data_admin.name}
                          </h6>
                          <span className="dropdown-title-text subtext op-6  tx-12">
                            {data_admin.level_user}
                          </span>
                        </div>
                      </div>
                    </div>
                    <Dropdown.Item
                      className="dropdown-item"
                      href={`${process.env.PUBLIC_URL}/pages/profileAdmin`}
                    >
                      <i className="far fa-user-circle"></i>Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown-item"
                      href={`${process.env.PUBLIC_URL}/pages/mail/chat`}
                    >
                      <i className="far fa-smile"></i> chat
                    </Dropdown.Item>

                    <Dropdown.Item
                      className="dropdown-item"
                      onClick={() => {
                        logout();
                        routeChange();
                      }}
                    >
                      <i className="far fa-arrow-alt-circle-left"></i> Sign Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </Navbar.Collapse>
          </div>
          <div className="d-flex">
            <Link
              className="demo-icon new nav-link"
              to="#"
              onClick={() => swichermainright()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="header-icon-svgs fa-spin"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M12 16c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.084 0 2 .916 2 2s-.916 2-2 2-2-.916-2-2 .916-2 2-2z" />
                <path d="m2.845 16.136 1 1.73c.531.917 1.809 1.261 2.73.73l.529-.306A8.1 8.1 0 0 0 9 19.402V20c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-.598a8.132 8.132 0 0 0 1.896-1.111l.529.306c.923.53 2.198.188 2.731-.731l.999-1.729a2.001 2.001 0 0 0-.731-2.732l-.505-.292a7.718 7.718 0 0 0 0-2.224l.505-.292a2.002 2.002 0 0 0 .731-2.732l-.999-1.729c-.531-.92-1.808-1.265-2.731-.732l-.529.306A8.1 8.1 0 0 0 15 4.598V4c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v.598a8.132 8.132 0 0 0-1.896 1.111l-.529-.306c-.924-.531-2.2-.187-2.731.732l-.999 1.729a2.001 2.001 0 0 0 .731 2.732l.505.292a7.683 7.683 0 0 0 0 2.223l-.505.292a2.003 2.003 0 0 0-.731 2.733zm3.326-2.758A5.703 5.703 0 0 1 6 12c0-.462.058-.926.17-1.378a.999.999 0 0 0-.47-1.108l-1.123-.65.998-1.729 1.145.662a.997.997 0 0 0 1.188-.142 6.071 6.071 0 0 1 2.384-1.399A1 1 0 0 0 11 5.3V4h2v1.3a1 1 0 0 0 .708.956 6.083 6.083 0 0 1 2.384 1.399.999.999 0 0 0 1.188.142l1.144-.661 1 1.729-1.124.649a1 1 0 0 0-.47 1.108c.112.452.17.916.17 1.378 0 .461-.058.925-.171 1.378a1 1 0 0 0 .471 1.108l1.123.649-.998 1.729-1.145-.661a.996.996 0 0 0-1.188.142 6.071 6.071 0 0 1-2.384 1.399A1 1 0 0 0 13 18.7l.002 1.3H11v-1.3a1 1 0 0 0-.708-.956 6.083 6.083 0 0 1-2.384-1.399.992.992 0 0 0-1.188-.141l-1.144.662-1-1.729 1.124-.651a1 1 0 0 0 .471-1.108z" />
              </svg>
            </Link>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Navbar>
  );
}

Header.propTypes = {};

Header.defaultProps = {};
