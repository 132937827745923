import React from "react";
import { useReducer, useEffect,useState } from "react";
import {
  Breadcrumb,
  Card,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Tab,
  Tabs, Modal, Alert
} from "react-bootstrap";

import { FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Input } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropzone from "react-dropzone";



const Edit_And_AddSilp = (porps:any) => {

  // console.log(porps)



    return ( 
        <>
        
        </>
     );
}
 
export default Edit_And_AddSilp;