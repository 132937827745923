import React, { useEffect, useMemo, useReducer, useState, useRef } from "react";
import { Breadcrumb, InputGroup } from "react-bootstrap";
import {
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Form,
  Collapse,
  Alert,
} from "react-bootstrap";
import { Card, Col, Row, Button, Modal } from "react-bootstrap";

import { Link } from "react-router-dom";
import AdminAPI from "../../api/Admin";
import ApiAuth from "../../../api/db";
import Apiscb from "../../api/Apiscb";
import Swal from "sweetalert2";
import Chack_wit from "./Chack_wit";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { UncontrolledDropdown, DropdownToggle } from "reactstrap";
import "moment/locale/th";
import DataTable from "react-data-table-component";
const Normal_withdrawal = () => {
  const [show, setShow] = React.useState(true);
  let [isFirstCollapsed, setisFirstCollapsed] = React.useState(true);
  let first = () => {
    if (isFirstCollapsed === false) {
      setisFirstCollapsed(true);
    } else if (isFirstCollapsed === true) {
      setisFirstCollapsed(false);
    }
  };
  const [modal_center_infotran11, setModal_center_infotran11] = useState(false);
  const [openck, setOpenck] = useState(false);
  const [remove, Setremove] = React.useState(true);
  const values = [true];
  const [fullscreen, setFullscreen] = React.useState<any>(true);
  const [shows, setShows] = React.useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [src, setSrc] = useState("");
  function handleShow(breakpoint: any) {
    setFullscreen(breakpoint);
    setShows(true);
  }
  let [isFirstCollapseds, setisFirstCollapseds] = React.useState(true);
  let firsts = () => {
    if (isFirstCollapseds === false) {
      setisFirstCollapseds(true);
    } else if (isFirstCollapseds === true) {
      setisFirstCollapseds(false);
    }
  };

  useEffect(() => {
    data_witmal();
  }, []);

  useEffect(() => {
    getTran_hit();
  }, []);

  const getTran_hit = () => {
    AdminAPI.getTran_hit()
      .then((res) => {
        //  console.log(res.data);
        if (res.data.success) {
          setState({
            data: res.data.data,
            data2: res.data.data2,
          });
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Export = ({ onExport }: any) => <></>;

  const data_witmal = () => {
    ApiAuth.getdata_mal()
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => {
        //  console.log(err);
      });
  };
  const [modal_center, setmodal_center] = useState(false);
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
      data2: [],
    }
  );

  const searchhtitran = (data: any) => {
    let datapost = {
      start_date: startDate,
      end_date: endDate,
    };

    AdminAPI.postTran_hit(datapost)
      .then((res) => {
        setState({
          data: res.data.data,
          data2: res.data.data2,
        });
      })
      .catch((err) => {
        //  console.log(err);
      });
  };
  function tog_center(data: any) {
    const datapostchack = {
      qr_sting: data.qr_sting,
      qr: data.qr,
    };

    //  console.log(datapostchack)

    Apiscb.chack_qrapikbank(datapostchack)
      .then((res) => {
        // console.log(res.data);

        if (res.data.success) {
          setStatec({
            data: res.data.data,
          });
          // console.log(res.data.dataqr);
          setSrc(res.data.dataqr);

          setModal_center_infotran11(!modal_center_infotran11);
          removeBodyCss();
        } else {
          Swal.fire({
            icon: "error",
            title:
              "ไม่พบข้อมูลในระบบ หรือรายการดังกล่าวมีอายุเกินระยะเวลาที่สามารถตรวจสอบได้",
          }).then((result) => {
            if (result.isConfirmed) {
              setOpenck(false);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const [statec, setStatec] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
    }
  );
  const actionsMemo = React.useMemo(
    () => (
      <Export
        onExport={() => {
          //downloadCSV(data);
        }}
      />
    ),
    []
  );
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  let selectdata: any = [];
  const handleRowSelected = React.useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const contextActions = React.useMemo(() => {
    const Selectdata = () => {
      if (
        window.confirm(`download:\r ${selectedRows.map((r: any) => r.SNO)}?`)
      ) {
        setToggleCleared(!toggleCleared);
      }
    };

    return <Export onExport={() => Selectdata()} icon="true" />;
  }, [selectdata, selectedRows]);

  var data = state.data;
  var data2 = state.data2;

  const columns = [
    {
      name: "เลขบัญชีปลายทาง",
      id: "accnum_to",
      cell: (row: any) => (
        <>
          <div className={"mx-auto"}>
            <img src={row.Bank.url_pic} alt="" width={20} /> {row.accnum_to}
            <br /> {row.name_to}
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: "โอนจากบัญชี",
      cell: (row: any) => (
        <>
          <div className={"mx-auto"}>
            <img src={row.Banks.url_pic} alt="" width={20} />{" "}
            {row.Account_bank.accnum}
            <br /> {row.Account_bank.name}
          </div>

          {/* <p>{row.Account_bank.accnum}</p> */}
        </>
      ),
    },
    {
      name: "Qrcode",
      cell: (row: any) => (
        <div className={"mx-auto"}>
          {" "}
          <img src={row.qr} alt="" width={40} />
        </div>
      ),
    },

    {
      name: "จำนวนเงิน",
      cell: (row: any) => <div className={"mx-auto"}>จำนวน {row.monny}</div>,
    },
    {
      name: "ชื่อผู้รับ",
      cell: (row: any) => <div className={" "}>{row.name_to}</div>,
    },
    {
      name: "วันที่ที่โอน",
      cell: (row: any) => <div className={" "}>{row.created_at}</div>,
    },
    {
      name: "ผู้ทำรายการ",
      cell: (row: any) => <div className={" "}>{row.add_from}</div>,
    },
    // {
    //   name: "บัญชีที่ทำรายการ",
    //   cell: (row: any) => (
    //     <UncontrolledDropdown>
    //       <DropdownToggle
    //         className="btn btn-link text-muted py-1 font-size-16 shadow-none"
    //         tag="a"
    //       >
    //         <button
    //           type="button"
    //           className="btn btn-info waves-effect btn-label waves-light"
    //           onClick={() => {
    //             tog_center({
    //               qr: row.qr,
    //               qr_sting: row.qr_sting,
    //             });
    //           }}
    //           data-toggle="modal"
    //           data-target=".bs-example-modal-center"
    //         >
    //           <i className="fas fa-exclamation-circle label-icon" /> ตรวจสอบ
    //         </button>
    //       </DropdownToggle>
    //     </UncontrolledDropdown>
    //   ),
    // },
  ];

  const columns2 = [
    {
      name: "เลขบัญชี",
      id: "accnum_to",
      selector: (row: any) => row.accnum_to,
      sortable: true,
    },
    {
      name: "บัญชีปลายทาง",
      cell: (row: any) => (
        <div className={" font-size-12"}>{row.tran_options}</div>
      ),
    },
    {
      name: "บัญชีปลายทาง",
      cell: (row: any) => (
        <div className={" "}>
          {" "}
          <img src={row.url_pic} alt="" width={20} /> {row.bankNameTh}
        </div>
      ),
    },

    {
      name: "จำนวนเงิน",
      cell: (row: any) => <div className={" "}>จำนวน {row.monny}</div>,
    },
    {
      name: "ชื่อผู้รับ",
      cell: (row: any) => <div className={" "}>{row.name_to}</div>,
    },
    {
      name: "วันที่ที่โอน",
      cell: (row: any) => <div className={" "}>{row.created_at}</div>,
    },
    {
      name: "ผู้ทำรายการ",
      cell: (row: any) => <div className={" "}>{row.add_from}</div>,
    },
    // {
    //   name: "บัญชีที่ทำรายการ",
    //   cell: (row: any) => (
    //     <UncontrolledDropdown>
    //       <DropdownToggle
    //         className="btn btn-link text-muted py-1 font-size-16 shadow-none"
    //         tag="a"
    //       >
    //         <button
    //           type="button"
    //           className="btn btn-info waves-effect btn-label waves-light"
    //           onClick={() => {
    //             tog_center({
    //               qr: row.qr,
    //               qr_sting: row.qr_sting,
    //             });
    //           }}
    //           data-toggle="modal"
    //           data-target=".bs-example-modal-center"
    //         >
    //           <i className="fas fa-exclamation-circle label-icon" /> ตรวจสอบ
    //         </button>
    //       </DropdownToggle>
    //     </UncontrolledDropdown>
    //   ),
    // },
  ];
  const [allData, setAllData] = React.useState<any>(data);

  // console.log(allData)

  let allElement2: any[] = [];

  let myfunction = (InputData: any) => {
    for (let allElement of data) {
      if (allElement.username.toLowerCase().includes(InputData.toLowerCase())) {
        if (
          allElement.username.toLowerCase().startsWith(InputData.toLowerCase())
        ) {
          allElement2.push(allElement);
        }
      }
    }
    setAllData(allElement2);
  };

  const Monthrange = () => {
    return (
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        className="form-control"
        onChange={(update: any) => {
          setDateRange(update);
        }}
        isClearable={true}
      />
    );
  };
  return (
    <React.Fragment>
      <div>
        {/* <!-- breadcrumb --> */}
        <div className="breadcrumb-header justify-content-between">
          <div className="left-content">
            <span className="main-content-title mg-b-0 mg-b-lg-1">
              ประวัติการโอนเงิน
            </span>
          </div>
          <div className="justify-content-center mt-2">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item className="breadcrumb-item tx-15" href="#">
                Pages
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="breadcrumb-item "
                active
                aria-current="page"
              >
                ประวัติการโอนเงิน
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        {/* <!-- /breadcrumb -->

					<!-- row --> */}
        <Row>
          <Col md={12} xl={12} xs={12} sm={12}>
            <Card>
              <Card.Body>
                <div className="table-responsive text-center">
                  <Row className="row-sm">
                    <Col sm={12} md={12} lg={12} xl={12}>
                      <Card>
                        <Card.Body>
                          <div>
                            <span className="datatable fixedheader">
                              <label className="float-end">
                                <input
                                  type="text"
                                  placeholder="Search..."
                                  className="mb-4 form-control-sm  form-control"
                                  onChange={(ele) => {
                                    myfunction(ele.target.value);
                                  }}
                                />
                              </label>
                              <DataTable
                                title="ประวัติการโอนเงิน"
                                columns={columns}
                                data={data}
                                fixedHeader
                                pagination
                              />

                              {/* <DataTable
                      title=""
                      columns={columns2}
                      data={data2}
                      fixedHeader
                      pagination
                    /> */}
                            </span>
                            <Modal
                              className="modal-dialog modal-lg"
                              size="lg"
                              isOpen={modal_center_infotran11}
                              toggle={() => {
                                tog_center(null);
                              }}
                              centered={true}
                            >
                              <div className="modal-header">
                                <h5 className="modal-title mt-0 text-center">
                                  ประวัติการโอนเงิน
                                </h5>

                                <button
                                  onClick={() => {
                                    setModal_center_infotran11(false);
                                  }}
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <Card>
                                <Chack_wit datachack={{ statec, src }} />
                              </Card>

                              <div className="modal-footer"></div>
                            </Modal>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <!-- row closed --> */}
      </div>
    </React.Fragment>
  );
};

Normal_withdrawal.propTypes = {};

Normal_withdrawal.defaultProps = {};

export default Normal_withdrawal;
