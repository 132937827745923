import axios from "axios";
import config from "../../config/app.config"
import { setAuthorizationToken } from "../../helpers/axios";

setAuthorizationToken();

const FriendAPI = {
    // เรียก api แบบ Get และส่ง พารามิเตอร์
    getProfile: async function (uid: number)  {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/profile/${uid}`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    verifyOtp: async (data: any) => {
        return await axios.post(`${config.apiURL}/api/v5/user/verify-otp`, data)
    },
    //เรียก api แบบ Get ธรรมดา
    getDashboard: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/dashboard`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    Editbankonline: async (data:any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/Editbankonline`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
            
        };

        return await axios(resData);
    },

    getpassner_chack_wits_s: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/getpassner_chack_wits_s`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    getbank_scb1: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getbank_scb1`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    getNoti: async (data: number) => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/noti/${data}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
           
        };

        return await axios(resData);
    },
    getbank_omline: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getbank_omline`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    getdatanoti_omline: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getdatanoti_omline`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    getbank_chack_wit: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/chack_wit`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    getbank_chack_wits: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/chack_wits`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    getbank_chack_wits_s: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/chack_witss`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    get_tranchack: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/scb/get_tranchack`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    PostUpdatelogout: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/postUpdatelogout`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    
    getbank_chack_witall: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/chack_witall`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    Dataalltangmuay: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/dataalltangmuay`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    //เรียก api แบบ post และส่ง พารามิเตอร์
    Postlogin: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/loginadmin`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },

    Posteditbank: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/editbank`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    Postupdatenoti: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/updatenoti`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    PostAddbank: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/postAddbank`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    withdraw_admin: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/withdraw_admin`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    getbankseting123: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getbankseting`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    getagent: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getagent`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    getbankseting_set: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getbankseting_set`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    getbankseting_onpro: async () => {

        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getbankseting_onpro`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    Postregister: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/register`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    del_bankseting: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/del_bankseting`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    Updatebank_bynew: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/Updatebank_bynew`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    PostUpdatenoti: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/PostUpdatenoti`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    getagentde: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/getagentde`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    startbankonline: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/startbankonline`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    stopbankonline: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/stopbankonline`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    gEtNotifytion: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/gEtNotifytion`,
            headers: {
                'Content-Type': 'application/json'
            },
           
        };

        return await axios(resData);
    }
}
export default FriendAPI