import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Row,
} from "react-bootstrap";
import { VerticallyCenteredModal } from "./uploadlogo1";
import { Link } from "react-router-dom";
import SettingAPI from "../../api/SettingAPI";
import Data_setting2 from "./data_setting2";
//import Apisetting from "../../../api/Apisetting";
import { useParams, useNavigate } from "react-router-dom";
const Setting_webfontend = () => {
  useEffect(() => {
    getapisetting();
    dataca();
  }, []);

  const getapisetting = () => {
    SettingAPI.getdatasetting_fontend()
      .then((res) => {
        //
        setState({
          data: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
    },
  );

  // console.log(state.data.loggo_url);

  const [show, setShow] = React.useState(true);
  let [isFirstCollapsed, setisFirstCollapsed] = React.useState(true);

  const [remove, Setremove] = React.useState(true);
  const values = [true];
  const [fullscreen, setFullscreen] = React.useState<any>(true);
  const [shows, setShows] = React.useState(false);

  function handleShow(breakpoint: any) {
    setFullscreen(breakpoint);
    setShows(true);
  }
  let [isFirstCollapseds, setisFirstCollapseds] = React.useState(true);
  let firsts = () => {
    if (isFirstCollapseds === false) {
      setisFirstCollapseds(true);
    } else if (isFirstCollapseds === true) {
      setisFirstCollapseds(false);
    }
  };

  const [statepost, setStatepost] = useState({
    tab: "",
    wl_wit: "",
    line: "",
    wl_online: "",
    token_line_with: "",
  });
  let navigate = useNavigate();
  //  console.log(statepost)

  const { tab, wl_wit, line, wl_online, token_line_with } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };
  const post_settingweb = (e: any) => {
    e.preventDefault();

    let datass = {
      gameid: tab ? tab : "Slot",
    };
    SettingAPI.playgamecasinos(datass)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data.code == 0) {
          setState1({
            data: res.data.data.data,
          });
          navigate("/setting_web/setting_game", { state: { username: res.data.data.data } })
         
        }
      })
      .catch((err) => {
        console.log(err);
      });

  };
  const dataca = () => {
    // let datass = {
    //   gameid: tab ? tab : "Slot",
    // };
    // SettingAPI.playgamecasinos(datass)
    //   .then((res) => {
    //     console.log(res.data.data);
    //     if (res.data.data.code == 0) {
    //       setState1({
    //         data: res.data.data.data,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const [state1, setState1] = useReducer(
    (state1: any, newState1: any) => ({ ...state1, ...newState1 }),
    {
      data: [],
    },
  );

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            <i className="fas fa-cog"></i> ตั้งค่าการใช้งาน หน้าเว็บ
          </span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb>
            <Breadcrumb.Item className="tx-15" href="#">
              ตั้งค่าเว็บ
            </Breadcrumb.Item>
            <Breadcrumb.Item active aria-current="page">
              ตั้งค่าการใช้งาน หน้าเว็บ
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}

      <Card className="mg-b-20">
        <Card.Body>
          <Col lg={12}>
            <Card className="custom-card">
              {/* <Card.Body>
                <div>
                  <h6 className="main-content-label mb-1">ตั้งค่าทั่งไป</h6>
                </div>
              </Card.Body> */}
            </Card>
          </Col>
        </Card.Body>
      </Card>
      <Row className=" row-sm">
        <Col lg={6} xl={6} md={12} sm={12}>
          <Card className="box-shadow-0">
            <Card.Header>
              <h4 className="card-title mb-1">LOGO1</h4>
              <p className="mb-2">
                <div className="text-wrap">
                  <div className="example">
                    <div>
                      <Link to="#">
                        <img
                          alt="Responsive"
                          width="400px"
                          className="img-fluid br-5"
                          src={state.data.loggo_url}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </p>
              <VerticallyCenteredModal pp={"loggo_url"} />
            </Card.Header>
            <Card.Body className=" pt-0"></Card.Body>
          </Card>
        </Col>
        <Col lg={6} xl={6} md={6} sm={12}>
          <Card className="box-shadow-0">
            <Card.Header>
              <h4 className="card-title mb-1">รูปภาพ ตรงกลางเว็บ</h4>
              <p className="mb-2">
                <div className="text-wrap">
                  <div className="example">
                    <div>
                      <Link to="#">
                        <img
                          alt="Responsive"
                          width="400px"
                          className="img-fluid br-5"
                          src={state.data.content_img5}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </p>
              <VerticallyCenteredModal pp={"content_img5"} />
            </Card.Header>
            <Card.Body className=" pt-0"></Card.Body>
          </Card>
        </Col>

        <Row className=" row-sm">
          <Col lg={3} xl={3} md={3} sm={3}>
            <Card className="box-shadow-0">
              <Card.Header>
                <h4 className="card-title mb-1">content_img1</h4>
                <p className="mb-2">
                  <div className="text-wrap">
                    <div className="example">
                      <div>
                        <Link to="#">
                          <img
                            alt="Responsive"
                            className="img-fluid br-5"
                            src={state.data.content_img1}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </p>
                <VerticallyCenteredModal pp={"content_img1"} />
              </Card.Header>
              <Card.Body className=" pt-0"></Card.Body>
            </Card>
          </Col>
          {/* content_img1 2 */}
          <Col lg={3} xl={3} md={3} sm={3}>
            <Card className="box-shadow-0">
              <Card.Header>
                <h4 className="card-title mb-1">content_img2</h4>
                <p className="mb-2">
                  <div className="text-wrap">
                    <div className="example">
                      <div>
                        <Link to="#">
                          <img
                            alt="Responsive"
                            className="img-fluid br-5"
                            src={state.data.content_img2}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </p>
                <VerticallyCenteredModal pp={"content_img2"} />
              </Card.Header>
              <Card.Body className=" pt-0"></Card.Body>
            </Card>
          </Col>

          {/* 3 */}
          <Col lg={3} xl={3} md={3} sm={3}>
            <Card className="box-shadow-0">
              <Card.Header>
                <h4 className="card-title mb-1">content_img3</h4>
                <p className="mb-2">
                  <div className="text-wrap">
                    <div className="example">
                      <div>
                        <Link to="#">
                          <img
                            alt="Responsive"
                            className="img-fluid br-5"
                            src={state.data.content_img3}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </p>
                <VerticallyCenteredModal pp={"content_img3"} />
              </Card.Header>
              <Card.Body className=" pt-0"></Card.Body>
            </Card>
          </Col>

          {/* 4 */}
          <Col lg={3} xl={3} md={3} sm={3}>
            <Card className="box-shadow-0">
              <Card.Header>
                <h4 className="card-title mb-1">content_img4</h4>
                <p className="mb-2">
                  <div className="text-wrap">
                    <div className="example">
                      <div>
                        <Link to="#">
                          <img
                            alt="Responsive"
                            className="img-fluid br-5"
                            src={state.data.content_img4}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </p>
                <VerticallyCenteredModal pp={"content_img4"} />
              </Card.Header>
              <Card.Body className=" pt-0"></Card.Body>
            </Card>
          </Col>
        </Row>
      </Row>
      <Row className=" row-sm">
        <Col lg={6} xl={6} md={12} sm={12}>
          <Card className="box-shadow-0 ">
            <Card.Header>
              <h4 className="card-title mb-1">ตั้งค่าแจ้งเตือนไลน์</h4>
              <p className="mb-2">ระบุโทเค้นที่ได้จากไลน์แจ้งเตือน</p>
            </Card.Header>
            <Card.Body className=" pt-0">
              {state.data.length != 0 && (
                <>
                  <Data_setting2 data={state.data} />
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} xl={6} md={12} sm={12}>
          <Card className="box-shadow-0 ">
            <Card.Header>
              <h4 className="card-title mb-1">ปิดหน้าเข้าเล่น</h4>
            </Card.Header>
            <Form onSubmit={post_settingweb}>
              <Card.Body className=" pt-0">
                ประเภทที่ต้องการ
                <select
                  name="tab"
                  id="tab"
                  className="form-select"
                  value={tab}
                  onChange={inputvalue("tab")}
                >
                  <option>ประเภทที่ต้องการ</option>
                  <option value="Slot">Slot</option>
                  <option value="Casino">Casino</option>
                  <option value="Keno">Keno</option>
                  <option value="Fish">Fish</option>
                  <option value="Card">Card</option>

                  <option value="Trade">Trade</option>

                  <option value="Poker">Poker</option>
                  <option value="Esport">Esport</option>
                  <option value="Cock">Cock</option>
                </select>

                     {state1.data &&
                      state1.data.map((items: any, index: any) => {
                        return (
                          <>
                            
                          </>
                        );
                      })}
                <button className="btn btn-block btn-primary mt-5">ค้นหา ประเภท </button>
              </Card.Body>
            </Form>
          </Card>
          {/* {tab != "" && (
            <>
              <Card className="box-shadow-0 ">
                <Card.Header>
                  <h4 className="card-title mb-1">ปิดหน้าเข้าเล่น</h4>
                </Card.Header>
                <Card.Body className=" pt-0">
                  ประเภทที่ต้องการ
                  <select>
                    <option>ประเภทที่ต้องการ</option>
                    {state1.data &&
                      state1.data.map((items: any, index: any) => {
                        return (
                          <>
                            <option value="Slot">{items.provider}</option>
                          </>
                        );
                      })}
                  </select>
                </Card.Body>
              </Card>
            </>
          )} */}
        </Col>
      </Row>
      {/* <!-- End Row --> */}
    </div>
  );
};

Setting_webfontend.propTypes = {};

Setting_webfontend.defaultProps = {};

export default Setting_webfontend;
