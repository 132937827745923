import React, { useState, useRef, useEffect, useReducer } from "react";
import TimeFormat from "hh-mm-ss";
import Countdown from "react-countdown-now";
//import "./index.css";
import Apiscb from "../../api/Apiscb";
import AdminAPI from "../../api/Admin";
import CountUp from "react-countup";
import Apibank from "../../../api/APIbank";

import {
  Breadcrumb,
  InputGroup,
  Alert,
  FormGroup,
  Form,
} from "react-bootstrap";
//import BasicPills from "./deposit_manual.tsx/BasicPills";
//import BasicPillss from "./withdraw_manual/BasicPills";
import { Table, Row, Col, Card, Button, Modal } from "react-bootstrap";
//import Setting_witauto from "./setting_witauto";

const Setting_witauto = ({ data, viewtran_trueClose2 }: any) => {
  //console.log(data);
  useEffect(() => {
    databank_list_f();
  }, []);

  const databank_list_f = () => {
    AdminAPI.databank_list()
      .then((res) => {
        setState111({ data: res.data.data });
        //console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [state111, setState111] = useReducer(
    (state111: any, newState111: any) => ({ ...state111, ...newState111 }),
    {
      data: [],
    },
  );

  const [show_add, SetShow_add] = useState(false);
  const [show_wit, SetShow_wit] = useState(false);
  const [show_option, SetShow_option] = useState(false);
  const [show_option1, SetShow_option1] = useState(false);
  const [show_option3, SetShow_option3] = useState(false);
  let [Switcher2, setSwitcher2] = useState("off");
  let [Switcher21, setSwitcher21] = useState("");
  let [Switcher211, setSwitcher211] = useState(false);

  const [statepost, setStatepost] = useState({
    accnum: data.accnum,
    name: data.name,
    username: data.username,
    password: data.password,
    bankname: data.bankname,
    edit_id: data.id,
    option_b: data.option_b,
    level: data.level,
    tobank_accnum: data.tobank_accnum,
    tobank_bank: data.tobank_bank,
    tobank_monney: data.tobank_monney,
    limit_wit: data.limit_wit,
    tobank_stust: data.tobank_stust,
    tobank_minmonny: data.tobank_minmonny,
  });
  const {
    option_b,
    accnum,
    password,
    username,
    name,
    edit_id,
    level,
    tobank_accnum,
    tobank_stust,
    limit_wit,
    tobank_monney,
    tobank_minmonny,
    tobank_bank,
  } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  const addBank = (e: any) => {
    e.preventDefault();
    const postData = {
      accnum: accnum,
      name: name,
      username: username,
      password: password,
      id: edit_id,
      level: level,
      tobank_monney: tobank_monney,
      tobank_minmonny: tobank_minmonny,
      tobank_bank: tobank_bank,
      tobank_stust: tobank_stust,
      tobank_accnum: tobank_accnum,
      // level: level,
      // otp:""
    };

    Apibank.Posteditbankseting1234(postData)
      .then((res) => {
        if (res.data.success) {
          //setmagdatas(true);
          setSwitcher211(true);
          setSwitcher21(res.data.msg);
          setTimeout(function () {}, 1500);
          viewtran_trueClose2("show_option1");
        } else {
          setSwitcher21(res.data.msg);
          // 103.253.75.245  183.89.213.199  console.log(res.data)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onShowsetting = () => {
    SetShow_add(!show_add);
  };

  return (
    <>
      {data && (
        <>
          <div className="row">
            <div className=" p-2 col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="d-flex">
                        <div className="settings-main-icon me-4 mt-1">
                          <i className="fe fe-home" />
                        </div>
                        <div>
                          <p className="tx-20 font-weight-semibold d-flex mb-0">
                            ระบบ โยกเงินออโต้ ไปยังบัญชีพักของท่าน
                          </p>
                          <p className="tx-13 text-muted mb-0">
                            ** ลูกค้าไม่ควรเก็บเงินไว้ในบัญชี ฝากหลัก
                            เพื่อป้องกันเงินสูนหาย
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <form className="" onSubmit={addBank}>
            <p className="text-center my-2">ตั้งค่าการโยกเงิน</p>
            <FormGroup className="form-group ">
              <Row className=" row-sm">
                <Col md={3}></Col>
              </Row>
            </FormGroup>
            <div className="p-3 card-footer">
              <h4 className="text-danger">
                สถานะ{" "}
                {tobank_stust == 0 ? (
                  <>
                    {" "}
                    <span className="text-danger">ยังไม่เปิดใช้งาน</span>{" "}
                  </>
                ) : (
                  <>
                    <span className="text-success">เปิดใช้งานอยู่</span>
                  </>
                )}
              </h4>
              <p></p>
              <Form.Label className="d-block mg-b-15-f">
                {tobank_stust != 1 ? (
                  <input
                    type="checkbox"
                    name="tobank_stust"
                    onChange={inputvalue("tobank_stust")}
                    value={1}
                    className="custom-switch-input"
                  />
                ) : (
                  <input
                    type="checkbox"
                    name="tobank_stust"
                    onChange={inputvalue("tobank_stust")}
                    value={0}
                    className="custom-switch-input"
                    defaultChecked
                  />
                )}

                <span className="custom-switch-indicator"></span>
                <span className="text-muted ms-2">เปิด/ปิดใช้งาน</span>
              </Form.Label>
            </div>
            {tobank_stust && (
              <>
                <div className="col-md-12 text-center">
                  {Switcher211 && (
                    <>
                      <Alert className="alert alert-success" role="alert">
                        <p>เกิดข้อผิดพลาด</p>
                        <h3>{Switcher21}</h3>
                      </Alert>
                    </>
                  )}
                  <div className="mb-3 mt-5">
                    <label htmlFor="tobank_accnum" className="form-label">
                      เลขบัญชีปลายทาง
                    </label>
                    <input
                      name="tobank_accnum"
                      id="tobank_accnum"
                      type="number"
                      className="form-control"
                      value={tobank_accnum}
                      onChange={inputvalue("tobank_accnum")}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="tobank_accnum" className="form-label">
                      ธนาคารปลายทาง
                    </label>

                    <select
                      className="form-control"
                      id="choices-single-no-search"
                      name="tobank_bank"
                      placeholder={tobank_bank}
                      value={tobank_bank}
                      onChange={inputvalue("tobank_bank")}
                    >
                      {state111.data &&
                        state111.data.map((item: any, index: any) => {
                          return (
                            <>
                              <option value={item.bank_id}>
                                {item.bankNameTh}{" "}
                              </option>
                            </>
                          );
                        })}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="tobank_accnum" className="form-label">
                      จำนวนเงินที่จะโยก
                    </label>
                    <input
                      name="tobank_monney"
                      id="tobank_monney"
                      type="number"
                      className="form-control"
                      value={tobank_monney}
                      onChange={inputvalue("tobank_monney")}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="tobank_accnum" className="form-label">
                      จำนวน เหลือติดบัญชี
                    </label>
                    <input
                      name="tobank_minmonny"
                      id="tobank_minmonny"
                      type="number"
                      className="form-control"
                      value={tobank_minmonny}
                      onChange={inputvalue("tobank_minmonny")}
                    />
                  </div>
                  <button className="btn btn-info mt-5" type="submit">
                    บันทึกการแก้ไข
                  </button>
                </div>
              </>
            )}
          </form>

          {/* <button onClick={()=> viewtran_trueClose2("show_option1")}></button> */}
          {data.tobank_stust != null && <></>}
        </>
      )}
    </>
  );
};
export default Setting_witauto;
