import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiAuth from "../api/db";
import { authenticate } from "../config/authsave";
//import * as Switcherdatacustam from "../../../../data/Switcherdata/Switcherdatacustam";
const Otp = () => {
  const location = useLocation();
  const userData: any = location.state;

  let navigate = useNavigate();

  const [status, setStatus] = useState(false);
  const [resendStatus, setResendStatus] = useState(false);
  const [resendStatus2, setResendStatus2] = useState(false);

  const [invalidOTPMessage, setInvalidOTPMessage] = useState("");
  const [invalidOTPMessage2, setInvalidOTPMessage2] = useState("");

  const [resendOTPMessage, setResendOTPMessage] = useState("");

  const [statepost, setStatepost] = useState({
    otp: "",
  });

  const { otp } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  const Setotp = (e: any) => {
    e.preventDefault();
    setStatus(true);
    const user_id = userData.user_id;

    const inputData = {
      otp: otp,
      user_id: user_id,
      user_device_id: userData.user_device_id,
    };

    // console.log(inputData)

    ApiAuth.verifyOtp(inputData)
      .then(async (res) => {
        if (res.data.success) {
          const data = {
            onlineOfflineRoom: "onLineOfflineStatusRoom",
            onlineFriends: res.data.onlineFriends,
          };
          setStatus(false);
          const payload = {
            token: res.data.token,
            userInfo: res.data.user,
          };
          authenticate(payload)


          
          navigate("/dashboard/dashboard");
          // navigate("/home", { state: { data: res.data } });
        } else {
          toast.error(`${res.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setStatus(false);
        }
      })
      .catch(function (error) {
        console.log("error ", error);
      });
  };

  return (
    <>
      <div>
        <div className="cover-image">
          <div className="page">
            <Row>
              <div className="d-flex">
                <Link className="demo-icon new nav-link" to="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="header-icon-svgs fa-spin"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 16c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.084 0 2 .916 2 2s-.916 2-2 2-2-.916-2-2 .916-2 2-2z"></path>
                    <path d="m2.845 16.136 1 1.73c.531.917 1.809 1.261 2.73.73l.529-.306A8.1 8.1 0 0 0 9 19.402V20c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-.598a8.132 8.132 0 0 0 1.896-1.111l.529.306c.923.53 2.198.188 2.731-.731l.999-1.729a2.001 2.001 0 0 0-.731-2.732l-.505-.292a7.718 7.718 0 0 0 0-2.224l.505-.292a2.002 2.002 0 0 0 .731-2.732l-.999-1.729c-.531-.92-1.808-1.265-2.731-.732l-.529.306A8.1 8.1 0 0 0 15 4.598V4c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v.598a8.132 8.132 0 0 0-1.896 1.111l-.529-.306c-.924-.531-2.2-.187-2.731.732l-.999 1.729a2.001 2.001 0 0 0 .731 2.732l.505.292a7.683 7.683 0 0 0 0 2.223l-.505.292a2.003 2.003 0 0 0-.731 2.733zm3.326-2.758A5.703 5.703 0 0 1 6 12c0-.462.058-.926.17-1.378a.999.999 0 0 0-.47-1.108l-1.123-.65.998-1.729 1.145.662a.997.997 0 0 0 1.188-.142 6.071 6.071 0 0 1 2.384-1.399A1 1 0 0 0 11 5.3V4h2v1.3a1 1 0 0 0 .708.956 6.083 6.083 0 0 1 2.384 1.399.999.999 0 0 0 1.188.142l1.144-.661 1 1.729-1.124.649a1 1 0 0 0-.47 1.108c.112.452.17.916.17 1.378 0 .461-.058.925-.171 1.378a1 1 0 0 0 .471 1.108l1.123.649-.998 1.729-1.145-.661a.996.996 0 0 0-1.188.142 6.071 6.071 0 0 1-2.384 1.399A1 1 0 0 0 13 18.7l.002 1.3H11v-1.3a1 1 0 0 0-.708-.956 6.083 6.083 0 0 1-2.384-1.399.992.992 0 0 0-1.188-.141l-1.144.662-1-1.729 1.124-.651a1 1 0 0 0 .471-1.108z"></path>
                  </svg>
                </Link>
              </div>
            </Row>
            <div className="page-single">
              <div className="container">
                <Row>
                  <Col
                    xl={5}
                    sm={8}
                    xs={10}
                    lg={6}
                    md={8}
                    className="card-sigin-main py-4 justify-content-center mx-auto"
                  >
                    <div className="card-sigin">
                      {/* <!-- Demo content--> */}
                      <div className="main-card-signin d-md-flex">
                        <div className="wd-100p">
                          <h4 className="text-center">VVIP AUTO BET</h4>
                          <div className="main-card-signin d-md-flex bg-white">
                            <div className="p-4 wd-100p">
                              <div className="main-signin-header">
                                <div className="avatar avatar-xxl avatar-xxl mx-auto text-center mb-2"></div>
                                <div className="mx-auto text-center mt-4 mg-b-20">
                                  <h5 className="mg-b-10 tx-16">
                                    กรุณายืนยัน รหัสความปลอดภัย
                                  </h5>
                                  <p className="tx-13 text-muted">
                                    Enter Your Password to View your Screen
                                  </p>
                                </div>
                                <Form onSubmit={Setotp}>
                                  <FormGroup className="form-group">
                                    <input
                                      name="otp"
                                      id="otp"
                                      placeholder="otp"
                                      type="number"
                                      value={otp}
                                      onChange={inputvalue("otp")}
                                      className="form-control"
                                    />
                                  </FormGroup>

                                  {status && (
                                    <>
                                      <Row className="btn-animation">
                                        <Button
                                          className="btn btn-danger btn-loaders ms-2 btn-block btn-animation w-100"
                                          type="submit"
                                        >
                                          {" "}
                                          <span className="loading">
                                            loading...
                                          </span>
                                        </Button>
                                      </Row>
                                    </>
                                  )}
                                  <ToastContainer />
                                  {!status && (
                                    <>
                                      <Button
                                        className="btn btn-danger btn-loaders ms-2 btn-block btn-animation w-100"
                                        type="submit"
                                      >
                                        ยืนยัน OTP
                                      </Button>
                                    </>
                                  )}
                                </Form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
      );
    </>
  );
};

Otp.propTypes = {};

Otp.defaultProps = {};

export default Otp;
