import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import AdminAPI from "../api/Admin";
import Swal from "sweetalert2";
import Approve_wit from "./approve_wit";
const App_new = (data: any) => {
    const [modal_center, setmodal_center] = useState(true);
    const [imgqr, setimgqr] = useState("");

    function appneow(data: any) {
        const postData2 = {
            username: data.user_id,
            id: data.id,
            monny: data.amount,

            // otp:""
        };
        AdminAPI.Approve_wit(postData2)
            .then((res) => {
                console.log(res.data);
                if (res.data.success == false) {
                    Swal.fire({
                        icon: "error",
                        title: res.data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            //
                        }
                    });
                } else if (res.data.success == true) {
                    setmodal_center(false);
                    setimgqr(res.data.data2)
                    // setStatew({
                    //     data: res.data.data,
                    //     data3: res.data.data3,
                    //     data4: res.data.data4,
                    //     data_mem: res.data.data_mem,
                    //     data_tran: res.data.data_tran,
                    // });
                } else if (res.data.data.data.status.code == 7001) {
                    setmodal_center(false);
                    Swal.fire({
                        icon: "error",
                        title: res.data.data.data.status.description,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            //
                        }
                    });
                } else if (res.data.data.data.status.code == 1000) {
                    // setStatew({
                    //     data: res.data.data,
                    //     data3: res.data.data3,
                    //     data4: res.data.data4,
                    //     data_mem: res.data.data_mem,
                    //     data_tran: res.data.data_tran,
                    // });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    // console.log(data);
    return (
        <>
            {modal_center && (
                <>
                    <Col lg={12}>
                        <Card className="bg-primary border-primary text-white-50 text-center">
                            <CardBody>
                                <h3 className="mb-3 text-white">
                                    ข้อมูล ลูกค้าแจ้งถอน
                                </h3>
                                <h4 className="card-text ">
                                    ชื่อ : {data.data.data.item.name_member}
                                </h4>
                                <h4 className="card-text">
                                    ประเภท : {data.data.data.item.remark}
                                </h4>
                                <h4 className="card-text">
                                    สถานะ : {data.data.data.item.status}
                                </h4>
                                <h4 className="card-text">
                                    username : {data.data.data.item.user_id}
                                </h4>
                                <h4 className="card-text">
                                    จำนวนเงิน : {data.data.data.item.amount}
                                </h4>

                                <hr />
                            </CardBody>
                        </Card>
                        <div className="d-flex flex-wrap gap-3 align-items-center my-5">
                            <button
                                type="button"
                                className="btn btn-success btn-sm waves-effect waves-light mx-auto w-50"
                                onClick={() => appneow(data.data.data.item)}
                            >
                                ถอนด่วน
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger btn-sm waves-effect waves-light mx-auto w-50"
                            >
                                ตัดเครดิต
                            </button>
                        </div>
                    </Col>
                </>
            )}

            {!modal_center && (
                <>
                    <Col lg={12}>
                        <Card className="bg-success border-primary text-white-50 text-center">
                            <CardBody>
                                <h3>โอนเงินสำเร็จ</h3>
                                <div className="card">
                                    <img
                                        className="card-img-top"
                                        src={imgqr}
                                        
                                   width="10%" />
                                    <div className="card-body">
                                        <p className="card-text">
                                            สามารถ สแกน QR นี้เพื่อตรวจสอบจากธนาคารอีกครั้ง
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </>
            )}
        </>
    );
};

export default App_new;
