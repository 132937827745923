import axios from "axios";
import config from "../config/app.config"
import { setAuthorizationToken } from "../helpers/axios";

setAuthorizationToken();
const Apisetting = {

    Loginadmin: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/loginadmin`,
            data: data
        };

        return await axios(resData);
    },
    signUp: async (data: any) => {
        return await axios.post(`${config.apiURL}/api/v5/admin/signup`, data)
    },
    verifyOtp: async (data: any) => {
        return await axios.post(`${config.apiURL}/api/v5/admin/verify-otp`, data)
    },
    resendOtp: async (data: any) => {
        return await axios.post(`${config.apiURL}/api/v5/admin/resend-otp`, data);
    },
    forgotPassword: async (data: any) => {
        return await axios.post(`${config.apiURL}/api/v5/admin/forgot-password`, data);
    },
    logout: async () => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/logout`,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        return await axios(resData);
    },
    getdatanewss: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/admin/getdatanews`,
            headers: {
                'Content-Type': 'application/json'
            }
           // data: data
            
        };

        return await axios(resData);
    },
    payment_information: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/admin/payment_information`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    Cratepay: async (data: any)  => {
        
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/cratepay`,
            headers: {
                'Content-Type': 'application/json'
            },
            data:data
        };

        return await axios(resData);
    },
    Postupdate_settings: async (data: any)  => {
        
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/amb/postupdate_set`,
            headers: {
                'Content-Type': 'application/json'
            },
            data:data
        };

        return await axios(resData);
    },
    postupdate_all: async (data: any)  => {
        
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/sarubyod`,
            headers: {
                'Content-Type': 'application/json'
            },
            data:data
        };

        return await axios(resData);
    },
    datasetting: async ()  => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/admin/get_datasetting`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    data_settingagent: async ()  => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/admin/data_settingagent`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    chack_logins: async ()  => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/admin/chack_logins`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    chack_loginv2: async ()  => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/admin/chack_loginv2`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },


    Postchack_settings: async (data: any)  => {
        
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/chack_logins`,
            headers: {
                'Content-Type': 'application/json'
            },
            data:data
        };

        return await axios(resData);
    },
    
    Postupdate_settings_webfontend: async (data: any)  => {
        
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/Postupdate_settings_webfontend`,
            headers: {
                'Content-Type': 'application/json'
            },
            data:data
        };

        return await axios(resData);
    },
}
export default Apisetting