import { Breadcrumb, Card, Col, Row, Button, Modal } from "react-bootstrap";
import { ExportCSV } from "./Exportcvs";
import Get_Playload from "../../../api/Api_class_scb";

const Personal_note = () => {
  const test = () => {
    let dataall = {
      deviceId: "35d6d038-ba8c-4ecf-8e3f-a162b1cea085",
      // dsig: "2b0c980851efc4e6a4beb2b2c88198f5f1c550ca6458729c103f0a29bb83bccc03",
      // dtag: "16429CAABFC5EDF69B340027DAF9B1ED42297D9F8C063A5A92B124C5D0E0CD774FAA42735DA948E526AD8CF4FFEE76812549A838594ED144CB170C4AF87CE75C1114",
      // payload:
      //   "4293017d3637bf159e9c39b39cf160458303af449e1a6f2a4c3fe363264ad1d827edd03134919f083105342bafce9d7b38426fbb06c2fc1ac37cf8f1d8334a87095b7abd880284afc615b9498e80a5f54e744a4d732d61915f2524003e2f109e",
      // tag: "516b918752db3dd739b11850fd9ad657686856b3635e145e3f2e4101446601b4",
    };

    Get_Playload.get_Playload_server(dataall)
      .then((res) => {

         console.log(res.data)

      })
      .catch((e) => {});
  };

  return (
    <>
      <div>
        {/* <!-- breadcrumb --> */}
        <div className="breadcrumb-header justify-content-between">
          <div className="left-content">
            <span className="main-content-title mg-b-0 mg-b-lg-1">
              Backlist
            </span>
          </div>
          <div className="justify-content-center mt-2">
            <Breadcrumb>
              <Breadcrumb.Item className="tx-15" href="#">
                Backlist
              </Breadcrumb.Item>
              <Breadcrumb.Item active aria-current="page">
                Backlist
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <Card className="mg-b-20">
          <Card.Body>
            <Col lg={12}>
              <Card className="custom-card">
                <Card.Body>
                  <div>
                    <h6 className="main-content-label mb-1">สมาชิก Backlist</h6>
                   
                  </div>
                  <div className="table-responsive fileexport pos-relative">
                    <ExportCSV />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

Personal_note.propTypes = {};

Personal_note.defaultProps = {};

export default Personal_note;
