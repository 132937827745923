import axios from "axios";
import config from "../../config/app.config"
import { setAuthorizationToken } from "../../helpers/axios";

setAuthorizationToken();

const SettingAPI = {
    
    getProfile: async (uid: number) => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/profile/${uid}`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    getdatasetting_fontend: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/admin/getdatasetting_fontend`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },

    chack_loginv2: async ()  => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/admin/chack_loginv2`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    
    
    listserver_mainss: async () => {
        
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/listserver_main`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    Resetpassword_ufa: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/Resetpassword_ufa`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    playgamecasinos: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/amb/playcasino_admin`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    create_gamelists: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/create_gamelist`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    create_gamelists1: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/create_gamelist1`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    gatdatamenu: async () => {
        
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/gatdatamenu_backend`,
            headers: {
                'Content-Type': 'application/json'
            }
           // data: data
            
        };

        return await axios(resData);
    },

    
    getdatasetting: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getdatasetting`,
            headers: {
                'Content-Type': 'application/json'
            }
           // data: data
            
        };

        return await axios(resData);
    },
  
    getdata_allwls: async () => {
        
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/getdata_allwls`,
            headers: {
                'Content-Type': 'application/json'
            }
           // data: data
            
        };

        return await axios(resData);
    },
    
    getdatasettingcon: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/admin/get_contact_create`,
            headers: {
                'Content-Type': 'application/json'
            }
           // data: data
            
        };

        return await axios(resData);
    },
    get_datapraners: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/admin/get_get_datapraners`,
            headers: {
                'Content-Type': 'application/json'
            }
           // data: data
            
        };

        return await axios(resData);
    },
    Postcreate_addmins: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/create_useradmin`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    getTran_scb: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getlogscb`,
            headers: {
                'Content-Type': 'application/json'
            }
           // data: data
            
        };

        return await axios(resData);
    },
   
    Postcreate_setting: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/Postcreate_setting`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    Postcreate_partner: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/create_partner`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    getnotifytion_all: async () => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/getnotifytion_all`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    datadashboard: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/admin/datadashboard`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    datadashboard_s: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/post_searchsummerryv1`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    getnotifytion_allupbyid: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/getnotifytion_allupbyid`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    getnotifytion_allup: async () => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/getnotifytion_updateall`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },tokenplay: async () => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/tokenplay`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    }
    ,update_linkgame:  async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/update_linkplaygame`,
            headers: {
                'Content-Type': 'application/json'
            },
            data:data
        };

        return await axios(resData);
    }
    ,get_linkplaygame: async () => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/get_linkplaygame`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    }
   
}
export default SettingAPI