import { useState, useRef, useEffect, useReducer } from "react";
import TimeFormat from "hh-mm-ss";
import Countdown from "react-countdown-now";
import "./index.css";
import Apiscb from "../api/Apiscb";
import AdminAPI from "../api/Admin";
import CountUp from "react-countup";
import config from "@Config/app.config";
import BasicPills from "./deposit_manual.tsx/BasicPills";
import BasicPillss from "./withdraw_manual/BasicPills";
import { Button, Modal } from "react-bootstrap";
import Setting_witauto from "./setting_witauto";

import Setting_witautos from "./autotranfer";
// //timer 1 minute counter
export function Middle(props:any) {
  const intervalRef = useRef<any>(null);

  const [timer, setTimer] = useState("00:00:00");
  useEffect(() => {
    function getTimeRemaining(endtime: any) {
      const total = Date.parse(endtime) - Date.parse(Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor(((total / 1000) * 60 * 60) % 60);

      return {
        total,
        hours,
        minutes,
        seconds,
      };
    }
    function startTimer(deadline: any) {
      let { total, hours, minutes, seconds } = getTimeRemaining(deadline);
      if (total >= 0) {
        setTimer(
          (hours ? hours : "0" + hours) +
            ":" +
            (minutes ? minutes : "0" + minutes) +
            ":" +
            (seconds ? seconds : "0" + seconds),
        );
      } else {
        clearInterval(intervalRef.current);
      }
    }

    function clearTimer(endtime: any) {
      setTimer("00:00:60");
      if (intervalRef.current) clearInterval(intervalRef.current);
      const id = setInterval(() => {
        startTimer(endtime);
      }, 1000);
      intervalRef.current = id;
    }
    function getDeadlineTime() {
      let deadline = new Date();
      deadline.setSeconds(deadline.getSeconds() + 60);
      return deadline;
    }

    clearTimer(getDeadlineTime());
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);
  return <>{timer}</>;
}

// // Time Counting From 0 to 3 min
// // const RESET_INTERVAL_S = 3; // 300s = 5m * 60s/m
const formatTime = (time: any) =>
  ` ${String(Math.floor(time / 60)).padStart(2, "0")} : ${String(
    time % 60,
  ).padStart(2, "0")}`;

const Timer = ({ time }: any) => {
  // const  RESET_INTERVAL_S = (time % RESET_INTERVAL_S);

  return (
    <>
      <div> 00 Days 00 :{formatTime(time)}</div>
    </>
  );
};
//timeup
const IntervalTimerFunctional = () => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime((t) => t + 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, []);

  return <Timer time={time} />;
};

export function Uptimer() {
  return <IntervalTimerFunctional />;
}
// //timer up end

// //Time Counting From 60 to 20
export const Timers = ({ min,dataoptions }: any) => {


 // console.log(dataoptions)
  useEffect(() => {
    getdatadis();
  }, []);

  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data_w: [],

      data_d: [],
    },
  );

  const [state1, setState1] = useReducer(
    (state1: any, newState1: any) => ({ ...state1, ...newState1 }),
    {
      data_w: [],
    },
  );

  const [state2, setState2] = useReducer(
    (state2: any, newState2: any) => ({ ...state2, ...newState2 }),
    {
      data: [],
    },
  );

  const [monnydis, setMonnydis] = useState(30);
  const [time_2, setTime_2] = useState(30);
  const [time_3, setTime_3] = useState(30);
  const [time_4, setTime_4] = useState(30);

  const [show_add, SetShow_add] = useState(false);
  const [show_wit, SetShow_wit] = useState(false);
  const [show_option, SetShow_option] = useState(false);
  const [show_option1, SetShow_option1] = useState(false);
  const [show_option3, SetShow_option3] = useState(false);

  const tran_true = (data: any) => {
    //  setStat_t({ data: data });
    SetShow_add(true);
  };
  const tran_true1 = (data: any) => {
    //  setStat_t({ data: data });
    SetShow_wit(true);
  };

  let viewtran_trueClose = (modal: any) => {
    SetShow_add(false);
  };
  let viewtran_optionClose1 = (modal: any) => {
    SetShow_option(false);
  };

  let viewtran_trueClose3 = (modal: any) => {
    SetShow_option3(false);
  };

  let setting_option = (modal: any) => {
    SetShow_option(true);
  };
  let setting_option1 = (modal: any) => {
    //console.log(modal)
    setState1({
      data: modal,
    });
    SetShow_option1(true);
  };
  let setting_option3 = (modal: any) => {
    setState2({
      data: modal,
    });
    SetShow_option3(true);
  };

  let viewtran_trueClose1 = (modal: any) => {
    SetShow_wit(false);
  };
  let viewtran_trueClose2 = (modal: any) => {
    SetShow_option1(false);
  };
  //   useEffect(() => {
  // console.log(state.data_d);
  let ds = {
    d: 1,
  };
  const getdatadis = () => {
    Apiscb.getdatabank_live(ds)
      .then((res) => {
        //console.log(res.data);
        if (res.data.success == true) {
          setState({
            data_w: res.data.bank_w,

            data_d: res.data.bank_d,
          });
          // setMonnydis(res.data.bank_w.data_1.time_crul)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const autotran_auto = () => {
  //   let ds = {
  //     dd: 1,
  //   };
  //   AdminAPI.autotran(ds)
  //     .then((res) => {
  //       // console.log(res.data);
  //       if (res.data.success == true) {
  //         // setMonnydis(res.data.bank_w.data_1.time_crul)
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const witauto = () => {
    // let ds = {
    //   dd: 1,
    // };
    // AdminAPI.disautoscb(ds)
    //   .then((res) => {
    //     //console.log(res.data);
    //     if (res.data.success == true) {
    //       // setMonnydis(res.data.bank_w.data_1.time_crul)
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  const witautokba = () => {
    // let ds = {
    //   dd: 1,
    // };
    // AdminAPI.disautokbank(ds)
    //   .then((res) => {
    //     // console.log(res.data);
    //     if (res.data.success == true) {
    //       // setMonnydis(res.data.bank_w.data_1.time_crul)
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  let mainTime: any;
  const secondsLeft = () => {
    const left = Number(min) * 60;
    return left;
  };
  let mainTime2: any;
  const secondsLeft2 = () => {
    const left = Number(min) * 60;
    return left;
  };

  let mainTime3: any;
  const secondsLeft3 = () => {
    const left = Number(min) * 60;
    return left;
  };
  let mainTime4: any;
  const secondsLeft4 = () => {
    const left = Number(min) * 60;
    return left;
  };
  const [seconds, setSeconds] = useState(secondsLeft());
  const [seconds2, setSeconds2] = useState(secondsLeft2());
  const [seconds3, setSeconds3] = useState(secondsLeft3());
  const [seconds4, setSeconds4] = useState(secondsLeft4());
  useEffect(() => {
    startTime();
    startTime2();

    return () => {
      stopTimer();
      stopTimer2();
    };
  });

  const startTime = () => {
    if (seconds && seconds > 0) {
      mainTime = setInterval(tick, 1000);
    }
  };
  const startTime2 = () => {
    if (seconds2 && seconds2 > 0) {
      mainTime2 = setInterval(tick2, 1000);
    }
  };
  const startTime3 = () => {
    if (seconds3 && seconds3 > 0) {
      mainTime = setInterval(tick3, 1000);
    }
  };
  const startTime4 = () => {
    if (seconds4 && seconds4 > 0) {
      mainTime = setInterval(tick4, 1000);
    }
  };
  const stopTimer = () => {
    clearInterval(mainTime);
  };
  const stopTimer2 = () => {
    clearInterval(mainTime2);
  };
  const stopTimer3 = () => {
    clearInterval(mainTime3);
  };
  const tick = () => {
    setSeconds((seconds: any) => {
      const updatedSeconds = seconds - 1;
      //getdatadis();
      //
      const updatedSeconds2 = seconds + 45;
      if (updatedSeconds < 1) {
        getdatadis();
       // autotran_auto();
        witauto();
        witautokba();
        return updatedSeconds2;
      }
      return updatedSeconds;
    });
  };

  const SetShow_option12 = () => {
    SetShow_option1(false)
  }
  const tick2 = () => {
    setSeconds2((seconds: any) => {
      const updatedSeconds = seconds - 1;
    //  getdatadis();
      const updatedSeconds2 = seconds + 58;
      if (updatedSeconds < 1) {
        return updatedSeconds2;
      }
      return updatedSeconds;
    });
  };
  const tick3 = () => {
    setSeconds3((seconds: any) => {
      const updatedSeconds = seconds - 1;
    //  getdatadis();
      const updatedSeconds2 = seconds + 54;
      if (updatedSeconds < 6) {
        return updatedSeconds2;
      }
      return updatedSeconds;
    });
  };
  const tick4 = () => {
    setSeconds((seconds: any) => {
      const updatedSeconds = seconds - 1;
     // getdatadis();
      const updatedSeconds2 = seconds + 50;
      if (updatedSeconds < 6) {
        return updatedSeconds2;
      }
      return updatedSeconds;
    });
  };
  //console.log(items)

  const display = TimeFormat.fromS(seconds, "hh:mm:ss");
  const display2 = TimeFormat.fromS(seconds2, "hh:mm:ss");
  const display3 = TimeFormat.fromS(seconds3, "hh:mm:ss");
  const display4 = TimeFormat.fromS(seconds4, "hh:mm:ss");
  const [h, m, s] = display.split(":");
  const [h2, m2, s2] = display2.split(":");
  const [h3, m3, s3] = display3.split(":");
  const [h4, m4, s4] = display4.split(":");
  return (
    <>
      <div className="row">
        <div className="col-md-6 col-xl-6">
          <div className="example  border-success text-success">
            <div className="">
              <div className="col-lg-12 col-md-6 mx-auto">
                {/* <span id="timer-countinbetween" className="h3"></span> */}
                <div className="col-lg-12 ">
                  <div className="card">
                    {state.data_d &&
                      state.data_d.map((items: any, index: any) => {
                        return (
                          <>
                            <h6>
                              {" "}
                              {items.option_b} ด่านที่ {items.id}
                            </h6>
                            <div className="row">
                              <div className="col-lg-8">
                                <div className="grid-container">
                                  <img
                                    src={`${config.apiURL}/storage/bank3/${items.from_b}.png`}
                                    alt=""
                                    width={50}
                                    className="ml-2"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="grid-container">
                                  <h4>
                                    <span className="h1 ms-auto mb-0">
                                      $
                                      <CountUp
                                        start={0}
                                        end={items?.baba?.toFixed(2)}
                                        duration={1}
                                      />
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-8">
                                <div className="grid-container">
                                  <h6 className="mt-2">{items?.accnum}</h6>
                                  <h6>{items?.name}</h6>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="grid-container">
                                  <h6 className="card-text">
                                    {" "}
                                    {h} : {m} : {s}
                                  </h6>
                                </div>
                              </div>
                            </div>

                            {items.tobank_stust != 1 && (
                              <>
                                <div className="row">
                                  <div className="d-flex flex-wrap gap-2">
                                    <button
                                      type="button"
                                      title="ยอดเงินคงเหลือ"
                                      className="btn btn-danger waves-effect waves-light flex-1 bg-primary op-1"
                                      onClick={() => setting_option1(items)}
                                    >
                                      โยกเงินออโต้
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                            {items.tobank_stust == 1 && (
                              <>
                                <div className="text-wrap">
                                  <Button
                                    variant=""
                                    className="btn btn-sm btn-secondary-gradient"
                                    onClick={() => setting_option1(items)}
                                  >
                                    ปิดการทำงาน
                                  </Button>
                                </div>
                              </>
                            )}

                            <hr />
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-xxl-6">
          <div className="example  border-secondary text-secondary">
            <div className="col-lg-12 ">
              <div className="card">
                {state.data_w &&
                  state.data_w.map((items: any, index: any) => {
                    return (
                      <>
                        <h6>
                          {" "}
                          {items.option_b} ด่านที่ {items.id}
                        </h6>
                        <div className="row">
                          <div className="col-lg-8">
                            <div className="grid-container">
                              <img
                               // src={`https://ufaapiv1.myshadownow.com/images/bank3/${items.from_b}.png`}
                                src={`${config.apiURL}/storage/bank3/${items.from_b}.png`}
                                alt=""
                                width={50}
                                className="ml-2"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="grid-container">
                              <h4>
                                <span className="h1 ms-auto mb-0">
                                  $
                                  <CountUp
                                    start={0}
                                    end={items.baba.toFixed(2)}
                                    duration={1}
                                  />
                                </span>
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-8">
                            <div className="grid-container">
                              <h6 className="mt-2">{items.accnum}</h6>
                              <h6>{items.name}</h6>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="grid-container">
                              <h6 className="card-text">
                                {" "}
                                {h} : {m} : {s}
                              </h6>
                            </div>
                          </div>
                        </div>

                        {items.autowit_status != 1 && (
                          <>
                            <div className="row">
                              <div className="d-flex flex-wrap gap-2">
                                <button
                                  type="button"
                                  title="ยอดเงินคงเหลือ"
                                  className="btn btn-danger waves-effect waves-light flex-1 bg-primary op-1"
                                  onClick={() => setting_option3(items)}
                                >
                                  ถอนเงินออโต้
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                        {items.autowit_status == 1 && (
                          <>
                            <div className="text-wrap">
                              <Button
                                variant=""
                                className="btn btn-sm btn-secondary-gradient"
                                onClick={() => setting_option3(items)}
                              >
                                ปิดการทำงาน
                              </Button>
                            </div>
                          </>
                        )}

                        <hr />
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <div className="example bg-success-transparent border-none text-light mt-2">
            <button
              type="button"
              className="btn btn-success btn-sm waves-effect waves-light btn-block w-50 mx-auto"
              onClick={tran_true}
            >
              ฝาก
            </button>
            <Modal show={show_add} size="lg">
              <Modal.Header
                closeButton
                onClick={() => {
                  viewtran_trueClose("show_add");
                }}
              >
                <Modal.Title>เพิมเครดิต</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <BasicPills  SetShow_wit={SetShow_add} />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    viewtran_trueClose("show_add");
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={show_wit} size="lg">
              <Modal.Header
                closeButton
                onClick={() => {
                  viewtran_trueClose1("show_wit");
                }}
              >
                <Modal.Title>เติมเครดิต</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <BasicPillss  SetShow_wit={SetShow_wit}/>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    viewtran_trueClose1("show_wit");
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            {/* ตั้งค่า โยกเงินออโต้ */}

            <Modal show={show_option1} size="lg">
              <Modal.Header
                closeButton
                onClick={() => {
                  viewtran_trueClose2("show_option1");
                }}
              >
                <Modal.Title>โยกเงินออโต้</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Setting_witauto data={state1.data} setUnLink={viewtran_trueClose2}  />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    viewtran_trueClose2("show_option1");
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            {/* จบตั้งค่า โยกเงินออโต้ */}

            {/* ตั้งค่าการฝากเงิน */}

            <Modal show={show_option3} size="lg">
              <Modal.Header
                closeButton
                onClick={() => {
                  viewtran_trueClose3("show_option3");
                }}
              >
                <Modal.Title>ถอนเงินออโต้</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Setting_witautos data={state2.data} setUnLink={SetShow_option12}  />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    viewtran_trueClose3("show_option3");
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            {/* จบตั้งค่าฝากเงิน */}
          </div>
        </div>
        <div className="col-6">
          <div className="example bg-secondary-transparent border-none text-secondary mt-2">
            <button
              type="button"
              className="btn btn-danger btn-sm waves-effect waves-light btn-block w-50 mx-auto"
              onClick={tran_true1}
            >
              ถอน
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
// //Time Counting From 60 to 20 end

// //Daycounter start
const AfterComplete = () => <span>You are good to go!</span>;

const rendering = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    return <AfterComplete />;
  } else {
    return (
      <div className="row">
        <div className="col-md-8 mx-auto">
          <div className="count-down row daycounter">
            <div className="col-xl-3 col-md-6 countdown  mb-6 mb-xl-0">
              <span className="days text-primary me-1">{days}</span>
              <span className="text-dark">Days</span>
            </div>
            <div className="col-xl-3 col-md-6 countdown mb-6 mb-xl-0">
              <span className="hours text-primary me-3 ">{hours}</span>

              <span className="text-dark me-2">Hrs</span>
            </div>
            <div className="col-xl-3 col-md-6 countdown  mb-6 mb-xl-0">
              <span className="minutes text-primary me-1">{minutes}</span>

              <span className="text-dark">Mins</span>
            </div>
            <div className="col-xl-3 col-md-6 countdown ">
              <span className="seconds text-primary me-1">{seconds}</span>

              <span className="text-dark">Secs</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export function DayCounter() {
  return <Countdown date={Date.now() + 3088800000} renderer={rendering} />;
}
// //Daycounter end

// //TimeCountingdaysLimit start

const AfterCompletion = () => <span>You are good to go!</span>;

const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    return <AfterCompletion />;
  } else {
    return (
      <span className="h3 text-white">
        <span>
          {days}Days {hours} Hours {minutes} Minutes {seconds} Seconds
        </span>
      </span>
    );
  }
};

export function TimeCountingdaysLimit() {
  return <Countdown date={Date.now() + 259200000} renderer={renderer} />;
}

//TimeCountingdaysLimit end
