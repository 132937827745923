const config = {
  apiURL: process.env.REACT_APP_API_URL,
  reCAPTCHA: process.env.reCAPTCHA,
  paginationPerPage: 10,
  totalSpriteColoumn:8,
  spriteImageWidth:1370,
  spriteImageHeight:200,
  url_scb:process.env.REACT_APP_API_URLSCB,
  bucketBaseUrl: 'https://battlelabdemo.s3.ap-southeast-1.amazonaws.com'
};
export default config;
 