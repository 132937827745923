import axios from "axios";
import config from "../config/app.config"
import { setAuthorizationToken } from "../helpers/axios";

setAuthorizationToken();
const ApiAuth = {

    Loginadmin: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/loginadmin`,
            data: data
        };

        return await axios(resData);
    },
    signUp: async (data: any) => {
        return await axios.post(`${config.apiURL}/api/v5/user/signup`, data)
    },
    verifyOtp: async (data: any) => {
        return await axios.post(`${config.apiURL}/api/v5/admin/verify-otp`, data)
    },
    resendOtp: async (data: any) => {
        return await axios.post(`${config.apiURL}/api/v5/user/resend-otp`, data);
    },
    forgotPassword: async (data: any) => {
        return await axios.post(`${config.apiURL}/api/v5/user/forgot-password`, data);
    },
    logout: async () => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/logout`,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        return await axios(resData);
    },
    payment_information: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/payment_information`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    getdatabacklist: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/admin/getdatabacklist`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    Cratepay: async (data: any)  => {
        
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/cratepay`,
            headers: {
                'Content-Type': 'application/json'
            },
            data:data
        };

        return await axios(resData);
    },
    
    deldatapays: async (data: any)  => {
        
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/deldatapays`,
            headers: {
                'Content-Type': 'application/json'
            },
            data:data
        };

        return await axios(resData);
    },
    addmemBer: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/create_usermember`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    d_setting_partner: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/d_setting_partners`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    
    getdataeditBank_code: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getdataeditBank_code`,
            headers: {
                'Content-Type': 'application/json'
            }
           // data: data
            
        };

        return await axios(resData);
    },
    getdata_mal: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/admin/getdata_mal`,
            headers: {
                'Content-Type': 'application/json'
            }
           // data: data
            
        };

        return await axios(resData);
    },
    post_datapartner_sumryv1: async (data:any) => {
        
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/post_datapartner_sumry`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
            
        };

        return await axios(resData);
    },
    cHack_partner_emBer: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/cHack_partner_emBer`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    cHack_partner_emBer1: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/cHack_partner_emBer1`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
}
export default ApiAuth