import axios from "axios";
import config from "../../config/app.config"
import { setAuthorizationToken } from "../../helpers/axios";

setAuthorizationToken();

const Apiufa = {
    // เรียก api แบบ Get และส่ง พารามิเตอร์
    Getbaba: async function ()  {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/agent_balance`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
   
    //เรียก api แบบ Get ธรรมดา
    getDatabankapi: async () => {
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/bankinfo`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
            
        };

        return await axios(resData);
    },
    get_Balancemember: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/get_Balancemember`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    update_tranallnone: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/update_tranallnone`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    
    Add_Balancemember: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/add_Balancemember`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    Add_Balancememberqr: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/Add_Balancememberqr`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    Add_Balancemember1: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/Add_Balancemember1`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    Add_Balancemember_SCB: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/Add_Balancemember_SCB`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    Add_Balancemember_KBANK: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/Add_Balancemember_KBANK`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    wit_Balancemember: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/wit_Balancemember`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    chack_qrapikbank_img: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/chack_qrapikbank_img`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    get_qrapikbank_img: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/dataqr_botline`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    getBacklists: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/getBacklists`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    update_transcbnone: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/update_transcbnone`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    update_trankbanknone: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/update_trankbanknone`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
}
export default Apiufa