import React from "react";
//import W_d_index from "./w_d_index";
import { useEffect, useState, useReducer } from "react";
import { logout, getUser } from "../../../config/authsave";
import BasicPills from "./BasicPills";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Container } from "reactstrap";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Modal,
  Progress,
} from "reactstrap";
import {
  ToastContainer,
  toast,
  Bounce,
  Slide,
  Flip,
  Zoom,
} from "react-toastify";

const Add_bank = (data:any) => {
    
  const [options, setOptions] = useState("");

  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
    },
  );
  const [stateauto, setStateauto] = useReducer(
    (stateauto: any, newStateauto: any) => ({ ...stateauto, ...newStateauto }),
    {
      data: [],
    },
  );

  
  const tranBank = (e: any) => {
    e.preventDefault();
    
    const postData2 = {
      accnum_tran: "accnum_tran",
      monny: "monny",
      bank_id: "bank_id",
      id: "id",
    };

  }
 
  return (
    <React.Fragment>
      
       <Row>
            <Col lg={12}>
              <Card>
              
                <BasicPills data={data.data} />
              </Card>
            </Col>
          </Row>


    </React.Fragment>
  );
};

export default Add_bank;
