import { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter,
  MemoryRouter,
  HashRouter,
  useNavigate,
} from "react-router-dom";
import { AuthContextProvider } from "@AuthContext/store";
import SearchProvider from "./context/SearchMatch";
import { Route, Routes } from "react-router-dom";
import React from "react";
import Auth from "@Auth/auth";
//import RenderRoute from '@Routes/_RenderRoute';
import SearchOppoProvider from "@Context/SearchOppoMatch";
import SearchTimerProvider from "@Context/SearchTimer";
import firebaseApp from "@Config/firebase.config";
//import { getMessaging, getToken } from "firebase/messaging";
import FriendChatProvider from "@Context/Friend/FriendChat";
import WalletProvider from "@Context/Wallet/Wallet";
import Dashboard3 from "@Components/Dashboard/Dashboard-3/Dashboard3";

import Dashboard_all from "@Components/Dashboard/Dashboard-1/index";

//import Apps from "@shade/layouts/App";
import Switcherapp from "@shade/layouts/Switcherapp";
import Custompages from "@shade//layouts/custompages";

//App
import Cards from "@Components/App/Cards/Cards";
import Contacts from "@Components/App/Contacts/Contacts";
import Filedetails from "@Components/App/File-details/Filedetails";
import Filemanager from "@Components/App/File-manager/Filemanager";
import Datapay from "@Components/Datapay/Dataplay";
import Datapayadd from "@Components/Datapay/create_member";

import Pro_partner from "@Components/Partner/pro_partner";

import Agent from "@Components/setting_web/Agent";

import Imagecompare from "@Components/App/Image-compare/Imagecompare";
import Notification from "@Components/App/Notification/Notification";
import Widgetnotification from "@Components/App/Widget-notification/Widget-notification";
import Treeview from "@Components/App/Treeview/Treeview";

import Calendar from "@Components/App/Calendar/Calendar";
import Filemanager1 from "@Components/App/File-manager1/Filemanager1";

import Rangeslider from "@Components/App/Range-slider/Rangeslider";

import Resulttoday from "@Components/Resulttoday/index";

import Aff from "@Components/Aff";
import wl from "@Components/Resulttoday/index";
//App end

// Bank

import Account_deposit_withdraw from "@Components/Bank/Account_deposit_withdraw/Account_deposit_withdraw";

import Normal_withdrawal from "@Components/Bank/Normal_withdrawal/Normal_withdrawal";
import Check_slip from "@Components/Bank/Check_slip/Check_slip";
import ProfileBank from "@Components/Bank/Account_deposit_withdraw/Profile/Profile";
import Personal_note from "@Components/Personal_note/personal_note/personal_note";

//Element
import Images from "@Components/Elements/Images/Images";
import Alerts from "@Components/Elements/Alerts/Alerts";
import Avatar from "@Components/Elements/Avatar/Avatar";
import Breadcrumbs from "@Components/Elements/Breadcrumbs/Breadcrumbs";
import Buttons from "@Components/Elements/Buttons/Buttons";
import Otp from "./Authentication/Otp";

import Badges from "@Components/Elements/Badge/Badge";
import Dropdowns from "@Components/Elements/Dropdown/Dropdown";
import Thumbnails from "@Components/Elements/Thumbnails/Thumbnails";
import ListGroups from "@Components/Elements/ListGroup/ListGroup";
import Mediaobject from "@Components/Elements/Mediaobject/Mediaobject";
import Navigation from "@Components/Elements/Navigation/Navigation";
import Pagination from "@Components/Elements/Pagination/Pagination";
import Popover from "@Components/Elements/Popover/Popover";
import Progress from "@Components/Elements/Progress/Progress";
import Spinners from "@Components/Elements/Spinners/Spinners";
import Typography from "@Components/Elements/Typography/Typography";
import Tooltip from "@Components/Elements/Tooltip/Tooltip";
import Toast from "@Components/Elements/Toast/Toast";
import Tabs from "@Components/Elements/Tabs/Tabs";
import Tags from "@Components/Elements/Tags/Tags";

//Element end
//advancedui
import Accordions from "@Components/AdvancedUI/Accordion/Accordion";
import Modals from "@Components/AdvancedUI/Modals/Modals";
import Rating from "@Components/AdvancedUI/Ratings/Ratings";
import Carousel from "@Components/AdvancedUI/Carousel/Carousel";
import Collapse from "@Components/AdvancedUI/Collapse/Collapse";
import Timeline from "@Components/AdvancedUI/Timeline/Timeline";
import Sweetalert from "@Components/AdvancedUI/Sweetalert/Sweetalert";
import Counters from "@Components/AdvancedUI/Counters/Counters";
import Blog from "@Components/AdvancedUI/Blog/Blog";
import Userlist from "@Components/AdvancedUI/Userlist/Userlist";
import Search from "@Components/AdvancedUI/Search/Search";
import Blogdetails from "@Components/AdvancedUI/Blog-details/Blogdetails";
import EditPost from "@Components/AdvancedUI/Edit-post/Editpost";
import Fileattachments from "@Components/AdvancedUI/FileAttachments/FileAttachments";
//advancedui
//charts
import Apexcharts from "@Components/Charts/Apexcharts/Apexcharts";
import ChartJS from "@Components/Charts/ChartJS/ChartJS";
import Widgets from "@Components/Widgets/Widgets";
import Echart from "@Components/Charts/Echart/Echart";
//charts
//pages
import SignUp from "@Components/Pages/Authentication/SignUp/SignUp";
import SignIn from "@Components/Pages/Authentication/SignIn/SignIn";
import ForgotPassword from "@Components/Pages/Authentication/ForgotPassword/ForgotPassword";
import Lockscreen from "@Components/Pages/Authentication/Lockscreen/Lockscreen";
import ResetPassword from "@Components/Pages/Authentication/ResetPassword/ResetPassword";
import UnderConstruction from "@Components/Pages/Authentication/UnderConstruction/UnderConstruction";
import Promotion from "@Components/promotion/index";
import Datasetgame from "@Components/setting_web/Fontend/dataset";
import Web from "@Components/setting_web/web";

import Staff from "@Components/Admin_setting/Staff/index";
import Setting_webfontend from "@Components/setting_web/Fontend/index";
import Setting_webbackend from "@Components/setting_web/Backend/index";
import Error404 from "@Components/Pages/Authentication/404Error/404Error";
import Error500 from "@Components/Pages/Authentication/500Error/500Error";
import Error501 from "@Components/Pages/Authentication/501Error/501Error";
import Cart from "@Components/Pages/Ecommerce/Cart/Cart";
import Checkout from "@Components/Pages/Ecommerce/Check-out/Check-out";

import ProductDetails from "@Components/Pages/Ecommerce/Product-Details/Product-Details";
import Shop from "@Components/Pages/Ecommerce/Shop/Shop";
import Wishlist from "@Components/Pages/Ecommerce/Wish-list/Wish-list";
import EmptyPage from "@Components/Pages/EmptyPage/EmptyPage";
import Faqs from "@Components/Pages/Faqs/Faqs";
import Gallery from "@Components/Pages/Gallery/Gallery";
import Invoice from "@Components/Pages/Invoice/Invoice";
import Chat from "@Components/Pages/Mail/Chat/Chat";
import Mail from "@Components/Pages/Mail/Mail/Mail";
import Mailsettings from "@Components/Pages/Mail/Mail-settings/Mail-settings";
import MailCompose from "@Components/Pages/Mail/MailCompose/MailCompose";
import Readmail from "@Components/Pages/Mail/Read-mail/Read-mail";
import Notificationslist from "@Components/Pages/Notifications-list/Notifications-list";
import Pricing from "@Components/Pages/Pricing/Pricing";
import Settings from "@Components/Pages/Settings/Settings";
import Todotask from "@Components/Pages/Todotask/Todotask";
import Aboutus from "@Components/Pages/Aboutus/Aboutus";
import Profile from "@Components/Pages/Profile/Profile";
import ProfileAdmin from "@Components/Pages/admin";

//pages
//Utilities
import Extras from "@Components/Utilities/Extras/Extras";
import Background from "@Components/Utilities/Background/Background";
import Border from "@Components/Utilities/Border/Border";
import Display from "@Components/Utilities/Display/Display";
import Width from "@Components/Utilities/Width/Width";
import Position from "@Components/Utilities/Position/Position";
import Padding from "@Components/Utilities/Padding/Padding";
import Margin from "@Components/Utilities/Margin/Margin";
import Flex from "@Components/Utilities/Flex/Flex";
import Height from "@Components/Utilities/Height/Height";

//Utilities end
//Icons
import FontAwesome from "@Components/Icons/FontAwesome/FontAwesome";
import MaterialIcons from "@Components/Icons/MaterialIcons/MaterialIcons";
import MaterialDesignIcons from "@Components/Icons/MaterialDesignIcons/MaterialDesignIcons";
import IonicIcons from "@Components/Icons/IonicIcons/IonicIcons";
import Pe7Icons from "@Components/Icons/Pe7Icons/Pe7Icons";
import SimpleLineIcons from "@Components/Icons/SimpleLineIcons/SimpleLineIcons";
import ThemifyIcons from "@Components/Icons/ThemifyIcons/ThemifyIcons";
import TypiconsIcons from "@Components/Icons/TypiconsIcons/TypiconsIcons";
import WeatherIcons from "@Components/Icons/WeatherIcons/WeatherIcons";
import BootstrapIcons from "@Components/Icons/BootstrapIcons/BootstrapIcons";
import FeatherIcons from "@Components/Icons/FeatherIcons/FeatherIcons";
import FlagIcons from "@Components/Icons/FlagIcons/FlagIcons";
import Maintenance from "./Authentication/Maintenance";
//Icons end
//Form
import FormElements from "@Components/Forms/FormElements/FormElements";
import FormEditor from "@Components/Forms/FormEditor/FormEditor";
import Formelementsizes from "@Components/Forms/Form-element-sizes/Form-element-sizes";
import FormLayouts from "@Components/Forms/FormLayouts/FormLayouts";
import FormInputSpinners from "@Components/Forms/FormInputSpinners/FormInputSpinners";
import FormValidation from "@Components/Forms/FormValidation/FormValidation";
import FormWizard from "@Components/Forms/FormWizard/FormWizard";
import AdvancedForms from "@Components/Forms/AdvancedForms/AdvancedForms";
import LeafletMaps from "@Components/Maps/LeafletMaps/LeafletMaps";
import VectorMaps from "@Components/Maps/VectorMaps/VectorMaps";
import DefaultTables from "@Components/Tables/DefaultTables/DefaultTables";
import DataTables from "@Components/Tables/DataTables/DataTables";

import Setting_partner from "@Components/Partner/setting_partner";
import Index_partner from "@Components/Partner/index";
import AuthLogin from "@Auth/Login";
//import RenderRoute from "@Routes/_RenderRoute";
import AuthSignup from "./Authentication/Signup";
import { getToken } from "@Config/authsave";
import Apps from "@shade/layouts/App";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
function App() {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   // const messaging = getToken();

  //   Apisetting.chack_loginv2()
  //     .then((res) => {
  //       if (res.data.succss == true) {
  //         setDataMmber(res.data.data);
  //       }

  //       // console.log(res.data)
  //     })
  //     .catch((e) => {

  //       navigate("/setting_web/setting_game")
  //     });

  //   // console.log(messaging)
  // });

  // const [dataMmber, setDataMmber]: any = useState("");

  // const AuthLogins = "";
  return (
    <HashRouter>
      {/* <BrowserRouter> */}
      {/*  <HashRouter> */}
      <Suspense fallback={loading}>
        <SearchProvider>
          <SearchOppoProvider>
            <FriendChatProvider>
              <WalletProvider>
                <Routes>
                  <Route path="/" element={<AuthLogin />}></Route>
                  <Route path="/verify-otp" element={<Otp />}></Route>
                  {/*  start app */}

                  <Route
                    path="/"
                    element={
                      <AuthContextProvider>
                        <Apps />
                      </AuthContextProvider>
                    }
                  >
                    <Route
                      path="/dashboard/dashboard"
                      element={<Dashboard_all />}
                    />
                    <Route
                      path="/promotion/promotion_setting"
                      element={<Promotion />}
                    />
                    <Route
                      path="/bank/normal_withdrawal"
                      element={<Normal_withdrawal />}
                    />
                    <Route
                      path="/bank/account_deposit_withdraw"
                      element={<Account_deposit_withdraw />}
                    />

                    <Route path="/bank/check_slip" element={<Check_slip />} />
                    <Route path="/datapay/datapay" element={<Datapay />} />
                    <Route
                      path="/note/personal_note"
                      element={<Personal_note />}
                    />
                    <Route path="/staff/setting_staff" element={<Staff />} />
                    <Route
                      path="/setting_web/setting_webfontend"
                      element={<Setting_webfontend />}
                    />
                    <Route path="/datapay/addmember" element={<Datapayadd />} />
                    <Route path="/setting_web/web" element={<Web />} />
                    <Route path="/setting_web/agent" element={<Agent />} />
                    <Route path="/setting_web/aff" element={<Aff />} />
                    <Route
                      path="/setting_partner"
                      element={<Setting_partner />}
                    />
                    <Route path="/Index_partner" element={<Index_partner />} />
                    <Route path="/resulttoday" element={<Resulttoday />} />
                    <Route path="/pages/settings" element={<Settings />} />

                    <Route
                      path="/setting_web/setting_game"
                      element={<Datasetgame />}
                    />
                    <Route path="/pages/profile" element={<Profile />} />
                  </Route>

                  <Route path="*" element={<Error404 />} />
                  {/* End app */}
                  {/* {<App />} */}
                </Routes>
                {/* <Routes>
                <AuthContextProvider>
                  
                </AuthContextProvider>
                </Routes> */}
                {/* <AuthLogin /> ? <Apps /> */}
              </WalletProvider>
            </FriendChatProvider>
          </SearchOppoProvider>
        </SearchProvider>
      </Suspense>
      {/* </HashRouter> */}
      {/*</BrowserRouter>  */}
    </HashRouter>
  );
}

export default App;
