import React, { useEffect, useMemo, useReducer, useState } from "react";
import config from "../../config/app.config";
import {
  Card,
  CardBody,
  Col,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Modal,
  Input,
  CardHeader,
  CardTitle,
} from "reactstrap";
import {
  Breadcrumb,
  Button,
  Form,
  FormGroup,
  InputGroup,
} from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import DatePicker from "react-datepicker";
import Moment from "moment";
import "moment/locale/th";
import { useSelector, useDispatch } from "react-redux";
import AdminAPI from "../api/Admin";
import FriendAPI from "../api/Friend";
import Info_tran from "./info_tran";
import TranAPI from "../api/TranAPI";
//import Breadcrumbs
import { socket } from "../../utils/socket";
import { Container } from "reactstrap";

import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
const Event_result = (data: any) => {
  useEffect(() => {
    getdatawitlive();
  }, []);
  // useEffect(() => {
  //     const id = setInterval(() => {
  //         getdatawitlive();
  //     }, 9500);
  //     return () => clearInterval(id);
  // }, []);
  const [modal_center_infotran5, setModal_center_infotran5] = useState("");

  const [modal_center_infotran2, setModal_center_infotran2] = useState("");

  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
      datas: [],
    }
  );

  const [state5, setState5] = useReducer(
    (state5: any, newState5: any) => ({ ...state5, ...newState5 }),
    {
      data: [],
    }
  );

  const [state6, setState6] = useReducer(
    (state6: any, newState6: any) => ({ ...state6, ...newState6 }),
    {
      data: [],
    }
  );
  const [tran_id, setTran_id] = useState("");

  let history = useNavigate();
  const gopro = (data: any) => {
    history("/pages/profile/", { state: { username: data } });
  };
  const data3 = state.data;

  const test = (data: any) => {
    //  Moment.locale('en');
    var dt = data;

    // console.log((<> {Moment(dt).format('MMMM do, yyyy H:mma')} </>) )
    return <> {Moment(dt).format("d MMM")} </>;
    //return(<View> {Moment(dt).format('d MMM')} </View>) //basically you can do all sorts of the formatting and others
  };
  const { rows } = useSelector((state: any) => ({
    rows: data3.row,
  }));
  const [modal_center_infotran, setModal_center_infotran] = useState(false);
  const [userss, setUserss] = useState("0");
  const [mo, setMo] = useState("0");
  const [modal_center_infotran1, setModal_center_infotran1] = useState(false);
  const [modal_center_infotran11, setModal_center_infotran11] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  useEffect(() => {
    socket.on("create_sendNotification_linebot", (data) => {
      getdatawitlive();
      testapi();
    });
  }, [socket]);

  const infotran_manual = (data: any) => {
    //setTran_id(data.id);

    // console.log(data)

    const postdata = {
      id: data.id,
    };
    TranAPI.get_TranAPIinfo(postdata)
      .then((res) => {
        //console.log(res.data)
        if (res.data.success) {
          setState5({
            data: res.data,
          });
          // setState4({
          //   data:res.data.data2
          // })
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setModal_center_infotran(!modal_center_infotran);
    removeBodyCss();
  };
  const cHack_c1 = (data: any) => {
    setModal_center_infotran11(!modal_center_infotran11);
    removeBodyCss();
  };

  const canceldata = (data: any) => {
    const postdata = {
      id: data.id,
      user_id: data.user_id,
    };
    //  setUserss(postdata.user_id)
    setStatepost({
      ...statepost,
      monny: data.amount,
      username: data.user_id,
    });
    //setStatepost({...statepost,username:data.user_id})

    // console.log(data);

    TranAPI.get_infobabamember(postdata)
      .then((res) => {
        //console.log(res.data)
        if (res.data.success) {
          let datap = {
            user: data.user_id,
            baba: res.data.data.data,
            baba_af: data.c_after,
            baba_b: data.c_before,
            time: data.createdAt,
          };

          setState6({
            data: datap,
          });
          setModal_center_infotran11(!modal_center_infotran11);
          removeBodyCss();
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [statepost, setStatepost] = useState({
    monny: "",
    username: userss,
    nodes: "",
  });
  const { monny, nodes, username } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  const Add_d = (e: any) => {
    e.preventDefault();

    const datapost = {
      username: username,
      monny: monny,
      node: nodes,
    };
    TranAPI.postremove_mony(datapost)
      .then((res) => {
        //console.log(res.data)
        if (res.data.success) {
          Swal.fire({
            icon: "success",
            title: "Oops...",
            text: res.data.message,
            // footer: '<a href="">Why do I have this issue?</a>'
          });
          setModal_center_infotran11(!modal_center_infotran11);
          removeBodyCss();
          getdatawitlive();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.data.message,
            // footer: '<a href="">Why do I have this issue?</a>'
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    //  console.log(datapost)
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endtDate, setEndDate] = useState(new Date());
  const [toa, setToa] = useState(0);

  const [stateposts, setStateposts] = useState({
    date_starts: startDate,
    date_end: endtDate,
  });
  const { date_starts, date_end } = stateposts;
  const inputvalues = (name: any) => (event: any) => {
    setStateposts({ ...stateposts, [name]: event.target.value });
  };

  // useEffect(() => {
  //   const id = setInterval(() => {
  //     getdatawitlive();
  //     testapi()
  //   }, 9500);
  //   return () => clearInterval(id);
  // }, []);

  useEffect(() => {



   

    getdatawitlive();
    testapi();
  }, []);

  const testapi = () => {};
  const getdatawitlive = () => {

    


    FriendAPI.getbank_chack_wit()
      .then((res) => {
        // console.log(res.data);

        if (res.data.success) {
          setToa(res.data.data2.length);
          setState({
            data: res.data.data2,
            datas: res.data.datasave,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Datasettp = (data: any) => {};

  async function s_date(data: any, data2: any) {
    //  e.preventDefault();

    const datapost = {
      date_starts: data,
      date_end: data2,
    };

    // console.log(datapost);
    FriendAPI.getbank_chack_wits_s(datapost)
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          setToa(res.data.data2.length);
          setState({
            data: res.data.data2,
            datas: res.data.datasave,
          });
        } else {
          if (res.data.datasave) {
            // setStartDate(res.data.datasave.getdata_start);
            // setEndDate(res.data.datasave.getdata_end)
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    //
  }

  const cHack_c = (data: any, data2: any) => {
    const postdata = {
      id: data.id,
      user_id: data.user_id,
    };
    TranAPI.get_infobabamember(postdata)
      .then((res) => {
        //console.log(res.data)
        if (res.data.success) {
          let datap = {
            user: data.user_id,
            baba: res.data.data.balance,
            baba_af: data.c_after,
            baba_b: data.c_before,
            time: data.createdAt,
          };

          setState6({
            data: datap,
          });
          setModal_center_infotran1(!modal_center_infotran1);
          removeBodyCss();
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // console.log(data);
  };
  //
  const Monthrange = () => {
    const handleChange = ([newStartDate, newEndDate]: any) => {
      setStartDate(newStartDate);
      setEndDate(newEndDate);

      if (newEndDate) {
        localStorage.setItem(
          "date_live",
          JSON.stringify({
            startdeys: newStartDate,
            enddeys: newEndDate,
          })
        );
        setEndDate(newEndDate);
        s_date(newStartDate, newEndDate);
      }
      //
      //chack_selt(newEndDate);
    };

    return (
      <DatePicker
        selected={startDate}
        onChange={handleChange}
        selectsRange
        startDate={startDate}
        endDate={endtDate}
        className="form-control"
        //showMonthYearPicker
      />
    );
  };
  const dispatch = useDispatch();

  
  const columns = [
    {
      name: "Username",
      id: "name",
      cell: (row: any) => (
        <>
          {row.user_id != "0" && (
            <>
              <div className={" -soft-" + row.color + " font-size-12"}>
                <button
                  onClick={() => gopro(row.user_id)}
                  className="btn btn-info "
                >
                  
                  {row.user_id}
                </button>
              </div>
            </>
          )}
          {row.user_id == "0" && (
            <>
              <p className="text-center">ไม่มีสมาชิก</p>
            </>
          )}
        </>
      ),
      sortable: true,
    },

    {
      name: "จำนวนเงิน",
      selector: (row: any) => row.amount,
      sortable: true,
    },
    {
      name: "ช่องทาง",
      selector: (row: any) => (
        <div className={" -soft-" + row.color + " font-size-12 text-center"}>
          {row.add_from == "netbank" && (
            <>
              <span className="tx-11 badge bg-dark text-white font-size-18 ">
                <i className="fa fa-university me-2"></i>
                NetBank
                {row.acc_from == "111111111111111111" ? <> ฝากโปร </> : <> </>}
              </span>

              <br />
            </>
          )}

          {row.add_from != "netbank" && (
            <>
              <span className="main-content-label tx-11 float-end badge bg-info-gradient text-white  mt-2"></span>

              {row.add_from != "netbank" ? (
                <span className="tx-11 badge bg-dark text-white font-size-12 ">
                  <i className="fa fa-user-plus me-2"></i>
                  <> ADMIN </>
                </span>
              ) : (
                <>Netbank</>
              )}
            </>
          )}

          {row.add_from != "โบนัส" && <></>}

          {row.type_option == "ฝาก" && <></>}
        </div>
      ),
      sortable: true,
    },
    {
      name: "ประเภท",
      selector: (row: any) => (
        <div className={" -soft-" + row.color + " font-size-12 "}>
          {row.type_option == "โบนัส" ? (
            <>
              {" "}
              <span className="tx-11 badge bg-success text-white font-size-18 text-center">
                {row.type_option}
              </span>
              <br />
              <span className="tx-11 badge bg-info text-white font-size-18 my-2 text-center">
                ฝากโบนัส
              </span>
            </>
          ) : (
            <></>
          )}

          {row.type_option == "ฝาก" && (
            <>
              <span className="badge bg-success-gradient me-1">
                {" "}
                {row.type_option}{" "}
              </span>
            </>
          )}
          {row.type_option == "ถอน" && (
            <>
              <span className="badge bg-danger-gradient me-1">
                {" "}
                {row.type_option}{" "}
              </span>
            </>
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: "สมาชิก",
      selector: (row: any) => (
        <div className={" -soft-" + row.color + " font-size-12 "}>
          <img
            src={`${config.apiURL}/storage/bank3/${row.bank_to}.png`}
            alt=""
            width={20}
          />{" "}
          {row?.Members?.accnum}
          <br /> {row.name_member}
        </div>
      ),
      sortable: true,
    },
    {
      name: "สถานะ",
      cell: (row: any) => (
        <div className={""}>
          {row.status == "rejected" && (
            <>
              <span className="tx-11 badge bg-danger text-white font-size-18 text-center">
                ยกเลิก
              </span>
            </>
          )}
          {row.status == "panding" && (
            <>
              <span className="tx-11 badge bg-danger text-white font-size-18 text-center">
                panding
              </span>
            </>
          )}
          {row.status == "successed" && (
            <>
              <span className="tx-11 badge bg-success text-white font-size-18 text-center">
                success
              </span>
            </>
          )}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },

    {
      name: "updated_at",
      selector: (row: any) => (
        <div className={" -soft-" + row.color + " font-size-12 text-center"}>
          <i className="mdi mdi-clock-outline"></i>{" "}
          {/* {Moment(row.updated_at).fromNow()}{" "} */}
          {Moment(row.updated_at).fromNow()} <br />
          <b className="text-info">{Moment(row.updated_at).format("LT")}</b>
          <br />
          <p className="">{Moment(row.updated_at).format("L")}</p>
        </div>
      ),
      sortable: true,
    },
    {
      name: "ทำรายการ",
      cell: (row: any) => (
        <div className={" -soft-" + row.color + " font-size-12 text-center"}>
          {/* {!row.add_from && (
            <span className="text-success">
              <i className="fas fa-crosshairs"></i> ไม่กำหนด
            </span>
          )} */}
          {/* {row.add_from == null && (
            <span className="text-info">
              <span className=" tx-11 float-end badge bg-secondary text-white  mt-2">
               
                <i className="fas fa-crosshairs"></i> {row.add_from}
              </span>
            </span>
          )} */}
          {row.add_from == "netbank" && (
            <span className="text-info">
              <span className=" tx-11 float-end badge bg-secondary text-white  mt-2">
                <i className="fa fa-university me-2"></i>
                {row.add_from}
              </span>
            </span>
          )}
          {row.add_from != "netbank" && (
            <span className="text-info">
              <span className=" tx-11 float-end badge bg-secondary text-white  mt-2">
                <i className="fa fa-user-plus me-2"></i>
                {row.add_from}
              </span>
            </span>
          )}
        </div>
      ),

      sortable: true,
    },
    {
      name: "หมายเหตุ",

      cell: (row: any) => (
        <div className={" -soft-" + row.color + " font-size-12 text-center"}>
          {/* {!row.add_from && (
            <span className="text-success">
              <i className="fas fa-crosshairs"></i> ไม่กำหนด
            </span>
          )} */}
          {row.nodere ? (
            <span className="text-info">
              <span className="float-end badge bg-secondary text-white  mt-2">
                {row.nodere}
              </span>
            </span>
          ) : (
            <>
              <span className=" tx-11 float-end badge bg-dark text-white  mt-2">
                ไม่ได้ระบุ
              </span>
            </>
          )}
        </div>
      ),

      sortable: true,
    },
    {
      name: "Action",
      //selector: (row: any) => row.user_ufa,
      cell: (row: any) => (
        <>
          <div className="btn-icon-list btn-list">
            <Button
              variant=""
              type="button"
              title="เช็คเครดิตลูกค้า"
              className="btn btn-icon btn-info me-1"
              //  onClick={() => cHack_c(row)}
            >
              <i className="fas fa-credit-card label-icon"></i>
            </Button>
            <Button
              variant=""
              type="button"
              title="รายละเอียดการทำรายการ"
              className="btn btn-icon  btn-warning me-1"
              onClick={() => {
                infotran_manual({
                  id: row.id,
                });
              }}
            >
              <i className="fas fa-exclamation-circle"></i>
            </Button>
           

            {/* <Button
              variant=""
              type="button"
              className="btn btn-icon  btn-warning me-1"
            >
              <i className="fe fe-star"></i>
            </Button>
            <Button
              variant=""
              type="button"
              className="btn btn-icon  btn-danger me-1 d-none d-sm-flex"
            >
              <i className="fe fe-trash"></i>
            </Button>
            <Button
              variant=""
              type="button"
              className="btn btn-icon  btn-purple me-1 d-none d-sm-flex"
            >
              <i className="fe fe-bar-chart"></i>
            </Button> */}
          </div>

          {/* <div className="d-flex flex-wrap gap-2 text-truncate">
            <button
              type="button"
              title="เช็คเครดิตลูกค้า"
              className="btn btn-primary waves-effect waves-light mt-2 "
              onClick={() => cHack_c(row)}
            >
              <i className="fas fa-credit-card label-icon" />
            </button>
            <button
              onClick={() => {
                infotran_manual({
                  id: row.id,
                });
              }}
              type="button"
              title="รายละเอียดการทำรายการ"
              className="btn btn-success waves-effect waves-light  mt-2 "
            >
              <i className="fas fa-exclamation-circle"></i>
            </button>

            {row.type_option == "ฝาก" && (
              <>
                <button
                  type="button"
                  title="ตัดเครดิต"
                  className="btn btn-danger waves-effect waves-light  mb-2 "
                  onClick={() => canceldata(row)}
                >
                  <i className="fas fa-times-circle" />
                </button>
              </>
            )}
          </div> */}
        </>
      ),
      // ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
    },
  ];
  return (
    <React.Fragment>
      <Card>
        <h4 className="my-5 p-1 text-center">
          รายการ ฝาก- ถอน ของวันที่{" "}
          <h2 className="text-center">
            {Moment(startDate).format("L")} -{Moment(endtDate).format("L")}{" "}
          </h2>
        </h4>

        <h3 className="text-center">
          มีรายการทั้งหมด <span className="text-success">{toa}</span> รายการ
        </h3>

        <CardBody>
          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  {/* <Col md="6">
                    <div className="mb-3 position-relative">
                      <h5 className="text-center">เริ่ม วัน/เวลา </h5>
                      <DatePicker
                        className="form-control"
                        name="date_starts"
                        selected={startDate}
                        onChange={(date: any) => s_date({ d1: date })}
                      />
                    </div>
                  </Col> */}
                  <Col md="6">
                    <div className="mb-3 position-relative">
                      <h5 className="text-center">ถึง วัน/เวลา</h5>
                      <Monthrange />
                    </div>
                  </Col>
                </div>
              </div>
            </div>
          </form>
          <DataTable columns={columns} data={state.data} pagination />
        </CardBody>
        {/* infotran */}
        <Modal
          className="modal-dialog modal-lg"
          size="sm"
          isOpen={modal_center_infotran}
          toggle={() => {
            infotran_manual(null);
          }}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">ข้อมูลรายการ </h5>
            <button
              onClick={() => {
                setModal_center_infotran(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <Info_tran data={state5.data} />
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                setModal_center_infotran(false);
              }}
              className="btn btn-secondary w-50 mx-auto"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>

        <Modal
          className="modal-dialog modal-lg"
          size="sm"
          isOpen={modal_center_infotran1}
          toggle={() => {
            // cHack_c(null);
          }}
          centered={true}
        >
          <div className="modal-header">
            <button
              onClick={() => {
                setModal_center_infotran1(false);
              }}
              type="button"
              className="close text-danger"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Card>
            {/* <CardHeader>
                            <CardTitle className="mb-0">About</CardTitle>
                        </CardHeader> */}

            <div className="col-lg-8 mx-auto">
              {state6.data && (
                <>
                  <div className="border border-success card">
                    <div className="card-header bg-transparent border-success">
                      <h3 className="text-center">รายการ เครดิต</h3>
                      <h5 className="text-center text-info">เครดิตคงเหลือ</h5>
                      <h5 className="my-0 text-success">
                        <i className="mdi mdi-check-all me-3" />
                        <h2 className="text-center text-success">
                          {state6.data.balance}
                        </h2>
                      </h5>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">
                        สมาชิก : {state6.data.username}
                      </h5>
                      <p className="card-text">
                        {state6.data.c_after}
                        {/* baba_b: data.c_before,
                        time: data.createdAt, */}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Card>

          <h3></h3>
          {/* <Info_tran data={state5.data} /> */}
          <div className="modal-footer">
            {/* <button
                            type="button"
                            onClick={() => {
                                setModal_center_infotran1(false);
                            }}
                            className="btn btn-secondary w-50 mx-auto"
                            data-dismiss="modal"
                        >
                            Close
                        </button> */}
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-lg"
          size="sm"
          isOpen={modal_center_infotran11}
          toggle={() => {
            cHack_c1(null);
          }}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0 text-center">ตัด เครดิต </h5>
            <button
              onClick={() => {
                setModal_center_infotran11(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Card>
            {/* <CardHeader>
                            <CardTitle className="mb-0">About</CardTitle>
                        </CardHeader> */}

            <div className="col-lg-8 mx-auto">
              <h5 className="text-center text-info">เครดิตคงเหลือ</h5>
              <h5 className="my-0 text-success">
                <h2 className="text-center text-success">{state6.data.baba}</h2>

                <form onSubmit={Add_d}>
                  <label
                    htmlFor="default-input"
                    className="form-label form-label"
                  >
                    จำนวนเครดิตที่จะตัด
                  </label>
                  <Input
                    className="form-control"
                    type="number"
                    value={monny}
                    onChange={inputvalue("monny")}
                    name="monny"
                    defaultValue=""
                    placeholder="ตัดเครดิต"
                  />
                  <label
                    htmlFor="default-input"
                    className="form-label form-label"
                  >
                    หมายเหตุ
                  </label>
                  <textarea
                    className="form-control mg-t-20"
                    placeholder="สาเหตุที่ตัดเครดิต (ไม่กำหนดก็ได้)"
                    value={nodes}
                    onChange={inputvalue("nodes")}
                    name="nodes"
                  ></textarea>
                  {/* <Input
                                className="form-control"
                                type="hidden"
                                value={username}
                                onChange={inputvalue("username")}
                                name="username"
                              
                            /> */}
                  <button
                    type="submit"
                    className="btn btn-danger btn-block w-100 mx-auto my-5 "
                    data-dismiss="modal"
                  >
                    ตัดเครดิต
                  </button>
                </form>
              </h5>
            </div>
          </Card>

          <div className="modal-footer"></div>
        </Modal>
        {/* end infotan */}
      </Card>
    </React.Fragment>
  );
};

export default Event_result;

export const Setdatanoti = (data: any) => {
  const [startDate, setStartDate] = useState(new Date());

  return <>555</>;
};
