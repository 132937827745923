import React from "react";
import { Button, Form, } from "react-bootstrap";
import Moment from "moment";
import "moment/locale/th";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import SummaryTable from "./SummaryTable";
export const COLUMNS:any = [
  { Header: "รหัส", accessor: "id" },
  { Header: "ชื่อผู้ใช้", accessor: "username" },
  
  { Header: "วันที่เริ่ม", accessor: "startDate" ,
    Cell: ({ row }: any) => {
      const { values } = row;
      return (
        <>
         {Moment(values.startDate).format('llll')}
        </>
      )
    }},
 
  { Header: "ประเภท WL", accessor: "type_wl" },
  { Header: "Com", accessor: "com" },
  { Header: "Stake", accessor: "stake" },
  { Header: "VL", accessor: "vl" },
  { Header: "WL", accessor: "wl" },
  { Header: "เงินรวม", accessor: "totel_monney" },
  {
    Header: "สถานะ WL",
    accessor: "status_wl",
    Cell: ({ row }: any) => {
      const { values } = row;
      return values.status_wl === 1 ? (
        <>
          <p className="badge bg-warning">รอดำเนินการ</p>{" "}
        </>
      ) : (
        <p className="badge bg-success">คืนยอดเสียแล้ว</p>
      );
    },
  },
  {
    Header: "สร้างเมื่อ",
    accessor: "created_at",
    Cell: ({ row }: any) => {
      const { values } = row;
      return (
        <>
         {Moment(values.created_at).format('llll')}
        </>
      )
    },
  },
];
export const WlTable = (props:any) => {
    const tableInstance = useTable(
      {
        columns: COLUMNS,
        data: props?.data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
  
    const {
      getTableProps, // table props from react-table
      headerGroups, // headerGroups, if your table has groupings
      getTableBodyProps, // table body props from react-table
      prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
      state,
      setGlobalFilter,
      page, // use, page or rows
      nextPage,
      previousPage,
      canNextPage,
      canPreviousPage,
      pageOptions,
      gotoPage,
      pageCount,
      setPageSize,
    }:any = tableInstance;
  
    const { globalFilter, pageIndex, pageSize } = state;
  
    return (
      <>
        <div className="d-flex">
          <select
            className=" mb-4 selectpage border me-1"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
        <table {...getTableProps()} className="table table-hover mb-0">
          <thead>
            {headerGroups.map((headerGroup:any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column:any) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={column.className}
                  >
                    <span className="tabletitle">{column.render("Header")}</span>
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="fa fa-angle-down"></i>
                        ) : (
                          <i className="fa fa-angle-up"></i>
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row:any) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell:any) => {
                    return (
                      <td className="borderrigth" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <SummaryTable data={props?.data}/>
        <div className="d-block d-sm-flex mt-4 ">
          <span className="">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <span className="ms-sm-auto ">
            <Button
              variant=""
              className="btn-default tablebutton me-2 d-sm-inline d-block my-1"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {" Previous "}
            </Button>
            <Button
              variant=""
              className="btn-default tablebutton me-2 my-1"
              onClick={() => {
                previousPage();
              }}
              disabled={!canPreviousPage}
            >
              {" << "}
            </Button>
            <Button
              variant=""
              className="btn-default tablebutton me-2 my-1"
              onClick={() => {
                previousPage();
              }}
              disabled={!canPreviousPage}
            >
              {" < "}
            </Button>
            <Button
              variant=""
              className="btn-default tablebutton me-2 my-1"
              onClick={() => {
                nextPage();
              }}
              disabled={!canNextPage}
            >
              {" > "}
            </Button>
            <Button
              variant=""
              className="btn-default tablebutton me-2 my-1"
              onClick={() => {
                nextPage();
              }}
              disabled={!canNextPage}
            >
              {" >> "}
            </Button>
            <Button
              variant=""
              className="btn-default tablebutton me-2 d-sm-inline d-block my-1"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {" Next "}
            </Button>
           
          </span>
        </div>
      </>
    );
  };
  const GlobalFilter = ({ filter, setFilter }:any) => {
    return (
      <span className="d-flex ms-auto">
        <Form.Control
          value={filter || ""}
          onChange={(e) => setFilter(e.target.value)}
          className="form-control mb-4"
          placeholder="Search..."
        />
      </span>
    );
  };