import React, { useState, useReducer, useEffect } from "react";
import { CardBody, NavItem, TabContent, TabPane, NavLink } from "reactstrap";
import "./index.css";
import classnames from "classnames";
import { Link } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import AdminAPI from "../../api/Admin";
import Apiufa from "../../api/AgentUfaapi";
import {
  Row,
  Col,
  Card,
  Button,
  Label,
  Input,
  CardHeader,
  FormGroup,
  InputGroup,
} from "reactstrap";

const BasicPillss = ({data,SetShow_wit}: any) => {
  const [activeTab, setactiveTab] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState();
  function toggleTab(tab: any) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab);
      }
    }
  }
  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
    },
  );
  const [state2, setState2] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
    },
  );

  useEffect(() => {
    getGames();
  }, []);
  useEffect(() => {
    getbank();
  }, []);

  // console.log(state.data)
  const [appdow, setAppdow] = useState(false);
  const [appdow2, setAppdow2] = useState(false);
  const [appdowerror, setAppdowerror] = useState(false);
  const [appdowerrorm, setAppdowerrorm] = useState("");

  const getGames = () => {
    AdminAPI.Getallmember()
      .then((res) => {
        // console.log(res.data);

        if (res.data.success) {
          // console.log(res.data.daauser)
          setState({
            data: res.data.users,
          });
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getbank = () => {
    AdminAPI.getBank_withdraw()
      .then((res) => {
        // console.log(res.data);

        if (res.data.success) {
          // console.log(res.data.daauser)
          setState2({
            data: res.data.data,
          });
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [validation, setValidation] = useState<any>({
    fnm: null,
    lnm: null,
    unm: null,
    city: null,
    stateV: null,
  });

  const optionList = state.data.map((item: any, index: number) => {
    return {
      value: item.user_ufa,
      label:
        item.user_ufa +
        " เบอร์โทร : " +
        item.username +
        "ชื่อสมาชิก : " +
        item.name,
    };
  });
  function handleSubmit(e: any) {
    e.preventDefault();
    const modifiedV = { ...validation };

    //Get validation ID
    let firstName: any = document.getElementById("validationTooltip01");
    let lastName: any = document.getElementById("validationTooltip02");
    let userName: any = document.getElementById("validationTooltipUsername");
    let cityUser: any = document.getElementById("validationTooltip03");
    let stateUser: any = document.getElementById("validationTooltip04");

    var fnm = firstName.value;
    var lnm = lastName.value;
    var unm = userName.value;
    var city = cityUser.value;
    var stateV = stateUser.value;

    if (fnm === "") {
      modifiedV["fnm"] = false;
    } else {
      modifiedV["fnm"] = true;
    }

    if (lnm === "") {
      modifiedV["lnm"] = false;
    } else {
      modifiedV["lnm"] = true;
    }

    if (unm === "") {
      modifiedV["unm"] = false;
    } else {
      modifiedV["unm"] = true;
    }

    if (city === "") {
      modifiedV["city"] = false;
    } else {
      modifiedV["city"] = true;
    }

    if (stateV === "") {
      modifiedV["stateV"] = false;
    } else {
      modifiedV["stateV"] = true;
    }
    setValidation(modifiedV);
  }

  function handleSelect(data: any) {
    setSelectedOptions(data);
  }
  const onChangeValidation = (fieldName: any, value: any) => {
    const modifiedV = { ...validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    setValidation(modifiedV);
  };

  const [statepost, setStatepost] = useState({
    id_from: "",
    user_to: "",
    date_time: "",
    monny: "",
    nodere: "",
  });
  const { id_from, user_to, monny, date_time, nodere } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  const Add_d = (e: any) => {
    e.preventDefault();
    //   let firstName: any = user_to;
    const datapost = {
      user_to: selectedOptions,
      id_from: id_from,
      date_time: date_time,
      monny: monny,
      nodere: nodere,
    };
    // console.log(datapost);

    if (datapost.id_from == "") {
      setAppdowerror(true);
      setAppdowerrorm("กรุณาเลือกบัญชี ที่ใช้ถอน!");
    } else if (datapost.monny == "") {
      // setOpenck(false);
      setAppdowerror(true);
      setAppdowerrorm("กรุณา ระบุจำนวนเครดิต ที่ใช้ ถอน!");
     
    } else if (datapost.user_to == undefined) {
      // setOpenck(false);
      setAppdowerror(true);
      setAppdowerrorm("กรุณาเลือก สมาชิก");
     
    } else {
      setAppdow2(true);

      // console.log(id_from)
      //console.log(datapost);
      Apiufa.wit_Balancemember(datapost)
        .then((res) => {
          // console.log(res.data);

          if (res.data.success) {
            setAppdow(true);
            setAppdow2(false);
            setTimeout(function () {
              SetShow_wit(false)
            }, 3500);
          } else {
            Swal.fire({
              icon: "error",
              title: "ยอดเครดิตสมาชิกไม่พอ ที่ใช้ถอน!",
              text: res.data.message,
            }).then((result) => {
              if (result.isConfirmed) {

                
                //  setAppdow(true);
                setAppdow2(false);
                //
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <React.Fragment>
      <CardBody>
        <div id="basic-pills-wizard" className="twitter-bs-wizard">
          {!appdow && (
            <>
              <h4 className="text-center text-danger my-5">บัญชี ถอนเครดิต</h4>
            </>
          )}
          {appdow && (
            <>
              <div className="col-sm-12">
                <div
                  className="px-4 mb-0 text-center alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                  />
                  <i className="mdi mdi-check-all d-block display-4 mt-2 mb-3 text-success" />
                  <h5 className="text-success">Success</h5>
                  <p>ทำรายการสำเร็จ</p>
                </div>
              </div>
            </>
          )}
          {appdowerror && (
            <>
              <div className="col-sm-12">
                <div
                  className="px-4 mb-0 text-center alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                  />

                  <h5 className="text-success">Error</h5>
                  <p> {appdowerrorm}</p>
                </div>
              </div>
            </>
          )}
          <TabContent
            className="twitter-bs-wizard-tab-content"
            activeTab={activeTab}
          >
            <TabPane tabId={1}>
              <form onSubmit={Add_d}>
                <div className="text-center mb-4">
                  <h5>เลือกบัญชีที่จะใช้ ถอนเครดิต</h5>
                  <select
                    className="form-select "
                    name="id_from"
                    value={id_from}
                    onChange={inputvalue("id_from")}
                  >
                    <option>เลือกบัญชีที่จะใช้ ถอนเครดิต</option>
                    {state2.data &&
                      state2.data.map((item: any, index: any) => {
                        return (
                          <>
                            <option value={item.id}>
                              {item.accnum}
                              {item.name}{" "}
                              {item.level == 1 && (
                                <span className="text-success">
                                  ประเภท : {item.option_b} : หลัก
                                </span>
                              )}{" "}
                              {item.level == 2 && (
                                <span className="text-success">สำรอง</span>
                              )}
                            </option>
                          </>
                        );
                      })}
                  </select>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3 text-success">
                      <h5 className="text-center">เลือกสมาชิก</h5>
                      <Select
                        options={optionList}
                        name="user_to"
                        placeholder="ระบุสมาชิก ที่ต้องการจะ ถอนเครดิต"
                        value={selectedOptions}
                        onChange={handleSelect}
                      />
                    </div>
                  </div>
                </div>
                <Col md="12">
                  <div className="mb-3 position-relative">
                    <h5 className="text-center">วัน/เวลา</h5>
                    <Input
                      className="form-control"
                      type="datetime-local"
                      value={date_time}
                      onChange={inputvalue("date_time")}
                      name="date_time"
                      defaultValue=""
                      id="example-datetime-local-input"
                    />
                  </div>
                </Col>
                <Col md="12">
                  <div className="mb-3 position-relative">
                    <h5 className="text-center">จำนวนเครดิต</h5>
                    <Input
                      type="number"
                      name="monny"
                      className="form-control"
                      id="validationTooltip02"
                      value={monny}
                      onChange={inputvalue("monny")}
                      placeholder="จำนวนที่ต้องการ ถอน"
                      valid={validation["lnm"] === true}
                      invalid={
                        validation["lnm"] !== true && validation["lnm"] !== null
                      }
                    />
                    <div
                      className={
                        validation["lnm"] === true
                          ? "valid-tooltip"
                          : "invalid-tooltip"
                      }
                      id="validate2"
                    >
                      {validation["lnm"] === true
                        ? "สามารถทำรายการ ได้"
                        : "กรุณาระบุ จำนวนที่ต้องการ ถอน"}
                    </div>
                  </div>
                </Col>
                <Col md="12">
                  <div className="mb-3 position-relative">
                    <h5 className="text-center">***หมายเหตุ</h5>
                    <Input
                      type="text"
                      className="form-control"
                      name="nodere"
                      value={nodere}
                      onChange={inputvalue("nodere")}
                      id="validationTooltip02"
                      placeholder="หมายเหตุ"
                    />
                  </div>
                </Col>

                {!appdow2 && (
                  <button className="btn btn-primary waves-effect waves-light my-5">
                    ดำเนินการฝากเงิน
                  </button>
                )}
                {appdow2 && (
                  <button
                    type="button"
                    className="btn btn-dark waves-effect waves-light"
                  >
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2  "></i>{" "}
                    กำลังดำเนินการเติมเงิน....
                  </button>
                )}
              </form>
            </TabPane>
          </TabContent>
        </div>
      </CardBody>
    </React.Fragment>
  );
};

export default BasicPillss;
