import React from "react";
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Row,
    UncontrolledAlert,
} from "reactstrap";

//import Breadcrumbs

import { Container } from "react-bootstrap";
import Moment from "moment";
const Transfer_information = (data: any) => {
    console.log(data);

    return (
        <React.Fragment>
            <div className="table-responsive text-center">
                <table className="table table-bordered mb-0 table-centered">
                    <tbody>
                        <tr>
                            <td style={{ width: "20%" }}>
                                <div className="py-3">
                                    <h5 className="font-size-16 mb-0">
                                        รายการเดินบัญชี ทั้งหมด{" "}
                                        <span className="text-success">
                                            {data?.datatran?.totalOfRecord}
                                        </span>{" "}
                                        รายการ
                                    </h5>
                                </div>
                            </td>
                        </tr>

                        {data?.datatran?.data?.data?.txnList &&
                            data?.datatran?.data?.data?.txnList?.map((item:any, index:any) => {
                                return (
                                    <>
                                        {item.txnCode.description ==
                                            "ฝากเงิน" && (
                                            <tr className="">
                                                <td className="">
                                                    <div className="">
                                                        <span>
                                                            {
                                                                item.txnCode
                                                                    .description
                                                            }
                                                            <span className="text-success">
                                                                {item.txnAmount}{" "}
                                                                บาท
                                                            </span>
                                                            ช่องทาง{" "}
                                                            {item.toAccountNo}{" "}
                                                            ช่องทาง :{" "}
                                                            {
                                                                item.txnChannel
                                                                    .description
                                                            }{" "}
                                                            {item.txnRemark}{" "}
                                                            เวลา :{" "}
                                                            {item.txnDateTime}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        <>
                                            {item.txnCode.description ==
                                                "ถอนเงิน" && (
                                                <tr className="">
                                                    <td>
                                                        <div>
                                                            <span>
                                                                {
                                                                    item.txnCode
                                                                        .description
                                                                }
                                                                <span className="text-danger">
                                                                    {
                                                                        item.txnAmount
                                                                    }{" "}
                                                                    บาท
                                                                </span>
                                                                ไปยัง{" "}
                                                                {
                                                                    item.toAccountNo
                                                                }{" "}
                                                                ช่องทาง :{" "}
                                                                {
                                                                    item
                                                                        .txnChannel
                                                                        .description
                                                                }{" "}
                                                                {item.txnRemark}{" "}
                                                                เวลา :{" "}
                                                                {
                                                                    item.txnDateTime
                                                                }
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    </>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            <div className="modal-body"></div>
        </React.Fragment>
    );
};

export default Transfer_information;
