import React, { useState } from "react";
import { useReducer, useEffect } from "react";
import {
  Breadcrumb,
  Card,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Tab,
  Tabs, Modal, Alert
} from "react-bootstrap";
import classnames from "classnames";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Progress,
} from "reactstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiAuth from "../../api/db";
import Loader, { LoaderType } from "react-loaders";
const MyVerticallyCenteredModal = (props: any, pp: any) => {
  // const location = useLocation();
  //const game: any = location.state;
  useEffect(() => {
    getdataeditBank_code();
  }, []);

  const getdataeditBank_code = () => {
    ApiAuth.getdataeditBank_code()
      .then((res) => {
        // 

        if (res.data.success) {
          setStatebank({
            data: res.data.data,
          });
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const [openck, setOpenck] = useState(false);
  const [openkbank, setOpenkbank] = useState(false);
  const [openerror, setOpenerror] = useState(false);
  const [openerrormsg, setOpenerrormsg] = useState("");


  const [statebank, setStatebank] = useReducer(
    (statebank: any, newStatebank: any) => ({ ...statebank, ...newStatebank }),
    {
      data: [],
    },
  );


  const [activeTab, setactiveTab] = useState(1);
  const [progressbarvalue, setprogressbarvalue] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);

  function toggleTab(tab: any, value: any) {
    if (activeTab !== tab) {
      var modifiedSteps: any = [...passedSteps, tab];

      if (tab >= 1 && tab <= 6) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setprogressbarvalue(value);
  }

  const [statepost, setStatepost] = useState({
    username: "",
    password: "",
    password2: "",
    line: "",
    accnum: "",
    bank: "",
    name: "",
    aff: "",
  });

  const { username, password, password2, line, accnum, bank, name, aff } =
    statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  const addBank = (e: any) => {
    e.preventDefault();
    const postData = {
      username: username,
      password: password,
      password2: password2,
      line: line,
      accnum: accnum,
      bank: bank,
      name: name,
      aff: aff,
    };
    setOpenck(true);
    if (postData.password !== postData.password2) {
      setOpenck(false);
      setOpenerror(true)
      setOpenerrormsg("password ไม่ตรงกัน")

    } else if (postData.accnum == "") {
      setOpenck(false);
      setOpenerror(true)
      setOpenerrormsg("กรุณาระบุ เลขบัญชี")

    } else if (postData.bank == "") {
      setOpenck(false);
      setOpenerror(true)
      setOpenerrormsg("กรุณาระบุ บัญชี")

    } else if (postData.name == "") {

      setOpenck(false);
      setOpenerror(true)
      setOpenerrormsg("กรุณาระบุ ชื่อ-นามสกุล")

    } else {
      ApiAuth.addmemBer(postData)
        .then((res) => {
          if (res.data.success) {
            // 

            //  console.log(res.data)

            toast.success(`ทำรายการสำเร็จ ${res.data.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setOpenck(false);
            setTimeout(function () {
             // navigate("/datapay/datapay/");
             window.location.reload()
            }, 5500);
            //onclick(props.onHide)

          } else {
            setOpenck(false);
            setOpenerror(true)
            setOpenerrormsg(`เกิดข้อผิดพลาด ${res.data.message}`)

          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>เพิ่มรายการ</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {openerror && <>
          <div role="alert" className="fade alert fade show alert-dismissible alert-danger alert show">
            {/* <span className="alert-inner--text text-center"><strong className="text-center">ERROR เกิดข้อผิดพลาด!</strong> </span> */}
            <h4 className="text-center"><strong className="text-center">ERROR เกิดข้อผิดพลาด!</strong></h4>
            <p className="text-center">{openerrormsg}</p>
          </div>
        </>}
        <form className="" onSubmit={addBank}>


          <div className="col-md-12">
            <div className="mb-3">
              <label
                htmlFor="exampleAddress"
                className="form-label"
              >
                Username
              </label>
              <input
                name="username"
                id="username"
                placeholder="Username"
                type="text"
                value={username}
                onChange={inputvalue("username")}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label
                htmlFor="exampleAddress"
                className="form-label"
              >
                รหัสผ่าน
              </label>
              <input
                name="password"
                id="password"
                placeholder="รหัสผ่าน"
                type="password"
                value={password}
                onChange={inputvalue("password")}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label
                htmlFor="exampleAddress"
                className="form-label"
              >
                รหัสผ่าน
              </label>
              <input
                name="password2"
                id="password2"
                placeholder="ยืนยัน รหัสผ่าน"
                type="password"
                value={password2}
                onChange={inputvalue("password2")}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                เลขบัญชี
              </label>
              <input
                name="accnum"
                id="accnum"
                placeholder="เลขบัญชี"
                type="text"
                className="form-control"
                value={accnum}
                onChange={inputvalue("accnum")}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                ชื่อ-นามสกุล
              </label>
              <input
                name="name"
                id="name"
                placeholder="ชื่อ-นามสกุล"
                type="text"
                value={name}
                onChange={inputvalue("name")}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                แนะนำโดย
              </label>
              <input
                name="aff"
                id="aff"
                placeholder="แนะนำโดย"
                type="text"
                value={aff}
                onChange={inputvalue("aff")}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                ธนาคาร
              </label>
              <select
                className="form-control form-control-sm"
                onChange={inputvalue("bank")}
                name="bank"
              >
                {statebank.data.map(
                  (item: any, index: number) => {
                    return (
                      <>
                        <option value={item.bank_id}>
                          {item.bank_name}
                        </option>
                      </>
                    );
                  },
                )}
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                ID line
              </label>
              <input
                name="line"
                id="line"
                placeholder="ID LINE"
                type="text"
                value={line}
                onChange={inputvalue("line")}
                className="form-control"
              />
            </div>
            <button
              type="submit"
              className={`mb-2 me-2 btn-icon btn-shadow btn-dashed btn btn-outline-primary w-100 ${openck && "disabled"
                }`}
            >
              {openck && (
                <Loader active type="ball-spin-fade-loader" />
              )}
              {openck && <> Lodding.....</>}
              {!openck && <>เพิ่มสมาชิกใหม่</>}
            </button>
          </div>

        </form>
      </Modal.Body>
      <ToastContainer />
      <Modal.Footer>
        <Button className="modal-effect  d-grid mb-3" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function VerticallyCenteredModal(pp: any) {
  const [modalShow, setModalShow] = React.useState(false);




  return (
    <>
      <Button
        className="btn ripple btn-primary"
        variant="danger"
        onClick={() => setModalShow(true)}
      >
        เพิ่มสมาชิก
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        sp={() => pp}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
