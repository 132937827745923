import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Row,
} from "react-bootstrap";
import Data_setting2 from "./data_setting2";
import Data_setting_wl from "./data_setting_wl";
import { Link } from "react-router-dom";
import SettingAPI from "../api/SettingAPI";
import { WlTable } from "./teble_wl";
import { AffTable } from "./teble_aff";
//import Apisetting from "../../../api/Apisetting";

const Aff = () => {
  useEffect(() => {
    getapisetting();
    getdataaff();
  }, []);
  const [statepost, setStatepost]: any = useState("");
 // console.log(statepost);
  const getapisetting = () => {
    SettingAPI.getdatasetting()
      .then((res) => {
        //console.log(res.data)
        //
        setState({
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getdataaff = () => {
    SettingAPI.getdata_allwls()
      .then((res) => {
        if (res.data.success == true) {
          setStatepost(res.data);
        }
        //console.log(res.data)
        //
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
    }
  );

  // console.log(state.data.perfix)

  const [show, setShow] = React.useState(true);
  let [isFirstCollapsed, setisFirstCollapsed] = React.useState(true);

  const [remove, Setremove] = React.useState(true);
  const values = [true];
  const [fullscreen, setFullscreen] = React.useState<any>(true);
  const [shows, setShows] = React.useState(false);

  function handleShow(breakpoint: any) {
    setFullscreen(breakpoint);
    setShows(true);
  }
  let [isFirstCollapseds, setisFirstCollapseds] = React.useState(true);
  let firsts = () => {
    if (isFirstCollapseds === false) {
      setisFirstCollapseds(true);
    } else if (isFirstCollapseds === true) {
      setisFirstCollapseds(false);
    }
  };

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            ตั้งค่า แนะนำเพื่อน + ค่าคอมมิชชั้น
          </span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb>
            <Breadcrumb.Item className="tx-15" href="#">
              ตั้งค่า แนะนำเพื่อน
            </Breadcrumb.Item>
            <Breadcrumb.Item active aria-current="page">
              ตั้งค่า ค่าคอมมิชชั้น
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}

      <Row className=" row-sm">
        <Col lg={6} xl={6} md={12} sm={12}>
          <Card className="box-shadow-0">
            <Card.Header>
              <h4 className="card-title mb-1">แนะนำเพื่อน</h4>
              <em className="mb-2 text-warning">
                <i className="fas fa-exclamation-circle"></i> % ที่จะให้เพื่อน
                คือยอด แพ้ชนะการการเล่นของเพื่อน
              </em>
            </Card.Header>
            <Card.Body className=" pt-0">
              {state.data.length != 0 && (
                <>
                  <Data_setting2 data={state.data} />
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} xl={6} md={12} sm={12}>
          <Card className="box-shadow-0">
            <Card.Header>
              <h4 className="card-title mb-1">ค่าคอมมิชชั้น</h4>
              <em className="mb-2 text-warning">
                <i className="fas fa-exclamation-circle"></i> % ที่จะ ให้
                ค่าคอมมิชชั้น คือยอด แพ้ชนะการการเล่น
              </em>
            </Card.Header>
            <Card.Body className=" pt-0">
              {state.data.length != 0 && (
                <>
                  <Data_setting_wl data={state.data} />
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card className="mg-b-20">
        <Card.Body>
          <Col lg={12}>
            <Card className="custom-card">
              <Card.Body>
                <div className="">
                  <h6 className="main-content-label mb-1"></h6>
                  {statepost.data_wl && <WlTable data={statepost.data_wl} />}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Card.Body>
      </Card>
      <Card className="mg-b-20">
        <Card.Body>
          <Col lg={12}>
            <Card className="custom-card">
              <Card.Body>
                <div className="">
                  <h6 className="main-content-label mb-1 my-5">
                    ประวัติแนะนำเพื่อน
                  </h6>
                  {statepost.data_aff && <AffTable data={statepost.data_aff} />}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Card.Body>
      </Card>
      {/* <!-- End Row --> */}
    </div>
  );
};

Aff.propTypes = {};

Aff.defaultProps = {};

export default Aff;
