import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  FormGroup,
  InputGroup,
  Alert,
  Table,
  ProgressBar,
} from "react-bootstrap";

const ShowSum = (props: any) => {
  return (
    <>
      <Col xl={3} lg={12} md={12} xs={12}>
        <Card className=" sales-card">
          <Row>
            <div className="col-8">
              <div className="ps-4 pt-4 pe-3 pb-4">
                <div className="">
                  <h6 className="mb-2 tx-12 ">ยอดโบนัส</h6>
                </div>
                {props?.data ? (
                  <>
                    <div className="pb-0 mt-0">
                      <div className="d-flex">
                        <h4 className="tx-32 text-secondary font-weight-semibold mb-2">
                          ฿{" "}
                          {props?.data?.data?.totalBonus != null ? (
                            <>
                              {parseFloat(props?.data?.data?.totalBonus).toFixed(2)}{" "}
                            </>
                          ) : (
                            <> 0 </>
                          )}{" "}
                        </h4>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center">
                      <div className="lds-spinner">
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-4">
              <div className="circle-icon bg-secondary-transparent text-center align-self-center overflow-hidden">
                <i className="fe fe-dollar-sign tx-16 text-success"></i>
              </div>
            </div>
          </Row>
        </Card>
      </Col>

      <Col xl={3} lg={12} md={12} xs={12}>
        <Card className="sales-card">
          <Row>
            <div className="col-8">
              <div className="ps-4 pt-4 pe-3 pb-4">
                <div className="">
                  <h6 className="mb-2 tx-12">
                    <span className="text-success">ยอดฝาก </span>{" "}
                  </h6>
                </div>
                {props?.data ? (
                  <>
                    {" "}
                    <div className="pb-0 mt-0">
                      <div className="d-flex">
                        <h4 className="tx-32  text-success font-weight-semibold mb-2">
                          ฿{" "}
                          {props?.data?.data?.totalDeposit != null ? (
                            <>
                              {parseFloat(props?.data?.data?.totalDeposit).toFixed(2)}
                            </>
                          ) : (
                            <> 0 </>
                          )}
                        </h4>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center">
                      <div className="lds-spinner">
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-4">
              <div className="circle-icon bg-success-transparent text-center align-self-center overflow-hidden">
                <i className="fe fe-dollar-sign tx-16 text-success"></i>
              </div>
            </div>
          </Row>
        </Card>
      </Col>
      <Col xl={3} lg={12} md={12} xs={12}>
        <Card className=" sales-card">
          <Row>
            <div className="col-8">
              <div className="ps-4 pt-4 pe-3 pb-4">
                <div className="">
                  <h6 className="mb-2 tx-12">
                    <span className="">ยอดถอน </span>{" "}
                  </h6>
                </div>
                {props?.data ? (
                  <>
                    {" "}
                    <div className="pb-0 mt-0">
                      <div className="d-flex">
                        <h4 className="tx-32 text-danger font-weight-semibold mb-2">
                          {" "}
                          ฿{" "}
                          {props?.data?.data?.totalWithdrawal ? (
                            <>{parseFloat(props?.data?.data?.totalWithdrawal).toFixed(2)} </>
                          ) : (
                            <>0</>
                          )}
                          {/* {props?.data?.datatran &&
                              props?.data?.datatran.w_scb_datenow} */}
                        </h4>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center">
                      <div className="lds-spinner">
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-4">
              <div className="circle-icon bg-secondary-transparent text-center align-self-center overflow-hidden">
                <i className="fe fe-dollar-sign tx-16 text-secondary"></i>
              </div>
            </div>
          </Row>
        </Card>
      </Col>
      <Col xl={3} lg={12} md={12} xs={12}>
        <Card className="sales-card">
          <Row>
            <div className="col-8">
              <div className="ps-4 pt-4 pe-3 pb-4">
                <div className="">
                  <h6 className="mb-2 tx-12">กำไร</h6>
                </div>
                {props?.data ? (
                  <>
                    {" "}
                    <div className="pb-0 mt-0">
                      <div className="d-flex">
                        {" "}
                        {props?.data ? (
                          <>
                            {props?.data?.data?.totalProfit > 0 ? (
                              <>
                                {" "}
                                <h4 className="tx-32 text-success font-weight-semibold mb-2">
                                  {" "}
                                  ฿ + {parseFloat(props?.data?.data?.totalProfit).toFixed(2)}{" "}
                                  
                                </h4>{" "}
                              </>
                            ) : (
                              <>
                                <h4 className="tx-32 text-danger font-weight-semibold mb-2">
                                  {parseFloat(props?.data?.data?.totalProfit).toFixed(2)}
                                </h4>{" "}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {" "}
                            <h4 className="tx-32 text-info font-weight-semibold mb-2">
                              ฿
                            </h4>{" "}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center">
                      <div className="lds-spinner">
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-4">
              <div className="circle-icon bg-warning-transparent text-center align-self-center overflow-hidden">
                <i className="fas fa-info-circle tx-16 text-warning"></i>
              </div>
            </div>
          </Row>
        </Card>
      </Col>
    </>
  );
};

export default ShowSum;
