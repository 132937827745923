import { useReducer, useContext, createContext } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { initialValue } from './initialState'
import { InitialState } from './interface/auth'
import { CreateContext } from './interface/action';
import { signIn, signOut, onReLoad } from './action'
import Chacklogin_web from './chack_login';
import Reducer from './reducer'

const StateContext = createContext<InitialState>(initialValue)
// const DispatchContext = createContext<CreateContext>({})
const DispatchContext = createContext<CreateContext>({
  dispatch: () => null,
  _signIn: () => null,
  _onReLoad: () => null,
  _signOut: () => null
});

const AuthContextProvider = ({ children }: { children?: React.ReactNode; }) => {
  // console.log('initialValue', initialValue)
  const [state, dispatch] = useReducer(Reducer, initialValue);

 // console.log('initialValue', initialValue)Chacklogin_web

 const isAuthenticated = Chacklogin_web(); // Call the hook
 //console.log('initialValue', isAuthenticated)



  const DispatchValue: CreateContext = {
    _signIn: signIn(dispatch),
    _signOut: signOut(dispatch),
    _onReLoad: onReLoad(dispatch),
  }
 //console.log('state', state)
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider
        value={DispatchValue}
      >
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

const useAuthContextState = () => {
  const context = useContext(StateContext);

   //console.log(context)

  if (context === undefined) {
    throw new Error("useSpinState must be used within a SpinProvider");
  }
  return context;
};

const useAuthContextDispatch = () => {
  const context = useContext(DispatchContext);
  if (context === undefined) {
    throw new Error("useSpinDispatch must be used within a SpinProvider");
  }
  return context;
};

export { AuthContextProvider, useAuthContextDispatch, useAuthContextState };