import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import { CardBody, Container, Input, Modal } from "reactstrap";
import Moment from "moment";
import Dropzone from "react-dropzone";
import FriendAPI from "../api/Friend";
import { Link } from "react-router-dom";
import SettingAPI from "../api/SettingAPI";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { useFormik } from "formik";
//import Apisetting from "../../../api/Apisetting";
import ProMotionAPI from "../api/ProMotion";
import { Upload } from "antd";

export default function Addpronew(props: any) {
  const [files, setFiles] = useState([] as any);
  const [fileName, setFileName] = useState("");
  useEffect(() => {
    getdatabank();
  }, []);

  const preview = (file: any) => {
    //
    setFiles(file);
    // setFileName(file.name);
  };
  console.log(files);

  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      data: [],
    }
  );
  const getdatabank = () => {
    FriendAPI.getbankseting_onpro()
      .then((res) => {
        // console.log(res.data);

        if (res.data.success) {
          // console.log(res.data);
          setData_bank({
            data: res.data.data,
          });
        } else {
          //  getGames();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [data_bank2, setData_bank2] = useState({
    data: [],
  });
  const [data_bank, setData_bank] = useState({
    data: [],
  });

  const validationContactForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      percen: "",
      x: "",
      text: "",
      name: "",
      img_name: "",
      limit_d: "",
      limit_w: "",
      level_pro: "",
      end_date: "",
      start_date: "",
      status_showpro: "",
      status_online: "",
      bank_dispodit: "",
      img_url: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("กรุณาตั้งชื่อโปรโมชั่น"),
      text: Yup.string().required("กรุณา ระบุ คำอธิบายของโปรโมชั่น"),
      x: Yup.string().required("กรุณา ระบุ จำนวนเทริน"),
      percen: Yup.string().required("กรุณา ระบุ จำนวนเปอร์เซนที่ได้รับ"),
      limit_d: Yup.string().required("กรุณา ระบุ จำนวน เครดิต รับได้ไม่เกิน"),
      limit_w: Yup.string().required("กรุณา ระบุ จำนวน ถอนได้สูงสุด"),
      // status_online: Yup.string().required("status_online"),
      // level_pro: Yup.string().required("กรุณาเลือกประเภทของสมาชิก"),
      // status_showpro: Yup.string().required("กรุณาเลือก ระบุ จะแสดงหรือไม่"),
      // text: Yup.string().required("กรุณาระบุ รายละเอียดโปรโมชั่น"),
      // x: Yup.string().required("กรุณาตั้ง เทรินโอเวอร์ ของยอดฝาก"),
      // limit_w: Yup.string().required("กรุณา ตั้งยอดถอนสูงสุด"),
      // name: Yup.string().required("กรุณา ตั้งชื่อโปรโมชั่น"),
    }),
    onSubmit: (values) => {
      const data = new FormData();
      data.append("percen", values.percen);
      data.append("x", values.x);
      data.append("text", values.text);
      data.append("name", values.name);
      data.append("limit_d", values.limit_d);
      data.append("limit_w", values.limit_w);
      data.append("level_pro", values.level_pro);
      data.append("status_showpro", values.status_showpro);
      data.append("status_online", values.status_online);
      data.append("bank_dispodit", values.bank_dispodit);

      data.append("img_url", files);

      //  console.log(data);
      ProMotionAPI.addpro(data)
        .then((res) => {
          if (res.data.success) {
            toast.success(
              <p className="text-white tx-16 mb-0">
                {`เพิ่มโปรโมชั่น  Successfully`}
              </p>,
              {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
                autoClose: 5000,
                theme: "colored",
              }
            );
            setTimeout(function () {
              window.location.reload();
            }, 2500);
          } else {
            //   setSuccessMessage("");
          }
        })
        .catch(function (error) {
          console.log("error ", error);
        });
    },
  });

  return (
    <>
      <div className="pd-30 pd-sm-20">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validationContactForm.handleSubmit();
            return false;
          }}
        >
          <Row className="row-xs">
            <Col md={12} className=" mg-t-10 mg-md-t-0">
              <FormGroup className="form-group">
                <Form.Label htmlFor="exampleInputEmail1">ชื่อโปร</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="inputName"
                  name="name"
                  placeholder="ชื่อโปร"
                  onChange={validationContactForm.handleChange}
                  onBlur={validationContactForm.handleChange}
                  value={validationContactForm.values.name}
                />
                {validationContactForm.errors.name ? (
                  <>
                    <p className="text-danger">
                      {validationContactForm.errors.name}
                    </p>
                  </>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={12} className=" mg-t-10 mg-md-t-0">
              <FormGroup className="form-group">
                <Form.Label htmlFor="exampleInputEmail1">
                  คำอธิบายของโปรโมชั่น
                </Form.Label>
                {/* <Form.Control
                                                type="text"
                                                className="form-control"
                                                id="inputName"
                                                name="text"
                                                placeholder="คำอธิบายของโปรโมชั่น"
                                                onChange={validationContactForm.handleChange}
                                                onBlur={validationContactForm.handleChange}
                                                value={validationContactForm.values.text}
                                            /> */}
                <textarea
                  className="form-control form-control"
                  name="text"
                  placeholder="รายละเอียดเกี่ยวกับโปรโมชั่น"
                  onChange={validationContactForm.handleChange}
                  onBlur={validationContactForm.handleChange}
                  value={validationContactForm.values.text}
                  rows={3}
                ></textarea>
                {validationContactForm.errors.text ? (
                  <>
                    <p className="text-danger">
                      {validationContactForm.errors.text}
                    </p>
                  </>
                ) : null}
              </FormGroup>
            </Col>

            <Col md={12} className=" mg-t-10 mg-md-t-0">
              <FormGroup className="form-group">
                <Form.Label htmlFor="exampleInputEmail1">
                  การให้โบนัส
                </Form.Label>
                <select
                  name="status_showpro"
                  id="status_showpro"
                  className="form-select"
                  onChange={validationContactForm.handleChange}
                  onBlur={validationContactForm.handleChange}
                  value={validationContactForm.values.status_showpro}
                >
                  <option value="บวกเพิ่ม">บวกเพิ่ม</option>
                  <option value="บวกเพิ่ม%">บวกเพิ่ม %</option>
                  <option value="ให้สิทธิการเล่น">ให้สิทธิการเล่น</option>
                </select>
                {validationContactForm.errors.status_showpro ? (
                  <>
                    <p className="text-danger">
                      {validationContactForm.errors.status_showpro}
                    </p>
                  </>
                ) : null}
              </FormGroup>
            </Col>

            <Col md={12} className=" mg-t-10 mg-md-t-0">
              <FormGroup className="form-group">
                <Form.Label htmlFor="exampleInputEmail1">
                  เลือกประเภทโปรโมชั่น
                </Form.Label>
                <select
                  name="level_pro"
                  id="level_pro"
                  className="form-select"
                  onChange={validationContactForm.handleChange}
                  onBlur={validationContactForm.handleChange}
                  value={validationContactForm.values.level_pro}
                >
                  <option value={"สมัครใหม่"}>สมัครใหม่</option>
                  <option value={"วันละครั้ง"}>วันละครั้ง</option>
                  <option value={"นาทีทอง"}>นาทีทอง</option>
                  <option value={"คืนยอดเสีย"}>คืนยอดเสีย</option>
                  <option value={"กงล้อ"}>กงล้อ</option>
                  <option value={"แจกฟรี"}>แจกฟรี</option>
                </select>
                {validationContactForm.errors.level_pro ? (
                  <>
                    <p className="text-danger">
                      {validationContactForm.errors.level_pro}
                    </p>
                  </>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={12} className=" mg-t-10 mg-md-t-0">
              <FormGroup className="form-group">
                <Form.Label htmlFor="exampleInputEmail1">
                  คำอธิบายของโปรโมชั่น
                </Form.Label>
                <select
                  name="bank_dispodit"
                  id="bank_dispodit"
                  className="form-select"
                  onChange={validationContactForm.handleChange}
                  onBlur={validationContactForm.handleChange}
                  value={validationContactForm.values.bank_dispodit}
                >
                  <option>เลือกบัญชี ที่ใช้ฝาก</option>

                  {data_bank.data && (
                    <>
                      {data_bank.data.map((items: any, index: any) => {
                        return (
                          <>
                            <option value={items.accnum}>
                              {items.name} : {items.accnum}
                            </option>
                          </>
                        );
                      })}
                    </>
                  )}
                </select>
              </FormGroup>
            </Col>

            <Col md={12} className=" mg-t-10 mg-md-t-0">
              <FormGroup className="form-group">
                <Form.Label htmlFor="exampleInputEmail1">สถานะ แสดง</Form.Label>
                <select
                  name="status_online"
                  id="status_online"
                  className="form-select"
                  onChange={validationContactForm.handleChange}
                  onBlur={validationContactForm.handleChange}
                  value={validationContactForm.values.status_online}
                >
                  <option>เลือกสถานะ แสดง</option>
                  <option value="online">online</option>
                  <option value="offline">offline</option>
                </select>
                {validationContactForm.errors.status_online ? (
                  <>
                    <p className="text-danger">
                      {validationContactForm.errors.status_online}
                    </p>
                  </>
                ) : null}
              </FormGroup>
            </Col>

            <Col md={12} className=" mg-t-10 mg-md-t-0">
              <FormGroup className="form-group">
                <Form.Label htmlFor="exampleInputEmail1">
                  จำนวนเครดิต {validationContactForm.values.status_showpro}
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="inputName"
                  name="percen"
                  placeholder="จำนวนเครดิต "
                  onChange={validationContactForm.handleChange}
                  onBlur={validationContactForm.handleChange}
                  value={validationContactForm.values.percen}
                />
                {validationContactForm.errors.percen ? (
                  <>
                    <p className="text-danger">
                      {validationContactForm.errors.percen}
                    </p>
                  </>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={12} className=" mg-t-10 mg-md-t-0">
              <FormGroup className="form-group">
                <Form.Label htmlFor="exampleInputEmail1">
                  จำนวนเทริน {validationContactForm.values.status_showpro}
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="inputName"
                  placeholder="จำนวนเทริน "
                  onChange={validationContactForm.handleChange}
                  onBlur={validationContactForm.handleChange}
                  value={validationContactForm.values.x}
                  name="x"
                />
                {validationContactForm.errors.x ? (
                  <>
                    <p className="text-danger">
                      {validationContactForm.errors.x}
                    </p>
                  </>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={12} className=" mg-t-10 mg-md-t-0">
              <FormGroup className="form-group">
                <Form.Label htmlFor="exampleInputEmail1">
                  จำนวนเครดิต {validationContactForm.values.status_showpro}
                </Form.Label>
                <Upload
                  name="img_url"
                  accept={"image/*"}
                  showUploadList={true}
                  onChange={(e) => {
                    preview(e.file.originFileObj);
                  }}
                  maxCount={1}
                >
                  <p className="btn btn-success">เพิ่ม รูปภาพ</p>
                </Upload>
              </FormGroup>
            </Col>
            <Col md={12} className=" mg-t-10 mg-md-t-0">
              <FormGroup className="form-group">
                <Form.Label htmlFor="exampleInputEmail1">
                  จำนวน ถอนได้สูงสุด
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="inputName"
                  name="limit_w"
                  placeholder="จำนวน ถอนได้สูงสุด "
                  onChange={validationContactForm.handleChange}
                  onBlur={validationContactForm.handleChange}
                  value={validationContactForm.values.limit_w}
                />
                {validationContactForm.errors.limit_w ? (
                  <>
                    <p className="text-danger">
                      {validationContactForm.errors.limit_w}
                    </p>
                  </>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={12} className=" mg-t-10 mg-md-t-0">
              <FormGroup className="form-group">
                <Form.Label htmlFor="exampleInputEmail1">
                  จำนวน เครดิต รับได้ไม่เกิน
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="inputName"
                  name="limit_d"
                  placeholder="จำนวน เครดิต รับได้ไม่เกิน "
                  onChange={validationContactForm.handleChange}
                  onBlur={validationContactForm.handleChange}
                  value={validationContactForm.values.limit_d}
                />
                {validationContactForm.errors.limit_d ? (
                  <>
                    <p className="text-danger">
                      {validationContactForm.errors.limit_d}
                    </p>
                  </>
                ) : null}
              </FormGroup>
            </Col>
            <div className="col-md mg-t-10 mg-md-t-0">
              <Button
                variant=""
                type="submit"
                className="btn btn-primary btn-block"
              >
                เพิ่มโปรโมชั่น
              </Button>
            </div>
          </Row>
        </form>
      </div>
    </>
  );
}
