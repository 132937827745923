import React, { Fragment, useState, useEffect, useReducer } from "react";

import { Button, Col, Form, Row, Alert, FormGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../Firebase/firebase";
import "./index.css";
import ApiAuth from "../api/db";
import FriendAPI from "../components/api/Friend";
import { authenticate } from "../config/authsave";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SettingAPI from "../components/api/SettingAPI";
import { socket } from "../utils/socket";
import Loader from "@shade/Loaders/Loaders";
import * as Switcherdata from "../data/Switcherdata/Switcherdata";

import Forgetpass from "./forgetpass";

const SignIn = () => {
  const [Lang, setLang] = React.useState(false);

  useEffect(() => {
    getapisetting();
    chacklogin();
    chacklog()
  }, []);

  const getapisetting = () => {
    SettingAPI.getdatasetting_fontend()
      .then((res) => {
        if (res.data.success == true) {
          setState2({
            data: res.data.data,
          });
          setLang(true);
        } else {
        }
        //
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const chacklogin = () => {
    SettingAPI.chack_loginv2()
      .then((res) => {
        if (res.data.success == true) {
          let path = `${process.env.PUBLIC_URL}/dashboard/dashboard`;
          navigate(path);
        }
        //
        // setState2({
        //   data: res.data.data,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const bgImages = [
    "./bg06.jpg",
    "./bg07.jpg", // Add more image paths here
    "./bg08.jpg",
  ];

  let currentImageIndex = 0;

  function changeBackgroundImage() {
    const bgInfomaxElement = document.querySelector(".bg-infomax");
    // bgInfomaxElement.style.backgroundImage = `url(${bgImages[currentImageIndex]})`;

    currentImageIndex = (currentImageIndex + 1) % bgImages.length;
  }

  // Initial background image
  changeBackgroundImage();

  // Change background image every 5 minutes (300000 milliseconds)
  setInterval(changeBackgroundImage, 300000);

  const [state2, setState2] = useReducer(
    (state2: any, newState2: any) => ({ ...state2, ...newState2 }),
    {
      data: "",
    }
  );

  const [Active, SetActive] = useState(false);

  const [err, setError] = useState("");
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = data;
  const changeHandler = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError("");
  };
  let navigate = useNavigate();
  const routeChange = (data: any) => {
    console.log(data);
    // let path = `${process.env.PUBLIC_URL}/dashboard/dashboard`;
    // navigate(path);
  };
  const handleCheckboxChange = (event: any) => {
    if (event.target.checked) {
      localStorage.setItem(
        "userData",
        JSON.stringify({
          email: email,
          password: password,
        })
      );
    } else {
      localStorage.removeItem("userData");
    }
  };
  const Showerror = () => {
    toast.error(`อยู่ในช่วงกำลัง พัฒณา ยังไม่เปิดให้บริการ`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const Login = (e: any) => {
    e.preventDefault();
    setLang(false);
    var navigator_info = window.navigator;
    var screen_info = window.screen;
    var uid: any = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, "");
    uid += navigator_info.plugins.length;
    uid += screen_info.height || "";
    uid += screen_info.width || "";
    uid += screen_info.pixelDepth || "";

    let user_device_id = uid;
    const datapost = {
      email: email,
      password: password,
      user_device_id: user_device_id,
    };
    socket.emit("search_team", datapost);
    FriendAPI.Postlogin(datapost)
      .then((res) => {
        if (res.data.success) {
          setLang(true);
          if (res.data.redirect === "otp") {
            const email = datapost.email;
            const user_id = res.data.data.user_id;
            // $(".appLoader").css("display", "none");
           
            navigate("/verify-otp", {
              state: {
                user_id: user_id,
                email: email,
                user_device_id: user_device_id,
              },
            });
          } else if (res.data.redirect === "home") {
            const data = {
              onlineOfflineRoom: "onLineOfflineStatusRoom",
              onlineFriends: res.data.onlineFriends,
              for: "online",
            };

            const payload = {
              token: res.data.token,
              userInfo: res.data.user,
            };
            authenticate(payload);
           
            toast.success("เข้าสู่ระบบสำเร็จ.. ⏭ ⏭ กรุณารอสักครู่ ", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setTimeout(function () {
              navigate("/dashboard/dashboard");
            }, 2500);
          }
        } else {
          setLang(true);
         
          toast.error(`${res.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        setLang(true);
        console.log(err);
      });

    // auth.signInWithEmailAndPassword(email, password).then(
    //   user => {console.log(user);routeChange()}).catch(err => {console.log(err);setError(err.message)})
  };


  const chacklog = () => {
    const userss : any = localStorage.getItem('userData');
    let userInfo = JSON.parse(userss)

    if(userInfo) {
      setData({
        email: userInfo.email,
        password: userInfo.password,
      })
    }

   // console.log(userInfo)

    

  }

  return (
    <React.Fragment>
      <div className="square-box">
        {" "}
        <div></div> <div></div> <div></div> <div></div> <div></div> <div></div>{" "}
        <div></div> <div></div> <div></div> <div></div> <div></div> <div></div>{" "}
        <div></div> <div></div> <div></div>{" "}
      </div>
      {Lang ? (
        <></>
      ) : (
        <>
          <Loader />
        </>
      )}

      <div className="page bg-infomax">
        <div className="page-single">
          <div className="container" style={{ marginTop: "89px" }}>
            {/* <Forgetpass Active={e => SetActive(e)} />{' '} */}
            <Row>
              <Col
                xl={5}
                lg={6}
                md={8}
                sm={8}
                xs={10}
                className="card-sigin-main mx-auto my-auto py-4 justify-content-center"
              >
                <div className="card-sigin bg-dark">
                  {/* <!-- Demo content--> */}
                  <div className="main-card-signin d-md-flex bg-dark ">
                    <div className="wd-100p">
                      <div className="d-flex mb-4">
                        <Link to="#">
                          <img
                            src={
                              state2?.data?.loggo_url
                                ? state2?.data?.loggo_url
                                : require("../assets/img/cropped-logo1.png")
                            }
                            alt="img"
                            className="media8"
                          />
                        </Link>
                      </div>
                      <div className="">
                        <div className="main-signup-header">
                          <h2>ยินดีต้อนรับ สู่ระบบจัดการสมาชิก!</h2>
                          <h2 className="">{state2?.data?.name_web}</h2>
                          <h6 className="font-weight-semibold mb-4"></h6>
                          <div className="panel panel-primary">
                            <div className=" tab-menu-heading mb-2 border-bottom-0">
                              <div className="tabs-menu1">
                                {err && <Alert variant="danger">{err}</Alert>}
                                <Form>
                                  <Form.Group className="form-group">
                                    <Form.Label className="text-danger">
                                      {" "}
                                      <h5>Emaill</h5>{" "}
                                    </Form.Label>{" "}
                                    <Form.Control
                                      className="form-control"
                                      placeholder="Enter your email"
                                      name="email"
                                      type="text"
                                      value={email}
                                      onChange={changeHandler}
                                      required
                                    />
                                  </Form.Group>
                                  <Form.Group className="form-group">
                                    <Form.Label className="text-danger">
                                      {" "}
                                      <h5>Password</h5>{" "}
                                    </Form.Label>
                                    <Form.Control
                                      className="form-control"
                                      placeholder="Enter your password"
                                      name="password"
                                      type="password"
                                      value={password}
                                      onChange={changeHandler}
                                      required
                                    />
                                  </Form.Group>
                                  <FormGroup className="form-group mb-0 justify-content-end">
                                    <div className="checkbox">
                                      <div className="custom-checkbox custom-control">
                                        <Form.Control
                                          type="checkbox"
                                          data-checkboxes="mygroup"
                                          className="custom-control-input"
                                          id="checkbox-2"
                                          onChange={handleCheckboxChange}
                                        />
                                        <Form.Label
                                          htmlFor="checkbox-2"
                                          className="custom-control-label mt-1"
                                        >
                                          จำฉันไว้ในระบบ
                                        </Form.Label>
                                      </div>
                                    </div>
                                  </FormGroup>
                                  
                                  <Button
                                    variant=""
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    onClick={Login}
                                  >
                                    เข้าสู่ระบบ
                                  </Button>
                                  <div className="main-signin-footer text-center mt-3">
                                    <h5 className="text-danger">
                                      <Link to="#" className="mb-3 ">
                                        Forgot password? ลืมรหัสผ่าน
                                      </Link>
                                    </h5>
                                  </div>
                                  <h3 className="text-info mt-5 text-center">
                                    เข้าสู่ระบบ ผ่านช่องทางอื่น
                                  </h3>
                                  <div className="mt-4 d-flex text-center justify-content-center mb-2">
                                    <Link
                                      to="#"
                                      className="btn btn-icon btn-facebook me-3"
                                      type="button"
                                      onClick={() => Showerror()}
                                    >
                                      <span className="btn-inner--icon">
                                        {" "}
                                        <i className="bx bxl-facebook tx-18 tx-prime"></i>{" "}
                                      </span>
                                    </Link>

                                    <Link
                                      to="#"
                                      className="btn btn-icon me-3"
                                      type="button"
                                      onClick={() => Showerror()}
                                    >
                                      <span className="btn-inner--icon">
                                        {" "}
                                        <i className="bx bxl-twitter tx-18 tx-prime"></i>{" "}
                                      </span>
                                    </Link>

                                    <ToastContainer />
                                  </div>
                                </Form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

SignIn.propTypes = {};

SignIn.defaultProps = {};

export default SignIn;
