import React from "react";
import { useReducer, useEffect,useState } from "react";
import {
  Breadcrumb,
  Card,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Tab,
  Tabs, Modal, Alert
} from "react-bootstrap";
import Apiscb from "../../api/Apiscb";
import classnames from "classnames";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Progress,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Collapse, Select, Upload } from "antd";
import { FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Input } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropzone from "react-dropzone";
//import ApiAuth from "../../api/db";
import Loader, { LoaderType } from "react-loaders";
const MyVerticallyCenteredModal = (props: any, pp: any) => {
  // const location = useLocation();
  //const game: any = location.state;
  // useEffect(() => {
  //   getdataeditBank_code();
  // }, []);

  // const getdataeditBank_code = () => {
  //   ApiAuth.getdataeditBank_code()
  //     .then((res) => {
  //       // 

  //       if (res.data.success) {
  //         setStatebank({
  //           data: res.data.data,
  //         });
  //       } else {
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }
  const [openck, setOpenck] = useState(false);
  const [openkbank, setOpenkbank] = useState(false);
  const [openerror, setOpenerror] = useState(false);
  const [openerrormsg, setOpenerrormsg] = useState("");


  const [statebank, setStatebank] = useReducer(
    (statebank: any, newStatebank: any) => ({ ...statebank, ...newStatebank }),
    {
      data: [],
    },
  );


  const [activeTab, setactiveTab] = useState(1);
  const [progressbarvalue, setprogressbarvalue] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);

  
    const [successMessage, setSuccessMessage] = useState("");
    const [contactReason, setContactReason] = useState("");
    const [fileName, setFileName] = useState("");
  
    const [files, setFiles] = useState([] as any);
    const [state, setState] = useReducer(
      (state: any, newState: any) => ({ ...state, ...newState }),
      {
        contactReason: [],
      },
    );
    const [selectedFiles, setselectedFiles] = useState<any>([]);
  
    function handleAcceptedFiles(files: any) {
      files.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        }),
      );
      setselectedFiles(files);
    }
  
    /**
     * Formats the size
     */
    function formatBytes(bytes: any, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
  
    const preview = (file: any) => {
      setFiles(file);
      setFileName(file.name);
    };
  
    // Form validation
    const validationContactForm = useFormik({
      enableReinitialize: true,
      initialValues: {
        contact_reason_id: "",
        description_en: "",
        img_url: [],
      },
      validationSchema: Yup.object({
        // contact_reason_id: Yup.string().required('Please select contact reason'),
        // description_en: Yup.string().required('Description is required'),
        // file: Yup.mixed().required('Attachment file is required')
        //     .test('fileFormat', 'We accpet only images (.png, .jpeg, .gif) and videos (.mp4, .mov)', (value) => {
        //         return value && ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'video/quicktime'].includes(value.type);
        //     }),
      }),
      onSubmit: (values) => {
        const data = new FormData();
        data.append("contact_reason_id", contactReason);
        data.append("description_en", values.description_en);
        data.append("img_url", files);
        Apiscb.sendMessage(data)
          .then((res) => {
            if (res.data.success) {
              setSuccessMessage(res.data.message);
            } else {
              setSuccessMessage("");
            }
          })
          .catch(function (error) {
            console.log("error ", error);
          });
      },
    });

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>เพิ่มรายการ</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {openerror && <>
          <div role="alert" className="fade alert fade show alert-dismissible alert-danger alert show">
            {/* <span className="alert-inner--text text-center"><strong className="text-center">ERROR เกิดข้อผิดพลาด!</strong> </span> */}
            <h4 className="text-center"><strong className="text-center">ERROR เกิดข้อผิดพลาด!</strong></h4>
            <p className="text-center">{openerrormsg}</p>
          </div>
        </>}
        <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validationContactForm.handleSubmit();
                  return false;
                }}
              >
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div className="dz-message needsclick mt-2" >
                        <Upload
                          name="img_url"
                          accept={"image/*"}
                          showUploadList={false}
                          onChange={(e) => {
                            preview(e.file.originFileObj);
                          }}
                          maxCount={1}
                        >
                          <p className="btn btn-success">เพิ่ม รูปภาพ</p>
                        </Upload>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="flex justify-left ">
                  <div className=" w-full h-14 border-l-4 border-[#94bd4b] flex Selecter items-center bg-[#2e2e2e]">
                    {selectedFiles.map((f: any, i: number) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link to="#" className="text-muted font-weight-bold">
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      );
                    })}

                  </div>
                </div>
                {validationContactForm.touched.contact_reason_id &&
                  validationContactForm.errors.contact_reason_id ? (
                  <FormFeedback
                    type="invalid"
                    style={{ marginLeft: "4%", color: "white" }}
                  >
                    {validationContactForm.errors.contact_reason_id}
                  </FormFeedback>
                ) : null}

                {validationContactForm.touched.description_en &&
                  validationContactForm.errors.description_en ? (
                  <FormFeedback
                    type="invalid"
                    style={{ marginLeft: "4%", color: "white" }}
                  >
                    {validationContactForm.errors.description_en}
                  </FormFeedback>
                ) : null}

                <div className="w-full flex justify-end ">
                  <div className="my-6 w-72 h-12 border-2 border-[#6bb8e7] flex items-center justify-center bg-[#000] rounded-md cursor-pointer">

                  </div>
                </div>
                {fileName !== "" && (
                  <p className="w-full flex justify-end text-white">{fileName}</p>
                )}

                {validationContactForm.touched.img_url &&
                  validationContactForm.errors.img_url ? (
                  <FormFeedback
                    type="invalid"
                    style={{ marginLeft: "4%", color: "white" }}
                  >
                    {validationContactForm.errors.img_url}
                  </FormFeedback>
                ) : null}

                {/* <FormFeedback type="valid"  style={{ marginLeft: '4%', color: 'white' }}>
                                                    { successMessage }
                                                </FormFeedback> */}




                <div className="mb-4">
                  <label htmlFor="default-input" className="form-label form-label">
                    หมายเหตุ
                  </label>
                  <input
                    id="default-input"
                    placeholder="หมายเหตุ"
                    name='description_en'
                    type="text"
                    className="form-control form-control"
                    onChange={validationContactForm.handleChange}
                    onBlur={validationContactForm.handleChange}
                    value={validationContactForm.values.description_en}
                  />
                </div>


                {successMessage === "" ? (
                  <div className="w-full flex justify-end ">
                    <div className="my-6 w-72 h-16 border-2 border-[#6bb8e7] flex items-center justify-center bg-[#253d4c] rounded-md cursor-pointer">
                      <button
                        type="submit"
                        className="btn btn-primary btn-sm waves-effect waves-light w-100 my-2 mx-auto"
                      >
                        อัพโหลด
                      </button>
                    </div>
                  </div>
                ) : (
                  <p className="text-center text-xl" style={{ color: "green" }}>
                    {successMessage}
                  </p>
                )}
              </form>
      </Modal.Body>
      <ToastContainer />
      <Modal.Footer>
        <Button className="modal-effect  d-grid mb-3" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function VerticallyCenteredModal(pp: any) {
  const [modalShow, setModalShow] = React.useState(false);

   const reload = () => {
    window.location.reload()
   }


  return (
    <>
      <Button
        className="btn ripple btn-primary"
        variant="danger"
        onClick={() => setModalShow(true)}
      >
        เพิ่ม รูปสลิป
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        sp={() => pp}
        onHide={() => reload()}
      />
    </>
  );
}
