import axios from "axios";
import config from "../../config/app.config"
import { setAuthorizationToken } from "../../helpers/axios";

setAuthorizationToken();

//console.log(setAuthorizationToken())

const Addmin_seting = {

    getNews_all: async (data: any) => {
        
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/getconnectscb`,
            headers: {
                'Content-Type': 'application/json'
            },
            data
        };

        return await axios(resData);
    },
    
    // บอท
    getNews_all_server: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/get_Newsall`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    depositkbankget_app: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/depositkbankufa2`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    }, depositscb: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/depositscb`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    }, depositscb2: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/depositscb`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
        
    }, depositscbufa2: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/depositscbufa2`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    Postupdate_settings_webfontend: async (data:any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/datapro`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
            
        };

        return await axios(resData);
    },

    Postupdatesubport: async (data:any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/Postupdatesubport`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
            
        };

        return await axios(resData);
    },
    Postupdatesubportall: async (data:any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/Postupdatesubportalls`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
            
        };

        return await axios(resData);
    },
    Postupdate_settings_webfontend2: async (data:any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/Setting_afmin_2`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
            
        };

        return await axios(resData);
    },
    
    
}
export default Addmin_seting