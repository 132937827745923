import React from "react";
import { Col, Row } from "reactstrap";
import "./index.css";

//import Breadcrumbs
//import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Container } from "reactstrap";
import Moment from "moment";
import "moment/locale/th";
//import Widgets

const Chack_wit = (datachack: any) => {
  document.title = "สรุปยอด ฝาก-ถอน | สรุปยอด ฝาก-ถอน ";

 // console.log(datachack);
  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid>
          <Row>
            {datachack.datachack.statec.data && (
              <>
                <div className="col-md-12 col-xl-12">
                  <Col lg={12} xl={12} md={12} sm={12}>
                    <img
                      src={`${datachack.datachack.statec.dataqr}`}
                      className="float-sm-canter wd-200p wd-sm-200 br-5"
                      alt=""
                    />
                  </Col>
                </div>

                <div className="col-md-12 col-lg-12 col-xl-12">
                  <div className="mg-b-20 text-center card">
                    <div className="row">
                      <div className="col-md-12 col-lg-6 col-xl-6">
                        <div className="danger-widget">
                          <h3 className="mt-3 text-danger">ธนาคาร</h3>
                          <p className="mt-3 mb-0">
                            <img
                              src={`https://ufaapiv1.myshadownow.com/images/bank3/${
                                datachack.datachack.statec.data.fromBankName ==
                                "กสิกรไทย"
                                  ? "kbank"
                                  : "scb"
                              }.png`}
                              alt=""
                              width={120}
                              className="w-20 mx-auto my-2"
                            />
                          </p>
                          ไทยพาณิชย์<p>ชื่อผู้รับโอน</p>
                          <h4>
                            {datachack.datachack.statec.data.toAccountNameEn}
                          </h4>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6 col-xl-6">
                        <div className="danger-widget">
                          <h3 className="mt-3 text-danger">ธนาคาร</h3>
                          <p className="mt-3 mb-0">
                            <img
                              src={`https://ufaapiv1.myshadownow.com/images/bank3/${
                                datachack.datachack.statec.data.toBankName ==
                                "กสิกรไทย"
                                  ? "kbank"
                                  : "scb"
                              }.png`}
                              alt=""
                              width={120}
                              className="w-20 mx-auto my-2"
                            />
                          </p>
                          ไทยพาณิชย์<p>ชื่อผู้โอน</p>
                          <h4 className="">
                            {datachack.datachack.statec.data.fromAccountNameEn}
                          </h4>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-12 col-xl-12">
                        <h4>
                          เวลาดำเดินการ :{" "}
                          {Moment(
                            datachack.datachack.statec.data.created_at,
                          ).format("LT")}{" "}
                        </h4>
                        <h4>
                          จำนวนเงิน{" "}
                          {datachack.datachack.statec.data.transAmount}.00 บาท{" "}
                        </h4>
                      </div>

                      <p>ผู้ทำรายการ : </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Chack_wit;
