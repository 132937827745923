import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  UncontrolledDropdown,
} from "reactstrap";
import AdminAPI from "../../api/Admin";
import { Link, useNavigate } from "react-router-dom";
import SettingAPI from "../../api/SettingAPI";
import Swal from "sweetalert2";
import Staffhit from "./Staff_info";
import { ToastContainer, toast } from "react-toastify";
import Moment from "moment";
import "moment/locale/th";
//import Apisetting from "../../../api/Apisetting";
import ProgressbarWizard from "./Staff_info/ProgressbarWizard";
const Staff = () => {
  const [successMessage1, setSuccessMessage1] = useState(false);
  const [open, setOpen] = useState(false);

  const [isBottom, setIsBottom] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard1, setmodal_standard1] = useState(false);
  const [modal_standard2, setmodal_standard2] = useState(false);
  const [modal_standard_spin, setmodal_standard_spin] = useState(false);

  useEffect(() => {
    getGames();
  }, []);

  const getGames = () => {
    AdminAPI.getdata_AdminModule()
      .then((res) => {
        console.log(res.data);

        if (res.data.success) {
          if (res.data.datapuper) {
            // console.log(res.data);

            setState2({
              data: res.data.dataadmin,
            });
            setSuccessMessage1(true);
          }

          // setState({
          //   data: res.data.users,
          // });
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const toggleBottomCanvas = (data: any) => {
    // console.log(data)

    let datapost = {
      data: data.item,
    };
    AdminAPI.get_hitstaff(datapost)
      .then((res) => {
        console.log(res.data);

        if (res.data.success) {
          setState5({
            data: res.data.data,
          });
          setmodal_standard2(!modal_standard2);
          removeBodyCss();
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // setIsBottom(!isBottom);
  };
  const [state2, setState2] = useReducer(
    (state2: any, newState2: any) => ({ ...state2, ...newState2 }),
    {
      data: [],
    }
  );
  const [state5, setState5] = useReducer(
    (state5: any, newState5: any) => ({ ...state5, ...newState5 }),
    {
      data: [],
      data_tok: [],
    }
  );
  function tog_standard_spin() {
    setmodal_standard_spin(!modal_standard_spin);
    removeBodyCss();
  }

  function tog_standard1() {
    setmodal_standard1(!modal_standard1);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  // console.log(state.data.perfix)

  const startban = (data: any) => {
    // console.log(data);
    Swal.fire({
      title: `คุณแน่ใจที่จะ ทำรายการ ${data.email} ?`,
      text: ` สมาชิก ${data.name}  !`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง, ทำรายการ!",
    }).then((result) => {
      const postData6 = "";
      if (result.isConfirmed) {
        const postData6 = {
          id: data.id,
          status: 0,
        };
        AdminAPI.startbanadmin(postData6)
          .then((res) => {
            //console.log(res.data);

            if (res.data.success) {
              Swal.fire("ทำรายการ!", " สำเร็จ", "success");
              getGames();
            } else {
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const startbankonline = (data: any) => {
    //console.log(data);
    Swal.fire({
      title: `คุณแน่ใจที่จะ ลบ ${data.username} ?`,
      text: `ลบ สมาชิก ${data.username}  !`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง, ลบ!",
    }).then((result) => {
      if (result.isConfirmed) {
        const postData6 = {
          id: data.id,
        };

        AdminAPI.delistUserstaff(postData6)
          .then((res) => {
            //console.log(res.data);

            if (res.data.success) {
              Swal.fire("ลบ!", `${res.data.message}`, "success");
              getGames();
            } else {
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const nostartban = (data: any) => {
    // console.log(data);
    Swal.fire({
      title: `คุณแน่ใจที่จะ ปลดระงับ ${data.username} ?`,
      text: ` สมาชิก ${data.username}  !`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง, ทำรายการ!",
    }).then((result) => {
      const postData6 = "";
      if (result.isConfirmed) {
        const postData6 = {
          id: data.id,
          status: 1,
        };
        AdminAPI.startbanadmin(postData6)
          .then((res) => {
            //console.log(res.data);

            if (res.data.success) {
              Swal.fire("ทำรายการ!", " สำเร็จ", "success");
              getGames();
            } else {
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  let history = useNavigate();
  const gopro_settings = (data: any) => {
    console.log(data);

    //  history("/pages/settings" , { state: data });
  };

  const toggleLeftCanvas = (data: any) => {
    // /pages/settings/
    history("/pages/settings", { state: data.data.id });

    // console.log(data)
  };

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  const [show, setShow] = React.useState(true);
  let [isFirstCollapsed, setisFirstCollapsed] = React.useState(true);

  const [remove, Setremove] = React.useState(true);
  const values = [true];
  const [fullscreen, setFullscreen] = React.useState<any>(true);
  const [shows, setShows] = React.useState(false);

  function handleShow(breakpoint: any) {
    setFullscreen(breakpoint);
    setShows(true);
  }
  let [isFirstCollapseds, setisFirstCollapseds] = React.useState(true);
  let firsts = () => {
    if (isFirstCollapseds === false) {
      setisFirstCollapseds(true);
    } else if (isFirstCollapseds === true) {
      setisFirstCollapseds(false);
    }
  };

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            ตั้งค่าการใช้งานเว็บ
          </span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb>
            <Breadcrumb.Item className="tx-15" href="#">
              ตั้งค่าการใช้งานเว็บ
            </Breadcrumb.Item>
            <Breadcrumb.Item active aria-current="page">
              ตั้งค่าการใช้งานเว็บ
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}

      {/* <Card className="mg-b-20">
        <Card.Body>
          <Col lg={12}>
            <Card className="custom-card">
              <Card.Body>
                <div>
                  <h6 className="main-content-label mb-1">ตั้งค่าทั่งไป</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Card.Body>
      </Card> */}
      <Row className=" row-sm">
        <Col lg={12} xl={12} md={12} sm={12}>
          <Card className="box-shadow-0">
            <Card.Header>
              {successMessage1 && (
                <>
                  <Row className="align-items-center">
                    <div className="col-xl-12 sm-12 md-12">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">รายชื่อพนักกงาน</h4>
                          <p className="card-title-desc text-danger">
                            *** เมนูนี้จะมีเพียงเจ้าของเว็บ ที่มองเห็น
                          </p>
                          <div className="float-sm-end">
                            <div>
                              {/* <button
                                className="btn btn-light"
                                onClick={() => {
                                  tog_standard_spin();
                                }}
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                {" "}
                                <i className="bx bx-plus me-1"></i> Spins
                              </button> */}
                            </div>
                            <div>
                              <button
                                className="btn btn-light"
                                onClick={() => {
                                  tog_standard1();
                                }}
                                data-toggle="modal"
                                data-target="#myModal"
                              >
                                {" "}
                                <i className="bx bx-plus me-1"></i> เพิ่มพนักงาน
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>

                  <div className="row">
                    {state2.data &&
                      state2.data.map((item: any, index: number) => {
                        return (
                          <>
                            <div className="col-sm-6 col-xl-3">
                              <div className="text-center card">
                                <Col sm={12}>
                                  {/* <UncontrolledAlert
                                color="danger"
                                className="px-4 mb-0 text-center"
                              >
                                <i className="mdi mdi-block-helper d-block display-4 mt-2 mb-3 text-danger"></i>
                                <h5 className="text-danger">Error</h5>
                                <p>A simple danger alert</p>
                              </UncontrolledAlert> */}
                                </Col>
                                <div
                                  className={`card-body ${item.status == 0 && "maxban"
                                    }`}
                                >
                                  <div className="dropdown text-end dropdown">
                                    <UncontrolledDropdown>
                                      <DropdownToggle
                                        className="btn btn-link text-muted py-1 font-size-16 shadow-none"
                                        tag="a"
                                      >
                                        <i className="mdi mdi-pencil d-block text-success font-size-20"></i>
                                      </DropdownToggle>

                                      <DropdownMenu className="dropdown-menu-end">
                                        <li>
                                          <DropdownItem to="#">
                                            แก้ไขสมาชิก
                                          </DropdownItem>
                                        </li>
                                        <li>
                                          {item.status == 1 && (
                                            <>
                                              <DropdownItem
                                                to="#"
                                                onClick={() => startban(item)}
                                              >
                                                ระงับ
                                              </DropdownItem>
                                            </>
                                          )}
                                          {item.status == 0 && (
                                            <>
                                              <DropdownItem
                                                to="#"
                                                onClick={() => nostartban(item)}
                                              >
                                                ปลดระงับ
                                              </DropdownItem>
                                            </>
                                          )}
                                          { }
                                        </li>
                                        <li>
                                          <DropdownItem
                                            to="#"
                                            onClick={() =>
                                              startbankonline(item)
                                            }
                                          >
                                            ลบ
                                          </DropdownItem>
                                        </li>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <div className="avatar-xl mx-auto mb-4 ">
                                    <span className="avatar-title bg-soft-light text-light display-4 m-0 rounded-circle">
                                      {item.level_user == "programmer" && (
                                        //  <i className="fas fa-user-astronaut"></i>
                                        <i className="fas fa-chess-knight"></i>
                                      )}{" "}
                                      {item.level_user == "superadmin" && (
                                        <i className="fas fa-chess-queen"></i>
                                      )}
                                      {item.level_user == "admin" && (
                                        <i className="fas fa-user-tag"></i>
                                      )}
                                    </span>
                                  </div>
                                  <h5 className="font-size-15 mb-1">
                                    <a className="text-dark">{item.name}</a>
                                  </h5>
                                  <p className="text-muted mb-2">
                                    {item.level_user == "programmer" && (
                                      <p className="rounded-pill bg-primary badge bg-secondary">
                                        โปรแกรมเมอร์{" "}
                                      </p>
                                    )}{" "}
                                    {item.level_user == "superadmin" && (
                                      <p className="rounded-pill bg-primary badge bg-secondary">
                                        เจ้าของเว็บ{" "}
                                      </p>
                                    )}
                                    {item.level_user == "admin" && (
                                      <p className="rounded-pill bg-primary badge bg-secondary">
                                        พนักงาน{" "}
                                      </p>
                                    )}
                                  </p>
                                </div>
                                <h5 className="font-size-15 mb-1">
                                  <a className="text-dark">{item.username}</a>
                                </h5>
                                <div className="btn-group" role="group">
                                  {item.status == 1 && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-outline-light text-truncate"
                                        onClick={() =>
                                          toggleLeftCanvas({ data: item })
                                        }
                                      >
                                        <i className="uil uil-user me-1" />{" "}
                                        การเข้าถึงเมนู
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-outline-light text-truncate"
                                        onClick={() =>
                                          toggleBottomCanvas({ item })
                                        }
                                      >
                                        <i className="uil uil-envelope-alt me-1" />{" "}
                                        ประวัติ พนักงาน
                                      </button>
                                    </>
                                  )}
                                  {item.status == 0 && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm waves-effect waves-light"
                                        onClick={() => nostartban(item)}
                                      >
                                        ปลดล็อกยูสนี้
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </>
              )}
            </Card.Header>
            <Card.Body className=" pt-0"></Card.Body>
          </Card>
        </Col>
        {/* <Col lg={6} xl={6} md={12} sm={12}>
          <Card className="box-shadow-0 ">
            <Card.Header>
              <h4 className="card-title mb-1">ตั้งค่าแจ้งเตือนไลน์</h4>
              <p className="mb-2">
                ระบุโทเค้นที่ได้จากไลน์แจ้งเตือน
              </p>
            </Card.Header>
            <Card.Body className=" pt-0">
           
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
      <Modal
        isOpen={modal_standard1}
        toggle={() => {
          tog_standard();
          getGames();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            เพิ่มรายชิ่อพนักงาน - รายชื่อผู้ดูแลระบบ
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard1(false);
              getGames();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <ProgressbarWizard
            isOpen={modal_standard1}
            setUnLink={setmodal_standard1}
            onHide={() => getGames()}
          />
        </div>
        <button
          onClick={() => {
            setmodal_standard1(false);
            getGames();
          }}
          className="btn btn-danger w-lg waves-effect waves-light mx-auto mt-2"
        >
          ปิด
        </button>
      </Modal>
      <Modal
        isOpen={modal_standard_spin}
        toggle={() => {
          tog_standard_spin();
          getGames();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Spin
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard_spin(false);
              getGames();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Staffhit/>
        </div>
        <button
          onClick={() => {
            setmodal_standard_spin(false);
            getGames();
          }}
          className="btn btn-danger w-lg waves-effect waves-light mx-auto mt-2"
        >
          ปิด
        </button>
      </Modal>
      {/*  */}
      <Modal
        isOpen={modal_standard2}
        toggle={() => {
          tog_standard();
          getGames();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            ประวัติ พนักงาน
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard2(false);
              getGames();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Table className="table mg-b-0 text-md-nowrap">
            <thead>
              <tr>
                <th>email</th>

                <th>วันที่/เวลา</th>
                <th>ประเภท</th>
              </tr>
            </thead>
            <tbody>
              {state5.data.Log_serveralls &&
                state5.data.Log_serveralls.map((list: any, index: any) => {
                  return (
                    <>
                      <tr>
                        <th scope="row">{list.username}</th>

                        <td>
                          {Moment(list.updated_at).format("L")}{" "}
                          {Moment(list.updated_at).format("LT")}
                        </td>
                        <td>เข้าสู่ระบบ</td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <button
          onClick={() => {
            setmodal_standard2(false);
            getGames();
          }}
          className="btn btn-danger w-lg waves-effect waves-light mx-auto mt-2"
        >
          ปิด
        </button>
      </Modal>
      {/* <!-- End Row --> */}
    </div>
  );
};

Staff.propTypes = {};

Staff.defaultProps = {};

export default Staff;
