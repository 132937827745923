import axios from "axios";
import config from "../../config/app.config"
import { setAuthorizationToken } from "../../helpers/axios";

setAuthorizationToken();

//console.log(setAuthorizationToken())

const Apiscb = {

    get_babascbnew: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/amb/getbabascbnew`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
     Resetpassword_ufa: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/Resetpassword_ufa`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    Postdatawinssss: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/amb/getdatawinlost`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    chack_GetmobileNo: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/scb/getmobileNos`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    upload_imgqrcodeadmin: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/upload_imgqrcodeadmin`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },

    getdatabank_live: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/amb/getdatabanklive`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    

    get_babascbnew_new: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/amb/getbabascbnew`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    chack_qrapikbank: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/chack_qrapikbank`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },

    getTran_hitscballs: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/getTran_hitscball`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    chack_qrapikbankby: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/chack_qrapikbankby`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    delit_qr: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/delit_srip`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    
    gettranscb: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/amb/depositAll`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    gettranscbtagnumber: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/scb/tagnumber`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    
    gettranscbs: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/amb/depositAll2`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    getNews_all_server: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/get_Newsall`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    depositkbankget_app: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/depositkbankufa2`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    }, depositscb: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/depositscb`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    }, depositscb2: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/depositscb`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
        
    }, depositscbufa2: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/depositscbufa2`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },
    sendMessage: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/create-contact-support`,
            data: data
        };

        return await axios(resData);
    },
    update_imgProfai: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/admin/changproadmin`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },

    sendlogo_fonrend2: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/create-contact-support`,
            data: data
        };

        return await axios(resData);
    },
    sendlogo_fonrend1: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/uploadlogo1`,
            data: data
        };

        return await axios(resData);
    },
    offline_pay: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/amb/offline_pay`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },

    
    
    sendlogo: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/sendlogo`,
            data: data
        };

        return await axios(resData);
    }, getlogo: async () => {
        
        const resData: any = {
            method: 'get',
            url: `${config.apiURL}/api/v5/user/getlogo`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return await axios(resData);
    },chack_getMember: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/chack_getMember`,
            data: data
        };

        return await axios(resData);
    },confirmotps: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/scb/confirmotpss`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        // const resData: any = {
        //     method: 'post',
        //     url: `${config.apiURL}/api/v5/scb/confirmotpss`,
        //     data: data
        // };

        return await axios(resData);
    },
    
    
}
export default Apiscb