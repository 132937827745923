import axios from "axios";
import config from "../../config/app.config"
import { setAuthorizationToken } from "../../helpers/axios";

setAuthorizationToken();

//console.log(setAuthorizationToken())

const TranAPI = {

    get_TranAPIinfo: async (data: any) => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/get_TranAPIinfo`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    chacktrue_Disposit: async () => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/user/chacktrue`,
            headers: {
                'Content-Type': 'application/json'
            },
            //data: data
        };

        return await axios(resData);
    },
    chackall_Disposit:async (data: any)  => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/amb/withdraw_auto`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    get_infobabamember:async (data: any)  => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/amb/get_infobabamember2`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    postremove_mony:async (data: any)  => {
        const resData: any = {
            method: 'post',
            url: `${config.apiURL}/api/v5/amb/remove_monny`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(resData);
    },
    
}
export default TranAPI