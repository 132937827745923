import { Modal, FormGroup ,Button} from "react-bootstrap";

const Model_img = (props: any) => {
  return (
    <>
      <Modal
        show={props.remove_show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <img className="card-img-top" src={props.dataimg} alt="" /> */}
        <Modal.Header closeButton>
          <Modal.Title
            as="h6"
            className="modal-title"
            id="contained-modal-title-vcenter"
          ></Modal.Title>
        </Modal.Header>
        <div
          className="yarl__fullsize yarl__flex_center"
          style={{ transform: "scale(1) translateX(0px) translateY(0px)", display : "flex" , justifyContent : "center"}}
        >
          <img
            draggable="false"
            className="yarl__slide_image"
            src={props.dataimg}
            style={{
              maxWidth: "min(400px, 100%)",
              maxHeight: "min(333px, 100%)",
            }}
          />
        </div>
        <Modal.Footer>
        <Button className="modal-effect  d-grid mb-3" onClick={()=> props.setRemove_show(false)}>
          Close
        </Button>
      </Modal.Footer>
      </Modal>
    </>
  );
};

export default Model_img;
