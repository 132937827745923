import React from "react";
import { useEffect, useReducer, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Input } from "reactstrap";
import ProMotionAPI from "../api/ProMotion";
//import Breadcrumbs
//import Datapro_v1 from "./datapro_v1";
//import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Table,
  CardHeader,
  Modal,
  Progress,
  Nav,
  NavLink,
  Alert,
  Button,
} from "reactstrap";
import {
  ToastContainer,
  toast,
  Bounce,
  Slide,
  Flip,
  Zoom,
} from "react-toastify";
import { Collapse, Select, Upload } from "antd";
import { FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import Dropzone from "react-dropzone";

const Edit_pro = (props: any) => {
  //console.log(data);

  const [files, setFiles] = useState([] as any);
  const [files2, setFiles2] = useState("" as any);
  const [files3, setFiles3] = useState("" as any);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [selectedFiles, setselectedFiles] = useState<any>([]);

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),

        formattedSize: formatBytes(file.size),
      })
    );

    setselectedFiles(files);
  }

  //console.log(selectedFiles)
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const preview = (file: any) => {
    setFiles(file);
  };

  const validationContactForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      percen: props.data.percen,
      x: props.data.x,
      id: props.data.id,
      text: props.data.text,
      name: props.data.name,
      img_name: props.data.img_name,
      limit_d: props.data.limit_d,
      limit_w: props.data.limit_w,
      //level_pro: data.data.level_pro,
      status_online: props.data.status_online,
      // end_date: data.data.end_date,
      //  start_date: data.data.start_date,
      // status_showpro: data.data.status_showpro,
      //status_add: data.data.status_add,
      //bank_dispodit: data.data.bank_dispodit,
      status_sow: props.data.status_sow,
      img_url: [],
    },
    validationSchema: Yup.object({
      // contact_reason_id: Yup.string().required('Please select contact reason'),
      // description_en: Yup.string().required('Description is required'),
      // file: Yup.mixed().required('Attachment file is required')
      //     .test('fileFormat', 'We accpet only images (.png, .jpeg, .gif) and videos (.mp4, .mov)', (value) => {
      //         return value && ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'video/quicktime'].includes(value.type);
      //     }),
    }),
    onSubmit: (values) => {
      const data = new FormData();
      data.append("percen", values.percen);
      data.append("x", values.x);
      data.append("text", values.text);
      data.append("name", values.name);
      data.append("limit_d", values.limit_d);
      data.append("limit_w", values.limit_w);
      data.append("id", values.id);
      // data.append("level_pro", values.level_pro);
      // data.append("end_date", values.end_date);
      // data.append("start_date", values.start_date);
      // data.append("status_showpro", values.status_showpro);
      // data.append("status_add", values.status_add);
      // data.append("bank_dispodit", values.bank_dispodit);
      data.append("status_online", values.status_online);
      data.append("status_sow", values.status_sow);

      data.append("img_url", selectedFiles[0]);
      ProMotionAPI.editPromotion(data)
        .then((res) => {
          if (res.data.success) {
            setSuccess(res.data.message);
            setTimeout(function () {
              setSuccess("");
              props.setdataall();
              //setShowloadingw(false);
            }, 1500);
          } else {
            //   setSuccessMessage("");
          }
        })
        .catch(function (error) {
          console.log("error ", error);
        });
    },
  });

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          validationContactForm.handleSubmit();
          return false;
        }}
      >
        <CardBody>
          <Row>
            <Col md={12} xl={12}>
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-sm-12 md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="form-Label form-label"
                        >
                          ชื่อโปรโมชั่น
                        </label>

                        <input
                          id="example-text-input"
                          type="text"
                          className="form-control form-control"
                          name="text"
                          placeholder="ชื่อโปรโมชั่น"
                          onChange={validationContactForm.handleChange}
                          onBlur={validationContactForm.handleChange}
                          value={validationContactForm.values.text}
                        />
                      </div>

                      {/* <div className="mb-3">
                                  <label
                                    htmlFor="exampleSelect"
                                    className="form-label"
                                  >
                                    แสดงหน้าเว็บ
                                  </label>

                                  <select
                                    name="status_showpro"
                                    id="status_showpro"
                                    className="form-select"
                                    value={
                                      validationContactForm.values.status_showpro
                                    }
                                    onChange={
                                      validationContactForm.handleChange
                                    }
                                  >
                                    <option value="แสดงหน้าเว็บ">
                                      แสดงหน้าเว็บ
                                    </option>
                                    <option value="ไม่แสดงหน้าเว็บ">
                                      ไม่แสดงหน้าเว็บ
                                    </option>
                                    <option value="แสดงหน้าเว็บ+ปุ่มกดรับเครดิต">
                                      แสดงหน้าเว็บ+ปุ่มกดรับเครดิต
                                    </option>
                                  </select>
                                </div> */}

                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="form-Label form-label"
                        >
                          รายละเอียดเกี่ยวกับโปรโมชั่น
                        </label>

                        <input
                          id="example-text-input"
                          type="text"
                          className="form-control form-control"
                          name="name"
                          placeholder="รายละเอียดเกี่ยวกับโปรโมชั่น"
                          onChange={validationContactForm.handleChange}
                          onBlur={validationContactForm.handleChange}
                          value={validationContactForm.values.name}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="form-Label form-label"
                        >
                          จำนวนเปอร์เซนที่ได้รับ
                        </label>

                        <input
                          id="example-text-input"
                          type="number"
                          className="form-control form-control"
                          // onChange={inputvalue("percen")}
                          name="percen"
                          onChange={validationContactForm.handleChange}
                          onBlur={validationContactForm.handleChange}
                          value={validationContactForm.values.percen}
                          placeholder="จำนวนเปอร์เซนที่ได้รับ อย่างเช่น 2 = x2"
                          // value={percen}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="form-Label form-label"
                        >
                          จำนวนเทริน
                        </label>

                        <input
                          id="example-text-input"
                          type="number"
                          className="form-control form-control"
                          onChange={validationContactForm.handleChange}
                          onBlur={validationContactForm.handleChange}
                          value={validationContactForm.values.x}
                          name="x"
                          placeholder="จำนวนเทริน ที่ต้องทำให้ถึง "
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="form-Label form-label"
                        >
                          เครดิต รับได้ไม่เกิน
                        </label>

                        <input
                          id="example-text-input"
                          type="number"
                          className="form-control form-control"
                          // onChange={inputvalue("x")}
                          name="limit_d"
                          placeholder="จำนวน เครดิต รับได้ไม่เกิน "
                          onChange={validationContactForm.handleChange}
                          onBlur={validationContactForm.handleChange}
                          value={validationContactForm.values.limit_d}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="form-Label form-label"
                        >
                          จำนวน ถอนได้สูงสุด
                        </label>

                        <input
                          id="example-text-input"
                          type="number"
                          className="form-control form-control"
                          // onChange={inputvalue("x")}
                          name="limit_w"
                          placeholder="จำนวน ถอนได้สูงสุด "
                          onChange={validationContactForm.handleChange}
                          onBlur={validationContactForm.handleChange}
                          value={validationContactForm.values.limit_w}
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="form-Label form-label"
                        >
                          status_online
                        </label>

                        <select
                          className="form-select"
                          name="status_online"
                          value={validationContactForm.values.status_online}
                          onChange={validationContactForm.handleChange}
                        >
                          <option>เลือก status_online</option>
                          <option value={"online"}>online</option>
                          <option value={"offonline"}>offonline</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* <Col md={12} xl={12}>
                        <Card>
                          <img className="card-img-top img-fluid" alt="" />
                          <CardBody>
                            <h4 className="card-title">รูปภาพโปรโมชั่น</h4>
                            

                            
                          </CardBody>
                        </Card>
                      </Col> */}

            <div className="col-12">
              {success && (
                <>
                  <Alert variant="success" className={""}>
                    {success}
                  </Alert>
                </>
              )}
              <div className="md-3">
                <div className="">
                  <div className="product-image">
                    <div className="d-flex flex-row justify-content-center mg-b-20 ">
                      <div className="pd-10 bg-gray-200">
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleAcceptedFiles(acceptedFiles);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <div className="mx-auto">
                                {" "}
                                <div className="">
                                  <div className="product-image">
                                    {/* <img
                                      data-dz-thumbnail=""
                                      height=""
                                      className=""
                                      alt={validationContactForm.values.img_url}
                                      src={validationContactForm.values.img_url}
                                    /> */}
                                    <div className="btn btn-success">
                                      แก้ไขรูป
                                    </div>
                                    {selectedFiles.map((f: any, i: number) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none  dz-processing dz-image-preview dz-success dz-complete"
                                          key={i + "-file"}
                                        >
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col className="col-auto ">
                                                <img
                                                  data-dz-thumbnail=""
                                                  height=""
                                                  className=""
                                                  alt={f.name}
                                                  src={f.preview}
                                                />
                                              </Col>
                                              <Col></Col>
                                            </Row>
                                          </div>
                                        </Card>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-example mt-2 mt-sm-0">
                <button className="btn btn-info w-100" type="submit">
                  ยืนยันแก้ไข
                </button>
              </div>
            </div>
          </Row>
        </CardBody>
      </form>
    </>
  );
};
export default Edit_pro;
