import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@mui/material";
import { Breadcrumb, Card, InputGroup, FormGroup } from "react-bootstrap";
import Draggable from "react-draggable";
import React, { useState, useReducer } from "react";
import DatePicker from "react-datepicker";
import { Container, UncontrolledAlert } from "reactstrap";
import Moment from "moment";
import "moment/locale/th";
import {
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import Apibank from "../../../../api/APIbank";
import Apiscb from "../../../api/Apiscb";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../../shade/Loaders/Loaders";
export function MyVerticallyCenteredModal(props: any) {
  const [error, setError] = useState(false);
  const [error_n, setError_n] = useState(false);
  const [errorsv1, setErrorsv1] = useState(false);
  const [sacelood, setsacelood] = useState(false);
  const [success, setsuccess] = useState(false);
  const [successs, setsuccesss] = useState(false);
  const [successs1, setsuccesss1] = useState(false);
  const [successss, setsuccessss] = useState("");
  const [successssz, setsuccessssz] = useState("");

  const [successs_otp, setsuccesss_otp] = useState(false);
  const [successs_otp1, setsuccesss_otp1] = useState(true);

  const [loader_maxky, setLoader_maxky] = useState(false);
  const [Singleselect, setSingleselect] = useState<any>("");
  const [statepost, setStatepost] = useState({
    acc_num: "",
    name: "",
    username: "",
    password: "",
    bankname: "",
    id: "",
    option: "",
    dateOfBirth: "",
    cardId: "",
    phone_ito: "",
    phone_ito2: "088xxxxxx",
    otp: "",
    // option: "",
  });

  const {
    bankname,
    acc_num,
    password,
    username,
    name,
    phone_ito,
    option,
    cardId,
    dateOfBirth,
    phone_ito2,
    otp,
  } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  console.log(props);

  const addBank = (e: any) => {
    e.preventDefault();
    const postData = {
      accnum: acc_num,
      name: name,
      username: username,
      password: password,
      from_b: bankname,
      option_b: option,
      // otp:""
    };
    setsacelood(true);
    if (postData.name == "") {
      setError(true);
      setsacelood(false);
      setTimeout(function () {
        setError(false);
      }, 3500);
    } else if (postData.accnum == "") {
      setError(true);
      setsacelood(false);
      setTimeout(function () {
        setError(false);
      }, 3500);
    } else if (postData.from_b == "") {
      setError(true);
      setsacelood(false);
      setTimeout(function () {
        setError(false);
      }, 3500);
    } else if (postData.option_b == "") {
      setError(true);
      setsacelood(false);
      setTimeout(function () {
        setError(false);
      }, 3500);
    } else {
      Apibank.CrateBankonline(postData)
        .then((res) => {
          if (res.data.success) {
            setsacelood(false);
            setsuccess(true);
            setTimeout(function () {
            
              props.onHide();
              props.setdata2();
              //setsuccess(true);
              // window.location.reload();
            }, 3500);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    { data: "" }
  );

  const confirm_otp = (e: any) => {
    e.preventDefault();

    let datapost = {
      Otp: otp,
      pin: password,
      tokenUUID: state.data.ref,
      Auth: state.data.Auth,
      deviceId: "",
      MobilePhoneNo: phone_ito,
    };
    Apiscb.confirmotps(datapost)
      .then((res) => {
        console.log(res.data);
        // if (res.data.success == true) {
        //   setLoader_maxky(false);

        //   if (res.data.data.status == 200) {
        //     setState({
        //       data: res.data.data,
        //     });
        //     setsuccesss_otp1(false)
        //     setError_n(true);
        //     setLoader_maxky(false);
        //     setsuccesss_otp(true)

        //   }

        // } else {

        //   setLoader_maxky(false);
        //   setsuccessss(res.data.data);
        // }
      })
      .catch((err) => {
        console.log(err);
      });

    //console.log(datapost)
  };
  const checkmobile = (e: any) => {
    e.preventDefault();
    setLoader_maxky(true);

    let datapost = {
      cardId: cardId,
      dateOfBirth: dateOfBirth,
      acc_num: acc_num,
      MobilePhoneNo: phone_ito,
    };

    Apiscb.chack_GetmobileNo(datapost)
      .then((res) => {
        //console.log();
        if (res.data.success == true) {
          setLoader_maxky(false);

          if (res.data.data.status == 200) {
            setState({
              data: res.data.data,
            });
            setsuccesss_otp1(false);
            setError_n(true);
            setLoader_maxky(false);
            setsuccesss_otp(true);
          }
        } else {
          setLoader_maxky(false);
          setsuccessss(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // setTimeout(function () {
    //   setLoader_maxky(false);
    //   // window.location.reload();
    // }, 3500);
  };
  //const options = state;
  // console.log(state.data);
  // console.log(state)
  const setgen = () => {
    setsuccesss(!successs);

    Apiscb.gettranscbtagnumber()
      .then((res) => {
        if (res.data.data.data == null) {
          //  setsuccessss("กรุณาต่ออินเตอร์เน็ต จากซิมที่ใช้เปิดบัญชี");
        } else {
          // setsuccessssz(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>เพิ่มรายการ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {successs && (
          <>
            {error_n && (
              <>
                {successs_otp && (
                  <>
                    <Row>
                      <Col
                        xl={12}
                        sm={12}
                        xs={12}
                        lg={12}
                        md={12}
                        className="card-sigin-main py-4 justify-content-center mx-auto"
                      >
                        <div className="card-sigin">
                          {/* <!-- Demo content--> */}
                          <div className="main-card-signin d-md-flex">
                            <div className="wd-100p">
                              <div className="d-flex mx-auto"> </div>
                              <div className="main-card-signin d-md-flex bg-white">
                                <div className="p-4 wd-100p">
                                  <div className="main-signin-header">
                                    <div className="avatar avatar-xxl avatar-xxl mx-auto text-center mb-2">
                                      <img
                                        alt=""
                                        className="avatar avatar-xxl rounded-circle  mt-2 mb-2 "
                                        src={require("../../../../assets/img/faces/6.jpg")}
                                      />
                                    </div>
                                    <div className="mx-auto text-center mt-4 mg-b-20">
                                      <h5 className="mg-b-10 tx-16">
                                        #CODE = {state.data?.msg}{" "}
                                      </h5>
                                      <h5 className="mg-b-10 tx-16">
                                        #REF = {state.data?.ref}{" "}
                                      </h5>
                                      <p className="tx-13 text-muted">
                                        กรุณาต่อ ยืนยัน OTP
                                      </p>
                                    </div>
                                    <Form onSubmit={confirm_otp}>
                                      <FormGroup className="form-group">
                                        <Form.Control
                                          className="form-control"
                                          placeholder="PIN เข้าสู่ระบบ SCB"
                                          type="number"
                                          required
                                          name="password"
                                          value={password}
                                          onChange={inputvalue("password")}
                                        />
                                        <Form.Control
                                          className="form-control"
                                          placeholder="ยืนยัน OTP "
                                          type="number"
                                          required
                                          name="otp"
                                          value={otp}
                                          onChange={inputvalue("otp")}
                                        />
                                      </FormGroup>
                                      <Button
                                        className="btn btn-primary btn-block"
                                        type="submit"
                                      >
                                        ยืนยัน
                                      </Button>
                                    </Form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}

            {!error_n && (
              <>
                {successss != "" && (
                  <>
                    {" "}
                    <div
                      role="alert"
                      className="fade alert fade show alert-dismissible alert-danger alert show"
                    >
                      {" "}
                      <strong>
                        <h3 className="text-center text-danger">{successss}</h3>
                      </strong>{" "}
                      <button type="button" className="btn-close btn">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>{" "}
                  </>
                )}

                {successs_otp1 && (
                  <>
                    <form onSubmit={checkmobile}>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="name" className="form-label">
                            เลขบัญชี
                          </label>
                          <input
                            name="acc_num"
                            id="acc_num"
                            placeholder="เลขบัญชี"
                            type="number"
                            required
                            value={acc_num}
                            onChange={inputvalue("acc_num")}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="name" className="form-label">
                            เบอร์โทรศัพมือถือ
                          </label>
                          <input
                            name="phone_ito"
                            id="phone_ito"
                            placeholder="เบอร์โทรศัพมือถือ ที่เชื่อมต่อกับธนาคาร"
                            type="number"
                            required
                            value={phone_ito}
                            onChange={inputvalue("phone_ito")}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="name" className="form-label">
                            เลขบัตรประชาชน
                          </label>
                          <input
                            name="cardId"
                            id="name"
                            placeholder="เลขบัตรประชาชน"
                            type="name"
                            required
                            value={cardId}
                            onChange={inputvalue("cardId")}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleAddress"
                            className="form-label"
                          >
                            ปี/เดือน/วัน วันเกิด(ค.ศ.)
                          </label>
                          <input
                            name="dateOfBirth"
                            id="dateOfBirth"
                            placeholder="1991-01-31"
                            type="text"
                            required
                            value={dateOfBirth}
                            onChange={inputvalue("dateOfBirth")}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <button className="btn btn-block btn-info w-50 mx-auto">
                        ดำเนินการ
                      </button>
                    </form>
                  </>
                )}

                {/* setotp */}
              </>
            )}

            {loader_maxky && (
              <>
                <Loader />
              </>
            )}
          </>
        )}

        {errorsv1 && <>{successssz}</>}

        {!successs && (
          <>
            {!successs1 && (
              <>
                <form className="" onSubmit={addBank}>
                  <div className="mb-3">
                    <label htmlFor="exampleSelect" className="form-label">
                      เลือกธนาคาร
                    </label>
                    <select
                      name="select"
                      id="exampleSelect"
                      className="form-select"
                      value={bankname}
                      onChange={inputvalue("bankname")}
                    >
                      <option>เลือกธนาคาร</option>
                      <option value={"scb"}>ธนาคาร ไทยพาณิชย์</option>
                      <option value={"kbank"}>ธนาคาร กสิกรไทย</option>
                      <option value={"truewallet"}>Truemoney Wallet</option>
                      <option value={"bonus"}>บัญชี bonus</option>
                    </select>
                  </div>
                  {success && (
                    <>
                      <UncontrolledAlert color="success">
                        <h4 className="alert-heading">เพิ่มบัญชี!</h4>
                        <p>เพิ่มบัญชี ธนาคาร สำเร็จ !!</p>
                        <hr />
                        <p className="mb-0">
                          You must not fill in the deposit account completely.
                        </p>
                      </UncontrolledAlert>
                    </>
                  )}
                  {error && (
                    <>
                      <UncontrolledAlert color="danger">
                        <h4 className="alert-heading">เกิดข้อผิดพลาด!</h4>
                        <p>คุณกรอกข้อมูล การเพิ่มบัญชี ธนาคารไม่ครบถ้วน</p>
                        <hr />
                        <p className="mb-0">
                          You must not fill in the deposit account completely.
                        </p>
                      </UncontrolledAlert>
                    </>
                  )}

                  {statepost.bankname == "scb" && (
                    <>
                      <img
                        src="https://ufaapiv1.myshadownow.com/images/bank3/scb.png"
                        alt=""
                        width={80}
                        className="w-20 mx-auto"
                      />
                      <div className="col-md-12 mt-5">
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            เลขบัญชี
                          </label>
                          <input
                            name="acc_num"
                            id="acc_num"
                            placeholder="เลขบัญชี"
                            type="text"
                            className="form-control"
                            value={acc_num}
                            onChange={inputvalue("acc_num")}
                          />
                          <input
                            name="bank_from"
                            id="bank_from"
                            type="hidden"
                            className="form-control"
                            value={"scb"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="name" className="form-label">
                            ชื่อบัญชี
                          </label>
                          <input
                            name="name"
                            id="name"
                            placeholder="ชื่อบัญชี"
                            type="name"
                            value={name}
                            onChange={inputvalue("name")}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleAddress"
                            className="form-label"
                          >
                            DeviceID
                          </label>
                          <input
                            name="username"
                            id="username"
                            placeholder="DeviceID"
                            type="text"
                            value={username}
                            onChange={inputvalue("username")}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            PIN
                          </label>
                          <input
                            name="password"
                            id="exampleAddress2"
                            placeholder="PIN"
                            value={password}
                            onChange={inputvalue("password")}
                            type="password"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mx-auto">
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            ประเภท
                          </label>
                          <select
                            name="option"
                            id="exampleSelect"
                            className="form-select"
                            value={option}
                            onChange={inputvalue("option")}
                          >
                            <option>เลือก ประเภท</option>
                            <option value={"ฝาก"}>ฝาก</option>
                            <option value={"ถอน"}>ถอน</option>
                          </select>
                        </div>
                        {!sacelood && (
                          <>
                            <Button
                              variant=""
                              type="submit"
                              className="mb-2 me-2 btn-icon btn-shadow btn-dashed btn btn-primary w-50 mx-auto mt-2"
                            >
                              เพิ่มต่อบัญชี
                            </Button>
                          </>
                        )}

                        <div className="row">
                          <div className="col-4"></div>
                          <div className="col-3">
                            {/* {sacelood && (
                              <>
                                <img
                                  src="https://test1.myshadownow.com/images/loaderNew.svg"
                                  alt=""
                                  className="mamxcan grid-example mt-2 mt-sm-0"
                                />{" "}
                                <p className="text-center">กำลังโหลด..</p>{" "}
                              </>
                            )} */}
                                 <button
                          type="submit"
                          className="mb-2 me-2 btn-icon btn-shadow btn-dashed btn btn-primary w-100 mx-auto mt-2"
                        >
                          <i className="pe-7s-tools btn-icon-wrapper"> </i>
                          เพิ่มบัญชี ธนาคาร
                        </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {statepost.bankname == "truewallet" && (
                    <>
                      <img
                        src="https://friend420-support.com/assets/img/bank3/true.png"
                        alt=""
                        width={80}
                        className="w-20 mx-auto"
                      />
                      <div className="col-md-12 mt-5">
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            เบอร์โทร trueMoneyWallet
                          </label>
                          <input
                            name="acc_num"
                            id="acc_num"
                            placeholder="ตัวอย่าง 0887522887"
                            type="number"
                            className="form-control"
                            value={acc_num}
                            onChange={inputvalue("acc_num")}
                          />
                          <input
                            name="bank_from"
                            id="bank_from"
                            type="hidden"
                            className="form-control"
                            value={"truewallet"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="name" className="form-label">
                            ชื่อ
                          </label>
                          <input
                            name="name"
                            id="name"
                            placeholder="ชื่อเจ้าของบัญชี trueMoneyWallet"
                            type="name"
                            value={name}
                            onChange={inputvalue("name")}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleAddress"
                            className="form-label"
                          >
                            JWTTOKEN
                          </label>
                          <input
                            name="username"
                            id="username"
                            placeholder="ได้จากแอพ trueMoneyWallet"
                            type="text"
                            value={username}
                            onChange={inputvalue("username")}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            PIN
                          </label>
                          <input
                            name="password"
                            id="exampleAddress2"
                            placeholder="PIN"
                            value={password}
                            onChange={inputvalue("password")}
                            type="password"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            ประเภท
                          </label>
                          <select
                            name="option"
                            id="exampleSelect"
                            className="form-select"
                            value={option}
                            onChange={inputvalue("option")}
                          >
                            <option>เลือก ประเภท</option>
                            <option value={"ฝาก"}>ฝาก</option>
                          </select>
                        </div>

                        <button
                          type="submit"
                          className="mb-2 me-2 btn-icon btn-shadow btn-dashed btn btn-primary w-50 mx-auto mt-2"
                        >
                          เพิ่มบัญชี ธนาคาร
                        </button>
                      </div>
                    </>
                  )}
                  {statepost.bankname == "bonus" && (
                    <>
                      <h3 className="text-center text-success">
                        {" "}
                        บัญชี โบนัส{" "}
                      </h3>
                      <div className="col-md-12 mt-5">
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            เลขบัญชี
                          </label>
                          <input
                            name="acc_num"
                            id="acc_num"
                            placeholder="เลขบัญชี"
                            type="text"
                            className="form-control"
                            value={acc_num}
                            onChange={inputvalue("acc_num")}
                          />
                          <input
                            name="bank_from"
                            id="bank_from"
                            type="hidden"
                            className="form-control"
                            value={"scb"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="name" className="form-label">
                            ชื่อบัญชี
                          </label>
                          <input
                            name="name"
                            id="name"
                            placeholder="ชื่อบัญชี"
                            type="name"
                            value={name}
                            onChange={inputvalue("name")}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleAddress"
                            className="form-label"
                          ></label>
                          <input
                            name="username"
                            id="username"
                            placeholder="DeviceID"
                            type="text"
                            value={username}
                            onChange={inputvalue("username")}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="mb-3">
                          <input
                            name="password"
                            id="exampleAddress2"
                            placeholder="PIN"
                            value={password}
                            defaultValue={"5555"}
                            onChange={inputvalue("password")}
                            type="password"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <select
                            name="option"
                            id="exampleSelect"
                            className="form-select"
                            value={option}
                            onChange={inputvalue("option")}
                          >
                            <option value={"บัญชีโบนัส"}>บัญชีโบนัส</option>
                            <option value={"ฝาก"}>ฝาก</option>
                            <option value={"ถอน"}>ถอน</option>
                          </select>
                        </div>

                        <button
                          type="submit"
                          className="mb-2 me-2 btn-icon btn-shadow btn-dashed btn btn-primary w-100 mx-auto mt-2"
                        >
                          <i className="pe-7s-tools btn-icon-wrapper"> </i>
                          เพิ่มบัญชี ธนาคาร
                        </button>
                      </div>
                    </>
                  )}
                  {statepost.bankname == "kbank" && (
                    <>
                      <img
                        src="https://ufaapiv1.myshadownow.com/images/bank3/kbank.png"
                        alt=""
                        width={80}
                        className="w-20 mx-auto"
                      />
                      <div className="col-md-12 mt-5">
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            เลขบัญชี
                          </label>
                          <input
                            name="acc_num"
                            id="acc_num"
                            placeholder="เลขบัญชี"
                            type="text"
                            className="form-control"
                            value={acc_num}
                            onChange={inputvalue("acc_num")}
                          />
                          <input
                            name="bank_from"
                            id="bank_from"
                            type="hidden"
                            className="form-control"
                            value={"scb"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="name" className="form-label">
                            ชื่อบัญชี
                          </label>
                          <input
                            name="name"
                            id="name"
                            placeholder="ชื่อบัญชี"
                            type="name"
                            value={name}
                            onChange={inputvalue("name")}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleAddress"
                            className="form-label"
                          >
                            DeviceID
                          </label>
                          <input
                            name="username"
                            id="username"
                            placeholder="DeviceID"
                            type="text"
                            value={username}
                            onChange={inputvalue("username")}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            PIN
                          </label>
                          <input
                            name="password"
                            id="exampleAddress2"
                            placeholder="PIN"
                            value={password}
                            onChange={inputvalue("password")}
                            type="password"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            ประเภท
                          </label>
                          <select
                            name="option"
                            id="exampleSelect"
                            className="form-select"
                            value={option}
                            onChange={inputvalue("option")}
                          >
                            <option>เลือก ประเภท</option>
                            <option value={"ฝาก"}>ฝาก</option>
                            <option value={"ถอน"}>ถอน</option>
                          </select>
                        </div>

                        <button
                          type="submit"
                          className="mb-2 me-2 btn-icon btn-shadow btn-dashed btn btn-primary w-100 mx-auto mt-2"
                        >
                          <i className="pe-7s-tools btn-icon-wrapper"> </i>
                          เพิ่มบัญชี ธนาคาร
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </>
            )}
          </>
        )}
      </Modal.Body>
      {/* <ToastContainer /> */}
      <Modal.Footer>
        <Button className="modal-effect  d-grid mb-3" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function VerticallyCenteredModal(props: any) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button
        className="btn ripple btn-primary"
        variant="danger"
        onClick={() => setModalShow(true)}
      >
        เพิ่ม ธนาคารฝาก-ถอน
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        setdata2={props.setdata}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
