import React from "react";
import { useEffect, useRef, useReducer, useState, useMemo } from "react";
import {
  Breadcrumb,
  Card,
  Col,
  Row,
  Button,
  Tabs,
  Form,
  Tab,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  OverlayTrigger,
  Nav,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { Modal, FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { getUser } from "@Config/authsave";
import { Scrollbars } from "react-custom-scrollbars";
import Apibank from "@api/APIbank";
const Modal_msg = (props: any) => {
  // console.log(props);
  const scrollbarRef = useRef<any>(null); // Specify the type here

  let data_admin = getUser();
  const [chosenEmoji, setChosenEmoji]: any = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [showPickermsg, setShowPickermsg] = useState("");
  const [chatInput, setChatInput] = useState("");

  // ... (your existing code)
  useEffect(() => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollToBottom(); // This should work now
    }
  });

  const handleEmojiSelect = (emoji: any) => {
    // console.log(emoji);
    if (chatInput) {
      setShowPicker(true);
    } else {
      setShowPicker(false);
    }
    setChatInput(emoji);
  };
  const onEmojiClick = (event: any, emojiObject: any) => {
    setChosenEmoji(emojiObject);
  };

  const sendMessage = (datachatInput: any) => {
    setShowPickermsg(datachatInput);

    let datareturn = {
      messages_return: datachatInput,
      replyToken: props?.dataall?.replyToken,
      id: props?.dataall?.id,
    };

    Apibank.sendMessage_return(datareturn).then((res) => {
      if (res.data.success == true) {
      } else {
      }
      console.log(res.data);
    });

    setChatInput("");

    // console.log(datachatInput);
  };

  // console.log(chatInput);

  return (
    <>
      <Modal
        show={props.remove_msg}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="panel panel-primary tabs-style-2">
          <div className=" tab-menu-heading">
            <div className="tabs-menu1">
              <div className="main-content-body main-content-body-chat">
                <div className="main-chat-header">
                  <div className="main-img-user">
                    <img alt="" src={props?.dataall?.line_pictureUrl} />
                  </div>
                  <div className="main-chat-msg-name">
                    <h6>{props?.dataall?.line_displayName}</h6>
                    <small>{props?.dataall?.created_at}</small>
                  </div>
                  <Nav className="nav">
                    <Link className="nav-link" to="#">
                      <i className="icon ion-md-more"></i>
                    </Link>
                    <button
                      onClick={() => props.setFullscreen(false)}
                      className="btn btn-danger"
                    >
                      <i className="fas fa-times"></i>
                    </button>
                    {/* <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Trash</Tooltip>}
                      >
                        <i className="nav-link fe fe-trash"></i>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>View Info</Tooltip>}
                      >
                        <i className="nav-link fe fe-alert-circle"></i>
                      </OverlayTrigger> */}
                  </Nav>
                </div>
                {/* <!-- main-chat-header --> */}
                <div className="main-chat-body" id="ChatBody">
                  <Scrollbars
                    style={{ height: "400px" }}
                    ref={scrollbarRef}
                    className="tHuMbY"
                  >
                    <div className="content-inner">
                      <label className="main-chat-time">
                        <span>เมื่อ {props?.dataall?.created_at}</span>
                      </label>
                      <div className="media">
                        <div className="main-img-user online">
                          <img src={props?.dataall?.line_pictureUrl} />
                        </div>
                        <div className="media-body">
                          <div className="main-msg-wrapper left">
                            <img
                              src={props?.dataall?.line_images}
                              style={{
                                width: "auto",
                                minWidth: 64,
                                height: 274,
                                OObjectFit: "cover",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                          <div>
                            <span>{props?.dataall?.created_at}</span>{" "}
                          </div>
                        </div>
                      </div>

                      {chatInput && (
                        <>
                          <div className="media flex-row-reverse">
                            <div className="main-img-user online">
                              <img
                                alt=""
                                src={
                                  !data_admin.profile_photo
                                    ? require(`../../../assets/img/admin/avatar.png`)
                                    : data_admin.profile_photo
                                }
                              />
                            </div>
                            <div className="media-body text-success">
                              <div className="lds-facebook">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              กำลังพิม...{" "}
                            </div>
                          </div>
                        </>
                      )}

                      {showPickermsg && (
                        <>
                          <div className="media flex-row-reverse">
                            <div className="main-img-user online">
                              <img
                                alt=""
                                src={
                                  !data_admin.profile_photo
                                    ? require(`../../../assets/img/admin/avatar.png`)
                                    : data_admin.profile_photo
                                }
                              />
                            </div>
                            <div className="media-body text-success">
                              <div className="media-body">
                                <div className="main-msg-wrappermax right">
                                  {showPickermsg ? <>{showPickermsg}</> : <></>}{" "}
                                </div>
                                <div>
                                  <span>ส่งเมื่อ 11:22 am</span>{" "}
                                  <a href="/nowa/preview/pages/mail/chat/">
                                    <i className="icon ion-android-more-horizontal" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {props?.dataall?.messages_return && (
                        <>
                          <div className="media flex-row-reverse">
                            <div className="main-img-user online">
                              <img
                                alt=""
                                src={
                                  !data_admin.profile_photo
                                    ? require(`../../../assets/img/admin/avatar.png`)
                                    : data_admin.profile_photo
                                }
                              />
                            </div>
                            <div className="media-body text-success">
                              <div className="media-body">
                                <div className="main-msg-wrappermax right">
                                  {props?.dataall?.messages_return}
                                </div>
                                <div>
                                  <span>ส่งเมื่อ {props?.dataall?.updated_at}</span>{" "}
                                  <a href="/nowa/preview/pages/mail/chat/">
                                    <i className="icon ion-android-more-horizontal" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </Scrollbars>
                </div>

                {props?.dataall?.replyToken && (
                  <>
                    <div className="main-chat-footer">
                      <Nav className="nav"></Nav>
                      <input
                        className="form-control"
                        placeholder="Type your message here..."
                        type="text"
                        value={chatInput}
                        onChange={(e) => handleEmojiSelect(e.target.value)}
                      />
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Add Emoticons</Tooltip>}
                      >
                        <i className="nav-link me-2 fe fe-paperclip"></i>
                      </OverlayTrigger>
                      <Link
                        className="main-msg-send"
                        to="#"
                        onClick={() => sendMessage(chatInput)}
                      >
                        <i className="fe fe-send"></i>
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <Modal.Header closeButton>
          <Modal.Title
            as="h6"
            className="modal-title"
            id="contained-modal-title-vcenter"
          ></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => props.setFullscreen(false)}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default Modal_msg;
