import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Row,
} from "react-bootstrap";
import Apisetting from "../../../api/Apisetting";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Data_setting2 = (data: any) => {
  const [statepost, setStatepost] = useState({
    
      "loggo_url": "https://api_ufav5.vvipcorp.org/storage/qr/1681559746382-Logo-UFA338plus_350x1002.png",
      "id": 1,
      "name_web": data.data.name_web,
      "line_support": data.data.line_support,
      "line_support2":  data.data.line_support2,
      "bg_treem": null,
      "deleted_at": null,
      "created_at": null,
      "updated_at": null
  
  });

    console.log(data)

  const {
    line_support,
    line_support2,
    name_web,
   
  } = statepost;
  const inputvalue = (name: any) => (event: any) => {
    setStatepost({ ...statepost, [name]: event.target.value });
  };

  const post_settingweb = (e: any) => {
    e.preventDefault();
    let datapost = {
      line_support: line_support,
      line_support2: line_support2,
      name_web: name_web,
     
      
    };

    Apisetting.Postupdate_settings_webfontend(datapost)
      .then((res) => {
       
        if(res.data.success == true){
          toast.success("แก้ไข  สำเร็จ ... ", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        // setTimeout(function () {

        // }, 5500);

        //
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Form className="form-horizontal" onSubmit={post_settingweb}>
        <FormGroup className="form-group">
          <Form.Label className="form-label">LINE @ ช่องทางการติดต่อ หลัก</Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            placeholder="line_support"
            name="line_support"
            value={line_support}
            onChange={inputvalue("line_support")}
            defaultValue="line_support"
          />
        </FormGroup>
        <FormGroup className="form-group">
          <Form.Label className="form-label">
          LINE @ ช่องทางการติดต่อ สำรอง
          </Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            placeholder="ชื่อเว็บไซต์"
            name="line_support2"
            value={line_support2}
            onChange={inputvalue("line_support2")}
            defaultValue="ชื่อเว็บไซต์"
          />
        </FormGroup>
        <FormGroup className="form-group">
          <Form.Label className="form-label">
           ชื่อเว็บไซต์
          </Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            placeholder="ชื่อเว็บไซต์"
            name="name_web"
            value={name_web}
            onChange={inputvalue("name_web")}
            defaultValue="name_web"
          />
        </FormGroup>
        
      <ToastContainer/>

        <FormGroup className="form-group mb-0 mt-3 justify-content-end">
          <div>
            <Button
              variant=""
              type="submit"
              className="btn btn-secondary btn-block w-50 mx-auto"
            >
              บันทึก
            </Button>
          </div>
        </FormGroup>
      </Form>
    </>
  );
};
export default Data_setting2;
