import Apisetting from "@api/Apisetting";
import { Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "@Config/authsave";

const Chacklogin_web = () => {
  const navigate = useNavigate();

  const [dataMmber, setDataMmber]: any = useState("");

  //   useEffect(() => {
  //     // const messaging = getToken();

  const AuthLogins = "";

  useEffect(() => {
    Apisetting.chack_loginv2()
      .then((res) => {
        if (res.data.succss == true) {
          setDataMmber(res.data.data);
        }

        // console.log(res.data)
      })
      .catch((e) => {
        navigate("/");
        logout();
      });

    // console.log(messaging)
  }, [dataMmber, navigate]);

  return <></>;
};

export default Chacklogin_web;
